import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useBooksellerContentDataStore } from '../data/BooksellerContentDataStore';
import { Translate, withLocalize } from 'react-localize-redux';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../paths';
import stackedBooksImage from '../../assets/images/stacked_books.png';
import './signUpModal.less';

SignUpModal.propTypes = {
	doRenderLearnMoreButton: PropTypes.bool,
	isVisibleAtRender: PropTypes.bool,
	translate: PropTypes.func,
};

SignUpModal.defaultProps = {
	doRenderLearnMoreButton: true,
	isVisibleAtRender: true,
};

function SignUpModal({
	doRenderLearnMoreButton,
	isVisibleAtRender,
	translate,
}) {
	const { state } = useBooksellerContentDataStore();
	const { retailerMessageBody } = state.customerApp;
	const location = useLocation();
	const isAuthPage = paths.isAuthPage(location.pathname);
	const [isVisible, setIsVisible] = React.useState(
		isVisibleAtRender && !isAuthPage && !retailerMessageBody.length > 0,
	);
	const benefitsListItems = [
		{ translationId: 'create_a_wishlist' },
		{ translationId: 'add_to_your_favorites_collection' },
		{ translationId: 'get_better_title_recommendations' },
		{ translationId: 'view_your_reviews_and_past_purchases' },
		{ translationId: 'save_your_order_preferences_for_next_time' },
	];

	return (
		<>
			{doRenderLearnMoreButton && (
				<Button
					onClick={() => setIsVisible(true)}
					className="learn-more-button">
					<Translate id="learn_more" />
				</Button>
			)}
			<Modal
				className="sign-up-modal"
				closable={false}
				width={480}
				onCancel={() => setIsVisible(false)}
				title={<Translate id="sign_up_for_a_free_account" />}
				visible={isVisible}
				footer={null}>
				<div className="sign-up-modal-container">
					<div className="sign-up-modal-header">
						<img
							src={stackedBooksImage}
							alt={translate(
								'a_colorful_illustration_of_several_books',
							)}
						/>
						<Translate id="sign_up_and_start_personalizing_your_bookstore_experience" />
					</div>
					<ul>
						{benefitsListItems.map((item, idx) => (
							<li key={idx}>
								<Translate id={item.translationId} />
							</li>
						))}
					</ul>
					<Button className="sign-up-button" href={paths.signup()}>
						<Translate id="sign_up" />
					</Button>
					<Button onClick={() => setIsVisible(false)}>
						<Translate id="no_thanks_uppercase" />
					</Button>
					<div className="login-link-container">
						<Translate id="already_have_an_account" />
						<Link to={paths.login()}>
							<Translate id="log_in" />
						</Link>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default withLocalize(SignUpModal);
