import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from '../../../api';
import TitleAggregateParser from '../../../title/TitleAggregateParser';
import { connectToCustomerID } from '../../data/CustomerIDStore';

const CollectionContext = React.createContext();

export class CollectionStore extends React.Component {
	static propTypes = {
		// react router props
		match: PropTypes.object,
		history: PropTypes.object,
		location: PropTypes.object,
		// customer state props
		customerState: PropTypes.object,
		// passed in from parent
		children: PropTypes.func,
	};

	static connect = (Component, mapToProps) => {
		return props => {
			return (
				<CollectionContext.Consumer>
					{collectionStore => {
						const store = collectionStore;

						if (typeof mapToProps === 'function') {
							const storeProps = mapToProps(store);
							return <Component {...storeProps} {...props} />;
						}

						return <Component collectionStore={store} {...props} />;
					}}
				</CollectionContext.Consumer>
			);
		};
	};

	state = {
		titles: [],
		catalog: {},
		isLoading: true,
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<CollectionContext.Provider value={dataStore}>
				{this.props.children(dataStore)}
			</CollectionContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchCollection().then(this.handleResponse);
	}

	getDataStore = (state = this.state) => ({
		state,
		getCatalog: () => state.catalog,
	});

	fetchCollection = () => {
		const { match, customerState: c } = this.props;
		const endpoint = `/org/${c.orgId}/catalogs/${match.params.catalogId}/titles`;
		return api.get(endpoint);
	};

	handleResponse = aggregateResponse => {
		const parser = new TitleAggregateParser(aggregateResponse);
		const catalog = this.getCatalogFromAggregate(aggregateResponse);

		const titles = parser.buildTitles();
		this.setState({ titles, catalog, isLoading: false });
	};

	getCatalogFromAggregate(aggregateResponse) {
		if (aggregateResponse.catalog) {
			return aggregateResponse.catalog;
		}
		return {};
	}
}

export default connectToCustomerID(withRouter(CollectionStore));
