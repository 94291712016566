import React from 'react';
import PropTypes from 'prop-types';
import TitleScroller from '../TitleLanes/TitleScroller';
import LaneContainer, { SeeAllAnchor } from '../UI/LaneContainer';
import { useRecentlyViewedDataStore } from './data/RecentlyViewedDataStore';
import { Translate } from 'react-localize-redux';
import withIsMobile from '../hoc/withIsMobile';
import ShowingTitlesCount from '../TitleLanes/ShowingTitlesCount';
import AllAvailableTitles from '../TitleLanes/AllAvailableTitles';
import StaffPicksDataStore from '../StaffPicks/data/StaffPicksDataStore';

RecentlyViewedLane.propTypes = {
	isMobile: PropTypes.func,
};

function RecentlyViewedLane({ isMobile }) {
	const recentlyViewed = useRecentlyViewedDataStore();
	const titles = recentlyViewed ? recentlyViewed.getTitles() : [];

	const getHeader = () => <Translate id="recently_viewed_full" />;

	const renderExtra = () => {
		if (isMobile()) return null;
		return <ShowingTitlesCount numberOfTitles={titles.length} />;
	};

	const renderAllTitles = () => {
		return (
			<StaffPicksDataStore>
				<AllAvailableTitles
					header={getHeader()}
					fetchAllAvailableTitles={() =>
						new Promise(resolve => resolve({ titles }))
					}
				/>
			</StaffPicksDataStore>
		);
	};

	return (
		<LaneContainer majorHeader={getHeader()} extra={renderExtra()}>
			{recentlyViewed && (
				<>
					<TitleScroller
						titles={titles}
						isLoading={recentlyViewed.isLoading()}
					/>
					{titles.length > 3 ? (
						<SeeAllAnchor renderAllEntities={renderAllTitles} />
					) : (
						<div style={{ height: 5 }} />
					)}
				</>
			)}
		</LaneContainer>
	);
}

export default withIsMobile(RecentlyViewedLane);
