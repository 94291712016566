import React from 'react';

/**
 * @param {Element} Context
 * @param {String} nameOfDataStore
 * @returns {Function}
 */
export default (Context, nameOfDataStore) => (Component, mapStoreToProps) => {
	return props => {
		return (
			<Context.Consumer>
				{dataStore => {
					if (typeof mapStoreToProps === 'function') {
						const storeProps = mapStoreToProps(dataStore, props);
						return <Component {...storeProps} {...props} />;
					}

					const fallbackProps = {
						[nameOfDataStore]: dataStore,
						...props,
					};

					return <Component {...fallbackProps} />;
				}}
			</Context.Consumer>
		);
	};
};
