import React from 'react';
import LaneContainer from '../UI/LaneContainer';
import { Progress } from 'antd';
import { Translate } from 'react-localize-redux';

export default class RewardsProgress extends React.Component {
	render() {
		return (
			<div className="rewards-progress">
				<LaneContainer
					majorHeader={<Translate id="rewards_progress" />}
					subHeader="...towards your next $20 off!">
					<div
						style={{
							width: '95%',
							marginTop: '-10px',
							backgroundColor: '#ffffff',
							marginBottom: '10px',
						}}>
						<div style={{ padding: '5px 10px 7px 10px' }}>
							<Progress percent={30} size="large" />
						</div>
					</div>
				</LaneContainer>
			</div>
		);
	}
}
