import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../../UI/PageTitle';
import { CollectionStore } from '../data/CollectionStore';
import { Translate } from 'react-localize-redux';

class CollectionHeader extends React.Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		catalogName: PropTypes.string,
	};

	render() {
		const content = this.getHeaderContent();
		return (
			<PageTitle
				content={content}
				styleOverrides={{ marginBottom: '1.5rem' }}
			/>
		);
	}

	getHeaderContent() {
		if (this.props.isLoading) {
			return <Translate id="loading" />;
		}

		if (this.props.catalogName) {
			return this.props.catalogName;
		}
		return this.getDefaultContent();
	}

	getDefaultContent() {
		return <Translate id="new_or_upcoming_titles_recommended_for_you" />;
	}
}

export default CollectionStore.connect(CollectionHeader, s => ({
	isLoading: s.state.isLoading,
	catalogName: s.getCatalog().name,
}));
