import React from 'react';
import TransactionDetailsDataStore from './data/TransactionDetailsDataStore';
import TitleDetailDrawer from '../Title/TitleDetailDrawer';
import { Table } from 'antd';

export default class TransactionsList extends React.Component {
	render() {
		return (
			<TransactionDetailsDataStore>
				{this.renderTransactions}
			</TransactionDetailsDataStore>
		);
	}

	renderTransactions = transactionDetailsDataStore => {
		if (transactionDetailsDataStore.transactionsData) {
			const {
				transactions,
			} = transactionDetailsDataStore.transactionsData;

			return (
				<div>
					<Table
						pagination={{
							pageSize: 50,
							simple: true,
						}}
						columns={this.buildColumns()}
						dataSource={transactions}
						size="small"
					/>
				</div>
			);
		} else {
			return <span />;
		}
	};

	buildColumns() {
		return [
			{
				title: 'Date',
				sorter: (a, b) =>
					new Date(b.purchaseDate) - new Date(a.purchaseDate),
				render: transaction => (
					<div>
						{new Date(transaction.purchaseDate).toLocaleDateString(
							'en-US',
							{
								year: '2-digit',
								month: '2-digit',
								day: '2-digit',
							},
						)}
					</div>
				),
			},
			{
				title: 'Title',
				render: transaction => (
					<div>
						<TitleDetailDrawer
							sku={transaction.sku}
							parentStyle="clickable">
							<div className="acc-font bold">
								{transaction.title}
							</div>
						</TitleDetailDrawer>
						<div>{transaction.author}</div>
					</div>
				),
			},
		];
	}
}
