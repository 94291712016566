import React from 'react';
import PropTypes from 'prop-types';
import DetailDrawerAnchor from './DetailDrawerAnchor';
import { Button, Icon } from 'antd';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import { Translate } from 'react-localize-redux';
import './laneContainer.less';

SeeAllAnchor.propTypes = {
	renderAllEntities: PropTypes.func.isRequired,
};

export function SeeAllAnchor({ renderAllEntities }) {
	return (
		<div className="see-all-anchor">
			<DetailDrawerAnchor renderDrawerContent={renderAllEntities}>
				{drawer => {
					return (
						<Button size="small" onClick={drawer.open}>
							<Translate id="see_all" />{' '}
							<Icon type="arrow-right" />
						</Button>
					);
				}}
			</DetailDrawerAnchor>
		</div>
	);
}

class LaneContainer extends React.Component {
	static propTypes = {
		// required
		majorHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
		// optional
		subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		extra: PropTypes.any,
		icon: PropTypes.string,
		shouldRenderIcons: PropTypes.bool,
		style: PropTypes.object,
		backgroundClass: PropTypes.string,
		preferencesDataStore: PropTypes.object,
	};

	static defaultProps = {
		style: {},
		shouldRenderIcons: false,
		backgroundClass: 'gradient-background',
	};

	static SeeAllAnchor = SeeAllAnchor;

	render() {
		const { backgroundClass, preferencesDataStore } = this.props;
		const gradient =
			backgroundClass === 'gradient-background'
				? preferencesDataStore.getGradient()
				: 'none';
		const style = {
			...this.props.style,
			backgroundImage: gradient,
		};

		return (
			<div className={`lane-container ${backgroundClass}`} style={style}>
				<div className="page-container">
					<div
						className="lane-container-content-wrapper"
						style={{ padding: '0.5rem 0' }}>
						{this.renderLaneHeaders()}
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}

	renderLaneHeaders() {
		const { majorHeader, subHeader, icon } = this.props;
		if (majorHeader) {
			return (
				<div className="lane-headers">
					<div>
						<div className="lane-primary-header">
							{this.getLanePrimaryHeader(majorHeader, icon)}
						</div>
						{subHeader && (
							<div className="lane-subheader">
								<span>
									{subHeader && subHeader.text
										? subHeader.text
										: subHeader}
								</span>
							</div>
						)}
					</div>
					{this.props.extra && (
						<div className="lane-headers-extra">
							{this.props.extra}
						</div>
					)}
				</div>
			);
		} else {
			return <React.Fragment />;
		}
	}

	getLanePrimaryHeader(headerValue, icon) {
		if (!this.props.shouldRenderIcons) {
			return <span>{headerValue}</span>;
		}

		return (
			<div>
				<div className={`icon-${icon} major-header-icon-large`} />
				<div className="column-spaced">
					<span className="major-header">{headerValue}</span>
				</div>
				<div className="clear" />
			</div>
		);
	}
}

export default connectToSellerPreferences(LaneContainer);
