import React from 'react';
import { Icon } from 'antd';
import Fade from 'treeline-react-core-components/Fade';

export default class UpdateConfirmation extends React.Component {
	render() {
		return (
			<Fade>
				<div
					className="flex-align-center"
					style={{ marginLeft: '1rem', color: '#5b8c00' }}>
					<Icon type="check-circle" />
					<span style={{ marginLeft: '0.5rem' }}>Updated</span>
				</div>
			</Fade>
		);
	}
}
