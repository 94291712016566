import React from 'react';
import PropTypes from 'prop-types';
import { withWindowSize } from 'react-fns';

export const WindowSizeContext = React.createContext();

export const useWindowSize = () => React.useContext(WindowSizeContext);

class WindowSizeProvider extends React.Component {
	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		children: PropTypes.element,
	};

	render() {
		const { width, height } = this.props;
		return (
			<WindowSizeContext.Provider value={{ width, height }}>
				{this.props.children}
			</WindowSizeContext.Provider>
		);
	}
}

export default withWindowSize(WindowSizeProvider);
