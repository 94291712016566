import { makeCancelable } from 'api-fetcher-treeline/dist';

// Number, Any -> Promise
// Timer function
export default function delay(t, v) {
	return new Promise(function(resolve) {
		setTimeout(resolve.bind(null, v), t);
	});
}

export const cancelableDelay = (t, v) => makeCancelable(delay(t, v));
