import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Tooltip, Button } from 'antd';
import './favoriteButton.css';

FavoriteButton.propTypes = {
	isLoading: PropTypes.bool,
	isFavoriteEntity: PropTypes.func,
	handleToggle: PropTypes.func,
};

export default function FavoriteButton({
	isLoading,
	isFavoriteEntity,
	handleToggle,
}) {
	return (
		<div className="favorite-button-root">
			<Tooltip
				title={
					isFavoriteEntity() ? (
						<Translate id="pinned_as_favorite" />
					) : (
						<Translate id="add_to_favorites" />
					)
				}
				placement="top">
				<Button
					loading={isLoading}
					className="favorite-button"
					onClick={() => handleToggle()}>
					<div
						className={`favorite-status-icon icon-${
							isFavoriteEntity() ? 'approved' : 'add-circle'
						}`}
					/>
					<Translate id="favorite" />
				</Button>
			</Tooltip>
		</div>
	);
}
