import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID, connectToPreviewMode } from './CustomerIDStore';
import combineConnectors from './combineConnectors';
import makeConnectTo from './makeConnectTo';

const RatingsAndReviewsContext = React.createContext();

export const connectToRatingsAndReviews = makeConnectTo(
	RatingsAndReviewsContext,
	'ratingsAndReviewsDataStore',
);

export const useRatingsAndReviewsDataStore = () => {
	return React.useContext(RatingsAndReviewsContext);
};

class RatingsAndReviewsDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		previewMode: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		reviews: {},
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<RatingsAndReviewsContext.Provider value={dataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(dataStore)
					: this.props.children}
			</RatingsAndReviewsContext.Provider>
		);
	}

	componentDidMount() {
		if (this.props.previewMode.isActive()) {
			return this.setState({ isLoading: false });
		}
		this.fetchAllReviewsByCustomer();
	}

	componentDidUpdate(prevProps) {
		const prevCustomerId = prevProps.customerState.customerId;
		const currentCustomerId = this.props.customerState.customerId;

		if (prevCustomerId !== currentCustomerId) {
			this.fetchAllReviewsByCustomer();
		}
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		fetchAllReviewsByCustomer: () => this.fetchAllReviewsByCustomer(),
		save: (entity, review, type) =>
			this.saveOneRatingOrReview(entity, review, type),
		update: (entity, review, type) =>
			this.updateOneRatingOrReview(entity, review, type),
		remove: (entity, type) => this.deleteOneRatingOrReview(entity, type),
		getRating: entityId => this.getRating(state, entityId),
		getReview: entityId => this.getReview(state, entityId),
	});

	fetchAllReviewsByCustomer = () => {
		const { orgId, customerId } = this.props.customerState;
		return api
			.get(`/org/${orgId}/customer/${customerId}/reviews/title`)
			.then(reviews => this.setState({ isLoading: false, reviews }))
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load ratings and reviews');
				});
			});
	};

	saveOneRatingOrReview = (recommendableEntity, review, type) => {
		const e = recommendableEntity;
		const customerReviewDTO = this.getRatingOrReviewDTOFromEntity(e);
		customerReviewDTO[type] = review;
		const request = api.post(`/customer/${type}`, customerReviewDTO);

		return request.then(() => this.fetchAllReviewsByCustomer());
	};

	updateOneRatingOrReview = (recommendableEntity, review, type) => {
		const e = recommendableEntity;
		const customerReviewDTO = this.getRatingOrReviewDTOFromEntity(e);
		customerReviewDTO[type] = review;

		const request = api.put(`/customer/${type}`, customerReviewDTO);

		return request.then(() => this.fetchAllReviewsByCustomer());
	};

	deleteOneRatingOrReview = (recommendableEntity, type) => {
		const e = recommendableEntity;
		const customerReviewDTO = this.getRatingOrReviewDTOFromEntity(e);

		return this.requestDelete(customerReviewDTO, type).then(() => {
			return this.fetchAllReviewsByCustomer();
		});
	};

	requestDelete(customerReviewDTO, type) {
		return api.call('DELETE', `/customer/${type}`, customerReviewDTO);
	}

	getRatingOrReviewDTOFromEntity = recommendableEntity => {
		const { customerId } = this.props.customerState;
		return {
			customerId,
			entityType: recommendableEntity.type,
			entityId: recommendableEntity.id,
		};
	};

	getRating = (state, entityId) => {
		const ratings = state.reviews.customerRatings;
		const entityRating =
			Array.isArray(ratings) &&
			ratings.find(rating => {
				return rating.entityId === entityId;
			});
		return entityRating ? entityRating.rating : 0;
	};

	getReview = (state, entityId) => {
		const reviews = state.reviews.customerReviews;
		const entityReview =
			Array.isArray(reviews) &&
			reviews.find(review => {
				return review.entityId === entityId;
			});
		return entityReview ? entityReview.review : '';
	};
}

const connectors = [connectToCustomerID, connectToPreviewMode];
export default combineConnectors(connectors)(RatingsAndReviewsDataStore);
