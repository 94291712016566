import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu } from 'antd';
import TitleDetailDrawer from '../../TitleDetailDrawer';
import { useCustomerID } from '../../../data/CustomerIDStore';
import TitleListPrice from '../TitleListPrice';
import { useOrganizationDataStore } from '../../../data/OrganizationDataStore';
import api from '../../../../api';
import {
	compareTitlesByFormatAndPrice,
	getFormatDescriptionDisplayName,
} from '../../../../util/titleUtility';
import './familyTitlesDropdown.less';

FamilyTitlesDropdown.propTypes = {
	title: PropTypes.object,
};

export default function FamilyTitlesDropdown({ title }) {
	const [isVisible, setIsVisible] = useState(false);
	const [familyTitles, setFamilyTitles] = useState([]);
	const { getOrgCountry } = useOrganizationDataStore();
	const { orgId } = useCustomerID();

	const getTitleWithDisplayFormat = useCallback(
		title => ({
			...title,
			formatDisplayName: getFormatDescriptionDisplayName(
				title.formatDescription,
			),
		}),
		[],
	);

	useEffect(() => {
		const endpoint = `/org/${orgId}/search/familyTitles?familyKey=${title.familyItem}`;
		const request = api.cancelableGet(endpoint);

		const sortFamilyByFormatAndPrice = titles => {
			const titlesWithDisplayFormat = titles.map(
				getTitleWithDisplayFormat,
			);
			return compareTitlesByFormatAndPrice(titlesWithDisplayFormat);
		};

		request.promise
			.then(familyTitles =>
				sortFamilyByFormatAndPrice(familyTitles.familyDropdownItems),
			)
			.then(sortedFamily => setFamilyTitles(sortedFamily))
			.catch(err => !err.isCanceled && console.log(err));

		return () => {
			api.revoke(request);
		};
	}, [orgId, title, getTitleWithDisplayFormat]);

	const renderOneDropdownItem = item => {
		return item ? (
			<div className="family-dropdown-item-container">
				{item.sku !== title.sku ? (
					<>
						<div
							className="family-dropdown-item-title dotDot"
							title={item.name}>
							{item.name}
						</div>
						,&nbsp;<b>{item.formatDisplayName}</b>
					</>
				) : (
					item.formatDisplayName
				)}
				,&nbsp;
				<TitleListPrice title={item} orgCountry={getOrgCountry()} />
			</div>
		) : null;
	};

	const getFamilyTitlesMenu = () => {
		const alternativeTitleFormats = familyTitles.filter(
			t => t.sku !== title.sku,
		);
		return (
			<Menu onSelect={() => setIsVisible(prev => !prev)}>
				{alternativeTitleFormats.map((title, idx) => (
					<Menu.Item key={idx} value={title.sku}>
						<TitleDetailDrawer sku={title.sku}>
							{renderOneDropdownItem(title)}
						</TitleDetailDrawer>
					</Menu.Item>
				))}
			</Menu>
		);
	};

	const renderCurrentTitle = () => {
		const currentTitle = familyTitles.find(t => t.sku === title.sku);
		return renderOneDropdownItem(currentTitle);
	};

	const renderDescriptionWithoutDropdown = () => {
		const formattedTitle = getTitleWithDisplayFormat(title);
		return (
			<div style={{ marginBottom: 5 }}>
				{formattedTitle.formatDisplayName},{' '}
				<TitleListPrice
					title={formattedTitle}
					orgCountry={getOrgCountry()}
				/>
			</div>
		);
	};

	return Array.isArray(familyTitles) &&
		familyTitles.length > 1 &&
		familyTitles.map(t => t.sku).includes(title.sku) ? (
		<Dropdown
			overlay={getFamilyTitlesMenu()}
			visible={isVisible}
			getPopupContainer={trigger => trigger.parentNode}
			overlayClassName={'family-titles-dropdown-overlay'}
			onVisibleChange={val => setIsVisible(val)}
			trigger={['click']}
			defaultValue={familyTitles[0].sku}
			style={{ paddingTop: 10 }}>
			<div className="family-titles-dropdown">
				{renderCurrentTitle()}
				<Icon
					type="down"
					style={{
						transform: isVisible ? 'rotate(180deg)' : null,
					}}
				/>
			</div>
		</Dropdown>
	) : (
		renderDescriptionWithoutDropdown()
	);
}
