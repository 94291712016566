import React from 'react';
import PropTypes from 'prop-types';
import StaffPicksDataStore from './data/StaffPicksDataStore';
import StaffPickLaneType from '../../entity/StaffPickLaneType';
import StaffPicksBySectionLane from './StaffPicksBySectionLane';
import StaffPicksByMemberLane from './StaffPicksByMemberLane';
import './staffPicks.css';

export default class StaffPicksSummary extends React.Component {
	static propTypes = {
		staffPickLaneType: PropTypes.string, // By Staff Member or By Section
	};

	render() {
		return (
			<StaffPicksDataStore>
				{this.renderStaffPicksLane}
			</StaffPicksDataStore>
		);
	}

	renderStaffPicksLane = staffPicksDataStore => {
		return (
			<div className="staff-picks">
				{!staffPicksDataStore.isLoading() &&
					this.renderLane(staffPicksDataStore)}
			</div>
		);
	};

	renderLane = staffPicksDataStore => {
		switch (this.props.staffPickLaneType) {
			case StaffPickLaneType.bySection:
				const staffPickSections = staffPicksDataStore.getStaffPickSections();
				return Array.isArray(staffPickSections) &&
					staffPickSections.length ? (
					<StaffPicksBySectionLane />
				) : null;
			case StaffPickLaneType.byStaffMember:
			default:
				return <StaffPicksByMemberLane />;
		}
	};
}
