import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './styles/index.less';
import 'treeline-react-core-components/index.css';
import './assets/fontastic.css';
import './index.css';

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root'),
);

serviceWorker.unregister();
