import React from 'react';
import PropTypes from 'prop-types';
import { useReferralSource } from '../../data/CustomerIDStore';
import CustomerSummaryDataStore from '../../data/CustomerSummaryDataStore';
import TransactionsDataStore from '../../data/TransactionsDataStore';

const propTypes = {
	children: PropTypes.func,
};

export default function WishlistCollectionPageDataStores({ children }) {
	const referralSource = useReferralSource();
	return (
		<CustomerSummaryDataStore customerState={referralSource}>
			{customerSummaryDataStore => {
				return (
					<TransactionsDataStore customerState={referralSource}>
						{transactionsDataStore => {
							return children({
								transactionsDataStore,
								customerSummaryDataStore,
							});
						}}
					</TransactionsDataStore>
				);
			}}
		</CustomerSummaryDataStore>
	);
}

WishlistCollectionPageDataStores.propTypes = propTypes;
