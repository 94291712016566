import React from 'react';
import PropTypes from 'prop-types';
import { Rate, notification } from 'antd';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import RecommendableEntity from '../../entity/RecommendableEntity';
import ReviewDisplay from './ReviewDisplay';
import ReviewInput from './ReviewInput';
import { connectToRatingsAndReviews } from '../data/RatingsAndReviewsDataStore';
import { Translate } from 'react-localize-redux';
import './rateAndReview.less';

export class RateAndReview extends React.Component {
	static propTypes = {
		entityId: PropTypes.string,
		transactions: PropTypes.object,
		isEditingReview: PropTypes.bool,
	};

	state = {
		value: 0,
		isEditingReview: false,
	};

	handleChange = (value, translate) => {
		const previousValue = this.state.value;
		const type = 'rating';
		this.setState({ value });

		const { ratingsAndReviewsDataStore, entityType, entityId } = this.props;
		const entity = new RecommendableEntity(entityId, entityType);

		if (value === 0) {
			return ratingsAndReviewsDataStore.remove(entity, type).then(() =>
				notification.success({
					message: translate('your_x_has_been_removed', {
						type: type,
					}),
				}),
			);
		} else if (previousValue > 0) {
			return ratingsAndReviewsDataStore
				.update(entity, value, type)
				.then(() =>
					notification.success({
						message: translate('your_x_has_been_updated', {
							type: type,
						}),
					}),
				);
		} else {
			return ratingsAndReviewsDataStore
				.save(entity, value, type)
				.then(() =>
					notification.success({
						message: translate('your_x_has_been_saved', {
							type: type,
						}),
					}),
				);
		}
	};

	render() {
		const { value, isEditingReview } = this.state;
		const { entityType, entityId } = this.props;

		return (
			<div className="rate-and-review">
				<div style={{ display: 'flex' }}>
					<div style={{ flex: '225px 0 0' }}>
						<span className="edit-rating-button">
							<Translate id="rate" />:{' '}
						</span>
						<Translate>
							{({ translate }) => {
								return (
									<Rate
										allowHalf
										value={value}
										allowClear={true}
										onChange={value =>
											this.handleChange(value, translate)
										}
									/>
								);
							}}
						</Translate>
					</div>
					<div className="edit-review-button acc-font">
						&{' '}
						<span onClick={() => this.editReview()}>
							<Translate id="review" />
						</span>
					</div>
				</div>
				{isEditingReview ? (
					<ReviewInput
						leaveEditMode={this.leaveEditMode}
						entityType={entityType}
						entityId={entityId}
					/>
				) : (
					<ReviewDisplay entityId={entityId} />
				)}
			</div>
		);
	}

	componentDidMount() {
		this.setInitialValue();
	}

	setInitialValue() {
		const { entityId, ratingsAndReviewsDataStore } = this.props;
		const rating = ratingsAndReviewsDataStore.getRating(entityId);
		if (rating) {
			this.setState({ value: rating });
		}
	}

	editReview = () => {
		this.setState({ isEditingReview: true });
	};

	leaveEditMode = () => {
		this.setState({ isEditingReview: false });
	};
}

const connect = combineConnectors([
	connectToRatingsAndReviews,
	connectToPreviewMode,
]);

export default connect(RateAndReview);
