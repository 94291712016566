import React from 'react';
import PropTypes from 'prop-types';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import './sectionHeader.css';

SectionHeader.propTypes = {
	displayName: PropTypes.object,
};

export default function SectionHeader({ displayName }) {
	const { getGradient } = useBooksellerPreferencesDataStore();
	return (
		<div
			className="section-header"
			style={{ backgroundImage: getGradient() }}>
			<div className="page-container">{displayName}</div>
		</div>
	);
}
