import React from 'react';
import PropTypes from 'prop-types';
import {
	connectToCustomerID,
	connectToPreviewMode,
} from '../../data/CustomerIDStore';
import api from '../../../api';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import combineConnectors from '../../data/combineConnectors';
import * as authorUtils from '../../../author/author.utils';

export const FavoritesContext = React.createContext();

class CustomerFavoritesDataStoreBase extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		previewMode: PropTypes.object,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		customerEntitiesController: PropTypes.shape({
			get: PropTypes.func,
			save: PropTypes.func,
			remove: PropTypes.func,
			find: PropTypes.func, // (entities, entityId) => { entity: object, result: bool },
		}),
	};

	state = {
		isLoading: true,
		titles: [],
		series: [],
		sections: [],
		authors: [],
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<FavoritesContext.Provider value={dataStore}>
				{this.renderChildren(dataStore)}
			</FavoritesContext.Provider>
		);
	}

	componentDidMount() {
		const { previewMode, customerState } = this.props;

		if (previewMode.isActive() || customerState.isUnknownCustomer) {
			return this.setState({ isLoading: false });
		}

		this.fetchCustomerFavorites();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getFavoriteGenres: () => state.sections,
		save: entity => this.saveOneEntityAsFavorite(entity),
		remove: entity => this.deleteOneEntityAsFavorite(entity),
		getFavoriteTitles: () => this.getFavoriteTitles(state),
		getFavoriteSeries: () => this.getFavoriteSeries(state),
		getFavoriteAuthors: () => this.getFavoriteAuthors(state),
		includes: (entityId, entityType) => {
			return this.findEntity(entityId, entityType).result;
		},
	});

	renderChildren(dataStore) {
		return typeof this.props.children === 'function'
			? this.props.children(dataStore)
			: this.props.children;
	}

	fetchCustomerFavorites = () => {
		this.request = this.props.customerEntitiesController.get();
		return this.request.promise.then(this.organizeFavoritesByEntityType);
	};

	organizeFavoritesByEntityType = customerFavorites => {
		const titles = [];
		const series = [];
		const authors = [];
		const sections = [];

		for (let i = 0; i < customerFavorites.length; i++) {
			const current = customerFavorites[i];
			const type = current.entityType.toLowerCase();

			switch (type) {
				case RecommendableEntity.title:
					titles.push(current);
					break;
				case RecommendableEntity.section:
				case RecommendableEntity.department:
					sections.push(current);
					break;
				case RecommendableEntity.author:
					authors.push(current);
					break;
				case RecommendableEntity.series:
					series.push(current);
					break;
				default:
					throw new Error(
						'Failed to identify Recommendable Entity type',
					);
			}
		}

		this.setState({
			isLoading: false,
			titles,
			series,
			authors,
			sections,
		});
	};

	getFavoriteTitles = state => {
		return state.titles.map(titleEntity => ({
			sku: titleEntity.entityId,
			name: titleEntity.entityName,
			author: titleEntity.entity.author,
			imageUri: titleEntity.entityImageUri,
		}));
	};

	getFavoriteAuthors = state => {
		return state.authors.map(authorEntity => {
			const name = authorEntity.entityName;
			const {
				initials,
				displayName,
			} = authorUtils.getAuthorDisplayNameAndInitials(name);
			return {
				name,
				initials,
				displayName,
				skuCount: authorEntity.entity.skuCount,
				id: authorEntity.entityId,
				imageUri: authorEntity.entityImageUri,
			};
		});
	};

	getFavoriteSeries = state => {
		return state.series.map((seriesEntity, idx) => ({
			idx,
			name: seriesEntity.entityName,
			skuCount: seriesEntity.entity && seriesEntity.entity.skuCount,
		}));
	};

	saveOneEntityAsFavorite = async recommendableEntity => {
		const { customerEntitiesController } = this.props;

		await customerEntitiesController.save(recommendableEntity);
		await this.fetchCustomerFavorites();
	};

	deleteOneEntityAsFavorite = async recommendableEntity => {
		const { customerEntitiesController } = this.props;
		await customerEntitiesController.remove(recommendableEntity);
		return this.fetchCustomerFavorites();
	};

	// (entityId: string, entityType: string) => { entity: object, result: bool }
	findEntity = (entityId, entityType) => {
		const entities = this.getFavoriteEntities(entityType);
		const { customerEntitiesController } = this.props;
		return customerEntitiesController.find(entities, entityId);
	};

	getFavoriteEntities(entityType) {
		switch (entityType) {
			case RecommendableEntity.title:
				return this.state.titles;
			case RecommendableEntity.section:
				return this.state.sections;
			case RecommendableEntity.author:
				return this.state.authors;
			case RecommendableEntity.series:
				return this.state.series;
			default:
				return [];
		}
	}
}

const connect = combineConnectors([connectToPreviewMode, connectToCustomerID]);

export default connect(CustomerFavoritesDataStoreBase);
