import React from 'react';
import { Link } from 'react-router-dom';
import BooksellerLogo from '../UI/BooksellerLogo/BooksellerLogo';
import { LinkName } from './NavigationItems';
import './classicHeaderLayout.less';

export default function ClassicHeaderLayout() {
	return (
		<Link to={LinkName.Home} className="header-logo-classic">
			<BooksellerLogo />
		</Link>
	);
}
