import React from 'react';
import PropTypes from 'prop-types';
import TitleAutocomplete from '../../Title/UI/TitleAutocomplete';
import LaneContainer, { SeeAllAnchor } from '../../UI/LaneContainer';
import withIsMobile from '../../hoc/withIsMobile';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import TitleScroller from '../../TitleLanes/TitleScroller';
import { Spin, notification } from 'antd';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import delay from '../../../util/delay';
import { connectToFavorites } from '../data/CustomerFavoritesDataStore';
import { Translate } from 'react-localize-redux';
import AllAvailableTitles from '../../TitleLanes/AllAvailableTitles';
import StaffPicksDataStore from '../../StaffPicks/data/StaffPicksDataStore';
import TitleJacketImageWithIconBadges from '../../UI/TitleJacketImageWithIconBadges';

class TitleFavoritesLane extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		previewMode: PropTypes.object,
		customerFavoritesDataStore: PropTypes.object,
	};

	state = {
		isRefreshingTitles: false,
	};

	render() {
		const mobile = this.props.isMobile();
		return (
			<LaneContainer
				majorHeader={<Translate id="favorite_books" />}
				subHeader={
					!mobile ? (
						<Translate id="let_us_know_what_books_you_love_most" />
					) : (
						''
					)
				}
				extra={
					<Translate>
						{({ translate }) => {
							return (
								<div className="autocomplete-wrapper">
									<TitleAutocomplete
										onSelectOption={(sku, title) =>
											this.onSelectTitle(
												sku,
												title,
												translate,
											)
										}
										placeholder={this.getPlaceholder(
											translate,
										)}
									/>
								</div>
							);
						}}
					</Translate>
				}>
				{this.handleRenderFavorites()}
			</LaneContainer>
		);
	}

	handleRenderFavorites = () => {
		const isLoading = this.props.customerFavoritesDataStore.isLoading();
		return isLoading
			? this.renderLoadingFavorites()
			: this.renderTitleFavorites();
	};

	renderLoadingFavorites() {
		return (
			<Spin>
				<div style={{ height: '5rem', width: '100%' }} />
			</Spin>
		);
	}

	onSelectTitle = (sku, title, translate) => {
		if (this.props.previewMode.isActive()) {
			return this.makeEmptyPromise();
		}

		const entityType = RecommendableEntity.title;
		const entity = new RecommendableEntity(sku, entityType, title.name);
		const name = entity.name;

		this.setState({ isRefreshingTitles: true });

		return this.props.customerFavoritesDataStore
			.save(entity)
			.then(() => {
				this.setState({ isRefreshingTitles: false });
			})
			.then(() =>
				notification.success({
					message: translate('saved_x_to_your_favorites', {
						name: name,
					}),
				}),
			);
	};

	renderTitleFavorites = () => {
		const titles = this.props.customerFavoritesDataStore.getFavoriteTitles();
		return titles.length ? (
			<Spin spinning={this.state.isRefreshingTitles}>
				<div>
					<TitleScroller
						titles={titles}
						renderOneTitle={t => (
							<TitleJacketImageWithIconBadges
								isInTitleLane={true}
								title={t}
							/>
						)}
					/>
					{this.renderSeeAllTitles(titles)}
				</div>
			</Spin>
		) : (
			<p style={{ fontWeight: 'bold', fontSize: '14px' }}>
				<em>
					<Translate id="no_favorite_books" />
				</em>
			</p>
		);
	};

	makeEmptyPromise = () => {
		return delay(300).then(() => this.props.previewMode.notify());
	};

	getPlaceholder = translate => {
		return translate('search_for_books');
	};

	renderSeeAllTitles = titles => {
		return (
			<div style={this.props.isMobile() ? { marginTop: 20 } : null}>
				<SeeAllAnchor
					renderAllEntities={() => (
						<StaffPicksDataStore>
							<AllAvailableTitles
								header={<Translate id="favorite_books" />}
								titles={titles}
							/>
						</StaffPicksDataStore>
					)}
				/>
			</div>
		);
	};
}

const connectors = [connectToFavorites, connectToPreviewMode, withIsMobile];

export default combineConnectors(connectors)(TitleFavoritesLane);
