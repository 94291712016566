import React from 'react';
import PropTypes from 'prop-types';
import LaneContainer from '../../UI/LaneContainer';
import BagTotal from '../../Bag/UI/BagTotal';
import { useBagDataStore } from '../../Bag/data/BagDataStore';
import AddTitlesAutocomplete from '../../Bag/UI/AddTitlesAutocomplete';
import './orderLaneContainer.css';
import { Translate } from 'react-localize-redux';

OrderLaneContainer.propTypes = {
	children: PropTypes.element,
};

export default function OrderLaneContainer({ children }) {
	const { getTotalEntitiesCount } = useBagDataStore();
	const totalEntitiesCount = getTotalEntitiesCount();

	const renderSubHeader = () => {
		const itemCountDisplay =
			totalEntitiesCount === 1 ? (
				<Translate id="one_item" />
			) : (
				<Translate id="x_items" data={{ number: totalEntitiesCount }} />
			);
		return (
			<span>
				<Translate id="subtotal" /> ({itemCountDisplay}): <BagTotal />
			</span>
		);
	};

	return (
		<LaneContainer
			majorHeader={<Translate id="your_order" />}
			subHeader={renderSubHeader()}
			extra={<AddTitlesAutocomplete />}>
			{children}
		</LaneContainer>
	);
}
