import { useOrganizationDataStore } from '../data/OrganizationDataStore';

export default function LocalizedPrice() {
	const { getOrgCountry } = useOrganizationDataStore();

	const getCultureAndCurrencyCodes = () => {
		switch (getOrgCountry()) {
			case 'UK':
				return {
					culture: 'en-GB',
					currency: 'GBP',
				};
			case 'CA':
				return {
					culture: 'en-CA',
					currency: 'CAD',
				};
			default:
			case 'US':
				return {
					culture: 'en-US',
					currency: 'USD',
				};
		}
	};

	return {
		getCultureAndCurrencyCodes,
	};
}
