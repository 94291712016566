import { useBagDataStore } from './BagDataStore';
import RecommendableEntity from '../../../entity/RecommendableEntity';

export default function useRemoveFromBag(title, setIsLoading) {
	const bagDataStore = useBagDataStore();

	const removeFromBag = async () => {
		setIsLoading(true);
		const type = RecommendableEntity.title;

		const { name, sku } = title;
		const entity = new RecommendableEntity(sku, type, name, title);

		await bagDataStore.remove(entity);
	};

	return { removeFromBag };
}
