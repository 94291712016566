import React from 'react';
import { Card } from 'antd';

export default function CollectionTitleSkeletons() {
	const total = 15;
	let skeletons = [];

	for (let i = 0; i < total; i++) {
		skeletons.push(<CollectionTitleSkeleton key={i} />);
	}

	return <React.Fragment>{skeletons}</React.Fragment>;
}

function CollectionTitleSkeleton() {
	return (
		<Card
			loading={true}
			style={{ width: '375px', height: '198px', marginBottom: '2rem' }}
		/>
	);
}
