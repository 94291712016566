import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { StoreLocationInfoEnum } from './StoreLocationInfoEnum';
import './storeLocationInfo.less';

StoreLocationInfo.propTypes = {
	location: PropTypes.object,
};

export default function StoreLocationInfo({ location }) {
	const { locationName, phoneNumber, address, hours } = location;

	const getStoreInfoProperties = properties => {
		return properties.map((p, i) => {
			const property = p.includes('.') ? p.split('.') : p;
			const value = Array.isArray(property)
				? location[property[0]][property[1]]
				: location[property];
			return (
				value && (
					<div
						key={i}
						className={Array.isArray(property) ? '' : property}>
						{value}
					</div>
				)
			);
		});
	};

	return (
		<div className="store-location-info">
			<div>
				{locationName &&
					getStoreInfoProperties(StoreLocationInfoEnum.locationName)}
				{address &&
					Object.values(address).some(value => value) &&
					getStoreInfoProperties(StoreLocationInfoEnum.address)}
				{phoneNumber &&
					getStoreInfoProperties(StoreLocationInfoEnum.phoneNumber)}
			</div>
			{hours && Object.values(hours).some(value => value) && (
				<div className="hours-container">
					<div className="hours-header">
						<Translate id="hours" />:
					</div>
					{getStoreInfoProperties(StoreLocationInfoEnum.hours)}
				</div>
			)}
		</div>
	);
}
