import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID } from '../data/CustomerIDStore';
import CustomerListType from '../../entity/CustomerListType';
import RecommendableEntity from '../../entity/RecommendableEntity';
import combineConnectors from '../data/combineConnectors';

class WishlistController extends React.Component {
	static propTypes = {
		sku: PropTypes.string,
		children: PropTypes.func,
		// Mapped to props
		customerId: PropTypes.string,
	};

	request = null;

	render() {
		return this.props.children({
			add: () => this.addToWishlist(),
			remove: () => this.removeFromWishlist(),
		});
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	addToWishlist = () => {
		const customerEntityDTO = this.makeEntityDTO();

		this.request = api.cancelablePost(
			'/customer/entity',
			customerEntityDTO,
		);
		return this.request.promise;
	};

	removeFromWishlist = () => {
		const customerEntityDTO = this.makeEntityDTO();

		this.request = api.cancelableCall(
			'DELETE',
			'/customer/entity',
			customerEntityDTO,
		);
		return this.request.promise;
	};

	makeEntityDTO = () => {
		const { sku, customerId } = this.props;

		return {
			customerId,
			listType: CustomerListType.wishlist,
			entityType: RecommendableEntity.title,
			entityId: sku,
		};
	};
}

const mapCustomerToProps = customerState => ({
	customerId: customerState.customerId,
});

const connectors = [
	Component => connectToCustomerID(Component, mapCustomerToProps),
];

export default combineConnectors(connectors)(WishlistController);
