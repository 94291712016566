import { useState } from 'react';
import api from '../../../../api';
import delay from '../../../../util/delay';
import { notification } from 'antd';
import { useCustomerID } from '../../../data/CustomerIDStore';
import { useImageUpload } from '../../Image/ImageUploadDataStore';
import { useBooksellerLogoDataStore } from '../../../data/BooksellerLogoStore';
import { ImageType } from '../../../data/BooksellerLogoStore';

export default function useSplashImageController() {
	const { orgId } = useCustomerID();
	const imageUploadDataStore = useImageUpload();
	const logoDataStore = useBooksellerLogoDataStore();

	const endpoint = `/org/${orgId}/images/splash`;

	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const makeRetailImageDTO = () => ({
		base64: imageUploadDataStore.getUploadedImageAsBase64(),
		imageType: ImageType.splash,
		fileName: imageUploadDataStore.state.fileName,
		fileType: imageUploadDataStore.state.fileType,
	});

	const clearPreviewAndReset = () => {
		imageUploadDataStore.reset();
		logoDataStore.clearSplashPreview();
	};

	const saveUploadedSplash = async translate => {
		setIsLoading(true);

		const imgDTO = makeRetailImageDTO();
		const { didUpdateSucceed, imageUri } = await api.put(endpoint, imgDTO);

		if (didUpdateSucceed && imageUri) {
			logoDataStore.setSplashUri(imageUri);
		}
		await delay(750);
		notification.success({ message: translate('splash_image_uploaded') });

		await delay(500);
		clearPreviewAndReset();
	};

	const deleteCurrentSplashImage = async translate => {
		setIsDeleting(true);
		await api.del(endpoint);

		notification.info({ message: translate('splash_image_deleted') });
		logoDataStore.setSplashUri(null);
	};

	return {
		isLoading,
		isDeleting,
		saveUploadedSplash,
		clearPreviewAndReset,
		deleteCurrentSplashImage,
	};
}
