import React from 'react';
import PageTitle from '../UI/PageTitle';
import TransactionsList from './TransactionsList';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';

export default class RewardsPage extends React.Component {
	render() {
		return (
			<WishlistSkusDataStore>
				<CustomerFavoritesDataStore>
					<div
						className="rewards-page"
						style={{ paddingBottom: '54px' }}>
						<PageTitle content="Your Purchases" />
						<TransactionsList />
					</div>
				</CustomerFavoritesDataStore>
			</WishlistSkusDataStore>
		);
	}
}
