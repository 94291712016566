import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NavigationAnchorRoot from './NavigationAnchorRoot';
import { paths } from '../../paths';
import SignUpPopover from './SignUpPopover';
import SignUpModal from '../UI/SignUpModal';
import {
	appSettingsByKey,
	useBooksellerPreferencesDataStore,
} from '../data/BooksellerPreferencesDataStore';

NavigationAnchor.propTypes = {
	icon: PropTypes.string,
	label: PropTypes.object,
	linkTo: PropTypes.string,
	alsoMatches: PropTypes.arrayOf(PropTypes.object), // array of RegExp
};

NavigationAnchor.defaultProps = {
	alsoMatches: [],
};

export default function NavigationAnchor({ icon, label, linkTo, alsoMatches }) {
	const { getAppSettingAtKey } = useBooksellerPreferencesDataStore();
	const doShowBookstoreLoyaltyPopup = getAppSettingAtKey(
		appSettingsByKey.doShowBookstoreLoyaltyPopup,
	);

	const renderNavigationLink = () => (
		<Link to={linkTo}>
			<div className={`column-icon icon-${icon}`} />
			<div className="column-text">{label}</div>
		</Link>
	);

	return (
		<NavigationAnchorRoot routes={[linkTo]} alsoMatches={alsoMatches}>
			{linkTo === paths.signup() && doShowBookstoreLoyaltyPopup ? (
				<>
					<SignUpPopover children={renderNavigationLink()} />
					<SignUpModal doRenderLearnMoreButton={false} />
				</>
			) : (
				renderNavigationLink()
			)}
		</NavigationAnchorRoot>
	);
}
