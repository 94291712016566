import React from 'react';
import PropTypes from 'prop-types';
import { FullDateFormatNoDayOfWeek } from '../../../dateFormats';
import ShowMoreText from 'react-show-more-text';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import TitleActionButtons from '../../Title/UI/TitleActionButtons';
import './staffReview.less';

class StaffReview extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		doShowActionButtons: PropTypes.bool,
		reviewerFirstName: PropTypes.string,
		reviewerFullName: PropTypes.string,
		reviewText: PropTypes.string,
		updatedDate: PropTypes.string,
		children: PropTypes.element,
		title: PropTypes.object,
	};

	static defaultProps = {
		doShowActionButtons: true,
	};

	render() {
		const {
			children,
			reviewerFullName,
			reviewText,
			doShowActionButtons,
			title,
			isMobile,
		} = this.props;

		return (
			<div
				className="staff-review-container"
				style={
					reviewText
						? {
								padding: isMobile() ? '25px 0' : 25,
						  }
						: null
				}>
				<div
					className="staff-review-header-container"
					style={{ textAlign: children ? 'left' : 'center' }}>
					{children && (
						<div className="staff-review-children">{children}</div>
					)}
					{this.renderHeader()}
				</div>
				{reviewText && (
					<React.Fragment>
						{this.renderReviewText()}
						<div className="staff-review-details">
							<Translate
								id="written_by_staff_member_x_on_y"
								data={{
									name: reviewerFullName,
									date: new Date(
										this.props.updatedDate,
									).toLocaleDateString(
										FullDateFormatNoDayOfWeek,
									),
								}}
							/>
						</div>
					</React.Fragment>
				)}
				{doShowActionButtons && (
					<TitleActionButtons
						title={title}
						isTitleDetail={isMobile()}
					/>
				)}
			</div>
		);
	}

	renderHeader() {
		const { reviewerFirstName, title, reviewText } = this.props;
		const titleName = title.name
			? title.name
			: title.product
			? title.product.name
			: null;
		return (
			<div className="staff-review-header">
				<Translate
					id={reviewText ? 'xs_take_on_y' : 'x_recommends_y'}
					data={{
						name: reviewerFirstName,
						title: titleName ? (
							<i>{titleName}</i>
						) : (
							<Translate id="this_title" />
						),
					}}
				/>
			</div>
		);
	}

	renderReviewText() {
		const { reviewText } = this.props;
		return (
			<div className="staff-review-text-container">
				<ShowMoreText
					lines={4}
					more="more"
					less="less"
					anchorClass=""
					expanded={false}>
					{reviewText}
				</ShowMoreText>
			</div>
		);
	}
}

export default withIsMobile(StaffReview);
