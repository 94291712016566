import React from 'react';
import useReferralWishlistTitles from './data/useReferralWishlistTitles';
import CollectionTitle from '../Collection/UI/CollectionTitle';
import CollectionTitlesWrapper from '../Collection/UI/CollectionTitlesWrapper';
import LoadingPage from '../UI/LoadingPage';
import WishlistCollectionPageHeader from './UI/WishlistCollectionPageHeader';
import WishlistCollectionPageDataStores from './data/WishlistCollectionPageDataStores';
import ErrorBoundary from '../UI/Error/ErrorBoundary';
import WishlistPageError from './UI/WishlistPageError';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import { HeaderLayout } from '../Navigation/HeaderLayout';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import WishlistSkusDataStore from './data/WishlistSkusDataStore';
import './wishlistCollectionPage.css';

export default function WishlistCollectionPage() {
	return (
		<ErrorBoundary FallbackComponent={WishlistPageError}>
			<WishlistCollectionPageContent />
		</ErrorBoundary>
	);
}

function WishlistCollectionPageContent() {
	const {
		wishlistTitles,
		isLoading,
		isInvalidReferral,
	} = useReferralWishlistTitles();

	const {
		getGradient,
		getAppSettingAtKey,
	} = useBooksellerPreferencesDataStore();

	if (isInvalidReferral) {
		throw new Error('Invalid Referral!');
	}

	const renderWishlistTitles = transactionsDataStore => {
		const titles = wishlistTitles.filter(
			t => !transactionsDataStore.includes(t.sku),
		);
		return titles.map(title => {
			const key = title.sku;
			return <CollectionTitle key={key} title={title} />;
		});
	};

	const renderPageContent = transactionsDataStore => {
		const headerLayout = getAppSettingAtKey('headerLayout');
		return (
			<div
				style={
					headerLayout === HeaderLayout.overlap
						? { minHeight: '1000px' }
						: null
				}>
				<WishlistCollectionPageHeader />
				<div className="page-container">
					<CollectionTitlesWrapper isLoading={isLoading}>
						{renderWishlistTitles(transactionsDataStore)}
					</CollectionTitlesWrapper>
				</div>
			</div>
		);
	};

	return (
		<WishlistSkusDataStore>
			<CustomerFavoritesDataStore>
				<WishlistCollectionPageDataStores>
					{({ customerSummaryDataStore, transactionsDataStore }) => {
						return (
							<div
								className="wishlist-collection-page"
								style={{ backgroundImage: getGradient() }}>
								{customerSummaryDataStore.isLoading() ? (
									<div style={{ paddingBottom: '35rem' }}>
										<LoadingPage />
									</div>
								) : (
									renderPageContent(transactionsDataStore)
								)}
							</div>
						);
					}}
				</WishlistCollectionPageDataStores>
			</CustomerFavoritesDataStore>
		</WishlistSkusDataStore>
	);
}
