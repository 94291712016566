import React from 'react';
import PropTypes from 'prop-types';
import noImagePlaceholder from '../../../assets/images/no_image_jacket.png';
import { useWindowSize } from '../../context/WindowSize';
import './titleJacketImage.css';

NoJacketImage.propTypes = {
	titleDetails: PropTypes.object,
	sku: PropTypes.string,
	size: PropTypes.string,
};

export default function NoJacketImage({ titleDetails, sku, size }) {
	const { width } = useWindowSize();
	const noJacketRef = React.createRef();
	const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });
	const updateDimensions = () =>
		noJacketRef.current &&
		setDimensions({
			height: noJacketRef.current.offsetHeight,
			width: noJacketRef.current.offsetWidth,
		});

	React.useEffect(updateDimensions, [width]);

	const renderNoJacketCoverText = () => {
		return titleDetails ? (
			<>
				<div className="bold no-jacket-text">
					{titleDetails.title || titleDetails.name}
				</div>
				<div className="no-jacket-text">
					{titleDetails.author || ''}
				</div>
			</>
		) : (
			<div className="bold no-jacket-sku">{sku}</div>
		);
	};

	return (
		<div className={`no-jacket ${size}`}>
			<img
				className={`${size} no-image-placeholder`}
				ref={noJacketRef}
				alt="default title jacket"
				src={noImagePlaceholder}
				onLoad={() => updateDimensions()}
			/>
			{dimensions.width > 0 && (
				<div
					className="jcktShadow no-jacket-text-overlay"
					style={dimensions}>
					<div className="no-jacket-text-container">
						{renderNoJacketCoverText()}
					</div>
				</div>
			)}
		</div>
	);
}
