import React from 'react';
import PropTypes from 'prop-types';
import CustomerFavoritesDataStore from './data/CustomerFavoritesDataStore';
import SeriesFavoritesLane from './SeriesFavorites/SeriesFavoritesLane';
import ShelfFavoritesLane from './ShelfFavorites/ShelfFavoritesLane';
import TitleFavoritesLane from './TitleFavorites/TitleFavoritesLane';
import AuthorFavoritesLane from './AuthorFavorites/AuthorFavoritesLane';
import { ScrollerConfigProvider } from '../UI/Scroller/ScrollerConfigContext';
import ScrollerType from '../UI/Scroller/ScrollerType.js';
import './customerFavorites.less';

class CustomerFavoritesService extends React.Component {
	static propTypes = {
		doShowSeriesLane: PropTypes.bool,
	};

	static defaultProps = {
		doShowSeriesLane: true,
	};

	render() {
		const scrollerConfig = {
			mobile: { scrollerType: ScrollerType.carousel },
			desktop: { scrollerType: ScrollerType.simpleBar },
		};

		return (
			<CustomerFavoritesDataStore>
				<div className="customer-favorites">
					<ScrollerConfigProvider scrollerConfig={scrollerConfig}>
						<TitleFavoritesLane />
					</ScrollerConfigProvider>
					<AuthorFavoritesLane />
					<ShelfFavoritesLane />
					{this.props.doShowSeriesLane && <SeriesFavoritesLane />}
				</div>
			</CustomerFavoritesDataStore>
		);
	}
}

export default CustomerFavoritesService;
