import React from 'react';
import { connectToLogo } from '../../data/BooksellerLogoStore';
import { Button, Icon, notification } from 'antd';
import ImageUploadAnchor from '../Image/ImageUploadAnchor';
import { Translate } from 'react-localize-redux';

function UploadButton({ label, ...rest }) {
	return (
		<Button type="primary" {...rest}>
			<Icon type="upload" /> {label ? label : <Translate id="upload" />}
		</Button>
	);
}

function SplashUploadAnchor({ logoStore }) {
	const onUpload = (base64, translate) => {
		notification.info({
			message: translate('this_is_a_preview'),
		});
		return logoStore.setSplashPreviewSource(base64);
	};
	return (
		<Translate>
			{({ translate }) => {
				return (
					<ImageUploadAnchor
						onUpload={base64 => onUpload(base64, translate)}>
						<UploadButton />
					</ImageUploadAnchor>
				);
			}}
		</Translate>
	);
}

export default connectToLogo(SplashUploadAnchor);
