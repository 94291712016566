import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Carousel from 'react-multi-carousel';
import './scroller.css';
import 'react-multi-carousel/lib/styles.css';
import { ScrollerConfigContext } from './ScrollerConfigContext';
import ScrollerType from '../../UI/Scroller/ScrollerType.js';

const ScrollerConfigConsumer = ScrollerConfigContext.Consumer;

const CustomButtonGroup = ({ mobile, next, previous, ...rest }) => {
	const {
		carouselState: { totalItems, currentSlide },
	} = rest;
	rest.carouselState.itemWidth = 150;

	return (
		<div
			className="carousel-button-group"
			style={{
				visibility: totalItems < 3 && 'hidden',
			}}>
			<button
				className="icon-chevron-left left-carousel-button"
				style={{
					visibility: currentSlide === 0 && 'hidden',
				}}
				onClick={() => previous()}
			/>
			<button
				className="icon-chevron-right right-carousel-button"
				onClick={() => next()}
				style={{
					visibility: currentSlide === totalItems - 2.5 && 'hidden',
				}}
			/>
		</div>
	);
};

class Scroller extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]).isRequired,
	};

	render() {
		return (
			<ScrollerConfigConsumer>
				{scrollerConfig => {
					const scrollerType = this.getScrollerType(scrollerConfig);
					if (scrollerType === ScrollerType.carousel) {
						return this.renderCarouselScroller();
					}
					return this.renderSimplebarScroller();
				}}
			</ScrollerConfigConsumer>
		);
	}

	getScrollerType = scrollerConfig => {
		if (this.props.isMobile()) {
			return scrollerConfig.mobile.scrollerType;
		}
		return scrollerConfig.desktop.scrollerType;
	};

	renderSimplebarScroller = () => {
		return (
			<div className="grandparent scroller-wrapper">
				<SimpleBar {...this.getSimplebarProps()}>
					<div className="parent" style={{ paddingBottom: '1rem' }}>
						{this.props.children}
						<div className="clear" />
					</div>
				</SimpleBar>
			</div>
		);
	};

	getSimplebarProps = () => {
		if (this.props.isMobile()) {
			return {};
		}
		return { forceVisible: 'x', autoHide: false };
	};

	renderCarouselScroller = () => {
		const responsive = {
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 4,
				slidesToSlide: 3,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 2.5,
				slidesToSlide: 1,
			},
		};

		const mobile = this.props.isMobile();

		return (
			<div
				style={{
					position: 'relative',
					paddingBottom: '20px',
				}}>
				<Carousel
					arrows={false}
					responsive={responsive}
					customTransition="all .5"
					draggable={false}
					renderButtonGroupOutside
					customButtonGroup={<CustomButtonGroup mobile={mobile} />}
					swipeable
					partialVisible>
					{this.getChildren()}
				</Carousel>
			</div>
		);
	};

	getChildren = () => {
		const { children } = this.props;
		if (children.props && children.props.children) {
			return React.Children.toArray(this.props.children.props.children);
		}
		return [];
	};
}

export default withIsMobile(Scroller);
