import React from 'react';
import PropTypes from 'prop-types';
import RateAndReview from '../../RateAndReview/RateAndReview';
import { useTransactionsDataStore } from '../../data/TransactionsDataStore';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import LaneContainer from '../../UI/LaneContainer';
import withIsMobile from '../../hoc/withIsMobile';

TitleInset.propTypes = {
	sku: PropTypes.string,
	isMobile: PropTypes.func,
	userHasPurchased: PropTypes.bool,
};

function TitleInset({ sku, userHasPurchased, isMobile }) {
	const { countTransactions } = useTransactionsDataStore();
	return (
		<LaneContainer backgroundClass="white-background">
			<div style={{ padding: '0.5rem 0', paddingRight: '20px' }}>
				{userHasPurchased && (
					<div style={{ display: 'flex' }}>
						<div
							style={{
								flex: isMobile() ? '65px 0 0' : '45px 0 0',
								marginTop: '5px',
							}}>
							<div
								className="icon-approved"
								style={{
									fontSize: '36px',
									height: '36px',
									color: 'green',
									marginTop: isMobile() ? 0 : '-13px',
									marginBottom: '10px',
								}}
							/>
						</div>
						<div
							style={{
								flexGrow: '1',
								fontSize: '18px',
								fontWeight: 'bold',
							}}>
							<div>{countTransactions(sku)}</div>
						</div>
					</div>
				)}
				<div>
					<RateAndReview
						entityId={sku}
						entityType={RecommendableEntity.title}
					/>
				</div>
			</div>
		</LaneContainer>
	);
}

export default withIsMobile(TitleInset);
