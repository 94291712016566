import Convert from '../util/Convert';

export default class RecommendableEntity {
	id = '';
	type = '';
	name = '';
	entity = {};

	static title = 'title';
	static section = 'section';
	static department = 'department';
	static event = 'event';
	static catalog = 'catalog';
	static author = 'author';
	static series = 'series';

	constructor(id, type, name, entity) {
		if (id) {
			this.id = Convert.toString(id);
		}

		if (type && typeof type === 'string') {
			this.type = type.toLowerCase();
		}

		if (name) {
			this.name = name;
		}

		if (entity) {
			this.entity = entity;
		}
	}
}
