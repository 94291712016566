import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import { connectToTransactions } from '../../data/TransactionsDataStore';
import TitleJacketImage from '../../Title/UI/TitleJacketImage';
import { Spin, Rate, Button } from 'antd';
import TitleDetailDrawer from '../../Title/TitleDetailDrawer';
import './purchaseDetail.css';
import { getAuthorDisplayNameAndInitials } from '../../../author/author.utils';
import { Translate } from 'react-localize-redux';

class PurchaseDetail extends React.Component {
	static propTypes = {
		uri: PropTypes.string,
		posData: PropTypes.object,
		rating: PropTypes.object,
		isMobile: PropTypes.func,
		transactionsDataStore: PropTypes.object,
	};

	render() {
		if (
			!this.props.transactionsDataStore ||
			this.props.transactionsDataStore.isLoading()
		) {
			return this.renderLoading();
		}
		return this.renderPurchaseDetailUI();
	}

	renderLoading() {
		return (
			<div style={{ padding: '6rem 2rem', display: 'flex' }}>
				<Spin spinning={true} />
				<span style={{ marginLeft: '1rem' }}>
					<Translate id="loading" />
					...
				</span>
			</div>
		);
	}

	renderPurchaseDetailUI() {
		const { transactionsDataStore, rating, posData, uri } = this.props;
		const isMobile = this.props.isMobile();
		const author =
			posData.author &&
			getAuthorDisplayNameAndInitials(posData.author).displayName;

		return (
			<div
				className="purchase-detail-container"
				style={{
					margin: isMobile ? '20px 20px 30px 20px' : '20px 0 0 20px',
				}}>
				<div
					style={{
						width: isMobile ? '100px' : '140px',
						minWidth: isMobile ? '100px' : '140px',
					}}>
					<TitleDetailDrawer sku={posData.sku}>
						<TitleJacketImage
							size={TitleJacketImage.fixedwidth}
							title={{
								sku: posData.sku,
								imageUri: uri,
								pos: [posData],
							}}
						/>
					</TitleDetailDrawer>
				</div>
				<div className="purchase-text">
					<div>
						<div>{posData.title}</div>
						{author && (
							<Translate
								id="by_author_x"
								data={{ authorName: author }}
							/>
						)}
					</div>
					{transactionsDataStore &&
						transactionsDataStore.includes(posData.sku) && (
							<div style={{ fontSize: '14px' }}>
								{transactionsDataStore.countTransactions(
									posData.sku,
								)}
							</div>
						)}
					{rating ? (
						<Rate
							allowHalf
							value={rating.rating}
							allowClear={true}
							onChange={this.handleChange}
						/>
					) : (
						<TitleDetailDrawer sku={posData.sku}>
							<Button
								style={{
									alignItems: 'center',
									display: 'flex',
									marginTop: '10px',
								}}>
								<div
									className="icon-star"
									style={{
										padding: '2px 5px 0 0',
									}}></div>{' '}
								<Translate id="rate_and_review" />
							</Button>
						</TitleDetailDrawer>
					)}
				</div>
			</div>
		);
	}
}

export default connectToTransactions(withIsMobile(PurchaseDetail));
