import React from 'react';
import AppFrame from './components/AppFrame';
import AppSwitchWithRouter from './components/AppSwitch';
import { ScrollerConfigProvider } from './components/UI/Scroller/ScrollerConfigContext';

export default function App() {
	if (window.Appcues) {
		window.Appcues.anonymous();
	}

	return (
		<AppFrame>
			<ScrollerConfigProvider>
				<AppSwitchWithRouter />
			</ScrollerConfigProvider>
		</AppFrame>
	);
}
