import React from 'react';
import PropTypes from 'prop-types';
import { useImage } from '../../hooks/useImage';
import TitleSkeleton from './TitleSkeleton';
import NoJacketImage from './NoJacketImage';
import './titleJacketImage.css';

const defaultJacketUris = [
	'images/common/noimage.png',
	'https://images.abovethetreeline.com/ea/OO/images/jacket_covers/default_jacket_oo.png',
	'https://edel-images.azureedge.net/ea/OO/images/jacket_covers/default_jacket_oo.png',
];

const small = 'small-image';
const medium = 'medium-image';
const large = 'large-image';
const pick = 'pick-image';
const mobile = 'mobile-image';
const fillwidth = 'fillwidth-image';
const fixedwidth = 'fixedwidth-image';

function JacketImage({ size, imageUri, title, isTitleDetail }) {
	const { hasLoaded, hasError } = useImage(imageUri);

	const hasValidImageUri = () => {
		return (
			imageUri && !defaultJacketUris.some(uri => imageUri.includes(uri))
		);
	};

	if (hasError || !hasValidImageUri()) {
		const titleDetails =
			Array.isArray(title.pos) && title.pos.length
				? title.pos[0]
				: title.product
				? title.product
				: title;
		return (
			<NoJacketImage
				sku={title.sku}
				titleDetails={titleDetails}
				size={size}
			/>
		);
	}

	if (!hasLoaded) {
		return <TitleSkeleton isTitleDetail={isTitleDetail} />;
	}

	return (
		<img
			className={`${size} jcktShadow`}
			src={imageUri}
			alt="Book Jacket"
		/>
	);
}

export default class TitleJacketImage extends React.Component {
	static propTypes = {
		size: PropTypes.string,
		onClick: PropTypes.func, // not required
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
		title: PropTypes.shape({
			sku: PropTypes.string,
			imageUri: PropTypes.string,
			pos: PropTypes.array,
		}),
		isTitleDetail: PropTypes.bool,
	};

	static small = small;
	static medium = medium;
	static mobile = mobile;
	static large = large;
	static pick = pick;
	static fillwidth = fillwidth;
	static fixedwidth = fixedwidth;

	static defaultProps = {
		size: mobile,
		title: {},
		isTitleDetail: false,
	};

	render() {
		let name = 'title-jacket-image relative';
		let onClick;
		const { size, title, isTitleDetail, children } = this.props;

		if (typeof this.props.onClick === 'function') {
			name = `${name} clickable`;
			onClick = this.props.onClick;
		}

		return (
			<div className={name} onClick={onClick}>
				<JacketImage
					size={size}
					imageUri={this.getImageUri()}
					title={title}
					isTitleDetail={isTitleDetail}
				/>
				{children}
			</div>
		);
	}

	getImageUri() {
		const { title } = this.props;
		return title.imageUri
			? title.imageUri
			: title.product
			? title.product.imageUri
			: null;
	}
}
