import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import CustomerListType from '../../entity/CustomerListType';
import RecommendableEntityType from '../../types/RecommendableEntity';
import { connectToCustomerID } from '../data/CustomerIDStore';
import { connectToRecentlyViewed } from './data/RecentlyViewedDataStore';
import combineConnectors from '../data/combineConnectors';

class RecentlyViewedEntity extends React.Component {
	static propTypes = {
		children: PropTypes.element,
		recommendableEntity: RecommendableEntityType,
		// mapped to props
		customerId: PropTypes.string,
		isPreviewMode: PropTypes.bool,
		isUnknownCustomer: PropTypes.bool,
		recentlyViewedDataStore: PropTypes.object,
	};

	render() {
		return this.props.children;
	}

	componentDidMount() {
		if (this.doSaveEntityToRecentlyViewedForCustomer()) {
			this.saveEntityToRecentlyViewed();
		}
	}

	doSaveEntityToRecentlyViewedForCustomer = () => {
		return !this.props.isPreviewMode && !this.props.isUnknownCustomer;
	};

	saveEntityToRecentlyViewed = () => {
		const { recentlyViewedDataStore, recommendableEntity } = this.props;

		if (typeof recentlyViewedDataStore !== 'undefined') {
			recentlyViewedDataStore.save(recommendableEntity);
		} else {
			this.postRecentlyViewedEntity();
		}
	};

	postRecentlyViewedEntity = () => {
		const { customerId, recommendableEntity } = this.props;

		const entityDTO = {
			customerId,
			entityId: recommendableEntity.id,
			entityType: recommendableEntity.type,
			listType: CustomerListType.recentlyViewed,
		};

		api.post('/customer/entity', entityDTO);
	};
}

const withCustomerState = Component => {
	return connectToCustomerID(Component, customerState => ({
		customerId: customerState.customerId,
		isPreviewMode: customerState.isPreviewMode,
		isUnknownCustomer: customerState.isUnknownCustomer,
	}));
};

const connectors = [withCustomerState, connectToRecentlyViewed];

export default combineConnectors(connectors)(RecentlyViewedEntity);
