import React from 'react';
import PropTypes from 'prop-types';
import DetailDrawerAnchor from '../../UI/DetailDrawerAnchor';
import api from '../../../api';
import { useCustomerID } from '../../data/CustomerIDStore';
import TitleAggregateParser from '../../../title/TitleAggregateParser';
import AllAvailableTitles from '../../TitleLanes/AllAvailableTitles';
import { Translate } from 'react-localize-redux';
import StaffPicksDataStore from '../../StaffPicks/data/StaffPicksDataStore';
import { FavoriteType } from '../FavoriteType';

FavoriteTitles.propTypes = {
	entity: PropTypes.object,
};

FavoriteTitlesDrawer.propTypes = {
	children: PropTypes.func,
	entity: PropTypes.object,
	typeOfFavorite: PropTypes.string,
};

export default function FavoriteTitlesDrawer({
	children,
	entity,
	typeOfFavorite,
}) {
	return (
		<DetailDrawerAnchor
			renderDrawerContent={() => (
				<FavoriteTitles
					entity={entity}
					typeOfFavorite={typeOfFavorite}
				/>
			)}>
			{children}
		</DetailDrawerAnchor>
	);
}

function FavoriteTitles({ entity, typeOfFavorite }) {
	const isFavoriteAuthor = typeOfFavorite === FavoriteType.author;
	const { orgId } = useCustomerID();
	const endpoint = isFavoriteAuthor
		? `/org/${orgId}/contributor/${entity.id}/titles`
		: `/org/${orgId}/search/series/titles?seriesName=${entity.name}`;

	const fetchFavoriteTitles = () => {
		return api.get(endpoint).then(titleAggregate => {
			const parser = new TitleAggregateParser(titleAggregate);
			return { titles: parser.buildTitles() };
		});
	};

	return (
		<StaffPicksDataStore>
			<AllAvailableTitles
				header={
					isFavoriteAuthor ? (
						<Translate
							id="works_by_x"
							data={{ name: entity.displayName }}
						/>
					) : (
						<Translate
							id="titles_in_the_x_series"
							data={{ name: entity.name }}
						/>
					)
				}
				fetchAllAvailableTitles={fetchFavoriteTitles}
			/>
		</StaffPicksDataStore>
	);
}
