import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import makeConnectTo from './makeConnectTo';
import { connectToCustomerID } from './CustomerIDStore';

const OrgContext = React.createContext();

export const connectToOrg = makeConnectTo(OrgContext, 'orgDataStore');

export const useOrganizationDataStore = () => {
	return React.useContext(OrgContext);
};

class OrganizationDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]),
	};

	state = {
		isLoading: true,
		organization: {},
		preferenceData: [],
		ordersSettings: {},
		shippingMethods: [],
	};

	render() {
		return (
			<OrgContext.Provider value={this.getDataStore()}>
				{this.props.children}
			</OrgContext.Provider>
		);
	}

	componentDidMount() {
		Promise.all([
			this.fetchOrg(),
			this.fetchPreferenceData(),
			this.fetchShippingMethods(),
		]).then(() => {
			this.setState({ isLoading: false });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const prevHasOrgName = this.hasOrgName(prevState);
		const thisHasOrgName = this.hasOrgName(this.state);

		if (!prevHasOrgName && thisHasOrgName) {
			window.document.title = this.getOrgName(this.state);
		}
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getOrgName: () => this.getOrgName(state),
		getOrgCountry: () => state.organization.country,
		getOrgShippingMethods: () => state.shippingMethods,
	});

	hasOrgName = state => {
		const { displayName, name, preferredName } = state.organization;
		return (
			this.isValidOrgName(name) ||
			this.isValidOrgName(displayName) ||
			this.isValidOrgName(preferredName)
		);
	};

	getOrgName = (state = this.state) => {
		const { displayName, name, preferredName } = state.organization;
		if (this.isValidOrgName(displayName)) return displayName;
		if (this.isValidOrgName(preferredName)) return preferredName;
		return this.isValidOrgName(name) ? name : 'Your Bookstore';
	};

	isValidOrgName = name => typeof name === 'string' && name.length > 0;

	fetchOrg = () => {
		const { orgId } = this.props.customerState;
		return api
			.get(`/org/${orgId}/organization`)
			.then(organization => {
				return this.setState({ organization });
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load organization');
				});
			});
	};

	fetchPreferenceData = () => {
		const { orgId } = this.props.customerState;
		return api
			.get(`/org/${orgId}/info`)
			.then(preferenceData => {
				return this.setState({ preferenceData });
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load organization preferences');
				});
			});
	};

	fetchShippingMethods = () => {
		const { orgId } = this.props.customerState;
		return api
			.get(`/orgs/${orgId}/shipping`)
			.then(shippingMethods => {
				return this.setState({ shippingMethods });
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load shipping methods');
				});
			});
	};
}

export default connectToCustomerID(OrganizationDataStore);
