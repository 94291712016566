import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSectionsDataStore } from '../../data/SectionsDataStore';
import { Button, Collapse, Icon } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import './staffProfile.less';

StaffProfile.propTypes = {
	staffUser: PropTypes.object,
	staffBio: PropTypes.object,
	isMobile: PropTypes.func,
};

function StaffProfile({ staffUser, staffBio, isMobile }) {
	const { getDepartment, getSection } = useSectionsDataStore();
	const shelfType =
		staffBio.shelves.length > 0 &&
		staffBio.shelves[0] &&
		(staffBio.shelves[0].departmentId
			? 'department'
			: staffBio.shelves[0].sectionId
			? 'section'
			: null);
	const shelfTypeId = `${shelfType}Id`;
	const isDepartment = shelfType === 'department';
	const nameType = isDepartment ? 'departmentName' : 'displayName';
	const favoriteShelves =
		shelfType &&
		staffBio.shelves.sort((a, b) => (a[nameType] > b[nameType] ? 1 : -1));
	const validShelfIds =
		shelfType &&
		staffBio.shelves.reduce((acc, shelf) => {
			const shelfDetails = isDepartment
				? getDepartment(shelf.departmentId)
				: getSection(shelf.sectionId);
			shelfDetails && acc.push(shelfDetails[shelfTypeId]);
			return acc;
		}, []);
	const { Panel } = Collapse;

	const isValidShelf = shelf =>
		Array.isArray(validShelfIds) &&
		validShelfIds.includes(shelf[shelfTypeId]);

	const renderStaffBio = () => (
		<div className="staff-bio-about">
			<p className="staff-bio-about-title">
				<Translate id="about_x" data={{ name: staffUser.firstName }} />
			</p>
			<p className="staff-bio-about-text">{staffBio.aboutText}</p>
		</div>
	);

	const renderShelfButton = (shelf, idx) => {
		const isValid = isValidShelf(shelf);
		return (
			<Button
				key={idx}
				className={isValid ? 'linked-shelf-button' : 'shelf-button'}>
				<p className="shelf-button-text dotDot">
					{isValid ? (
						<Link
							to={`/section/${shelfType}/${shelf[shelfTypeId]}`}>
							{shelf[nameType]}
						</Link>
					) : (
						shelf[nameType]
					)}
				</p>
			</Button>
		);
	};

	const renderFavoriteShelves = () => (
		<div className="staff-bio-favorite-shelves">
			<p>
				<Translate
					id="xs_favorite_shelves"
					data={{ name: staffUser.firstName }}
				/>
			</p>
			<div className="favorite-shelves">
				{Array.isArray(favoriteShelves) &&
					favoriteShelves.map((shelf, idx) =>
						renderShelfButton(shelf, idx),
					)}
			</div>
		</div>
	);

	return (
		<div className="staff-bio-container">
			<Collapse
				expandIcon={({ isActive }) => (
					<Icon type="right" rotate={isActive ? -90 : 0} />
				)}>
				<Panel
					key="1"
					header={
						<Translate
							id="learn_more_about_x"
							data={{ name: staffUser.firstName }}
						/>
					}
					style={{ width: isMobile() ? '100%' : '700px' }}>
					{staffBio.aboutText && renderStaffBio()}
					{shelfType && renderFavoriteShelves()}
				</Panel>
			</Collapse>
		</div>
	);
}

export default withIsMobile(StaffProfile);
