import React from 'react';
import LaneContainer from '../UI/LaneContainer';
import SectionsScroller from '../Section/UI/SectionsScroller';
import RecommendableEntity from '../../entity/RecommendableEntity';
import DetailDrawerAnchor from '../UI/DetailDrawerAnchor';
import { Translate } from 'react-localize-redux';
import StaffPicksDetail from './Drawers/StaffPicksDetail';
import { useStaffPicksDataStore } from './data/StaffPicksDataStore';

export default function StaffPicksBySectionLane() {
	const { getStaffPickSections } = useStaffPicksDataStore();
	const staffPickSections = getStaffPickSections();

	const renderSections = () => {
		return staffPickSections.map(section => {
			return (
				<div key={section.sectionCode} style={{ width: 260 }}>
					{renderOneStaffPickSectionAnchor(section)}
				</div>
			);
		});
	};

	const renderOneStaffPickSectionAnchor = section => {
		const {
			titles,
			sectionCode,
			displayName,
			sectionName,
			sectionId,
		} = section;
		const name = displayName ? displayName : sectionName;

		const sectionType = RecommendableEntity.section;
		const entity = new RecommendableEntity(sectionId, sectionType, name);

		return (
			<DetailDrawerAnchor
				renderDrawerContent={() => renderDrawerContent(sectionCode)}>
				{drawer => {
					return (
						<SectionsScroller.SectionAnchor
							genreCount={staffPickSections.length}
							entity={entity}
							onClickOnName={drawer.open}
							count={titles.length}
						/>
					);
				}}
			</DetailDrawerAnchor>
		);
	};

	const renderDrawerContent = sectionCode => (
		<StaffPicksDetail sectionCode={sectionCode} />
	);

	return (
		<LaneContainer
			majorHeader={<Translate id="staff_picks_by_shelf" />}
			subHeader={<Translate id="see_what_our_booksellers_recommend" />}>
			<SectionsScroller>{renderSections()}</SectionsScroller>
		</LaneContainer>
	);
}
