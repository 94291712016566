import PropTypes from 'prop-types';

const Review = PropTypes.shape({
	abstract: PropTypes.string,
	appUserId: PropTypes.number,
	user: PropTypes.object,
	overallAssessment: PropTypes.number,
	sku: PropTypes.string,
	submissions: PropTypes.object,
	updatedDate: PropTypes.string,
});

export default Review;
