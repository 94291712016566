import React from 'react';
import PropTypes from 'prop-types';
import delay from '../../../util/delay';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useBagDataStore } from '../data/BagDataStore';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import { useDrawer } from '../../context/DrawerContext';
import { Translate } from 'react-localize-redux';
import { paths } from '../../../paths';
import './bagBuyNowButton.css';

BagBuyNowButton.propTypes = {
	sku: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.object,
	titleName: PropTypes.string,
	block: PropTypes.bool,
};

BagBuyNowButton.defaultProps = {
	block: true,
	type: 'primary',
	label: <Translate id="buy_now_uppercase" />,
};

export default function BagBuyNowButton({
	sku,
	titleName,
	label,
	type,
	block,
}) {
	const drawer = useDrawer();
	const bagDataStore = useBagDataStore();

	const history = useHistory();
	const routeMatch = useRouteMatch();

	const [isLoading, setIsLoading] = React.useState(false);

	const getTitleAsEntity = () => {
		const type = RecommendableEntity.title;
		const title = { sku, name: titleName };
		return new RecommendableEntity(sku, type, titleName, title);
	};

	const handleAddToBag = async () => {
		setIsLoading(true);
		const isInBag = bagDataStore.includes(sku);

		if (isInBag) {
			await delay(1000);
		} else {
			await bagDataStore.save(getTitleAsEntity());
			await delay(300);
		}

		drawer && drawer.close && drawer.close();
		await redirectToOrderPage();
	};

	const redirectToOrderPage = async () => {
		await delay(800);
		history.push('/order');
	};

	const getButtonType =
		isLoading || routeMatch.path === paths.order() ? 'disabled' : type;

	return (
		<div className="bag-buy-now-button-root">
			<Spin spinning={isLoading}>
				<Button
					block={block}
					type={getButtonType}
					className="bag-buy-now-button"
					onClick={handleAddToBag}>
					{label}
				</Button>
			</Spin>
		</div>
	);
}
