import React from 'react';
import { Modal, Button } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { useBooksellerContentDataStore } from '../data/BooksellerContentDataStore';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import { Translate } from 'react-localize-redux';
import { useLocation } from 'react-router-dom';
import { paths } from '../../paths';
import './retailerMessage.css';

export default function RetailerMessage() {
	const { state } = useBooksellerContentDataStore();
	const { getAppSettingAtKey } = useBooksellerPreferencesDataStore();
	const doShowMessageOnExplorePageOnly = getAppSettingAtKey(
		'doShowMessageOnExplorePageOnly',
	);
	const { retailerMessageTitle, retailerMessageBody } = state.customerApp;
	const location = useLocation();
	const isAuthPage = paths.isAuthPage(location.pathname);
	const isExplorePage = location.pathname === paths.explore();

	const [isVisible, setIsVisible] = React.useState(
		!isAuthPage &&
			!sessionStorage.hasViewedRetailerMessage &&
			(!doShowMessageOnExplorePageOnly ||
				(doShowMessageOnExplorePageOnly && isExplorePage)),
	);

	const onCancel = () => {
		sessionStorage.setItem('hasViewedRetailerMessage', true);
		setIsVisible(false);
	};

	return (
		<>
			<div
				onClick={() => setIsVisible(true)}
				className="column retailer-message-navigation-anchor">
				<div className="column-icon icon-comment" />
				<div className="column-text">
					<Translate id="info" />
				</div>
			</div>
			<Modal
				className="retailer-message-modal"
				closable={false}
				width={600}
				onCancel={onCancel}
				title={
					retailerMessageTitle ? (
						ReactHtmlParser(retailerMessageTitle)
					) : (
						<Translate id="welcome" />
					)
				}
				visible={isVisible}
				footer={[
					<Button key={'ok'} onClick={onCancel}>
						<Translate id="ok" />
					</Button>,
				]}>
				<div style={{ marginTop: 10 }}>
					{ReactHtmlParser(retailerMessageBody)}
				</div>
			</Modal>
		</>
	);
}
