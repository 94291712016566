import React from 'react';
import PropTypes from 'prop-types';
import BooksellerSplashImage from '../UI/BooksellerSplashImage';
import RecentShelvesTitleLanes from '../RecentShelves/RecentShelvesTitleLanes';
import WishListTitlesLane from '../Wishlist/WishlistTitlesLane';
import StaffPicksSummary from '../StaffPicks/StaffPicksSummary';
import OrganizationInfo from '../Organization/OrganizationInfo';
import StaffPickLaneType from '../../entity/StaffPickLaneType';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import RewardsProgress from '../Rewards/RewardsProgress';
import { connectToLogo } from '../data/BooksellerLogoStore';
import {
	withIsUnknownCustomer,
	connectToPreviewMode,
	withIsDemoBookstore,
} from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import TitleSearchService from '../Title/Search/TitleSearchService';
import withIsMobile from '../hoc/withIsMobile';
import RecentlyViewedLane from '../RecentlyViewed/RecentlyViewedLane';
import { connectToRecentlyViewed } from '../RecentlyViewed/data/RecentlyViewedDataStore';
import { Translate } from 'react-localize-redux';
import { connectToSellerContent } from '../data/BooksellerContentDataStore';
import './homePage.css';

class HomePage extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		onDidMount: PropTypes.func,
		logoStore: PropTypes.object,
		isUnknownCustomer: PropTypes.bool,
		previewMode: PropTypes.object,
		isDemoBookstore: PropTypes.bool,
		recentlyViewedDataStore: PropTypes.object,
		appSettings: PropTypes.shape({
			doIncludeCommerce: PropTypes.bool,
			headerColor: PropTypes.string,
		}),
		gradient: PropTypes.string,
		booksellerContentStore: PropTypes.object,
	};

	render() {
		return (
			<TitleSearchService>
				{titleSearchService => {
					return this.renderHomePageContent(titleSearchService);
				}}
			</TitleSearchService>
		);
	}

	renderHomePageContent(titleSearchService) {
		const { headerColor, gradient } = this.props.appSettings;
		const { getStoreLocations } = this.props.booksellerContentStore;
		const { isMobile } = this.props;
		const style = isMobile()
			? { backgroundImage: gradient }
			: { background: headerColor };
		const storeLocations = getStoreLocations();

		return (
			<div className="home-page" style={style}>
				{this.doRenderSplash() && <BooksellerSplashImage />}
				{titleSearchService.renderTitleSearch(
					!isMobile() ? <Translate id="search" /> : '',
				)}
				{this.doRenderRecentlyViewedLane() && <RecentlyViewedLane />}
				{this.hasValidCustomer() && <WishListTitlesLane />}
				{this.doRenderRewardsProgressLane() && <RewardsProgress />}
				<StaffPicksSummary
					staffPickLaneType={StaffPickLaneType.byStaffMember}
				/>
				<RecentShelvesTitleLanes maxLanes={1} />
				{storeLocations.length > 0 && (
					<OrganizationInfo storeLocations={storeLocations} />
				)}
			</div>
		);
	}

	componentDidMount() {
		if (typeof this.props.onDidMount === 'function') {
			this.props.onDidMount();
		}
	}

	doRenderRewardsProgressLane() {
		const { appSettings, isDemoBookstore } = this.props;
		return (
			appSettings.doIncludeCommerce &&
			this.hasValidCustomer() &&
			isDemoBookstore
		);
	}

	doRenderRecentlyViewedLane() {
		const { recentlyViewedDataStore: recentlyViewed } = this.props;
		return recentlyViewed.any() && this.hasValidCustomer();
	}

	doRenderSplash() {
		const { logoStore, previewMode } = this.props;
		return previewMode.isActive() ? true : logoStore.hasSplashImage();
	}

	hasValidCustomer() {
		const { previewMode, isUnknownCustomer } = this.props;
		return !isUnknownCustomer && !previewMode.isActive();
	}
}

const withAppSettings = Component => {
	return connectToSellerPreferences(Component, preferences => ({
		appSettings: preferences.getAppSettings(),
		gradient: preferences.getGradient(),
	}));
};

const connectors = [
	connectToLogo,
	withIsDemoBookstore,
	connectToPreviewMode,
	withIsUnknownCustomer,
	withAppSettings,
	withIsMobile,
	connectToRecentlyViewed,
	connectToSellerContent,
];

export default combineConnectors(connectors)(HomePage);
