import React from 'react';
import PropTypes from 'prop-types';
import makeConnectTo from '../../data/makeConnectTo';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import api from '../../../api';

const WishlistSkusContext = React.createContext();

const name = 'wishlistSkusDataStore';
export const connectToWishlistSkus = makeConnectTo(WishlistSkusContext, name);

const actions = {
	ADD_SKU: 'ADD_SKU',
	REMOVE_SKU: 'REMOVE_SKU',
};

class WishlistSkusDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	};

	state = {
		skus: [],
		isLoading: true,
	};

	render() {
		return (
			<WishlistSkusContext.Provider value={this.getDataStore()}>
				{typeof this.props.children === 'function'
					? this.props.children(this.getDataStore())
					: this.props.children}
			</WishlistSkusContext.Provider>
		);
	}

	componentDidMount() {
		if (this.props.customerState.isUnknownCustomer) {
			return this.setState({ isLoading: false });
		}
		this.fetchWishlistSkus();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		count: () => state.skus.length,
		add: sku => this.addSkuAction(sku),
		remove: sku => this.removeSkuAction(sku),
		includes: sku => this.wishlistIncludesSku(sku),
	});

	fetchWishlistSkus = () => {
		const { customerState: c } = this.props;
		const endpoint = `/org/${c.orgId}/customer/${c.customerId}/titles/wishlist/skus`;

		this.request = api.cancelableGet(endpoint);

		this.request.promise.then(wishlistData => {
			this.setState({ skus: wishlistData.skus, isLoading: false });
		});
	};

	wishlistIncludesSku(sku) {
		return this.getSkus().includes(sku);
	}

	addSkuAction = sku => {
		this.update({ type: actions.ADD_SKU, payload: sku });
	};

	removeSkuAction = sku => {
		this.update({ type: actions.REMOVE_SKU, payload: sku });
	};

	update = action => {
		this.setState(state => this.resolveNextStateFromAction(state, action));
	};

	resolveNextStateFromAction = (state, action) => {
		switch (action.type) {
			case actions.ADD_SKU:
				return this.addSku(state, action.payload);
			case actions.REMOVE_SKU:
				return this.removeSku(state, action.payload);
			default:
				return state;
		}
	};

	addSku = (state, sku) => {
		return { ...state, skus: [...state.skus, sku] };
	};

	removeSku = (state, targetSku) => {
		return { ...state, skus: state.skus.filter(sku => sku !== targetSku) };
	};

	getSkus = () => {
		return this.state.skus;
	};
}

export default connectToCustomerID(WishlistSkusDataStore);
