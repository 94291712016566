import React from 'react';
import PropTypes from 'prop-types';
import useActiveRoute from '../hooks/useActiveRoute';

NavigationAnchorRoot.propTypes = {
	onClick: PropTypes.func,
	routes: PropTypes.arrayOf(PropTypes.string),
	alsoMatches: PropTypes.arrayOf(PropTypes.object), // array of RegExp
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

NavigationAnchorRoot.defaultProps = {
	alsoMatches: [],
};

export default function NavigationAnchorRoot({
	routes,
	children,
	onClick,
	alsoMatches,
}) {
	const { isAnyRouteActive, isAnyMatchActive } = useActiveRoute(
		routes,
		alsoMatches,
	);

	const activeName = 'nav-anchor-active';

	const isNavAnchorActive = () => {
		return isAnyRouteActive() || isAnyMatchActive();
	};

	const baseName = 'footer-navigation-anchor column';
	const isActive = isNavAnchorActive();

	const className = isActive ? `${baseName} ${activeName}` : baseName;

	return (
		<div className={className} onClick={onClick}>
			{children}
		</div>
	);
}
