import React from 'react';
import PropTypes from 'prop-types';
import SectionTitleLanesDataStore from './data/SectionTitleLanesDataStore';
import TitleLanes from '../TitleLanes';
import SectionHeader from './SectionHeader';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import StaffPicksDataStore from '../StaffPicks/data/StaffPicksDataStore';
import StaffPicksByMemberLane from '../StaffPicks/StaffPicksByMemberLane';
import { Spin } from 'antd';
import SectionLevel from '../../entity/SectionLevel';
import { useSectionsDataStore } from '../data/SectionsDataStore';
import { useTitleSearchService } from '../Title/Search/TitleSearchService';
import withIsMobile from '../hoc/withIsMobile';
import SectionPopover from './SectionPopover';
import './sectionHome.css';

SectionHome.propTypes = {
	entityId: PropTypes.string,
	sectionLevel: PropTypes.string,
	isMobile: PropTypes.func,
};

function SectionHome({ entityId, sectionLevel, isMobile }) {
	const { renderTitleSearch } = useTitleSearchService(isMobile);
	const { getDepartment, getSection } = useSectionsDataStore();
	const [shelfName, setShelfName] = React.useState('');

	const getDisplayName = () => {
		if (sectionLevel === SectionLevel.section) {
			return getHeaderForSection();
		}
		if (sectionLevel === SectionLevel.department) {
			return getHeaderForDepartment();
		}
		throw new Error('Invalid Path!');
	};

	const getHeaderForDepartment = () => {
		const department = getDepartment(entityId);
		setShelfName(department.departmentName);
		return department.sections.length ? (
			<SectionPopover department={department} />
		) : (
			department.departmentName
		);
	};

	const findDepartment = section => {
		const { departmentId } = section;
		return getDepartment(departmentId);
	};

	const getHeaderForSection = () => {
		const section = getSection(entityId);
		const sectionName = section.displayName
			? section.displayName
			: section.sectionName;
		const department =
			section.departmentId && section.departmentId !== '0'
				? findDepartment(section)
				: null;
		setShelfName(sectionName);
		return (
			<div className="dotDot section-header-container">
				{department && department.sections.length ? (
					<SectionPopover department={department} section={section} />
				) : (
					sectionName
				)}
			</div>
		);
	};

	const renderLoading = () => {
		return (
			<div className="loading-container">
				<Spin />
			</div>
		);
	};

	const renderStaffPicks = () => {
		return (
			<div className="staff-picks">
				<StaffPicksByMemberLane shelfName={shelfName} />
			</div>
		);
	};

	const renderPageWithDataStore = titleLanesDataStore => {
		const displayName = getDisplayName();
		return (
			<div className="section-home">
				<SectionHeader displayName={displayName} />
				{renderTitleSearch()}
				<StaffPicksDataStore
					entityId={entityId}
					sectionLevel={sectionLevel}>
					{staffPicksDataStore => {
						return staffPicksDataStore.isLoading()
							? renderLoading()
							: !staffPicksDataStore.isLoading() &&
							  staffPicksDataStore.hasReviews()
							? renderStaffPicks()
							: null;
					}}
				</StaffPicksDataStore>
				<TitleLanes titleLanesDataStore={titleLanesDataStore} />
			</div>
		);
	};

	return (
		<WishlistSkusDataStore>
			<CustomerFavoritesDataStore>
				<SectionTitleLanesDataStore
					entityId={entityId}
					sectionLevel={sectionLevel}>
					{titleLanesDataStore => {
						return renderPageWithDataStore(titleLanesDataStore);
					}}
				</SectionTitleLanesDataStore>
			</CustomerFavoritesDataStore>
		</WishlistSkusDataStore>
	);
}

export default withIsMobile(SectionHome);
