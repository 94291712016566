export default class Convert {
	static toString(input) {
		return typeof input === 'number' ? input.toString() : input;
	}

	static toNumber(input) {
		if (!input) return 0;
		if (typeof input === 'number') return input;
		const asNum = parseInt(input);
		return Number.isNaN(asNum) ? 0 : asNum;
	}
}
