import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import TitleJacketImage from './TitleJacketImage';
import TitleSummary from './TitleSummary';
import { Card } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import { connectToFavorites } from '../../CustomerFavorites/data/CustomerFavoritesDataStore';
import FavoriteEntityAnchor from '../../CustomerFavorites/UI/FavoriteEntityAnchor';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import { withIsUnknownCustomer } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import { connectToSellerPreferences } from '../../data/BooksellerPreferencesDataStore';

class TitleCard extends React.Component {
	static propTypes = {
		title: Customer360PropTypes.Title,
		titleNameFontSize: PropTypes.number,
		children: PropTypes.element,
		// Mapped to props only
		isMobile: PropTypes.func,
		isUnknownCustomer: PropTypes.bool,
		favoritesDataStoreIsPresent: PropTypes.bool,
		gradient: PropTypes.string,
	};

	static defaultProps = {
		titleNameFontSize: 18,
	};

	render() {
		const { title, isMobile, gradient } = this.props;
		const size = isMobile() ? 'fillwidth-image' : 'medium-image';
		return (
			<Card
				className="title-card"
				style={{
					backgroundImage: gradient,
				}}>
				<div className="flex biblio-section">
					<div
						className="main-jacket"
						style={{
							margin: isMobile() ? '20px' : '0 0 0 10px',
							position: 'relative',
						}}>
						<TitleJacketImage
							size={size}
							title={title}
							isTitleDetail={true}>
							{this.props.favoritesDataStoreIsPresent &&
								!this.props.isUnknownCustomer &&
								this.renderFavoriteEntityAnchor()}
						</TitleJacketImage>
					</div>
					<TitleSummary
						title={title}
						titleNameFontSize={this.props.titleNameFontSize}
						shouldTruncateName={false}
						doShowSection={true}>
						{this.props.children}
					</TitleSummary>
				</div>
			</Card>
		);
	}

	renderFavoriteEntityAnchor() {
		const { title } = this.props;

		const type = RecommendableEntity.title;
		const entity = new RecommendableEntity(title.sku, type, title.name);

		return <FavoriteEntityAnchor entity={entity} isTitleDetail={true} />;
	}
}

const withIsFavoritesDataPresent = Component => {
	return connectToFavorites(Component, favoritesDataStore => ({
		favoritesDataStoreIsPresent: typeof favoritesDataStore !== 'undefined',
	}));
};

const withAppSettings = Component => {
	return connectToSellerPreferences(Component, preferences => ({
		gradient: preferences.getGradient(),
	}));
};

const connect = combineConnectors([
	withAppSettings,
	withIsUnknownCustomer,
	withIsFavoritesDataPresent,
]);

export default connect(withIsMobile(TitleCard));
