import React from 'react';
import PropTypes from 'prop-types';
import { connectToTransactions } from '../../data/TransactionsDataStore';
import PurchaseDetail from './PurchaseDetail';
import { connectToRatingsAndReviews } from '../../data/RatingsAndReviewsDataStore';
import combineConnectors from '../../data/combineConnectors';
import { Translate } from 'react-localize-redux';

class MyPurchases extends React.Component {
	static propTypes = {
		purchases: PropTypes.array,
		purchasePosData: PropTypes.object,
		purchaseUris: PropTypes.object,
		reviews: PropTypes.object,
	};

	render() {
		const {
			purchases,
			purchasePosData,
			purchaseUris,
			reviews,
		} = this.props;
		return (
			<div style={{ backgroundColor: 'white', paddingBottom: '20%' }}>
				<div
					style={{
						padding: '20px 0px 0px 20px',
						fontSize: '20px',
					}}>
					<Translate id="my_purchases" /> ({purchases.length || 0})
				</div>
				{purchases.length &&
					purchases.map((purchase, index) => {
						return (
							purchasePosData[purchase.sku] && (
								<PurchaseDetail
									key={index}
									rating={reviews.customerRatings.find(
										rating =>
											rating.entityId === purchase.sku,
									)}
									posData={purchasePosData[purchase.sku][0]}
									uri={purchaseUris[purchase.sku]}
								/>
							)
						);
					})}
			</div>
		);
	}
}

const withUserPurchases = Component => {
	const mapToProps = transactionsDataStore => {
		const purchases =
			transactionsDataStore.state.transactionsData.transactions;
		const purchasePosData =
			transactionsDataStore.state.transactionsData.skuPosData;
		const purchaseUris =
			transactionsDataStore.state.transactionsData.skuUris;
		return {
			purchases,
			purchasePosData,
			purchaseUris,
		};
	};
	return connectToTransactions(Component, mapToProps);
};

const withUserReviews = Component => {
	const mapToProps = ratingsAndReviewsDataStore => {
		const reviews = ratingsAndReviewsDataStore.state.reviews;
		return {
			reviews,
		};
	};
	return connectToRatingsAndReviews(Component, mapToProps);
};

const connectors = [withUserReviews, withUserPurchases];

export default combineConnectors(connectors)(MyPurchases);
