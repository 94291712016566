import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from '../../api';
import queryString from 'query-string';
import Convert from '../../util/Convert';
import { notification } from 'antd';
import makeConnectTo from './makeConnectTo';
import { withLocalize } from 'react-localize-redux';

export const CustomerIDContext = React.createContext();

export const connectToCustomerID = makeConnectTo(
	CustomerIDContext,
	'customerState',
);

export const useCustomerID = () => useContext(CustomerIDContext);
export const useReferralSource = () =>
	useContext(CustomerIDContext).referralSource;

const notifyUserOfPreviewMode = translate => {
	notification.info({ message: translate('not_applicable_in_preview_mode') });
};

export const connectToPreviewMode = Component => {
	return props => {
		return (
			<CustomerIDContext.Consumer>
				{customerState => {
					const previewMode = {
						notify: () => customerState.notifyUserOfPreviewMode(),
						isActive: () => customerState.isPreviewMode,
					};
					return <Component previewMode={previewMode} {...props} />;
				}}
			</CustomerIDContext.Consumer>
		);
	};
};

export const withIsUnknownCustomer = Component => {
	return connectToCustomerID(Component, customerState => ({
		isUnknownCustomer: customerState.isUnknownCustomer,
	}));
};

const isValidReferralSource = state => {
	return (
		typeof state.referralSourceOrgId === 'string' &&
		state.referralSourceCustomerId !== null
	);
};

export const withIsDemoBookstore = Component => {
	return connectToCustomerID(Component, ({ orgId }) => ({
		isDemoBookstore: orgId === 'IBSG',
	}));
};

const unknownCustomerId = '0';

class CustomerIDStore extends React.Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		location: PropTypes.object,
		children: PropTypes.func,
		translate: PropTypes.func,
	};

	state = {
		orgId: null,
		customerId: unknownCustomerId,
		emailAddress: '',
		messageId: null,
		isLoading: true,
		domainSourceOrgId: null,
		referralSourceOrgId: null,
		referralSourceCustomerId: null,
		referralSourceEmailAddress: null,
	};

	render() {
		return (
			<CustomerIDContext.Provider value={this.getDataStore()}>
				{this.props.children(this.state)}
			</CustomerIDContext.Provider>
		);
	}

	async componentDidMount() {
		await this.retrieveOrgId();
		this.retrieveCustomerId();
		this.retrieveMessageIdAndSetInState();
	}

	getDataStore = (state = this.state) => ({
		orgId: state.orgId ? state.orgId : state.referralSourceOrgId,
		customerId: state.customerId,
		messageId: state.messageId,
		emailAddress: state.emailAddress,
		isPreviewMode: Convert.toNumber(state.customerId) === -1,
		isUnknownCustomer: Convert.toNumber(state.customerId) === 0,
		referralSource: {
			orgId: state.referralSourceOrgId,
			customerId: state.referralSourceCustomerId,
			email: state.referralSourceEmailAddress,
			isValidReferral: isValidReferralSource(state),
			isUnknownCustomer:
				Convert.toNumber(state.referralSourceCustomerId) === 0,
		},
		setCustomerId: customerId => {
			this.setState({ customerId });
		},
		notifyUserOfPreviewMode: () =>
			notifyUserOfPreviewMode(this.props.translate),
	});

	async retrieveOrgId() {
		await this.getOrgFromUrl();
	}

	retrieveCustomerId() {
		const hasIdentityInUrl = this.hasEncodedIdValuesInUrl();
		const identityIsStored = this.localStorageHasIdentityToken();

		const isKnownCustomer = hasIdentityInUrl || identityIsStored;
		const hasReferralSourceToken = this.urlHasReferralSourceToken();

		if (hasIdentityInUrl) {
			this.parseIdValuesInUrl();
		} else if (hasReferralSourceToken) {
			this.parseReferralSourceFromUrl();
		} else if (identityIsStored && !hasIdentityInUrl) {
			this.getIdentityTokenFromLocalStorage();
		} else if (!isKnownCustomer && this.hasStoredRefferalSourceToken()) {
			this.deriveIdentityFromStoredReferralSource();
		} else {
			if (process.env.NODE_ENV === 'development') {
				this.setState({
					orgId: `${process.env.REACT_APP_ORG_ID}`,
				});
			}
			this.setState(state => {
				if (!state.orgId) {
					throw new Error('No organization for the given hostname');
				}
				return { ...state, isLoading: false };
			});
		}
	}

	hasEncodedIdValuesInUrl() {
		return (
			this.hasValidLocation() &&
			this.urlContainsValidIdentityTokenParameter()
		);
	}

	parseIdValuesInUrl() {
		const toDecrypt = this.getIdentityTokenToDecryptFromUrl();
		localStorage.setItem('data', toDecrypt);
		this.decryptEncodedIdentityAndSetInState(toDecrypt);
	}

	// If there's a messgeId, there must also be an identityToken
	retrieveMessageIdAndSetInState() {
		const { search: searchString } = this.props.location;

		const identityIsStored = this.localStorageHasIdentityToken();
		const urlHasIdentityToken = this.urlContainsValidIdentityTokenParameter();

		const isInUrl = searchString.indexOf('&messageId=') > -1;
		const isStored = typeof localStorage.getItem('messageId') === 'string';

		if (isInUrl && urlHasIdentityToken) {
			const { messageId } = queryString.parse(searchString);
			localStorage.setItem('messageId', messageId);
			this.setState({ messageId });
		} else if (isStored && identityIsStored) {
			const id = localStorage.getItem('messageId');
			this.setState({ messageId: id });
		}
	}

	getIdentityTokenToDecryptFromUrl() {
		const { location } = this.props;
		const { data } = queryString.parse(location.search);
		return data;
	}

	localStorageHasIdentityToken() {
		return typeof localStorage.getItem('data') === 'string';
	}

	getIdentityTokenFromLocalStorage() {
		const toDecrypt = localStorage.getItem('data');
		this.decryptEncodedIdentityAndSetInState(toDecrypt);
	}

	hasValidLocation() {
		const { location: loc } = this.props;
		return loc && typeof loc.search === 'string' && loc.search.length > 0;
	}

	urlContainsValidIdentityTokenParameter() {
		return this.urlContainsParameter('?data=');
	}

	decryptEncodedIdentityAndSetInState(toDecrypt) {
		return api.post('/decrypt', toDecrypt).then(this.handleDecryptedIds);
	}

	handleDecryptedIds = decryptedStr => {
		const { customerId, email, orgId } = queryString.parse(decryptedStr);
		this.setState(state => {
			const { domainSourceOrgId } = state;
			return {
				...state,
				orgId: domainSourceOrgId ? domainSourceOrgId : orgId,
				customerId: this.getCustomerIdToSet(state, orgId, customerId),
				emailAddress: email ? email : '',
				isLoading: false,
			};
		});
	};

	getCustomerIdToSet(state, parsedOrgId, parsedCustomerId) {
		const { domainSourceOrgId } = state;
		if (domainSourceOrgId) {
			const isSameOrg = domainSourceOrgId === parsedOrgId;
			return isSameOrg ? parsedCustomerId : unknownCustomerId;
		}
		return parsedCustomerId ? parsedCustomerId : unknownCustomerId;
	}

	getOrgFromUrl() {
		return api
			.post('/store/search', {
				domain: window.location.hostname,
			})
			.then(store => {
				const orgId = store.orgId;
				this.setState({ orgId, domainSourceOrgId: orgId });
				return true;
			})
			.catch(error => {
				return false;
			});
	}

	parseReferralSourceFromUrl() {
		const { location } = this.props;
		const { referralsource } = queryString.parse(location.search);
		return this.setStateWithReferralSource(referralsource);
	}

	hasStoredRefferalSourceToken = () => {
		return typeof localStorage.getItem('referralsource') === 'string';
	};

	deriveIdentityFromStoredReferralSource() {
		const refSourceToken = localStorage.getItem('referralsource');

		return api.post('/decrypt', refSourceToken).then(decrypted => {
			const { orgId } = queryString.parse(decrypted);

			this.setState(state => {
				const { domainSourceOrgId } = state;
				return {
					...state,
					orgId: domainSourceOrgId ? domainSourceOrgId : orgId,
					customerId: '0',
					emailAddress: '',
					isLoading: false,
				};
			});
		});
	}

	setStateWithReferralSource(referralSource) {
		return api
			.post('/decrypt', referralSource)
			.then(decrypted =>
				this.handleParsedReferralSourceToken(decrypted, referralSource),
			);
	}

	handleParsedReferralSourceToken = async (decryptedStr, encryptedToken) => {
		const identityIsStored = this.localStorageHasIdentityToken();
		const hasIdentityInUrl = this.urlContainsValidIdentityTokenParameter();

		const isKnownCustomer = identityIsStored || hasIdentityInUrl;
		const { customerId, email, orgId } = queryString.parse(decryptedStr);

		const getNextState = state => ({
			orgId: state.domainSourceOrgId ? state.domainSourceOrgId : orgId,
			isLoading: false,
			referralSourceOrgId: orgId,
			referralSourceCustomerId: customerId,
			referralSourceEmailAddress: email ? email : '',
		});

		if (!isKnownCustomer) {
			localStorage.setItem('referralsource', encryptedToken);
			return this.setState(state => ({
				...state,
				...getNextState(state),
				customerId: '0',
				isLoading: false,
				emailAddress: '',
			}));
		}

		const identityDataToken = this.getIdentityTokenToDecrypt();

		const decrypted = await api.post('/decrypt', identityDataToken);
		const identity = queryString.parse(decrypted);

		this.setState(state => ({
			...state,
			...getNextState(state),
			customerId: this.getCustomerIdToSet(
				state,
				identity.orgId,
				identity.customerId,
			),
			isLoading: false,
			emailAddress: identity.email ? identity.email : null,
		}));
	};

	getIdentityTokenToDecrypt() {
		if (this.localStorageHasIdentityToken()) {
			return localStorage.getItem('data');
		}
		return this.getIdentityTokenToDecryptFromUrl();
	}

	urlHasReferralSourceToken() {
		return this.urlContainsParameter('referralsource');
	}

	urlContainsParameter(param) {
		const { location: loc } = this.props;
		return loc.search.indexOf(param) > -1;
	}
}

export default withRouter(withLocalize(CustomerIDStore));
