import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import TitleAggregateParser from '../../title/TitleAggregateParser';
import { cancelableDelay } from '../../util/delay';
import { connectToCustomerID } from './CustomerIDStore';
import { findUniqueFamilyTitles } from '../../util/titleUtility';

class TitleLanesDataStore extends React.Component {
	static propTypes = {
		getEndpoint: PropTypes.func,
		laneConfigs: PropTypes.arrayOf(PropTypes.object),
		children: PropTypes.func,
		titleName: PropTypes.string,
	};

	static defaultProps = {
		getEndpoint: () => {},
		laneConfigs: [],
	};

	state = {
		isLoading: true,
		lanesByLaneKey: {},
	};

	delay = null;
	fetchCalls = [];

	render() {
		return this.props.children(this.getDataStore());
	}

	componentDidMount() {
		this.delay = cancelableDelay(850);
		this.delay.promise
			.then(() => this.fetchTitleLanes())
			.catch(err => !err.isCanceled && console.log(err));
	}

	componentWillUnmount() {
		const isArr = Array.isArray(this.fetchCalls);
		isArr && this.fetchCalls.forEach(call => call.cancel && call.cancel());

		if (this.delay && typeof this.delay.cancel === 'function') {
			this.delay.cancel();
		}
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: state.isLoading,
		getLaneConfigs: () => this.props.laneConfigs,
		getLane: laneKey => this.getLane(laneKey),
		getLaneHeader: laneKey => this.getOneLaneConfig(laneKey).title,
		getLaneSubtitle: laneKey => this.getOneLaneConfig(laneKey).subtitle,
		getTitles: laneKey => state.lanesByLaneKey[laneKey].titles,
		updateLane: laneKey => this.updateLane(laneKey),
		fetchMoreTitles: laneKey => this.fetchMoreTitles(laneKey),
		hasTitlesInLane: laneKey => this.hasTitlesInLane(laneKey),
		fetchAllAvailableTitles: laneKey => {
			return this.fetchAllAvailableTitles(laneKey);
		},
		hasTitlesInAnyLane: () => this.hasTitlesInAnyLane(),
	});

	getLane = laneNameKey => {
		const { lanesByLaneKey } = this.state;
		if (lanesByLaneKey[laneNameKey]) {
			return lanesByLaneKey[laneNameKey];
		}
		return { titles: [], totalAvailableCount: 0 };
	};

	getOneLaneConfig = laneKey => {
		const { laneConfigs } = this.props;
		for (let i = 0; i < laneConfigs.length; i++) {
			if (laneConfigs[i].key === laneKey) {
				return laneConfigs[i];
			}
		}
	};

	hasTitlesInLane = laneKey => {
		const lane = this.getLane(laneKey);
		return lane.totalAvailableCount > 0;
	};

	fetchTitleLanes = async () => {
		const { laneConfigs } = this.props;

		this.fetchCalls = laneConfigs.map(laneConfig => {
			return this.fetchOneTitleLane(laneConfig.key);
		});

		const lanesByLaneKey = {};
		const responses = await Promise.all(
			this.fetchCalls.map(c => c.promise),
		);

		responses.forEach((res, idx) => {
			lanesByLaneKey[laneConfigs[idx].key] = this.buildLaneState(res);
		});

		this.setState({ isLoading: false, lanesByLaneKey });
		this.fetchCalls = [];
	};

	// String -> Promise { Object { titles: Array, totalAvailableCount: number } }
	// Returns fetch request for all the available titles in given lane identifed by its 'key'
	fetchAllAvailableTitles = laneNameKey => {
		const { totalAvailableCount } = this.getLane(laneNameKey);
		const req = this.fetchOneTitleLane(laneNameKey, totalAvailableCount);
		return req.promise.then(this.buildLaneState);
	};

	fetchOneTitleLane = (laneNameKey, total = 0) => {
		const endpoint = this.props.getEndpoint(laneNameKey, total);
		return api.cancelableGet(endpoint);
	};

	buildLaneState = response => {
		const { titleAggregate } = response;
		const parser = new TitleAggregateParser(titleAggregate);
		const laneTitles = parser.buildTitles();
		return this.findTitlesToParse(laneTitles, response);
	};

	findTitlesToParse = (laneTitles, response) => {
		const filteredTitles = this.filterTitlesWithTitleName(laneTitles);
		const uniqueTitles = findUniqueFamilyTitles(filteredTitles);
		const countOfRemovedTitles = laneTitles.length - uniqueTitles.length;
		return {
			titles: uniqueTitles,
			totalAvailableCount:
				response.totalAvailableTitles - countOfRemovedTitles,
		};
	};

	filterTitlesWithTitleName = titles => {
		const { titleName } = this.props;
		return titleName ? titles.filter(t => t.name !== titleName) : titles;
	};

	fetchMoreTitles = laneName => {
		const lane = this.state.lanesByLaneKey[laneName];
		const availableCt = lane.totalAvailableCount;

		const titlesInLane = lane.titles;
		const total = titlesInLane.length;

		const nextTotal = total + 20 > availableCt ? availableCt : total + 20;

		const request = this.fetchOneTitleLane(laneName, nextTotal);
		this.fetchCalls = [request];

		return request.promise
			.then(this.parseNextStateFromResponse(laneName))
			.then(nextLane => {
				this.setState(state => ({
					...state,
					lanesByLaneKey: {
						...state.lanesByLaneKey,
						[nextLane.key]: nextLane.nextState,
					},
				}));
			});
	};

	updateLane = laneNameKey => {
		const laneUpdateRequests = [this.requestLaneRefresh(laneNameKey)];
		return this.setNewStateWithLaneRefreshes(laneUpdateRequests);
	};

	requestLaneRefresh(laneName) {
		const request = this.fetchOneTitleLane(laneName);

		if (Array.isArray(this.fetchCalls)) {
			this.fetchCalls.push(request);
		} else {
			this.fetchCalls = [request];
		}

		return request.promise.then(this.parseNextStateFromResponse(laneName));
	}

	parseNextStateFromResponse = laneName => response => {
		const r = response;
		return {
			key: laneName,
			nextState: this.buildLaneState(r),
		};
	};

	setNewStateWithLaneRefreshes = laneRequests => {
		return Promise.all(laneRequests).then(lanes => {
			if (lanes.length === 0) return;

			const nextLanesByKey = {};
			lanes.forEach(lane => (nextLanesByKey[lane.key] = lane.nextState));

			return this.setState(state => ({
				...state,
				lanesByLaneKey: {
					...state.lanesByLaneKey,
					...nextLanesByKey,
				},
			}));
		});
	};

	hasTitlesInAnyLane = () => {
		const { laneConfigs } = this.props;
		return laneConfigs.some(lane => this.hasTitlesInLane(lane.key));
	};
}

export default connectToCustomerID(TitleLanesDataStore);
