import React from 'react';
import PropTypes from 'prop-types';
import useLocalizedCurrencyCode from './useLocalizedCurrencyCode';

LocalizedPrice.propTypes = {
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function LocalizedPrice({ price }) {
	const { getCultureAndCurrencyCodes } = useLocalizedCurrencyCode();
	const { culture, currency } = getCultureAndCurrencyCodes();

	const displayPrice = price.toLocaleString(culture, {
		style: 'currency',
		currency,
	});

	return <span>{displayPrice}</span>;
}
