import React from 'react';
import PropTypes from 'prop-types';
import HomePage from './HomePage';
import { Route, Switch, Redirect } from 'react-router-dom';
import TitleDetailDrawer from '../Title/TitleDetailDrawer';
import { connectToOrg } from '../data/OrganizationDataStore';
import { connectToCustomerID } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import LoadingPage from '../UI/LoadingPage';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import WishlistLaneDataStore from '../Wishlist/data/WishlistLaneDataStore';
import { connectToLogo } from '../data/BooksellerLogoStore';
import RecentlyViewedDataStore from '../RecentlyViewed/data/RecentlyViewedDataStore';

class HomePageRoot extends React.Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		location: PropTypes.object,
		// Mapped to Props
		orgName: PropTypes.string,
		isLoadingLogos: PropTypes.bool,
		isLoadingOrgData: PropTypes.bool,
		customerState: PropTypes.shape({
			isPreviewMode: PropTypes.bool,
			isUnknownCustomer: PropTypes.bool,
		}),
	};

	render() {
		const { isPreviewMode, isUnknownCustomer } = this.props.customerState;

		if (isPreviewMode || isUnknownCustomer) {
			return this.renderPage();
		}
		return (
			<WishlistLaneDataStore>{this.renderPage()}</WishlistLaneDataStore>
		);
	}

	renderPage() {
		if (this.props.isLoadingLogos) {
			return <LoadingPage />;
		}

		return (
			<WishlistSkusDataStore>
				<CustomerFavoritesDataStore>
					<RecentlyViewedDataStore>
						<Switch>
							<Route
								exact
								path="/explore"
								render={() => <HomePage />}
							/>
							<Route
								path="/explore/title/:sku"
								render={this.renderHomeWithTitleDetail}
							/>
							<Redirect to="/explore" />
						</Switch>
					</RecentlyViewedDataStore>
				</CustomerFavoritesDataStore>
			</WishlistSkusDataStore>
		);
	}

	renderHomeWithTitleDetail = ({ match }) => {
		return (
			<TitleDetailDrawer sku={match.params.sku}>
				{drawer => {
					if (this.props.isLoadingOrgData) {
						return <LoadingPage />;
					}

					return <HomePage onDidMount={() => drawer.open()} />;
				}}
			</TitleDetailDrawer>
		);
	};
}

const withOrg = Component => {
	return connectToOrg(Component, orgDataStore => ({
		orgName: orgDataStore.getOrgName(),
		isLoadingOrgData: orgDataStore.isLoading(),
	}));
};

const withIsLoadingLogos = Component => {
	return connectToLogo(Component, ({ isLoadingSplash, isLoading }) => ({
		isLoadingLogos: isLoadingSplash() || isLoading(),
	}));
};

const connectors = [withOrg, connectToCustomerID, withIsLoadingLogos];

export default combineConnectors(connectors)(HomePageRoot);
