import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import TitleJacketImage from '../../Title/UI/TitleJacketImage';
import { Button, Rate } from 'antd';
import CustomerAvater from '../UI/CustomerAvatar';
import TitleDetailDrawer from '../../Title/TitleDetailDrawer';
import { Translate } from 'react-localize-redux';

class ReviewDetail extends React.Component {
	static propTypes = {
		review: PropTypes.object,
		rating: PropTypes.object,
		posData: PropTypes.array,
		uri: PropTypes.string,
		isMobile: PropTypes.func,
	};

	render() {
		const { posData, uri, review, rating } = this.props;
		const isMobile = this.props.isMobile();

		return Array.isArray(posData) && posData.length ? (
			<div
				style={{
					display: 'flex',
					position: 'relative',
					margin: isMobile ? '20px 20px 30px 20px' : '20px 0 0 20px',
				}}>
				<div
					style={{
						width: isMobile ? '100px' : '140px',
						minWidth: isMobile ? '100px' : '140px',
					}}>
					<TitleDetailDrawer sku={posData[0].sku}>
						<TitleJacketImage
							size={TitleJacketImage.fixedwidth}
							title={{
								sku: posData.sku,
								imageUri: uri,
								pos: posData,
							}}
						/>
					</TitleDetailDrawer>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						paddingLeft: '20px',
					}}>
					<div style={{ display: 'flex', paddingBottom: 10 }}>
						<div style={{ width: '25px' }}>
							<CustomerAvater size={25} />
						</div>
						<div style={{ paddingLeft: '10px' }}>
							<Translate
								id="my_take_on_x"
								options={{ renderInnerHtml: true }}
								data={{ title: posData[0].title }}
							/>
						</div>
					</div>
					{rating && (
						<Rate
							allowHalf
							value={rating.rating}
							allowClear={true}
							onChange={this.handleChange}
						/>
					)}
					{review ? (
						<div style={{ fontSize: '14px' }}>
							{review.review}
							<TitleDetailDrawer sku={posData[0].sku}>
								<Button style={{ marginTop: '10px' }}>
									<Translate id="edit_review" />
								</Button>
							</TitleDetailDrawer>
						</div>
					) : (
						<TitleDetailDrawer sku={posData[0].sku}>
							<Button style={{ marginTop: '10px' }}>
								<Translate id="review" />
							</Button>
						</TitleDetailDrawer>
					)}
				</div>
			</div>
		) : null;
	}
}

export default withIsMobile(ReviewDetail);
