import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Link } from 'react-router-dom';
import './sectionPopover.css';

SectionPopover.propTypes = {
	department: PropTypes.object,
	section: PropTypes.object,
};

export default function SectionPopover({ department, section }) {
	const getSectionName = section =>
		section.displayName ? section.displayName : section.sectionName;

	const renderLinksToDepartmentAndSections = () => {
		return (
			<div className="section-popover">
				{section ? (
					<div>
						<Link
							className="acc-font clickable"
							to={`/section/department/${department.departmentId}`}>
							{department.departmentName}
						</Link>{' '}
						Shelves:
					</div>
				) : (
					`${department.departmentName} Shelves:`
				)}
				{getSectionList()}
			</div>
		);
	};

	const getSectionList = () => {
		return (
			<ul className="section-list">
				{department.sections.map((s, idx) => {
					const sectionName = getSectionName(s);
					return (
						<li key={idx}>
							{!section || s.sectionId !== section.sectionId ? (
								<Link
									className="acc-font clickable"
									to={`/section/section/${s.sectionId}`}>
									{sectionName}
								</Link>
							) : (
								sectionName
							)}
						</li>
					);
				})}
			</ul>
		);
	};

	return (
		<Popover
			placement="right"
			content={renderLinksToDepartmentAndSections()}>
			<div className="section-header-with-popover">
				{section
					? getSectionName(section)
					: `${department.departmentName} Department`}
			</div>
		</Popover>
	);
}
