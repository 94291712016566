import React from 'react';
import { Popconfirm, Button } from 'antd';
import useSplashImageController from './hooks/useSplashImageController';
import { Translate } from 'react-localize-redux';

export default function DeleteSplashButton() {
	const splashController = useSplashImageController();
	const { deleteCurrentSplashImage, isDeleting } = splashController;

	const title = (
		<Translate id="are_you_sure_you_want_to_delete_the_splash_image" />
	);

	return (
		<Translate>
			{({ translate }) => {
				return (
					<Popconfirm
						title={title}
						onConfirm={() => deleteCurrentSplashImage(translate)}
						okText={<Translate id="yes_delete" />}>
						<Button
							type="danger"
							style={{ margin: '0 10px' }}
							loading={isDeleting}
							disabled={splashController.isLoading}>
							{isDeleting ? (
								<Translate id="loading" />
							) : (
								<Translate id="delete" />
							)}
						</Button>
					</Popconfirm>
				);
			}}
		</Translate>
	);
}
