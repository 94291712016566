import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import DrawerAnchor from '../../UI/DrawerAnchor';
import MyReviews from './MyReviews';

class MyReviewsDrawerAnchor extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	};

	render() {
		const isMobile = this.props.isMobile();
		return (
			<DrawerAnchor
				closable={isMobile}
				width={isMobile ? '100%' : '700px'}
				placement={isMobile ? 'bottom' : 'left'}
				height={isMobile ? '90%' : '100%'}
				parentStyle={'clickable'}
				renderDrawerContent={this.renderDrawerContent}>
				{this.props.children}
			</DrawerAnchor>
		);
	}

	renderDrawerContent = drawer => {
		return drawer.isVisible && <MyReviews />;
	};
}

export default withIsMobile(MyReviewsDrawerAnchor);
