import React from 'react';
import api from '../../../api';
import { useBagDataStore } from './BagDataStore';
import { useCustomerID } from '../../data/CustomerIDStore';

export default function useBagDataTotalAmount() {
	const bag = useBagDataStore();
	const [isLoading, setIsLoading] = React.useState(true);

	const [amountsBySku, setAmountsForSkus] = React.useState({});
	const { orgId } = useCustomerID();

	const skus = bag.getTitles().map(t => t.sku);

	React.useEffect(() => {
		const endpoint = `/orgs/${orgId}/orders/skus/pos`;
		const request = api.cancelablePost(endpoint, skus);

		request.promise.then(res => {
			setAmountsForSkus(res);
			setIsLoading(false);
		});

		return () => {
			api.revoke(request);
		};
		// eslint-disable-next-line
	}, [orgId, skus.length]);

	const getTotal = () => {
		const quantitesBySku = bag.getQuantitiesBySku();
		return Object.keys(amountsBySku).reduce((totalPrice, sku) => {
			let quantity = 0;
			if (typeof quantitesBySku[sku] === 'number') {
				quantity = quantitesBySku[sku];
			}
			return totalPrice + amountsBySku[sku][0] * quantity;
		}, 0);
	};

	const getPrice = sku => {
		const hasPrice = amountsBySku && amountsBySku[sku];
		if (hasPrice && amountsBySku[sku][0]) {
			return amountsBySku[sku][0];
		}
		return 'Unavailable';
	};

	return {
		isLoading,
		amountsBySku,
		getTotal,
		getPrice,
		getFixedTotal: () => getTotal().toFixed(2),
	};
}
