import React from 'react';
import CustomerListType from '../../../entity/CustomerListType';
import CustomerEntitiesController from '../../data/CustomerEntitiesController';
import CustomerFavoritesDataStoreBase from './CustomerFavoritesDataStoreBase';
import makeConnectTo from '../../data/makeConnectTo';
import { FavoritesContext } from './CustomerFavoritesDataStoreBase';

export const connectToFavorites = makeConnectTo(
	FavoritesContext,
	'customerFavoritesDataStore',
);

export const useFavoritesDataStore = () => React.useContext(FavoritesContext);

export default function CustomerFavoritesDataStore({ children }) {
	const listType = CustomerListType.favorites;

	const renderBaseDataStore = customerEntitiesController => {
		return (
			<CustomerFavoritesDataStoreBase
				customerEntitiesController={customerEntitiesController}>
				{children}
			</CustomerFavoritesDataStoreBase>
		);
	};

	return (
		<CustomerEntitiesController customerListType={listType}>
			{renderBaseDataStore}
		</CustomerEntitiesController>
	);
}
