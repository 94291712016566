import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LaneContainer from '../../UI/LaneContainer';
import { Drawer } from 'antd';
import { Empty, Card } from 'antd';
import BrowseTitleAutocomplete from './BrowseTitleAutocomplete';
import * as TitleStatus from '../../../title/TitleStatus';
import TitleLanesByStatus from './TitleLanesByStatus';
import { withLocalize } from 'react-localize-redux';
import delay from '../../../util/delay';
import { findUniqueFamilyTitles } from '../../../util/titleUtility';
import './detailedTitleSearch.less';

DetailedTitleSearch.propTypes = {
	isMobile: PropTypes.func,
	onCancel: PropTypes.func,
	doShowResults: PropTypes.bool,
	onSearchResultsReceived: PropTypes.func,
	header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	translate: PropTypes.func,
};

function DetailedTitleSearch({
	isMobile,
	onCancel,
	doShowResults,
	onSearchResultsReceived,
	header,
	translate,
}) {
	const [titles, setTitles] = useState([]);
	const [userInput, setUserInput] = useState('');
	const [didExecuteSearch, setDidExecute] = useState(false);
	const [doResetInternalState, setDoResetInternalState] = useState(false);

	const onTitleOptions = (
		userInput,
		{ retailTitles, productImageUrisBySku, productsBySku },
	) => {
		const productInfo = Object.values(productsBySku);
		const titles = retailTitles.map(t => {
			const imageKey = Object.keys(productImageUrisBySku).find(
				key => key.toLowerCase() === t.sku.toLowerCase(),
			);
			const titleInfo = productInfo.find(p => p.sku === t.sku);
			return {
				...t,
				imageUri: productImageUrisBySku[imageKey],
				familyItem: titleInfo.familyItem,
				retailPrice: titleInfo.retailPrice,
			};
		});

		const titlesWithPrices = titles.filter(
			t => t.listPrice || t.retailPrice,
		);
		const uniqueTitles = findUniqueFamilyTitles(titlesWithPrices);

		setTitles(uniqueTitles);
		setUserInput(userInput);

		if (!didExecuteSearch) {
			setDidExecute(true);
		}

		onSearchResultsReceived(uniqueTitles);
		return [];
	};

	const getTitlesByStatus = () => {
		const titlesByStatus = {
			onShelf: [],
			comingSoon: [],
			availableForOrder: [],
		};
		for (let i = 0; i < titles.length; i++) {
			const title = titles[i];
			if (TitleStatus.isNotYetPublished(title.pubDate)) {
				titlesByStatus.comingSoon.push(title);
			} else if (typeof title.onHand === 'number' && title.onHand > 0) {
				titlesByStatus.onShelf.push(title);
			} else {
				titlesByStatus.availableForOrder.push(title);
			}
		}
		return titlesByStatus;
	};

	const hasNoResults = titlesByStatus => {
		return (
			titlesByStatus.onShelf.length === 0 &&
			titlesByStatus.comingSoon.length === 0 &&
			titlesByStatus.availableForOrder.length === 0
		);
	};

	const getNoResultsPrompt = () => {
		return didExecuteSearch
			? translate('no_results_found')
			: translate('search_results_will_appear_here');
	};

	const clearSearch = () => {
		resetSearch();
		onCancel();
		setDidExecute(false);
	};

	const resetSearch = async () => {
		setDoResetInternalState(true);
		await delay(850);
		setDoResetInternalState(false);
	};

	const renderResults = titlesByStatus => {
		return hasNoResults(titlesByStatus) ? (
			<Card className="no-search-results">
				<Empty description={getNoResultsPrompt()} />
			</Card>
		) : (
			<TitleLanesByStatus titlesByStatus={titlesByStatus} />
		);
	};

	const titlesByStatus = getTitlesByStatus(titles);
	const drawerHeader = (
		<>
			{translate('results_for')} <i>{userInput}</i>
		</>
	);
	const pagePlaceholder = translate('search_for_books_by_title_or_author');
	const drawerPlaceholder = translate('try_another_search');

	const renderSearchLane = (header, placeholder) => {
		return (
			<div
				className="detailed-title-search"
				style={!header ? { paddingTop: 20 } : null}>
				<LaneContainer majorHeader={header}>
					<div className="search-bar-container">
						<BrowseTitleAutocomplete
							isClearable={true}
							includeProductImagesInResults
							placeholder={placeholder}
							handleOptionsResponse={onTitleOptions}
							shouldResetInternalState={doResetInternalState}
						/>
					</div>
				</LaneContainer>
			</div>
		);
	};

	return (
		<>
			{renderSearchLane(header, pagePlaceholder)}
			{doShowResults && (
				<Drawer
					className="search-results-drawer"
					onClose={clearSearch}
					width={isMobile() ? '100%' : '700px'}
					placement={isMobile() ? 'bottom' : 'left'}
					height="90%"
					visible={didExecuteSearch}>
					{renderSearchLane(drawerHeader, drawerPlaceholder)}
					{renderResults(titlesByStatus)}
				</Drawer>
			)}
		</>
	);
}

export default withLocalize(DetailedTitleSearch);
