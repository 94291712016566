import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID } from './CustomerIDStore';
import { set } from 'lodash';

const ContentContext = React.createContext();

export const useBooksellerContentDataStore = () => {
	return React.useContext(ContentContext);
};

export const connectToSellerContent = Component => {
	return props => {
		return (
			<ContentContext.Consumer>
				{bcs => <Component booksellerContentStore={bcs} {...props} />}
			</ContentContext.Consumer>
		);
	};
};

const contentTypes = {
	storeInfo: 'indie360.storeInfo',
	customerApp: 'indie360.customerApp',
};

class BooksellerContentDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	};

	state = {
		storeInfo: {
			location1: {
				locationName: '',
				address: {},
				hours: {},
				phoneNumber: '',
			},
			links: {},
		},
		customerApp: {
			retailerMessageBody: '',
			retailerMessageTitle: '',
			staffBios: {},
		},
		isLoading: true,
	};

	render() {
		return (
			<ContentContext.Provider value={this.getDataStore()}>
				{typeof this.props.children === 'function'
					? this.props.children(this.getDataStore())
					: this.props.children}
			</ContentContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchBooksellerContent();
	}

	componentWillUnmount() {
		this.contentFetch.cancel && this.contentFetch.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getStoreLocations: () => this.getStoreLocations(),
	});

	getStoreLocations = () => {
		const { storeInfo } = this.state;
		return storeInfo
			? Object.keys(storeInfo)
					.filter(key => key.includes('location'))
					.map(key => storeInfo[key])
			: [];
	};

	fetchBooksellerContent = () => {
		const { orgId } = this.props.customerState;

		const booksellerContent = Object.values(contentTypes).map(
			contentType => {
				const endpoint = `/org/${orgId}/contents?contentType=${contentType}`;
				this.contentFetch = api.get(endpoint);
				return this.contentFetch;
			},
		);

		return Promise.all(booksellerContent)
			.then(contentPairs =>
				contentPairs.map((pairs, i) =>
					this.parseContentIntoState(pairs, i),
				),
			)
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load Bookseller Content');
				});
			});
	};

	parseContentIntoState = (contentPairs, i) => {
		const contentType = Object.values(contentTypes)[i];
		const contentTypeName = contentType.split('.')[1];
		const content = this.state[contentTypeName];
		const isLoading = i !== Object.keys(contentTypes).length - 1;
		if (content) {
			const parsedContent =
				contentType === contentTypes.storeInfo
					? this.parseStoreLocations(contentPairs)
					: this.parseContentPairs(contentTypeName, contentPairs);
			return this.setState({
				[contentTypeName]: parsedContent,
				isLoading,
			});
		} else {
			this.setState({ isLoading });
		}
	};

	parseStoreLocations = contentPairs => {
		return contentPairs
			.filter(c => c.value)
			.reduce((acc, c) => {
				const isFirstLocation =
					c.key === 'locationName' ||
					(!c.key.includes('link') && !c.key.includes('location'));
				if (isFirstLocation) c.key = 'location1.' + c.key;
				acc = set(acc, c.key, c.value);
				return acc;
			}, {});
	};

	parseContentPairs = (contentType, contentPairs) => {
		return contentPairs.reduce(
			(acc, cp) => {
				if (cp.key.indexOf('.') === -1) {
					const value =
						cp.key === 'staffBios'
							? JSON.parse(cp.value)
							: cp.value;
					acc[cp.key] = value;
				} else {
					const keys = cp.key.split('.');
					acc[keys[0]][keys[1]] = cp.value;
				}
				return acc;
			},
			{ ...this.state[contentType] },
		);
	};
}

export default connectToCustomerID(BooksellerContentDataStore);
