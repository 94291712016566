import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Button, notification } from 'antd';
import { connectToRatingsAndReviews } from '../data/RatingsAndReviewsDataStore';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import './rateAndReview.less';
import RecommendableEntity from '../../entity/RecommendableEntity';
import { Translate } from 'react-localize-redux';

const { TextArea } = Input;
const confirm = Modal.confirm;

export class ReviewInput extends React.Component {
	static propTypes = {
		entityType: PropTypes.string,
		entityId: PropTypes.string,
		review: PropTypes.object,
	};

	state = {
		review: '',
		initialReview: '',
	};

	componentDidMount() {
		this.setInitialReview();
	}

	onChange = ({ target: { value } }) => {
		this.setState({ review: value });
	};

	render() {
		const { review } = this.state;

		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className="review-input">
							<TextArea
								value={review}
								style={{ fontSize: '18px' }}
								onChange={this.onChange}
								placeholder={this.getPlaceholder(translate)}
								autosize={{ minRows: 4 }}
							/>
							<div style={{ paddingTop: '10px' }}>
								<div
									style={{
										float: 'left',
										width: '33%',
										padding: '0 5px',
									}}>
									<Button
										className="generic-button"
										onClick={() => this.cancelEdits()}>
										<Translate id="cancel" />
									</Button>
								</div>
								<div
									style={{
										float: 'left',
										width: '33%',
										padding: '0 5px',
									}}>
									<Button
										className="generic-button"
										onClick={() =>
											this.deleteReview(translate)
										}>
										<Translate id="delete" />
									</Button>
								</div>
								<div
									style={{
										float: 'left',
										width: '33%',
										padding: '0 5px',
									}}>
									<Button
										className="generic-button"
										onClick={() =>
											this.saveReview(translate)
										}>
										<Translate id="save" />
									</Button>
								</div>
								<div style={{ clear: 'both' }} />
							</div>
						</div>
					);
				}}
			</Translate>
		);
	}

	setInitialReview() {
		const { entityId, ratingsAndReviewsDataStore } = this.props;
		const reviewText = ratingsAndReviewsDataStore.getReview(entityId);
		if (reviewText) {
			this.setState({
				review: reviewText,
				initialReview: reviewText,
			});
		}
	}

	cancelEdits = () => {
		this.props.leaveEditMode();
	};

	saveReview = translate => {
		const initialReview = this.state.initialReview;
		const currentReview = this.state.review;
		const { ratingsAndReviewsDataStore, entityId, entityType } = this.props;
		const entity = new RecommendableEntity(entityId, entityType);
		const type = 'review';

		if (initialReview) {
			this.setState({ initialReview: currentReview });
			return ratingsAndReviewsDataStore
				.update(entity, currentReview, type)
				.then(() => {
					this.props.leaveEditMode();
				})
				.then(() =>
					notification.success({
						message: translate('your_x_has_been_updated', {
							type: type,
						}),
					}),
				);
		} else {
			this.setState({ initialReview: currentReview });
			return ratingsAndReviewsDataStore
				.save(entity, currentReview, type)
				.then(() => {
					this.props.leaveEditMode();
				})
				.then(() =>
					notification.success({
						message: translate('your_x_has_been_saved', {
							type: type,
						}),
					}),
				);
		}
	};

	deleteReview = translate => {
		const initialReview = this.state.initialReview;
		const type = 'review';

		if (initialReview) {
			confirm({
				title: this.getTitle(translate),
				onOk: () => {
					const {
						ratingsAndReviewsDataStore,
						entityId,
						entityType,
					} = this.props;
					const entity = new RecommendableEntity(
						entityId,
						entityType,
					);
					return ratingsAndReviewsDataStore
						.remove(entity, 'review')
						.then(() => {
							this.props.leaveEditMode();
						})
						.then(() =>
							notification.success({
								message: translate('your_x_has_been_removed', {
									type: type,
								}),
							}),
						);
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		}
	};

	getTitle = translate => {
		return translate('are_you_sure_you_want_to_delete_review');
	};

	getPlaceholder = translate => {
		return translate('what_did_you_think_review');
	};
}

const connect = combineConnectors([
	connectToRatingsAndReviews,
	connectToPreviewMode,
]);

export default connect(ReviewInput);
