import api from '../../api';
import { useCustomerID } from '../data/CustomerIDStore';

export default function useTaxes() {
	const { orgId } = useCustomerID();

	const getLocationTaxRate = async address => {
		const addressQueryParams = new URLSearchParams(address).toString();
		return api.get(`/orgs/${orgId}/taxes?${addressQueryParams}`);
	};

	return {
		getLocationTaxRate,
	};
}
