import React from 'react';
import PropTypes from 'prop-types';
import TitleDetail from './TitleDetail';
import TitleDataStore from './data/TitleDataStore';
import DrawerAnchor from '../UI/DrawerAnchor';
import withIsMobile from '../hoc/withIsMobile';
import DrawerContextProvider from '../context/DrawerContext';
import StaffPicksDataStore from '../StaffPicks/data/StaffPicksDataStore';

class TitleDetailDrawer extends React.Component {
	static propTypes = {
		sku: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
		parentStyle: PropTypes.string,
		// Mapped to props
		isMobile: PropTypes.func,
	};

	static defaultProps = {
		parentStyle: 'clickable dotDot',
	};

	render() {
		const isMobile = this.props.isMobile();
		const { parentStyle } = this.props;
		return (
			<DrawerAnchor
				closable={isMobile}
				width={isMobile ? '100%' : '700px'}
				placement={isMobile ? 'bottom' : 'left'}
				height={isMobile ? '90%' : '100%'}
				parentStyle={parentStyle}
				renderDrawerContent={this.renderDrawerContent}>
				{this.props.children}
			</DrawerAnchor>
		);
	}

	renderDrawerContent = drawer => {
		return drawer.isVisible && this.renderTitleDetail(drawer);
	};

	renderTitleDetail = drawer => {
		const { sku } = this.props;
		return (
			<DrawerContextProvider drawer={drawer}>
				<TitleDataStore sku={sku}>
					<StaffPicksDataStore>
						<TitleDetail />
					</StaffPicksDataStore>
				</TitleDataStore>
			</DrawerContextProvider>
		);
	};
}

export default withIsMobile(TitleDetailDrawer);
