import React from 'react';
import PropTypes from 'prop-types';
import WishlistButton from '../../Wishlist/WishlistButton';
import BuyOrReviewButton from './BuyOrReviewButton';
import { withIsUnknownCustomer } from '../../data/CustomerIDStore';
import CopyLinkToTitleAnchor from './CopyLinkToTitleAnchor';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import FavoriteEntityAnchor from '../../CustomerFavorites/UI/FavoriteEntityAnchor';
import './titleActionButton.css';

TitleActionButtons.propTypes = {
	title: PropTypes.object,
	isUnknownCustomer: PropTypes.bool,
	isTitleDetail: PropTypes.bool,
};

TitleActionButtons.defaultProps = {
	isTitleDetail: false,
};

function TitleActionButtons({ title, isUnknownCustomer, isTitleDetail }) {
	const entity = new RecommendableEntity(
		title.sku,
		RecommendableEntity.title,
		title.name,
	);

	return (
		<div className="title-action-buttons-container">
			<BuyOrReviewButton title={title} isTitleDetail={isTitleDetail} />
			{!isUnknownCustomer && (
				<>
					{!isTitleDetail && (
						<FavoriteEntityAnchor
							entity={entity}
							isTitleDetail={isTitleDetail}
						/>
					)}
					<WishlistButton sku={title.sku} titleName={title.name} />
				</>
			)}
			<CopyLinkToTitleAnchor sku={title.sku} />
		</div>
	);
}

export default withIsUnknownCustomer(TitleActionButtons);
