import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import Convert from '../../util/Convert';
import combineConnectors from './combineConnectors';
import { connectToCustomerID } from './CustomerIDStore';

class CustomerEntitiesController extends React.Component {
	static propTypes = {
		// Mapped to props
		customerState: PropTypes.object,
		// Passed in from Parent
		customerListType: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	};

	render() {
		const customerEntitiesController = this.getController();
		return this.props.children(customerEntitiesController);
	}

	getController = () => ({
		get: () => this.fetchCustomerEntities(),
		save: recommendableEntity => this.save(recommendableEntity),
		remove: entity => this.remove(entity),
		find: (entities, entityId) => this.findOneEntity(entities, entityId),
	});

	fetchCustomerEntities = () => {
		const { customerState, customerListType: listType } = this.props;
		const { orgId, customerId } = customerState;
		const endpoint = `/org/${orgId}/customer/${customerId}/entities/${listType}`;
		return api.cancelableGet(endpoint);
	};

	save = recommendableEntity => {
		const entity = recommendableEntity;
		const entityDTO = this.buildCustomerEntityDTO(entity);
		return api.post('/customer/entity', entityDTO);
	};

	remove = recommendableEntity => {
		const entity = recommendableEntity;
		const entityDTO = this.buildCustomerEntityDTO(entity);
		return this.requestDelete(entityDTO);
	};

	requestDelete(customerEntityDTO) {
		return api.call('DELETE', '/customer/entity', customerEntityDTO);
	}

	buildCustomerEntityDTO = recommendableEntity => {
		const customerId = this.getCustomerId();
		const listType = this.props.customerListType;
		return {
			customerId,
			listType,
			entityType: recommendableEntity.type,
			entityId: recommendableEntity.id,
		};
	};

	findOneEntity = (customerEntityDTOs, entityId) => {
		const entities = customerEntityDTOs;
		for (let i = 0; i < entities.length; i++) {
			if (entities[i].entityId === entityId) {
				return { entity: entities[i], result: true };
			}
		}
		return { entity: {}, result: false };
	};

	getCustomerId() {
		const { customerId } = this.props.customerState;
		return Convert.toNumber(customerId);
	}
}

const connect = combineConnectors([connectToCustomerID]);

export default connect(CustomerEntitiesController);
