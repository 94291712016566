import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID } from '../data/CustomerIDStore';
import makeConnectTo from '../data/makeConnectTo';
import { FullDateFormatNoDayOfWeek } from '../../dateFormats';
import { Translate } from 'react-localize-redux';

const TransactionsContext = React.createContext();

export const connectToTransactions = makeConnectTo(
	TransactionsContext,
	'transactionsDataStore',
);

export const useTransactionsDataStore = () => {
	return React.useContext(TransactionsContext);
};

export class TransactionsDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		transactionsData: {
			transactions: [],
			skus: [],
			skuPosData: {},
			skuUris: {},
		},
	};

	render() {
		return (
			<TransactionsContext.Provider value={this.getDataStore()}>
				{typeof this.props.children === 'function'
					? this.props.children(this.getDataStore())
					: this.props.children}
			</TransactionsContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchTransactions();
	}

	componentWillUnmount() {
		api.revoke(this.transactionsFetch);
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		includes: sku => this.purchasesIncludeSku(sku),
		getSkuTransactions: sku => this.getSkuTransactions(sku),
		countTransactions: sku => this.countTransactions(sku),
	});

	purchasesIncludeSku(sku) {
		return this.getSkus().includes(sku);
	}

	getSkus = () => {
		return this.state.transactionsData.skus;
	};

	getSkuTransactions(sku) {
		return this.state.transactionsData.transactions.filter(
			tr => tr.sku === sku,
		);
	}

	fetchTransactions = () => {
		const { orgId, customerId } = this.props.customerState;
		const endpoint = `/org/${orgId}/customer/${customerId}/transactions/transactions`;

		this.transactionsFetch = api.cancelableGet(endpoint);
		this.transactionsFetch.promise
			.then(this.cacheApiResponse)
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load transactions');
				});
			});
	};

	cacheApiResponse = apiResponse => {
		const transactionsData = this.getTransactionsData(apiResponse);
		this.setState({ transactionsData, isLoading: false });
	};

	getTransactionsData = apiResponse => {
		const r = apiResponse;
		return {
			transactions: r.transactions,
			skus: r.transactions.map(t => t.sku),
			skuPosData: r.skuPosData,
			skuUris: r.productImageUrisBySku,
		};
	};

	countTransactions = sku => {
		const transactions = this.getSkuTransactions(sku);

		var copies = 0;
		var recentPurchase = new Date(
			transactions[0].purchaseDate,
		).toLocaleDateString('en-US', FullDateFormatNoDayOfWeek);

		transactions.forEach(transaction => {
			copies += transaction.qty;
		});
		if (copies === 1) {
			return (
				<Translate
					id="one_copy_purchased_on_x"
					data={{ date: recentPurchase }}
				/>
			);
		} else {
			return (
				<Translate
					id="x_copies_last_purchased_on_y"
					data={{ number: copies, date: recentPurchase }}
				/>
			);
		}
	};
}

export default connectToCustomerID(TransactionsDataStore);
