import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../hoc/withIsMobile';
import { connectToCustomerID } from '../data/CustomerIDStore';
import PreviewModeAlert from '../UI/PreviewModeAlert';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import BagAnchor from '../Bag/UI/BagAnchor';
import combineConnectors from '../data/combineConnectors';
import { withIsUnknownCustomer } from '../data/CustomerIDStore';
import NavigationAnchor from './NavigationAnchor';
import NavigationItems from './NavigationItems';
import { HeaderLayout } from './HeaderLayout';
import { LogoShape } from '../UI/BooksellerLogo/LogoShape';
import { connectToLogo } from '../data/BooksellerLogoStore';
import ClassicHeaderLayout from './ClassicHeaderLayout';
import OverlapHeaderLayout from './OverlapHeaderLayout';
import HeaderMargin from './HeaderMargin';
import RetailerMessage from '../UI/RetailerMessage';
import { connectToSellerContent } from '../data/BooksellerContentDataStore';
import classNames from 'classnames';
import './landingHeader.less';

class LandingHeader extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		isUnknownCustomer: PropTypes.bool,
		isPreviewMode: PropTypes.bool,
		children: PropTypes.object,
		appSettings: PropTypes.shape({
			doIncludeCommerce: PropTypes.bool,
			headerLayout: PropTypes.string,
			headerColor: PropTypes.string,
		}),
		logoShape: PropTypes.string,
		doShowRetailerBrandLogo: PropTypes.bool,
		booksellerContentStore: PropTypes.object,
	};

	static defaultProps = {
		doShowRetailerBrandLogo: true,
	};

	state = { didClosePreviewModeAlert: false };

	render() {
		const isMobile = this.props.isMobile();
		const { HomeNav, ShelvesNav } = NavigationItems;
		const {
			appSettings,
			doShowRetailerBrandLogo,
			children,
			booksellerContentStore: content,
		} = this.props;
		const { headerLayout } = appSettings;
		const isClassicHeader = headerLayout === HeaderLayout.classic;
		const { retailerMessageBody } = content.state.customerApp;
		const hasMessageBody = retailerMessageBody.length > 0;

		return (
			<React.Fragment>
				<HeaderMargin
					isPreviewModeBannerVisible={this.shouldShowAlert()}
				/>
				<HeaderContainer>
					{this.shouldShowAlert() && this.renderPreviewModeAlert()}
					<div
						className="landing-header"
						style={{
							background: appSettings.headerColor,
						}}>
						{!isClassicHeader && doShowRetailerBrandLogo && (
							<OverlapHeaderLayout />
						)}
						<div className="page-container">
							<div
								className="page-header"
								style={{
									justifyContent: this.getJustifyContentValue(),
									height: this.getHeaderHeight(),
								}}>
								{!isMobile && (
									<div className="columns">
										{!isClassicHeader && hasMessageBody && (
											<RetailerMessage />
										)}
										{this.renderNavItem(HomeNav)}
										{appSettings.doShowBrowseShelvesPage &&
											this.renderNavItem(ShelvesNav)}
									</div>
								)}
								{isClassicHeader && doShowRetailerBrandLogo && (
									<ClassicHeaderLayout />
								)}
								{!isMobile && this.renderDesktopHeaderItems()}
							</div>
						</div>
					</div>
					{appSettings.doIncludeCommerce &&
						isMobile &&
						this.renderBagAnchor()}
				</HeaderContainer>
				{children}
			</React.Fragment>
		);
	}

	renderNavItem(navItem) {
		return <NavigationAnchor {...navItem} />;
	}

	renderBagAnchor() {
		const bagAnchorWrapperClasses = classNames('bag-anchor-wrapper', {
			'bag-anchor-wrapper-alert-shown': this.shouldShowAlert(),
		});
		return (
			<div className={bagAnchorWrapperClasses}>
				<BagAnchor />
			</div>
		);
	}

	shouldShowAlert() {
		return this.props.isPreviewMode && !this.state.didClosePreviewModeAlert;
	}

	onCloseAlert = () => {
		this.setState({ didClosePreviewModeAlert: true });
	};

	renderPreviewModeAlert = () => {
		return <PreviewModeAlert onClose={this.onCloseAlert} />;
	};

	renderDesktopHeaderItems() {
		const {
			isUnknownCustomer,
			appSettings,
			booksellerContentStore: content,
		} = this.props;
		const { SignUpNav, AccountNav } = NavigationItems;
		const { headerLayout } = appSettings;
		const isClassicHeader = headerLayout === HeaderLayout.classic;
		const { retailerMessageBody } = content.state.customerApp;
		const hasMessageBody = retailerMessageBody.length > 0;

		return (
			<div className="desktop-header-items-container flex-default">
				{isClassicHeader && hasMessageBody && <RetailerMessage />}
				{appSettings.doIncludeCommerce && (
					<div>{this.renderBagAnchor()}</div>
				)}
				{this.renderNavItem(isUnknownCustomer ? SignUpNav : AccountNav)}
			</div>
		);
	}

	getHeaderHeight() {
		const { headerLayout } = this.props.appSettings;
		const { logoShape, isMobile } = this.props;
		if (headerLayout === HeaderLayout.classic && !isMobile()) {
			switch (logoShape) {
				case LogoShape.square:
					return '125px';
				case LogoShape.portrait:
					return '150px';
				default:
					return 'auto';
			}
		}
		return 'auto';
	}

	getJustifyContentValue() {
		const { headerLayout } = this.props.appSettings;
		const isMobile = this.props.isMobile();
		return isMobile
			? 'center'
			: headerLayout === HeaderLayout.classic
			? 'space-between'
			: 'flex-end';
	}
}

function HeaderContainer({ children }) {
	return (
		<div className="header-container">
			<div className="header-children-container">{children}</div>
		</div>
	);
}

const withIsPreviewMode = Component => {
	return connectToCustomerID(Component, customerState => ({
		isPreviewMode: customerState.isPreviewMode,
	}));
};

const withAppSettings = Component => {
	return connectToSellerPreferences(Component, preferences => ({
		appSettings: preferences.getAppSettings(),
	}));
};

const withLogoShape = Component => {
	return connectToLogo(Component, logoStore => ({
		logoShape: logoStore.state.logoShape,
	}));
};

const connect = combineConnectors([
	withLogoShape,
	withAppSettings,
	withIsPreviewMode,
	withIsUnknownCustomer,
	connectToSellerContent,
]);

export default connect(withIsMobile(LandingHeader, 915));
