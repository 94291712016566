import PropTypes from 'prop-types';

const RecommendableEntity = PropTypes.shape({
	id: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	entity: PropTypes.object,
});

export default RecommendableEntity;
