import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { urlPrefix } from '../../../api';
import SeriesAutocompleteRow from './SeriesAutocompleteRow';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import LoadableAutocomplete from 'treeline-react-core-components/LoadableAutocomplete';
import { Translate } from 'react-localize-redux';

class SeriesAutocomplete extends React.Component {
	static propTypes = {
		// Passed in from parent
		placeholder: PropTypes.string,
		onSelectOption: PropTypes.func,
		renderOption: PropTypes.func,
		// Mapped to props
		orgId: PropTypes.string,
	};

	static defaultProps = {
		onSelectOption: () => {},
		placeholder: <Translate id="find_a_series" />,
		renderOption: s => <SeriesAutocompleteRow series={s} />,
	};

	state = {
		isLoading: false,
	};

	render() {
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className="series-autocomplete">
							<Spin spinning={this.state.isLoading}>
								<LoadableAutocomplete
									optionIdName="id"
									optionDisplayNameKey="name"
									placeholder={this.props.placeholder}
									canShowSuccess={false}
									onSelectHook={this.onSelectHook}
									hasSearchIcon={true}
									getURLForOptions={this.getURLForOptions}
									getOptionLabel={this.props.renderOption}
									inputRequiredFeedback={this.getFeedback(
										translate,
									)}
									handleOptionsResponse={
										this.handleOptionsResponse
									}
								/>
							</Spin>
						</div>
					);
				}}
			</Translate>
		);
	}

	handleOptionsResponse = series => {
		return series.map((s, i) => ({
			id: i.toString(),
			name: s.series,
			skuCount: s.skuCount,
		}));
	};

	getURLForOptions = userInput => {
		const { orgId } = this.props;
		const endpoint = `/org/${orgId}/search/series?q=${userInput}`;
		return `${urlPrefix}${endpoint}`;
	};

	onSelectHook = autocompleteState => {
		const { selectedId: id } = autocompleteState;

		this.setState({ isLoading: true });
		const selected = autocompleteState.selectedOption;

		this.props.onSelectOption(id, selected).then(() => {
			this.setState({ isLoading: false });
		});
	};

	getFeedback = translate => {
		return translate('too_short');
	};
}

export default connectToCustomerID(SeriesAutocomplete, customerState => ({
	orgId: customerState.orgId,
}));
