import React from 'react';
import PropTypes from 'prop-types';
import TitleSkeleton from '../Title/UI/TitleSkeleton';

export class TitleSkeletons extends React.Component {
	static propTypes = {
		isRenderedInDrawer: PropTypes.bool,
	};

	render() {
		const { isRenderedInDrawer } = this.props;
		return [...Array(isRenderedInDrawer ? 5 : 10).keys()].map(titleIdx => {
			return (
				<React.Fragment key={`mock-title-${titleIdx}`}>
					<TitleSkeleton />
				</React.Fragment>
			);
		});
	}
}

export default TitleSkeletons;
