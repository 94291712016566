import React from 'react';
import api from '../../../api';
import { useCustomerID } from '../../data/CustomerIDStore';

export default function useCampaign(campaignId) {
	const { orgId } = useCustomerID();

	const [campaign, setCampaign] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(true);
	const [error, setError] = React.useState({ exists: false, body: null });

	React.useEffect(() => {
		let request;
		const endpoint = `/orgs/${orgId}/campaign/${campaignId}`;

		const fetchCampaign = async () => {
			try {
				request = api.cancelableGet(endpoint);
				const c = await request.promise;

				if (c) {
					setCampaign(c);
					setIsLoading(false);
				} else {
					throw new Error(
						`Failed to match CampaignId ${campaignId} to a campaign`,
					);
				}
			} catch (err) {
				setError({ exists: true, body: err });
			}
		};

		fetchCampaign();

		return () => {
			api.revoke(request);
		};
	}, [orgId, campaignId]);

	return {
		campaign,
		hasError: error.exists,
		isLoadingCampaign: isLoading,
	};
}
