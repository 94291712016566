import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import makeConnectTo from '../../data/makeConnectTo';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import Convert from '../../../util/Convert';

const TitleContext = React.createContext();

export const connectToTitle = makeConnectTo(TitleContext, 'titleDataStore');

class TitleDataStore extends React.Component {
	static propTypes = {
		sku: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		// Mapped to props
		customerState: PropTypes.object,
	};

	state = {
		title: {},
		isLoading: true,
		reviewerUsersById: {},
	};

	render() {
		const titleDataStore = this.getDataStore();
		return (
			<TitleContext.Provider value={titleDataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(titleDataStore)
					: this.props.children}
			</TitleContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchTitle();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		getTitle: () => state.title,
		isLoading: () => state.isLoading,
		hasReviews: () => state.title.reviews.length > 0,
		getReviewer: appUserId => this.getReviewer(state, appUserId),
		getFormatDescription: () => state.title.formatDescription,
	});

	fetchTitle = () => {
		const { sku, customerState: c } = this.props;
		const endpoint = `/org/${c.orgId}/customer/${c.customerId}/titles/${sku}/data`;

		this.request = api.cancelableGet(endpoint);
		this.request.promise
			.then(this.buildStateFromTitleAggregate)
			.catch(err => !err.isCanceled && console.log(err));
	};

	getReviewer = (state, appUserId) => {
		const id = Convert.toString(appUserId);
		const reviewer = state.reviewerUsersById[id];
		return reviewer ? reviewer : {};
	};

	buildStateFromTitleAggregate = titleAggregate => {
		const title = this.buildTitle(titleAggregate);
		const reviewerUsersById = this.buildReviewerUsersById(titleAggregate);

		this.setState({
			title,
			isLoading: false,
			reviewerUsersById,
		});
	};

	buildTitle = titleAggregate => ({
		...titleAggregate.product,
		pos: titleAggregate.skuPosData,
		reviews: titleAggregate.reviews,
		imageUri: titleAggregate.productImageUri,
		attributes: titleAggregate.attributes,
		isOnWishlist: titleAggregate.isOnWishlist,
		eCommerceUri: titleAggregate.eCommerceUri,
	});

	buildReviewerUsersById = titleAggregate => {
		const reviewerUsersById = {};

		const {
			usersById,
			userAvatarsByAppUserId: avatarsById,
		} = titleAggregate;

		if (!usersById || !avatarsById) return reviewerUsersById;

		Object.keys(usersById).forEach(appUserId => {
			reviewerUsersById[appUserId] = usersById[appUserId];
			reviewerUsersById[appUserId].avatar = avatarsById[appUserId];
		});

		return reviewerUsersById;
	};
}

export default connectToCustomerID(TitleDataStore);
