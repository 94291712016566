import React from 'react';
import PropTypes from 'prop-types';
import SeriesAutocomplete from '../../Series/UI/SeriesAutocomplete';
import LaneContainer from '../../UI/LaneContainer';
import withIsMobile from '../../hoc/withIsMobile';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import SeriesScroller from '../../Series/UI/SeriesScroller';
import RemoveFavoriteController from '../RemoveFavoriteController';
import { Button, Spin, notification } from 'antd';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import delay from '../../../util/delay';
import { connectToFavorites } from '../data/CustomerFavoritesDataStore';
import { Translate } from 'react-localize-redux';

class SeriesFavoritesLane extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		previewMode: PropTypes.object,
		customerFavoritesDataStore: PropTypes.object,
	};

	render() {
		const mobile = this.props.isMobile();
		return (
			<LaneContainer
				majorHeader={<Translate id="favorite_series" />}
				subHeader={
					!mobile ? (
						<Translate id="let_us_know_what_your_favorite_series_are" />
					) : (
						''
					)
				}
				extra={
					<Translate>
						{({ translate }) => {
							return (
								<div className="autocomplete-wrapper">
									<SeriesAutocomplete
										onSelectOption={(idx, series) =>
											this.onSelectSeries(
												idx,
												series,
												translate,
											)
										}
										placeholder={this.getPlaceholder(
											translate,
										)}
									/>
								</div>
							);
						}}
					</Translate>
				}>
				{this.handleRenderFavorites()}
			</LaneContainer>
		);
	}

	handleRenderFavorites = () => {
		const isLoading = this.props.customerFavoritesDataStore.isLoading();
		return isLoading
			? this.renderLoadingFavorites()
			: this.renderSeriesFavorites();
	};

	renderLoadingFavorites() {
		return (
			<Spin>
				<div style={{ height: '5rem', width: '100%' }} />
			</Spin>
		);
	}

	onSelectSeries = (idx, series, translate) => {
		if (this.props.previewMode.isActive()) {
			return this.makeEmptyPromise();
		}
		const entity = this.getSeriesAsEntity(series);
		const name = entity.name;

		return this.props.customerFavoritesDataStore.save(entity).then(() =>
			notification.success({
				message: translate('saved_x_to_your_favorites', {
					name: name,
				}),
			}),
		);
	};

	getSeriesAsEntity = series => {
		const entityType = RecommendableEntity.series;
		return new RecommendableEntity(series.name, entityType, series.name);
	};

	renderSeriesFavorites = () => {
		const { customerFavoritesDataStore } = this.props;
		const series = customerFavoritesDataStore.getFavoriteSeries();
		return series.length ? (
			<SeriesScroller key={series.length}>
				{series.map(this.renderOneSeries)}
			</SeriesScroller>
		) : (
			<p style={{ fontWeight: 'bold', fontSize: '14px' }}>
				<em>
					<Translate id="no_favorite_series" />
				</em>
			</p>
		);
	};

	renderOneSeries = series => {
		const entity = this.getSeriesAsEntity(series);
		return (
			<RemoveFavoriteController key={series.idx} entity={entity}>
				{({ isLoading, handleRemove }) => {
					return (
						<Spin spinning={isLoading}>
							<SeriesScroller.SeriesItem series={series}>
								<Button
									type="danger"
									size="small"
									icon="close"
									loading={isLoading}
									disabled={isLoading}
									onClick={handleRemove}
								/>
							</SeriesScroller.SeriesItem>
						</Spin>
					);
				}}
			</RemoveFavoriteController>
		);
	};

	makeEmptyPromise = () => {
		return delay(300).then(() => this.props.previewMode.notify());
	};

	getPlaceholder = translate => {
		return translate('search_for_series');
	};
}

const connectors = [connectToFavorites, connectToPreviewMode, withIsMobile];

export default combineConnectors(connectors)(SeriesFavoritesLane);
