import React from 'react';
import { useBagDataStore } from '../data/BagDataStore';
import BagOrderTitleSummary from './BagOrderTitleSummary';
import { Translate } from 'react-localize-redux';
import './bagOrderTitleSummary.less';

export default function BagOrderTitleSummaries() {
	const { getTitles } = useBagDataStore();
	const titles = getTitles();

	const renderNoTitlesInBag = () => {
		return (
			<div className="no-titles-in-bag-prompt">
				<em>
					<Translate
						id="your_bag_is_empty"
						options={{ renderInnerHtml: true }}
					/>
				</em>
			</div>
		);
	};

	const renderBagTitles = () => {
		return titles.map(t => <BagOrderTitleSummary title={t} key={t.sku} />);
	};

	return (
		<div className="bag-order-title-summary">
			{titles.length > 0 ? renderBagTitles() : renderNoTitlesInBag()}
		</div>
	);
}
