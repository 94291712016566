import React from 'react';
import PropTypes from 'prop-types';
import combineConnectors from '../data/combineConnectors';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import { connectToFavorites } from './data/CustomerFavoritesDataStore';
import Customer360PropTypes from '../../types';

class RemoveFavoriteController extends React.Component {
	static propTypes = {
		children: PropTypes.func.isRequired,
		entity: Customer360PropTypes.RecommendableEntity.isRequired,
		// Mapped to props
		previewMode: PropTypes.object,
		customerFavoritesDataStore: PropTypes.object,
	};

	state = {
		isLoading: false,
	};

	render() {
		const s = this.state;
		return this.props.children({
			isLoading: s.isLoading,
			handleRemove: () => this.handleRemove(),
		});
	}

	handleRemove = () => {
		const { previewMode, customerFavoritesDataStore, entity } = this.props;

		if (previewMode.isActive()) {
			return previewMode.notify();
		}

		this.setState({ isLoading: true });
		customerFavoritesDataStore.remove(entity);
	};
}

const connect = combineConnectors([connectToFavorites, connectToPreviewMode]);

export default connect(RemoveFavoriteController);
