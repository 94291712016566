import React from 'react';
import PropTypes from 'prop-types';
import TitleIndiePicksDataStore from '../data/TitleIndiePicksDataStore';
import DrawerAnchor from '../../UI/DrawerAnchor';
import withIsMobile from '../../hoc/withIsMobile';
import IndieReview from '../../StaffPicks/UI/IndieReview';
import { StaffUserAvatar } from '../../StaffPicks/UI/StaffUserAvatar';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import { useBooksellerPreferencesDataStore } from '../../data/BooksellerPreferencesDataStore';
import { Translate } from 'react-localize-redux';
import './titleIndiePicks.less';

TitleIndiePicks.propTypes = {
	title: PropTypes.object,
	isMobile: PropTypes.func,
};

function TitleIndiePicks({ title, isMobile }) {
	const isMobileSize = isMobile();
	const { getGradient } = useBooksellerPreferencesDataStore();

	const renderIndiePicks = titleIndiePicksDataStore => {
		const { reviews } = titleIndiePicksDataStore.getTitleIndiePicks();
		const reviewers = titleIndiePicksDataStore.getReviewers();

		if (reviews && reviews.length > 0) {
			return (
				<React.Fragment>
					<DrawerAnchor
						closable={isMobileSize}
						width={isMobileSize ? '100%' : '700px'}
						placement={isMobileSize ? 'bottom' : 'left'}
						height="100%"
						renderDrawerContent={() =>
							renderDrawerContent(reviews, reviewers)
						}>
						<div
							className="title-indie-picks-container"
							style={{
								backgroundImage: getGradient(),
							}}>
							<div className="icon-love-icon drawer-anchor-heart" />
							<div className="indie-picks-title">
								{reviews.length === 1 ? (
									<Translate id="given_much_love_by_one_indie_bookseller" />
								) : (
									<Translate
										id="given_much_love_by_x_indie_booksellers"
										data={{ number: reviews.length }}
									/>
								)}
							</div>
						</div>
					</DrawerAnchor>
				</React.Fragment>
			);
		} else {
			return <React.Fragment />;
		}
	};

	const renderDrawerContent = (reviews, reviewers) => {
		return (
			<div className="indie-picks-drawer-content">
				{isMobileSize && <div className="title-fixed-header" />}
				<div className="indie-picks-drawer-title-container">
					<div className="icon-love-icon" />
					<div className="indie-picks-drawer-title">
						<Translate
							id="much_loves_given_for_x"
							options={{ renderInnerHtml: true }}
							data={{ title: title.name }}
						/>
					</div>
				</div>
				{renderReviews(reviews, reviewers)}
			</div>
		);
	};

	const renderReviews = (reviews, reviewers) => {
		return reviews.map((review, index) => (
			<React.Fragment key={index}>
				{review.appUserId in reviewers &&
					renderReview(review, reviewers[review.appUserId])}
			</React.Fragment>
		));
	};

	const renderReview = (review, reviewer) => {
		const { assessmentId, appUserId, updatedDate } = review;

		const key = `${assessmentId}-${appUserId}`;

		return (
			<div key={key} className="indie-review-container">
				<IndieReview
					updatedDate={updatedDate}
					reviewText={review.review}
					reviewer={reviewer}>
					<StaffUserAvatar
						firstName={reviewer.firstName}
						lastName={reviewer.lastName}
						avatarUri={reviewer.avatar.uri}
						displayName={reviewer.fullName}
						size={UserAvatar.medium}
						appUserId={appUserId}
						shouldRenderStaffDetails={false}
					/>
				</IndieReview>
			</div>
		);
	};

	return (
		<TitleIndiePicksDataStore sku={title.sku}>
			{renderIndiePicks}
		</TitleIndiePicksDataStore>
	);
}

export default withIsMobile(TitleIndiePicks);
