import api from '../../../api';
import { useCustomerID } from '../../data/CustomerIDStore';
import { OrderProvider } from '../../../orders/OrderProvider';
import { AddressType } from '../../../orders/AddressType';
import { PreferredContactMethod } from '../../../orders/PreferredContactMethod';
import { DeliveryMode } from '../../../orders/DeliveryMode';
import { useBagDataStore } from '../../Bag/data/BagDataStore';

export default function usePayPalOrders(deliveryMode) {
	const { customerId, orgId } = useCustomerID();
	const bagDataStore = useBagDataStore();

	const hasValidLineItems = paypalOrderDetails => {
		const { purchase_units } = paypalOrderDetails;
		return (
			Array.isArray(purchase_units) &&
			Array.isArray(purchase_units[0].items)
		);
	};

	const buildLineItems = paypalOrderDetails => {
		const { purchase_units } = paypalOrderDetails;

		if (!hasValidLineItems(paypalOrderDetails)) {
			throw new Error('Invalid order details');
		}
		return purchase_units[0].items.map(item => ({
			sku: item.sku,
			amount: item.unit_amount.value,
			quantity: item.quantity,
			currencyCode: item.unit_amount.currency_code,
		}));
	};

	const buildShippingAddress = paypalOrderDetails => {
		const { purchase_units } = paypalOrderDetails;
		const { shipping } = purchase_units[0];
		return {
			fullName: shipping.name.full_name,
			addressType: AddressType.Shipping,
			streetAddress1: shipping.address.address_line_1,
			streetAddress2: shipping.address.address_line_2,
			region: shipping.address.admin_area_1,
			locality: shipping.address.admin_area_2,
			postalCode: shipping.address.postal_code,
			countryCode: shipping.address.country_code,
			homePhone: null,
			mobilePhone: null,
		};
	};

	const buildAddresses = paypalOrderDetails => {
		if (deliveryMode !== DeliveryMode.HomeDelivery) {
			return null;
		}
		return [buildShippingAddress(paypalOrderDetails)];
	};

	const buildOrderDTO = paypalOrderDetails => ({
		orgId,
		customerId,
		deliveryMode,
		isGift: false,
		doWrap: false,
		referenceId: paypalOrderDetails.id,
		email: paypalOrderDetails.payer.email_address,
		lastName: paypalOrderDetails.payer.name.surname,
		firstName: paypalOrderDetails.payer.name.given_name,
		orderProvider: OrderProvider.PayPal,
		preferredContactMethod: PreferredContactMethod.Email,
		status: paypalOrderDetails.status,
		shoppingCartGuid: bagDataStore.getShoppingCartGuid(),
		addresses: buildAddresses(paypalOrderDetails),
		lineItems: buildLineItems(paypalOrderDetails),
	});

	const createOrders = async paypalOrderDetails => {
		if (!paypalOrderDetails) return;

		const endpoint = `/org/${orgId}/orders`;
		const orderDTO = buildOrderDTO(paypalOrderDetails);

		const didSucceed = await api.post(endpoint, [orderDTO]);
		return didSucceed;
	};

	return {
		createPayPalOrders: createOrders,
	};
}
