/**
 *
 * @param {String} authorName
 * @returns {Object} { displayName, initials }
 * Parses author.name from {lastName}, {firstName} to {firstName} {lastName}
 * and derives initials
 */
export const getAuthorDisplayNameAndInitials = authorName => {
	if (!authorName || typeof authorName !== 'string') {
		return { displayName: 'Unknown', initials: null };
	}

	const nameTokens = authorName.split(',');

	if (nameTokens.length !== 2) {
		return { displayName: authorName, initials: null };
	}

	const lastName = nameTokens[0].trim();
	const firstName = nameTokens[1].trim();

	const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

	return { displayName: `${firstName} ${lastName}`, initials };
};
