import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import CollectionTitleCard from './CollectionTitleCard';
import TitleDetailDrawer from '../../Title/TitleDetailDrawer';
import { Button } from 'antd';
import BuyTitleButton from '../../Title/Buy/BuyTitleButton';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import './collectionTitle.css';

const propTypes = {
	isMobile: PropTypes.func,
	title: Customer360PropTypes.Title,
};

function CollectionTitle({ title, isMobile }) {
	const renderViewTitleButton = drawer => {
		return (
			<Button style={{ marginRight: '10px' }} onClick={drawer.open}>
				<Translate id="view" />
			</Button>
		);
	};

	const renderTitleActionButtons = () => {
		return (
			<div className="collection-title-action-buttons">
				<div className="flex">
					<TitleDetailDrawer sku={title.sku}>
						{renderViewTitleButton}
					</TitleDetailDrawer>
					<BuyTitleButton
						title={title}
						label={<Translate id="buy" />}
						block={false}
						doRenderBagControllerButton={false}
					/>
				</div>
			</div>
		);
	};

	return (
		<div
			className="collection-title"
			style={{ width: isMobile() ? '100%' : '50%' }}>
			<TitleDetailDrawer sku={title.sku}>
				{drawer => {
					return (
						<CollectionTitleCard
							title={title}
							onTitleJacketClick={drawer.open}>
							{renderTitleActionButtons()}
						</CollectionTitleCard>
					);
				}}
			</TitleDetailDrawer>
		</div>
	);
}

export default withIsMobile(CollectionTitle);

CollectionTitle.propTypes = propTypes;
