import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import TextTruncator from '../../UI/TextTruncator';
import { connectToSections } from '../../data/SectionsDataStore';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import FamilyTitlesDropdown from './FamilyTitlesDropdown/FamilyTitlesDropdown';
import './titleSummary.css';

export class TitleSummary extends React.Component {
	static propTypes = {
		title: Customer360PropTypes.Title,
		shouldTruncateName: PropTypes.bool,
		titleNameFontSize: PropTypes.number,
		children: PropTypes.element,
		sections: PropTypes.arrayOf(PropTypes.object),
		doShowSection: PropTypes.bool,
	};

	static defaultProps = {
		title: {},
		shouldTruncateName: true,
		titleNameFontSize: 16,
		doShowSection: false,
	};

	render() {
		return (
			<div className="title-summary-wrapper">
				{this.renderTitleSummary()}
				{this.props.children}
			</div>
		);
	}

	renderTitleSummary() {
		const {
			title,
			titleNameFontSize: sz,
			shouldTruncateName,
			doShowSection,
		} = this.props;

		let secDisplayName = '';

		if (doShowSection && Array.isArray(title.pos) && title.pos[0]) {
			secDisplayName = this.getSectionDisplayName(
				title.pos[0].sectionCode,
			);
		}

		return (
			<div style={{ marginBottom: '0.5rem', fontSize: sz }}>
				<div style={{ marginBottom: '0.2rem' }}>
					<b>
						{shouldTruncateName ? (
							<TextTruncator text={title.name} />
						) : (
							title.name
						)}
					</b>
				</div>
				<div className="title-meta-summary">
					<div>{title.author}</div>
					<div>
						<Translate id="published" />{' '}
						{new Date(title.pubDate).toLocaleDateString('en-US', {
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</div>
					<FamilyTitlesDropdown title={title} />
					{secDisplayName && (
						<em>
							<Translate
								id="in_our_x_section"
								data={{ sectionName: secDisplayName }}
							/>
						</em>
					)}
				</div>
			</div>
		);
	}

	getSectionDisplayName(sectionCode) {
		const { sections } = this.props;
		if (!sections) return '';

		const section = sections.find(s => s && s.sectionCode === sectionCode);

		return section ? (
			<Link
				className="acc-font bold clickable"
				to={`/section/section/${section.sectionId}`}>
				{section.displayName || section.sectionName}
			</Link>
		) : (
			''
		);
	}
}

const withSections = Component => {
	return connectToSections(Component, sectionsDataStore => ({
		sections: sectionsDataStore.getSections(),
	}));
};

export default withSections(TitleSummary);
