import React from 'react';
import TitleAutocomplete from '../../Title/UI/TitleAutocomplete';
import { useBagDataStore } from '../data/BagDataStore';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import { notification, Spin } from 'antd';
import { Translate } from 'react-localize-redux';

export default function AddTitlesAutocomplete() {
	const bagDataStore = useBagDataStore();
	const [isLoading, setIsLoading] = React.useState(false);

	const addTitleToBag = translate => async (sku, chosenOption) => {
		const name = chosenOption.name;
		const type = RecommendableEntity.title;

		setIsLoading(true);
		const entity = new RecommendableEntity(sku, type, name);

		await bagDataStore.save(entity);
		notification.success({
			message: translate('added_x_to_your_bag', { name: name }),
		});
		setIsLoading(false);
	};

	const getPlaceholder = translate => {
		return translate('search_for_more_titles');
	};

	return (
		<Translate>
			{({ translate }) => {
				return (
					<div className="autocomplete-wrapper">
						<Spin spinning={isLoading}>
							<TitleAutocomplete
								isClearable={false}
								placeholder={getPlaceholder(translate)}
								onSelectOption={addTitleToBag(translate)}
							/>
						</Spin>
					</div>
				);
			}}
		</Translate>
	);
}
