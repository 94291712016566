import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import { Select, Form } from 'antd';
import { useBagDataStore } from '../data/BagDataStore';
import { Translate } from 'react-localize-redux';

const { Option } = Select;

BagItemQuantitySelect.propTypes = {
	quantity: PropTypes.number,
	entity: Customer360PropTypes.RecommendableEntity,
};

const maxCopies = 7;

export default function BagItemQuantitySelect({ entity, quantity }) {
	const bag = useBagDataStore();
	const [isLoading, setIsLoading] = React.useState(false);

	const renderOptions = () => {
		const options = [];
		for (let i = 1; i < maxCopies; i++) {
			options.push(
				<Option key={i} value={i} disabled={quantity === i}>
					<div style={{ width: 60 }}>
						{i === 1 ? (
							<span>
								1 <Translate id="copy" />
							</span>
						) : (
							<span>
								{i} <Translate id="copies" />
							</span>
						)}
					</div>
				</Option>,
			);
		}
		return options;
	};

	const onSelect = async nextQuantity => {
		setIsLoading(true);
		await bag.updateQuantity(entity, nextQuantity);
		setIsLoading(false);
	};

	return (
		<Form layout="inline">
			<Form.Item style={{ marginRight: 0 }}>
				<Select
					size="small"
					loading={isLoading}
					defaultValue={quantity}
					onSelect={onSelect}>
					{renderOptions()}
				</Select>
			</Form.Item>
		</Form>
	);
}
