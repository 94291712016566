import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import {
	connectToLogo,
	useBooksellerLogoDataStore,
} from '../../data/BooksellerLogoStore';
import {
	connectToSellerPreferences,
	appSettingsByKey,
} from '../../data/BooksellerPreferencesDataStore';
import combineConnectors from '../../data/combineConnectors';
import './booksellerLogo.css';
import { LogoShape } from './LogoShape';
import withIsMobile from '../../hoc/withIsMobile';
import { HeaderLayout } from '../../Navigation/HeaderLayout';
import { useImage } from '../../hooks/useImage';

function LogoImage({ logoUri, height, isMobile, headerLayout }) {
	const { hasLoaded, hasError } = useImage(logoUri);
	const { setLogoShape } = useBooksellerLogoDataStore();
	const logoRef = React.createRef();
	const isMobileClassicLayout =
		isMobile && headerLayout === HeaderLayout.classic;

	if (hasError) {
		return null;
	}

	return (
		<Spin spinning={!hasLoaded}>
			<div
				className={
					isMobileClassicLayout
						? 'mobile-classic-layout-logo-container'
						: ''
				}>
				<img
					alt="Bookseller Logo"
					src={logoUri}
					ref={logoRef}
					height={height}
					onLoad={() =>
						setLogoShape({
							height: logoRef.current.naturalHeight,
							width: logoRef.current.naturalWidth,
						})
					}
				/>
			</div>
		</Spin>
	);
}

class BooksellerLogo extends React.Component {
	static propTypes = {
		logoStore: PropTypes.object,
		isLoadingPreferences: PropTypes.bool,
		appHeaderLogoPreferenceUri: PropTypes.string,
		isMobile: PropTypes.func,
		headerLayout: PropTypes.string,
	};

	render() {
		return (
			<Spin spinning={this.isLoading()}>
				<LogoImage
					logoUri={this.getLogoUri()}
					height={this.getLogoHeight()}
					isMobile={this.props.isMobile()}
					headerLayout={this.props.headerLayout}
				/>
			</Spin>
		);
	}

	getLogoHeight() {
		const { isMobile, headerLayout } = this.props;
		const { logoShape } = this.props.logoStore.state;
		if (headerLayout === HeaderLayout.classic) {
			if (!isMobile()) {
				switch (logoShape) {
					case LogoShape.square:
						return '110px';
					case LogoShape.portrait:
						return '130px';
					default:
						return '55px';
				}
			}
			return '100%';
		}

		switch (logoShape) {
			case LogoShape.square:
				return isMobile() ? '110px' : '250px';
			case LogoShape.portrait:
				return isMobile() ? '110px' : '280px';
			default:
				return isMobile() ? '70px' : '170px';
		}
	}

	getLogoUri() {
		const { logoUri } = this.props.logoStore.state;
		const { appHeaderLogoPreferenceUri } = this.props;
		if (this.isLoading()) {
			return '';
		}
		if (appHeaderLogoPreferenceUri) {
			return appHeaderLogoPreferenceUri;
		}
		return logoUri;
	}

	isLoading() {
		const { isLoadingPreferences, logoStore } = this.props;
		return isLoadingPreferences || logoStore.state.isLoading;
	}
}

const mapToProps = preferencesDataStore => {
	const uriKey = appSettingsByKey.appHeaderLogo;
	const uri = preferencesDataStore.getAppSettingAtKey(uriKey);
	const layoutKey = appSettingsByKey.headerLayout;
	const headerLayout = preferencesDataStore.getAppSettingAtKey(layoutKey);
	return {
		isLoadingPreferences: preferencesDataStore.isLoading(),
		appHeaderLogoPreferenceUri: uri && typeof uri === 'string' ? uri : null,
		headerLayout,
	};
};

const connectors = [
	withIsMobile,
	connectToLogo,
	C => connectToSellerPreferences(C, mapToProps),
];

export default combineConnectors(connectors)(BooksellerLogo);
