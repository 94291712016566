import { Button, Form, Input, Alert } from 'antd';
import React from 'react';
import { e360Api } from '../../../api';
import './subscribeForm.less';
import { FormComponentProps } from 'antd/es/form';
import { useOrganizationDataStore } from '../../data/OrganizationDataStore';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

type CustomerType = {
	[key: string]: string;
};

type AlertType = {
	isSuccessful: boolean;
	message?: string;
};

interface ISubscribeFormProps
	extends FormComponentProps,
		LocalizeContextProps {}

const SubscribeForm = ({ form, translate }: ISubscribeFormProps) => {
	const isFormTouched = form.isFieldsTouched();
	const { getFieldDecorator } = form;
	const [alert, setAlert] = React.useState<AlertType | null>(null);
	const {
		state: { organization },
	} = useOrganizationDataStore();

	React.useEffect(() => {
		if (isFormTouched) {
			setAlert(null);
		}
	}, [isFormTouched]);

	const config = {
		onResponse: (response: any) => response,
	};

	const postCustomer = async (customer: CustomerType) => {
		try {
			const response = await e360Api(config).post(
				`/organizations/${organization.orgID}/customers/directUpload`,
				customer,
			);
			const isSuccessful = response.ok;
			const data = await response.json();

			isSuccessful && form.resetFields();
			setAlert({ isSuccessful, message: data?.message });
		} catch (_e) {
			setAlert({ isSuccessful: false });
		}
	};

	const handleSubmit = (): void => {
		form.validateFields((err, values) => {
			if (!err) {
				values.tags = [translate('newsletter_subscribe')];
				postCustomer(values);
			}
		});
	};

	const getAlertMessage = () => {
		if (alert?.isSuccessful) return 'upload_successful';

		if (alert?.message === 'Email is invalid.') {
			return 'email_is_invalid';
		} else {
			return 'there_was_an_error_with_your_request';
		}
	};

	return (
		<Form>
			<Form.Item label={translate('first_name')}>
				{getFieldDecorator('firstName')(<Input id="firstName" />)}
			</Form.Item>
			<Form.Item label={translate('last_name')}>
				{getFieldDecorator('lastName')(<Input id="lastName" />)}
			</Form.Item>
			<Form.Item label={translate('email')}>
				{getFieldDecorator('email', {
					rules: [
						{
							required: true,
							message: translate('email_is_required'),
						},
					],
				})(<Input id="email" />)}
			</Form.Item>
			<Form.Item>
				<Button onClick={handleSubmit}>{translate('submit')}</Button>
			</Form.Item>
			{alert && (
				<Alert
					closable
					message={translate(getAlertMessage())}
					type={alert.isSuccessful ? 'success' : 'error'}
				/>
			)}
		</Form>
	);
};

const WrappedSubscribeForm = Form.create<ISubscribeFormProps>({})(
	SubscribeForm,
);

export default withLocalize(WrappedSubscribeForm);
