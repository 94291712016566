import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'antd';
import TitleActionButtons from './TitleActionButtons';
import FamilyTitlesDropdown from './FamilyTitlesDropdown/FamilyTitlesDropdown';
import './titleDescription.css';

TitleDescription.propTypes = {
	title: PropTypes.object,
	isTitleDetail: PropTypes.bool,
};

export default function TitleDescription({ title, isTitleDetail }) {
	const [expanded, setExpanded] = React.useState(false);
	const publisherDescription = title.attributes.summary;

	const renderPublisherDescription = () => {
		return (
			<>
				<div
					style={{
						maxHeight: expanded ? '100%' : 93.5,
						overflow: 'hidden',
					}}>
					{ReactHtmlParser(publisherDescription).filter(
						section => section.type === 'span' || 'p' || 'div',
					)}
				</div>
				{publisherDescription.length > 200 && renderMoreButton()}
			</>
		);
	};

	const renderMoreButton = () => {
		return (
			<div className="title-description-button-container">
				<p style={{ marginBottom: 3 }}>{!expanded ? '...' : null}</p>
				<Button
					style={{ fontSize: 16, padding: 0 }}
					type="link"
					onClick={() => setExpanded(prev => !prev)}>
					{expanded ? 'less' : 'more'}
				</Button>
			</div>
		);
	};

	return (
		<div className="title-description">
			<div className="title-description-container">
				<div className="title-description-title-name">{title.name}</div>
				<FamilyTitlesDropdown title={title} />
				<div className="title-description-pub-description-container">
					{publisherDescription && renderPublisherDescription()}
				</div>
			</div>
			<div className="title-description-action-button-container">
				<TitleActionButtons
					title={title}
					isTitleDetail={isTitleDetail}
				/>
			</div>
		</div>
	);
}
