import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useBagDataStore } from '../data/BagDataStore';
import { Button } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import './bagAnchor.css';
import { useBooksellerPreferencesDataStore } from '../../data/BooksellerPreferencesDataStore';
import { Translate } from 'react-localize-redux';

BagAnchor.propTypes = {
	isMobile: PropTypes.func,
};

function BagAnchor({ isMobile }) {
	const history = useHistory();
	const bagDataStore = useBagDataStore();
	const isLoading = bagDataStore.isLoading();
	const totalCount = bagDataStore.getTitles().length;
	const { getAppSettings } = useBooksellerPreferencesDataStore();
	const { headerColor } = getAppSettings();

	const getCountDisplay = () => {
		if (isLoading) return '-';
		return totalCount;
	};

	const goToCheckout = () => {
		history.push('/order');
	};

	const renderForMobile = () => {
		return (
			<div className="bag-anchor-root relative">
				<Button style={{ padding: '2px 7px' }} onClick={goToCheckout}>
					<div className="bag-button-label-wrapper">
						<div className="bag-anchor">
							<div className="icon-shop" />
							<div
								className="items-count"
								style={{ color: headerColor }}>
								{getCountDisplay()}
							</div>
						</div>
					</div>
				</Button>
			</div>
		);
	};

	const renderForDesktop = () => {
		const bagLabel = <Translate id="bag" />;
		const labelWithCount = (
			<>
				{bagLabel} ({getCountDisplay()})
			</>
		);
		return (
			<div className="bag-anchor-root">
				<button className="bag-button" onClick={goToCheckout}>
					<div className="column-icon icon-bag" />
					<div className="column-text">
						{totalCount > 0 ? labelWithCount : bagLabel}
					</div>
				</button>
			</div>
		);
	};

	if (isMobile()) {
		return renderForMobile();
	}
	return renderForDesktop();
}

export default withIsMobile(BagAnchor, 915);
