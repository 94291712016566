import { useState, useEffect } from 'react';
import { useCustomerID } from '../../data/CustomerIDStore';
import TitleAggregateParser from '../../../title/TitleAggregateParser';
import api, { isBadRequest } from '../../../api';

export default function useReferralWishlistTitles() {
	const c = useCustomerID();

	const [isLoading, setIsLoading] = useState(true);
	const [wishlistTitles, setWishlistTitles] = useState([]);
	const [isInvalidReferral, setIsInvalidReferral] = useState(false);

	const buildWishlistTitles = ({ titleAggregate }) => {
		const parser = new TitleAggregateParser(titleAggregate);
		setWishlistTitles(parser.buildTitles());
		setIsLoading(false);
	};

	const fetchWishlistTitlesEffect = () => {
		const referral = c.referralSource;
		const skusEndpoint = `/org/${referral.orgId}/customer/${referral.customerId}/titles/wishlist/skus`;

		let request = api.cancelableGet(skusEndpoint);

		request.promise
			.then(wishlistData => {
				const ct = wishlistData.skus.length;
				const endpoint = `/org/${referral.orgId}/customer/${referral.customerId}/titles/wishList/all/0/${ct}`;

				request = api.cancelableGet(endpoint);
				return request.promise;
			})
			.then(buildWishlistTitles)
			.catch(err => {
				if (isBadRequest(err) && !referral.orgId) {
					setIsInvalidReferral(true);
				}
			});
		return () => {
			api.revoke(request);
		};
	};

	useEffect(fetchWishlistTitlesEffect, [wishlistTitles.length]);

	return {
		isLoading,
		wishlistTitles,
		isInvalidReferral,
	};
}
