import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../paths';
import { Translate } from 'react-localize-redux';
import SignUpModal from '../UI/SignUpModal';
import { useBooksellerContentDataStore } from '../data/BooksellerContentDataStore';
import { useWithIsMobile } from '../hoc/withIsMobile';
import './signUpPopover.less';

SignUpPopover.propTypes = {
	children: PropTypes.object,
};

function SignUpPopover({ children }) {
	const { isMobile } = useWithIsMobile();
	const { state } = useBooksellerContentDataStore();
	const { retailerMessageBody } = state.customerApp;
	const location = useLocation();
	const isAuthPage = paths.isAuthPage(location.pathname);
	const [isVisible, setIsVisible] = React.useState(
		!isAuthPage && retailerMessageBody.length > 0,
	);

	const content = (
		<div className="sign-up-popover-content">
			<Button
				onClick={() => setIsVisible(false)}
				className="close-button"
				shape="circle">
				X
			</Button>
			<div className="sign-up-message">
				<Translate id="sign_up_and_access_more_features" />
			</div>
			<div onClick={() => setIsVisible(false)}>
				<SignUpModal isVisibleAtRender={false} />
			</div>
			<div style={{ display: 'flex' }}>
				<Translate id="already_have_an_account" />
				<Link to={paths.login()} style={{ marginLeft: 5 }}>
					<Translate id="log_in" />
				</Link>
			</div>
		</div>
	);

	return (
		<Popover
			content={content}
			overlayClassName="sign-up-popover"
			placement={isMobile() ? 'topRight' : 'bottomLeft'}
			visible={isVisible}>
			<div onMouseOver={() => setIsVisible(true)}>{children}</div>
		</Popover>
	);
}

export default SignUpPopover;
