import React from 'react';
import { useAuth } from '../../Auth/AuthProvider';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import './authAnchor.css';

function AuthAnchor({ isMobile }) {
	const { isAuthenticated } = useAuth();

	return isAuthenticated ? (
		<div className="clickable">
			<Link className="ant-btn auth-anchor-link" to="/logout">
				<Translate id="log_out" />
				<Icon type="right" style={{ marginTop: 8 }} />
			</Link>
		</div>
	) : (
		<div className="clickable">
			<Link className="ant-btn auth-anchor-link" to="/login">
				<Translate id="complete_registration" />{' '}
				<div className="complete-registration-icon-container">
					{!isMobile() && <Icon type="user" />}
					<Icon type="right" />
				</div>
			</Link>
		</div>
	);
}

export default withIsMobile(AuthAnchor);
