import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import StaffUserAvatar from '../UI/StaffUserAvatar';
import StaffProfile from '../UI/StaffProfile';
import { connectToStaffPicks } from '../data/StaffPicksDataStore';
import combineConnectors from '../../data/combineConnectors.js';
import { Translate } from 'react-localize-redux';
import { connectToSellerContent } from '../../data/BooksellerContentDataStore';
import { connectToSections } from '../../data/SectionsDataStore';
import { Link } from 'react-router-dom';
import AllAvailableTitles from '../../TitleLanes/AllAvailableTitles';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import withIsMobile from '../../hoc/withIsMobile';
import './staffPicksDetail.less';

class StaffPicksDetail extends React.Component {
	static propTypes = {
		// Passed in from parent
		appUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		sectionCode: PropTypes.string,
		shelfName: PropTypes.string,
		// Mapped to Props
		sectionsDataStore: PropTypes.object,
		booksellerContentStore: PropTypes.object,
		staffPicksDataStore: PropTypes.object,
		titleReviews: PropTypes.arrayOf(PropTypes.object),
		isMobile: PropTypes.func,
	};

	render() {
		const { staffPicksDataStore } = this.props;
		if (staffPicksDataStore && !staffPicksDataStore.isLoading()) {
			return this.renderReviewedTitles();
		}
		return this.renderLoading();
	}

	renderLoading() {
		return (
			<div className="loading-container">
				<Spin spinning={true} />
				<span style={{ marginLeft: '1rem' }}>
					<Translate id="loading" />
					...
				</span>
			</div>
		);
	}

	renderDrawerHeader(staffUser) {
		const { appUserId, sectionCode, isMobile } = this.props;
		return (
			<div className="staff-picks-detail-header-container">
				{!sectionCode && (
					<div className="header-avatar">
						<StaffUserAvatar
							appUserId={appUserId}
							shouldRenderStaffDetails={false}
							size={
								isMobile()
									? UserAvatar.medium
									: UserAvatar.large
							}
						/>
					</div>
				)}
				<div className="header-title">
					{this.getHeaderTitle(staffUser)}
				</div>
			</div>
		);
	}

	getHeaderTitle(staffUser) {
		const { sectionCode, shelfName } = this.props;
		return sectionCode ? (
			<Translate
				data={{
					sectionName: this.getSectionLink(sectionCode),
				}}
				id="x_picks"
			/>
		) : shelfName ? (
			<Translate
				data={{
					staffName: staffUser.firstName,
					shelfName,
				}}
				id="xs_y_picks"
			/>
		) : (
			<Translate data={{ staffName: staffUser.fullName }} id="xs_picks" />
		);
	}

	getSectionLink(sectionCode) {
		const { sectionsDataStore } = this.props;
		const section = sectionsDataStore.getSectionFromSectionCode(
			sectionCode,
		);
		return section ? (
			<Link
				className="acc-font bold clickable"
				to={`/section/section/${section.sectionId}`}>
				{section.displayName || section.sectionName}
			</Link>
		) : (
			<Translate id="section" />
		);
	}

	findStaffBioStatus(staffUser) {
		const { booksellerContentStore } = this.props;
		const { staffBios } = booksellerContentStore.state.customerApp;
		const staffUserBio = staffBios[staffUser.id];
		const hasStaffBio =
			staffUserBio &&
			(staffUserBio.aboutText || staffUserBio.shelves.length > 0);
		return { hasStaffBio, staffUserBio };
	}

	renderStaffBio(staffUser) {
		const { hasStaffBio, staffUserBio } = this.findStaffBioStatus(
			staffUser,
		);
		return (
			hasStaffBio && (
				<StaffProfile staffBio={staffUserBio} staffUser={staffUser} />
			)
		);
	}

	renderReviewedTitles() {
		const {
			appUserId,
			staffPicksDataStore,
			sectionCode,
			titleReviews,
		} = this.props;
		const staffUser = !sectionCode
			? staffPicksDataStore.getStaffUser(appUserId)
			: null;
		return (
			<div className="staff-picks-detail">
				<AllAvailableTitles
					header={this.renderDrawerHeader(staffUser)}
					titles={titleReviews}
					doShowStaffAvatar={sectionCode ? true : false}
				/>
				{staffUser && this.renderStaffBio(staffUser)}
			</div>
		);
	}
}

const withStaffUserTitleReviews = Component => {
	const mapToProps = (staffPicksDataStore, { sectionCode, appUserId }) => {
		const titlesWithReviews = sectionCode
			? staffPicksDataStore.getTitlesWithProductInfoBySection(sectionCode)
			: appUserId
			? staffPicksDataStore.getTitleReviewsByStaffUser(appUserId)
			: [];
		const sortedTitlesWithReviewText = titlesWithReviews
			.filter(title => title.review)
			.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate));
		return { titleReviews: sortedTitlesWithReviewText };
	};
	return connectToStaffPicks(Component, mapToProps);
};

const connectors = [
	withIsMobile,
	withStaffUserTitleReviews,
	connectToSellerContent,
	connectToSections,
	connectToStaffPicks,
];

export default combineConnectors(connectors)(StaffPicksDetail);
