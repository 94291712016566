import React from 'react';
import PropTypes from 'prop-types';
import TitleLanes from '../../TitleLanes';
import { Spin } from 'antd';
import RelatedDataStore from './data/RelatedDataStore';
import { ScrollerConfigProvider } from '../../UI/Scroller/ScrollerConfigContext';
import ScrollerType from '../../UI/Scroller/ScrollerType.js';
import withIsMobile from '../../hoc/withIsMobile';
import './relatedTitlesLane.css';

class RelatedTitlesLane extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		sku: PropTypes.string,
		titleName: PropTypes.string,
		retailTitleLaneType: PropTypes.string,
	};

	render() {
		const { sku, titleName, retailTitleLaneType } = this.props;
		return (
			<RelatedDataStore
				sku={sku}
				titleName={titleName}
				retailTitleLaneType={retailTitleLaneType}>
				{this.renderRelatedTitleLane}
			</RelatedDataStore>
		);
	}

	renderRelatedTitleLane = relatedDataStore => {
		const isMobile = this.props.isMobile();
		return (
			<Spin spinning={relatedDataStore.isUpdating()}>
				<div className="related-titles-lane">
					<ScrollerConfigProvider
						scrollerConfig={{
							mobile: { scrollerType: ScrollerType.carousel },
							desktop: {
								scrollerType: ScrollerType.simpleBar,
							},
						}}>
						<TitleLanes
							isRenderedInDrawer={true}
							doRenderSeeAllAnchor={!isMobile}
							titleLanesDataStore={relatedDataStore}
						/>
					</ScrollerConfigProvider>
				</div>
			</Spin>
		);
	};
}

export default withIsMobile(RelatedTitlesLane);
