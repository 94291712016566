import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Translate } from 'react-localize-redux';

export default class TextTruncator extends React.Component {
	static propTypes = {
		text: PropTypes.string,
		maxLength: PropTypes.number,
		ending: PropTypes.string,
		fallBackText: PropTypes.object,
		canUseTooltip: PropTypes.bool,
	};

	static defaultProps = {
		maxLength: 35,
		ending: '...',
		fallBackText: <Translate id="no_content" />,
		canUseTooltip: true,
	};

	render() {
		const { text, canUseTooltip } = this.props;
		const { resultText, isTruncated } = this.handleTruncateText(text);

		if (isTruncated && canUseTooltip) {
			return <Tooltip title={text}>{resultText}</Tooltip>;
		}
		return <span>{resultText}</span>;
	}

	handleTruncateText(text) {
		const { maxLength, fallBackText } = this.props;
		if (typeof text !== 'string') {
			return { resultText: fallBackText, isTruncated: false };
		}
		if (text.length > maxLength) {
			const resultText = this.truncate(text);
			return { resultText, isTruncated: true };
		}
		return { resultText: text, isTruncated: false };
	}

	truncate(text) {
		const { maxLength, ending } = this.props;
		return `${text.substring(0, maxLength - ending.length)}${ending}`;
	}
}
