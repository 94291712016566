import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import DrawerAnchor from '../../UI/DrawerAnchor';
import MyPurchases from './MyPurchases';
import { AuthContext } from '../../Auth/AuthProvider';
import { Modal, Spin, Result } from 'antd';
import delay from '../../../util/delay';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

const AuthConsumer = AuthContext.Consumer;

class MyPurchasesDrawerAnchor extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	};

	state = {
		isRedirecting: false,
		isVisible: false,
	};

	render() {
		return (
			<AuthConsumer>
				{auth => {
					if (auth && auth.isAuthenticated) {
						return this.renderDrawerAnchor();
					}
					return (
						<div>
							<div
								className="clickable"
								onClick={() =>
									this.setState({ isVisible: true })
								}>
								{this.props.children}
							</div>
							{this.renderRegistrationModal()}
						</div>
					);
				}}
			</AuthConsumer>
		);
	}

	renderDrawerAnchor() {
		const isMobile = this.props.isMobile();
		return (
			<DrawerAnchor
				closable={isMobile}
				width={isMobile ? '100%' : '700px'}
				placement={isMobile ? 'bottom' : 'left'}
				height={isMobile ? '90%' : '100%'}
				parentStyle={'clickable'}
				renderDrawerContent={this.renderDrawerContent}>
				{this.props.children}
			</DrawerAnchor>
		);
	}

	renderRegistrationModal = () => {
		const { isRedirecting, isVisible } = this.state;
		return (
			<Modal
				closable={false}
				visible={isVisible}
				title={<Translate id="complete_registration" />}
				okText={<Translate id="register" />}
				cancelText={<Translate id="cancel" />}
				onOk={this.redirectToLoginPage}
				onCancel={() => this.setState({ isVisible: false })}>
				<Spin spinning={isRedirecting}>
					<Result
						status="info"
						title={
							<Translate id="complete_registration_to_view_purchases" />
						}
					/>
				</Spin>
			</Modal>
		);
	};

	redirectToLoginPage = async () => {
		const { history } = this.props;

		this.setState({ isRedirecting: true });
		await delay(900);

		this.setState({ isVisible: false });
		await delay(500);

		history.push('/login');
	};

	renderDrawerContent = drawer => {
		return drawer.isVisible && <MyPurchases />;
	};
}

export default withRouter(withIsMobile(MyPurchasesDrawerAnchor));
