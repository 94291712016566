import { useEffect } from 'react';
import { useAuth } from './AuthProvider';

export default function Logout() {
	const { isAuthenticated, logoutWithRedirect } = useAuth();

	useEffect(() => {
		if (!isAuthenticated) return;

		logoutWithRedirect();
	});

	return null;
}
