import { sortBy } from 'lodash';
import { TitleFormatDescription } from '../components/Title/TitleFormatDescription';

export function findUniqueFamilyTitles(titles) {
	return titles.reduce((acc, title) => {
		!acc.map(t => t.familyItem).includes(title.familyItem) &&
			acc.push(title);
		return acc;
	}, []);
}

export const getFormatDescriptionDisplayName = formatDescription => {
	const format = formatDescription || TitleFormatDescription.unknown;
	switch (typeof format === 'string' ? format.toLowerCase() : format) {
		case 'trade paperback':
			return TitleFormatDescription.paperback;
		case 'mass market':
			return TitleFormatDescription.paperback;
		case 'b-format paperback':
			return TitleFormatDescription.paperback;
		case 'hardcover with dust jacket':
			return TitleFormatDescription.hardcover;
		case 'hardcover paper over boards':
			return TitleFormatDescription.hardcover;
		case 'book':
			return TitleFormatDescription.unknown;
		default:
			return format;
	}
};

export const compareTitlesByFormatAndPrice = titles => {
	const getTitlePrice = title => title.listPrice || title.retailPrice || 0;
	const formatOrder = [
		TitleFormatDescription.hardcover,
		TitleFormatDescription.paperback,
		TitleFormatDescription.audioCassette,
		TitleFormatDescription.audioCD,
		TitleFormatDescription.unknown,
	];
	const titlesSortedByFormat = sortBy(titles, i => {
		const matchingFormat = formatOrder.indexOf(i.formatDisplayName);
		return matchingFormat !== -1 ? matchingFormat : titles.length;
	});
	return titlesSortedByFormat.sort((a, b) => {
		return a.formatDisplayName === b.formatDisplayName
			? getTitlePrice(b) - getTitlePrice(a)
			: 0;
	});
};
