import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Translate } from 'react-localize-redux';
import withIsMobile from '../../hoc/withIsMobile';
import './purchasedEntityIndicator.css';

PurchasedEntityIndicator.propTypes = {
	right: PropTypes.number,
	isMobile: PropTypes.func,
};

PurchasedEntityIndicator.defaultProps = {
	right: 30,
};

function PurchasedEntityIndicator({ right, isMobile }) {
	return (
		<div className="purchased-entity-indicator" style={{ right: right }}>
			<Tooltip
				title={!isMobile() ? <Translate id="purchased" /> : null}
				placement="rightTop">
				<div className="purchased-icon-underlay" />
				<div
					className={`icon-approved ${isMobile() &&
						'icon-approved-mobile'}`}
				/>
			</Tooltip>
		</div>
	);
}

export default withIsMobile(PurchasedEntityIndicator);
