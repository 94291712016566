import React from 'react';
import Customer360PropTypes from '../../../types';

const propTypes = {
	author: Customer360PropTypes.Author,
};

export default function AuthorAutocompleteRow({ author }) {
	return (
		<div className="author-autocomplete-row">
			<span style={{ marginRight: '1rem' }}>{author.name}</span>
		</div>
	);
}

AuthorAutocompleteRow.propTypes = propTypes;
