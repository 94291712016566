import React from 'react';
import PropTypes from 'prop-types';
import SectionAutocomplete from '../../Section/UI/SectionAutocomplete';
import LaneContainer from '../../UI/LaneContainer';
import withIsMobile from '../../hoc/withIsMobile';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import RemoveGenreFavoriteButton from '../UI/RemoveGenreFavoriteButton';
import SectionsScroller from '../../Section/UI/SectionsScroller';
import { useCustomerID } from '../../data/CustomerIDStore';
import { useSectionsDataStore } from '../../data/SectionsDataStore';
import { Spin, notification } from 'antd';
import delay from '../../../util/delay';
import { useFavoritesDataStore } from '../data/CustomerFavoritesDataStore';
import { Translate } from 'react-localize-redux';
import SectionLevel from '../../../entity/SectionLevel';

ShelfFavoritesLane.propTypes = {
	isMobile: PropTypes.func,
};

function ShelfFavoritesLane({ isMobile }) {
	const { isLoading, save, getFavoriteGenres } = useFavoritesDataStore();
	const { isPreviewMode, notifyUserOfPreviewMode } = useCustomerID();
	const { getSection, getDepartment } = useSectionsDataStore();

	const handleRenderFavorites = () => {
		return isLoading() ? renderLoadingFavorites() : renderGenreFavorites();
	};

	const renderLoadingFavorites = () => {
		return (
			<Spin>
				<div style={{ height: '5rem', width: '100%' }} />
			</Spin>
		);
	};

	const notifyUserOfPreviewModeAfterDelay = () => {
		return delay(300).then(() => notifyUserOfPreviewMode());
	};

	const getSearchBoxPlaceholder = translate => {
		return translate('search_for_shelves');
	};

	const onSelectSection = (section, translate) => {
		if (isPreviewMode) {
			return notifyUserOfPreviewModeAfterDelay();
		}

		const { sectionId: id, sectionLevel: entityType } = section;
		const entity = new RecommendableEntity(id, entityType, section.name);
		const name = entity.name;
		return save(entity).then(() =>
			notification.success({
				message: translate('saved_x_to_your_favorites', {
					name: name,
				}),
			}),
		);
	};

	const renderGenreFavorites = () => {
		const genres = getFavoriteGenres();
		return genres.length ? (
			<SectionsScroller key={genres.length}>
				{genres.map(renderOneFavoriteGenre)}
			</SectionsScroller>
		) : (
			<p style={{ fontWeight: 'bold', fontSize: '14px' }}>
				<em>
					<Translate id="no_favorite_shelves" />
				</em>
			</p>
		);
	};

	const renderOneFavoriteGenre = genreEntity => {
		const { entityId: id, entityType, entityName } = genreEntity;
		const entity = new RecommendableEntity(id, entityType, entityName);
		const isValidSectionOrDepartment =
			(entityType.toLowerCase() === SectionLevel.section
				? getSection(id)
				: getDepartment(id)) !== undefined;

		return (
			<SectionsScroller.SectionAnchor
				key={id}
				entity={entity}
				doLinkToSectionRoute={isValidSectionOrDepartment}>
				<RemoveGenreFavoriteButton entity={entity} />
			</SectionsScroller.SectionAnchor>
		);
	};

	return (
		<LaneContainer
			majorHeader={<Translate id="favorite_shelves" />}
			subHeader={
				!isMobile() ? (
					<Translate id="let_us_know_where_you_like_to_browse" />
				) : (
					''
				)
			}
			extra={
				<Translate>
					{({ translate }) => {
						return (
							<div className="autocomplete-wrapper">
								<SectionAutocomplete
									onSelectOption={section =>
										onSelectSection(section, translate)
									}
									placeholder={getSearchBoxPlaceholder(
										translate,
									)}
								/>
							</div>
						);
					}}
				</Translate>
			}>
			{handleRenderFavorites()}
		</LaneContainer>
	);
}

export default withIsMobile(ShelfFavoritesLane);
