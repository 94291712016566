import Convert from '../util/Convert';

export default class TitleAggregateParser {
	aggregate = {};

	constructor(aggregate) {
		this.aggregate = aggregate;
	}

	buildTitles() {
		return this.getSkuList()
			.map(sku => this.buildOneTitle(sku))
			.filter(title => this.hasPrice(title));
	}

	getSkuList = () => {
		return this.aggregate.skus || Object.keys(this.aggregate.productsBySku);
	};

	buildOneTitle = sku => ({
		...this.getTitleProductData(sku),
		pos: this.getTitlePOSData(sku),
		reviews: this.getTitleReviews(sku),
		imageUri: this.getTitleImageUri(sku),
		offers: this.getOffersForOneTitle(sku),
		currentRecs: this.getCurrentRecsForSku(sku),
		hasImageUri: this.hasImageUri(sku),
		eCommerceUri: this.getEcommerceUri(sku),
	});

	hasPrice = title =>
		(Array.isArray(title.pos) &&
			title.pos.length &&
			title.pos[0].listPrice) ||
		title.retailPrice;

	getTitleProductData(sku) {
		const { productsBySku } = this.aggregate;

		if (productsBySku && sku in productsBySku) {
			return productsBySku[sku];
		}
		return { name: 'Title' };
	}

	getTitlePOSData(sku) {
		const { skuPosData } = this.aggregate;

		if (skuPosData && sku in skuPosData) {
			return skuPosData[sku];
		}
		return [];
	}

	getTitleImageUri(sku) {
		const { productImageUrisBySku } = this.aggregate;

		if (productImageUrisBySku && sku in productImageUrisBySku) {
			return productImageUrisBySku[sku];
		}
		return null;
	}

	hasImageUri(sku) {
		const imageUri = this.getTitleImageUri(sku);
		if (!imageUri || typeof imageUri !== 'string') return false;
		return !imageUri.includes('/noimage.png');
	}

	getCurrentRecsForSku(sku) {
		const { currentRecommendationsBySku: recsBySku } = this.aggregate;

		if (recsBySku && recsBySku[sku]) {
			return recsBySku[sku];
		}
		return [];
	}

	getTitleReviews(sku) {
		const { reviews } = this.aggregate;

		if (Array.isArray(reviews)) {
			const skuReviews = reviews.filter(r => r.sku === sku);
			return skuReviews.map(this.addUserDataToReview);
		}
		return [];
	}

	addUserDataToReview = skuReview => {
		const { userAvatarsByAppUserId, usersById } = this.aggregate;
		const userId = Convert.toString(skuReview.appUserId);

		const user = usersById ? usersById[userId] : {};
		const av = userAvatarsByAppUserId ? userAvatarsByAppUserId[userId] : {};

		return { ...skuReview, avatar: av ? av : {}, user: user ? user : {} };
	};

	getOffersForOneTitle(sku) {
		const { offersBySku } = this.aggregate;

		if (offersBySku && sku in offersBySku) {
			return offersBySku[sku];
		}
		return [];
	}

	getStaffPicksBySku(sku) {
		const { staffPicksBySku } = this.aggregate;

		if (staffPicksBySku && sku in staffPicksBySku) {
			return staffPicksBySku[sku];
		}
		return [];
	}

	getEcommerceUri(sku) {
		const { eCommerceUrisBySku } = this.aggregate;

		if (eCommerceUrisBySku && sku in eCommerceUrisBySku) {
			return eCommerceUrisBySku[sku];
		}
		return '';
	}
}
