import React from 'react';
import PropTypes from 'prop-types';
import TitleLanesDataStore from '../../data/TitleLanesDataStore';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import RetailTitleLaneType from '../../../entity/RetailTitleLaneType';
import makeConnectTo from '../../data/makeConnectTo';
import CopyLinkToWishlistAnchor from '../UI/CopyLinkToWishlistAnchor';
import { Translate } from 'react-localize-redux';

const { wishList } = RetailTitleLaneType;

const WishlistContext = React.createContext();

export const connectToWishlist = makeConnectTo(
	WishlistContext,
	'wishlistLaneDataStore',
);

class WishlistLaneDataStore extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
		sectionLevel: PropTypes.string,
		sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// Mapped to props
		customerState: PropTypes.object,
	};

	static defaultProps = {
		sectionId: '0',
		sectionLevel: 'all',
	};

	state = {
		isUpdating: false,
	};

	render() {
		return (
			<TitleLanesDataStore
				getEndpoint={this.getEndpoint}
				laneConfigs={this.getLaneConfigs()}>
				{this.renderChildren}
			</TitleLanesDataStore>
		);
	}

	renderChildren = titleLanesDataStore => {
		const wishlistLaneDataStore = this.getWishlistLaneDataStore(
			titleLanesDataStore,
		);

		if (typeof this.props.children === 'function') {
			return this.props.children(wishlistLaneDataStore);
		}
		return (
			<WishlistContext.Provider value={wishlistLaneDataStore}>
				{this.props.children}
			</WishlistContext.Provider>
		);
	};

	getWishlistLaneDataStore = titleLanesDataStore => ({
		...titleLanesDataStore,
		getLane: () => {
			return titleLanesDataStore.getLane(wishList);
		},
		getTitles: () => {
			return titleLanesDataStore.getTitles(wishList);
		},
		fetchMoreTitles: () => {
			return titleLanesDataStore.fetchMoreTitles(wishList);
		},
		hasTitlesInLane: () => {
			return titleLanesDataStore.hasTitlesInLane(wishList);
		},
		isUpdating: () => this.state.isUpdating,
		updateLane: () => this.updateLane(titleLanesDataStore),
	});

	updateLane = titleLanesDataStore => {
		this.setState({ isUpdating: true });
		return titleLanesDataStore.updateLane(wishList).then(() => {
			this.setState({ isUpdating: false });
		});
	};

	getEndpoint = (laneType, total) => {
		const { sectionId, sectionLevel: lvl, customerState: c } = this.props;
		return `/org/${c.orgId}/customer/${c.customerId}/titles/${wishList}/${lvl}/${sectionId}/${total}`;
	};

	getLaneConfigs() {
		const wishlistTitlesLaneConfig = {
			key: wishList,
			icon: '',
			title: <Translate id="wishlist" />,
			subtitle: {
				text: <Translate id="books_youd_like_to_read_next" />,
				link: (
					<CopyLinkToWishlistAnchor
						key={wishList}
						text={<Translate id="share_wishlist" />}
					/>
				),
			},
		};
		return [wishlistTitlesLaneConfig];
	}
}

export default connectToCustomerID(WishlistLaneDataStore);
