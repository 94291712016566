import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID } from './CustomerIDStore';
import makeConnectTo from './makeConnectTo';
import { LogoShape } from '../UI/BooksellerLogo/LogoShape';

const LogoContext = React.createContext();

export const connectToLogo = makeConnectTo(LogoContext, 'logoStore');
export const useBooksellerLogoDataStore = () => useContext(LogoContext);

export const ImageType = {
	logo: 1,
	splash: 2,
};

class BooksellerLogoStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]),
	};

	state = {
		logoUri: null,
		splashUri: null,
		isLoading: true,
		isLoadingSplash: true,
		splashPreviewSource: null,
		logoShape: null,
		splashIsVisible: false,
	};

	render() {
		const store = this.getDataStore(this.state);
		return (
			<LogoContext.Provider value={store}>
				{this.props.children}
			</LogoContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchBooksellerLogo();
		this.fetchBooksellerSplash();
	}

	componentWillUnmount() {
		[this.logoFetch, this.splashFetch].forEach(r => api.revoke(r));
	}

	getDataStore = state => ({
		state,
		getLogoUri: () => state.logoUri,
		getSplashImage: () => state.splashUri,
		setSplashUri: uri => this.setSplashUri(uri),
		getSplashPreviewSource: () => state.splashPreviewSource,
		isLoading: () => state.isLoading,
		isLoadingSplash: () => state.isLoadingSplash,
		hasSplashImage: () => !state.isLoadingSplash && state.splashUri,
		setSplashPreviewSource: base64 => this.setSplashPreviewSource(base64),
		clearSplashPreview: () => this.setSplashPreviewSource(null),
		hasSplashPreview: () => state.splashPreviewSource !== null,
		setLogoShape: dimensions => this.setLogoShape(dimensions),
		getLogoShape: () => state.logoShape,
		setSplashVisbility: bool => this.setSplashVisbility(bool),
		getSplashVisibility: () => state.splashIsVisible,
	});

	fetchBooksellerLogo() {
		this.logoFetch = this.fetchLogoCancelable(ImageType.logo);
		this.logoFetch.promise.then(this.cacheLogoUri).catch(err => {
			console.error(err.message);
			this.setState(() => {
				throw new Error('Failed to load logo');
			});
		});
	}

	cacheLogoUri = logoUri => {
		if (logoUri) {
			this.setState({ logoUri, isLoading: false });
		} else {
			this.setState({ isLoading: false });
		}
	};

	fetchBooksellerSplash() {
		this.splashFetch = this.fetchLogoCancelable(ImageType.splash);
		this.splashFetch.promise.then(this.cacheSplashUri).catch(err => {
			console.error(err.message);
			this.setState(() => {
				throw new Error('Failed to load splash');
			});
		});
	}

	cacheSplashUri = splashUri => {
		if (splashUri) {
			this.setSplashUri(splashUri);
		} else {
			this.setState({ isLoadingSplash: false });
		}
	};

	setSplashUri = splashUri => {
		this.setState({ splashUri, isLoadingSplash: false });
	};

	setSplashPreviewSource = splashPreviewSource => {
		this.setState({ splashPreviewSource });
	};

	fetchLogoCancelable = imageType => {
		const { orgId } = this.props.customerState;
		return api.cancelableGet(`/org/${orgId}/images/${imageType}`);
	};

	setLogoShape = dimensions => {
		const { height, width } = dimensions;
		if (height && width) {
			this.setState({
				logoShape:
					Math.abs(height - width) <= 20
						? LogoShape.square
						: height > width
						? LogoShape.portrait
						: LogoShape.landscape,
			});
		}
	};

	setSplashVisbility = bool => {
		this.setState({ splashIsVisible: bool });
	};
}

export default connectToCustomerID(BooksellerLogoStore);
