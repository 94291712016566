import React from 'react';
import PropTypes from 'prop-types';
import { FullDateFormatNoDayOfWeek } from '../../../dateFormats';
import * as TitleStatus from '../../../title/TitleStatus';

export default class BrowseTitleAutocompleteRow extends React.Component {
	static propTypes = {
		title: PropTypes.object,
	};

	render() {
		const { title } = this.props;
		return (
			<div className="clickable">
				<div
					className="acc-font bold dotDot full-width"
					title={title.title}>
					{title.title}
				</div>
				<div className="dotDot full-width">{title.author}</div>
				<div className="dotDot full-width text-small">
					{title.pubDate !== '0001-01-01T00:00:00' && (
						<React.Fragment>
							{new Date(title.pubDate).toLocaleDateString(
								'en-US',
								FullDateFormatNoDayOfWeek,
							)}
							&nbsp;&bull;&nbsp;
						</React.Fragment>
					)}
					{title.formatDescription}
				</div>
				{this.isFrontList(title.pubDate) ? (
					<div style={{ fontStyle: 'italic' }}>Coming Soon!</div>
				) : title.onHand ? (
					<div style={{ fontStyle: 'italic' }}>On the Shelf</div>
				) : (
					<div style={{ fontStyle: 'italic' }}>Available</div>
				)}
			</div>
		);
	}

	isFrontList(pubDate) {
		return TitleStatus.isNotYetPublished(pubDate);
	}
}
