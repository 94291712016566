import React from 'react';
import Customer360PropTypes from '../../../types';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useFavoritesDataStore } from '../data/CustomerFavoritesDataStore';
import withIsMobile from '../../hoc/withIsMobile';
import './favoriteEntityIndicator.css';

FavoriteEntityIndicator.propTypes = {
	isTitleDetail: PropTypes.bool,
	isInTitleLane: PropTypes.bool,
	entity: Customer360PropTypes.RecommendableEntity,
	tooltip: PropTypes.object, // optional
	isActive: PropTypes.bool, // optional
	style: PropTypes.object,
	isMobile: PropTypes.func,
};

FavoriteEntityIndicator.defaultProps = {
	style: {
		bottom: -12,
		right: 58,
	},
};

function FavoriteEntityIndicator({
	isTitleDetail,
	isInTitleLane,
	entity,
	tooltip,
	isActive,
	style,
	isMobile,
}) {
	const { includes } = useFavoritesDataStore();

	const isFavoriteEntity = () => {
		return typeof isActive === 'boolean'
			? isActive
			: includes(entity.id, entity.type);
	};

	return (
		<div
			className={`favorite-entity-indicator ${
				isMobile() && !isInTitleLane && isTitleDetail
					? 'favorite-entity-mobile-title-card'
					: null
			}`}
			style={{
				...style,
				backgroundColor: isFavoriteEntity() ? '#FB1E00' : '#525050',
			}}>
			<Tooltip
				title={!isMobile() && tooltip ? tooltip : null}
				placement="bottom">
				<div className="icon-heart" />
			</Tooltip>
		</div>
	);
}

export default withIsMobile(FavoriteEntityIndicator);
