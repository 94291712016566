import React from 'react';
import PropTypes from 'prop-types';
import CollectionStore from './data/CollectionStore';
import CollectionHeader from './UI/CollectionHeader';
import CollectionOverview from './UI/CollectionOverview';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import CollectionTitlesWrapper from './UI/CollectionTitlesWrapper';
import CollectionTitle from './UI/CollectionTitle';
import './collection.css';

export default class CollectionLanding extends React.Component {
	static propTypes = {
		// from withIsMobile
		isMobile: PropTypes.func,
	};

	render() {
		return (
			<WishlistSkusDataStore>
				<CustomerFavoritesDataStore>
					<CollectionStore>
						{this.renderCollectionLandingUI}
					</CollectionStore>
				</CustomerFavoritesDataStore>
			</WishlistSkusDataStore>
		);
	}

	renderCollectionLandingUI = collectionStore => {
		const { state } = collectionStore;
		const { overview } = state.catalog;

		return (
			<div className="collection-landing">
				<CollectionHeader />
				<div className="page-container" style={{ paddingRight: 20 }}>
					{overview && <CollectionOverview />}
					<CollectionTitlesWrapper isLoading={state.isLoading}>
						{state.titles.map(title => {
							const key = title.sku;
							return <CollectionTitle key={key} title={title} />;
						})}
					</CollectionTitlesWrapper>
				</div>
			</div>
		);
	};
}
