import React from 'react';
import ImageUploadDataStore from '../Image/ImageUploadDataStore';
import SplashImageUpload from './SplashImageUpload';

export default props => {
	return (
		<ImageUploadDataStore>
			<SplashImageUpload {...props} />
		</ImageUploadDataStore>
	);
};
