import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import Customer360PropTypes from '../../../types';
import Scroller from '../../UI/Scroller/Scroller';
import FavoriteTitlesDrawer from '../../CustomerFavorites/UI/FavoriteTitlesDrawer';
import { FavoriteType } from '../../CustomerFavorites/FavoriteType';
import './seriesScroller.css';

SeriesScrollerItem.propTypes = {
	children: PropTypes.element,
	series: Customer360PropTypes.Series,
};

function SeriesScrollerItem({ series, children }) {
	return (
		<div className="series-scroller-item">
			<Card size="small">
				<div className="flex-default">
					<FavoriteTitlesDrawer
						entity={series}
						typeOfFavorite={FavoriteType.series}>
						{drawer => (
							<div className="series-name dotDot bold acc-font clickable">
								<span onClick={drawer.open}>{series.name}</span>
							</div>
						)}
					</FavoriteTitlesDrawer>
					<span style={{ marginLeft: 10 }}>{children}</span>
				</div>
			</Card>
		</div>
	);
}

export default class SeriesScroller extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	};

	static SeriesItem = SeriesScrollerItem;

	render() {
		return (
			<Scroller>
				<div
					className="series-scroller"
					style={{ marginBottom: '1rem' }}>
					{this.props.children}
				</div>
			</Scroller>
		);
	}
}
