import React from 'react';
import { Translate } from 'react-localize-redux';
import { paths } from '../../paths';

export const LinkName = {
	Shelves: paths.browseShelves(),
	Home: paths.explore(),
	Events: paths.events(),
	Account: paths.account(),
	SignUp: paths.signup(),
};

const NavigationItems = {
	HomeNav: {
		icon: 'compass',
		label: <Translate id="explore" />,
		linkTo: LinkName.Home,
	},
	ShelvesNav: {
		icon: 'books',
		label: <Translate id="shelves" />,
		linkTo: LinkName.Shelves,
		alsoMatches: [new RegExp(/\/section\/(section|department)\/\d+/g)],
	},
	EventsNav: {
		icon: 'calendar-num',
		label: <Translate id="events" />,
		linkTo: LinkName.Events,
	},
	AccountNav: {
		icon: 'person',
		label: <Translate id="account" />,
		linkTo: LinkName.Account,
	},
	SignUpNav: {
		icon: 'person',
		label: <Translate id="sign_up" />,
		linkTo: LinkName.SignUp,
	},
};

export default NavigationItems;
