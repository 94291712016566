import React from 'react';
import PropTypes from 'prop-types';
import LoadingPage from '../UI/LoadingPage';
import { useAuth } from './AuthProvider';
import { useCustomerID } from '../data/CustomerIDStore';
import { useBooksellerLogoDataStore } from '../data/BooksellerLogoStore';
import { useOrganizationDataStore } from '../data/OrganizationDataStore';
import { useCustomerSummaryDataStore } from '../data/CustomerSummaryDataStore';
import {
	useBooksellerPreferencesDataStore,
	appSettingsByKey,
} from '../data/BooksellerPreferencesDataStore';

Login.propTypes = {
	goToSignUp: PropTypes.bool,
};

Login.defaultProps = {
	goToSignUp: false,
};

function Login({ returnUri, goToSignUp }) {
	const { orgId } = useCustomerID();
	const { loginWithRedirect } = useAuth();

	const { getOrgName } = useOrganizationDataStore();
	const { getEmailAddress } = useCustomerSummaryDataStore();

	const { getLogoUri } = useBooksellerLogoDataStore();
	const { getAppSettingAtKey } = useBooksellerPreferencesDataStore();

	React.useEffect(() => {
		const customer = {
			email: getEmailAddress(),
		};

		const tenant = {
			orgId,
			name: getOrgName(),
			logoImage: getLogoUri(),
			backgroundImage: getAppSettingAtKey(
				appSettingsByKey.loginPageImage,
			),
		};

		loginWithRedirect({
			acr_values: 'tenant:' + tenant.orgId,
			login_hint: customer.email,
			state: {
				orgId: tenant.orgId,
				returnUri: returnUri || '/',
			},
			extraQueryParams: {
				tenant: btoa(JSON.stringify(tenant)),
				signup: goToSignUp,
			},
		});
	});

	return <LoadingPage />;
}

export default Login;
