import React from 'react';
import PropTypes from 'prop-types';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import ShowMoreText from 'react-show-more-text';
import './rateAndReview.less';
import { connectToRatingsAndReviews } from '../data/RatingsAndReviewsDataStore';

export class ReviewDisplay extends React.Component {
	static propTypes = {
		entityId: PropTypes.string,
	};

	render() {
		const { entityId, ratingsAndReviewsDataStore } = this.props;
		const reviewText = ratingsAndReviewsDataStore.getReview(entityId);

		return (
			<div className="review-display">
				{reviewText ? (
					<div
						style={{
							paddingTop: '10px',
							fontSize: '18px',
						}}>
						<ShowMoreText
							lines={4}
							more="more"
							less="less"
							anchorClass=""
							expanded={false}>
							{reviewText}
						</ShowMoreText>
					</div>
				) : (
					<span />
				)}
			</div>
		);
	}
}

const connect = combineConnectors([
	connectToRatingsAndReviews,
	connectToPreviewMode,
]);

export default connect(ReviewDisplay);
