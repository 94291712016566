import { Card } from 'antd';
import React from 'react';
import { useBooksellerLogoDataStore } from '../../data/BooksellerLogoStore';
import './subscribePage.less';
import { useOrganizationDataStore } from '../../data/OrganizationDataStore';
import SubscribeForm from '../SubscribeForm';
import { Translate } from 'react-localize-redux';

const SubscribePage = (): JSX.Element => {
	const {
		getLogoUri,
		isLoading: isLogoLoading,
	} = useBooksellerLogoDataStore();
	const { getOrgName, isLoading: isOrgLoading } = useOrganizationDataStore();
	const orgName = getOrgName() ?? '';
	const isLoading = isLogoLoading() || isOrgLoading();
	const logoUri = getLogoUri();

	return (
		<div className="subscribe-page">
			<Card
				className="subscribe-form"
				loading={isLoading}
				title={
					logoUri && (
						<img
							alt="Bookseller Logo"
							className="logo"
							src={logoUri}
						/>
					)
				}>
				<h1 className="subscribe-header">
					<Translate
						id="subscribe_to_x_newsletter"
						data={{ name: orgName }}
					/>
				</h1>
				<SubscribeForm />
			</Card>
		</div>
	);
};

export default SubscribePage;
