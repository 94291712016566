import React from 'react';
import { Translate } from 'react-localize-redux';

export default function SeriesAutocompleteRow({ series }) {
	return (
		<div>
			<div>{series.name}</div>
			<em>
				<Translate
					data={{ number: series.skuCount }}
					id="contains_x_titles"
				/>
			</em>
		</div>
	);
}
