import React from 'react';
import PropTypes from 'prop-types';
import TitleLanes from '../TitleLanes';
import { Spin } from 'antd';
import { connectToWishlist } from './data/WishlistLaneDataStore';
import CopyLinkToWishlistAnchor from './UI/CopyLinkToWishlistAnchor';

class WishlistTitlesLane extends React.Component {
	static propTypes = {
		wishlistLaneDataStore: PropTypes.object,
	};

	render() {
		const { wishlistLaneDataStore } = this.props;
		return (
			<Spin spinning={wishlistLaneDataStore.isUpdating()}>
				<div className="wishlist-titles-lane">
					<TitleLanes
						renderIcon={this.renderCopyLinkToWishlistAnchor}
						titleLanesDataStore={wishlistLaneDataStore}
					/>
				</div>
			</Spin>
		);
	}

	renderCopyLinkToWishlistAnchor = () => {
		return <CopyLinkToWishlistAnchor />;
	};
}

export default connectToWishlist(WishlistTitlesLane);
