import React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Spin } from 'antd';
import Convert from '../../util/Convert';
import { Translate } from 'react-localize-redux';
import { useSectionsDataStore } from '../data/SectionsDataStore';
import SectionLevel from '../../entity/SectionLevel';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import SectionTitleLanesDataStore from '../Section/data/SectionTitleLanesDataStore';
import './shelves.less';

const { Panel } = Collapse;

export default function Shelves() {
	const { getSections, getDepartments, isLoading } = useSectionsDataStore();
	const sections = getSections();
	const departments = getDepartments();

	const renderLoading = () => {
		return <Spin className="all-shelves-spinner" spinning={true} />;
	};

	const renderShelvesBySectionsOrDepartments = () => {
		return booksellerHasDefinedEnoughDepartments()
			? renderAllDepartments()
			: renderAllSections();
	};

	const booksellerHasDefinedEnoughDepartments = () => {
		return departments && departments.length > 5;
	};

	const renderAllDepartments = () => {
		return departments.map(department =>
			renderDepartmentOrSectionWithDataStore(
				department,
				SectionLevel.department,
			),
		);
	};

	const renderAllSections = () => {
		return sections.map(section =>
			renderDepartmentOrSectionWithDataStore(
				section,
				SectionLevel.section,
			),
		);
	};

	const renderDepartmentOrSectionWithDataStore = (shelf, level) => {
		const isDepartment = level === SectionLevel.department;
		const entityId = Convert.toString(
			isDepartment ? shelf.departmentId : shelf.sectionId,
		);
		return (
			<SectionTitleLanesDataStore
				key={entityId}
				entityId={entityId}
				sectionLevel={level}>
				{titleLanesDataStore =>
					titleLanesDataStore.isLoading
						? renderLoading()
						: isDepartment
						? renderOneDepartment(titleLanesDataStore, shelf)
						: renderOneSection(titleLanesDataStore, shelf)
				}
			</SectionTitleLanesDataStore>
		);
	};

	const renderOneDepartment = (titleLanesDataStore, department) => {
		const { departmentId, departmentName, sections } = department;
		const key = Convert.toString(departmentId);
		const hasTitles = titleLanesDataStore.hasTitlesInAnyLane();

		return (
			hasTitles && (
				<Collapse bordered={false}>
					<Panel
						key={key}
						header={departmentName}
						extra={key && linkToDepartment(key)}>
						{sections.map(section =>
							renderDepartmentOrSectionWithDataStore(
								section,
								SectionLevel.section,
							),
						)}
					</Panel>
				</Collapse>
			)
		);
	};

	const linkToDepartment = departmentId => {
		return (
			<Link
				className="acc-font bold clickable"
				to={`/section/department/${departmentId}`}>
				<Translate id="view" />
			</Link>
		);
	};

	const renderOneSection = (titleLanesDataStore, section) => {
		const { sectionId, displayName } = section;
		const hasEnoughDepartments = booksellerHasDefinedEnoughDepartments();
		const hasTitles = titleLanesDataStore.hasTitlesInAnyLane();

		return (
			hasTitles && (
				<div
					className="section-link-container"
					style={hasEnoughDepartments ? { paddingLeft: 20 } : null}>
					<Link
						className="acc-font bold clickable"
						to={`/section/section/${sectionId}`}>
						{displayName}
					</Link>
				</div>
			)
		);
	};

	return (
		<WishlistSkusDataStore>
			<CustomerFavoritesDataStore>
				<div className="all-shelves-container page-container">
					<div className="all-shelves-header">
						<Translate id="all_shelves" />
					</div>
					{isLoading()
						? renderLoading()
						: renderShelvesBySectionsOrDepartments()}
				</div>
			</CustomerFavoritesDataStore>
		</WishlistSkusDataStore>
	);
}
