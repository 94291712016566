import React from 'react';
import PropTypes from 'prop-types';
import {
	connectToCustomerID,
	connectToPreviewMode,
} from '../../data/CustomerIDStore';
import api from '../../../api';
import combineConnectors from '../../data/combineConnectors';

export const RecentlyViewedContext = React.createContext();

class RecentlyViewedDataStoreBase extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		previewMode: PropTypes.object,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		customerEntitiesController: PropTypes.shape({
			get: PropTypes.func,
			save: PropTypes.func,
			remove: PropTypes.func,
			find: PropTypes.func, // (entities, entityId) => { entity: object, result: bool },
		}),
	};

	state = {
		isLoading: true,
		entities: [],
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<RecentlyViewedContext.Provider value={dataStore}>
				{this.renderChildren(dataStore)}
			</RecentlyViewedContext.Provider>
		);
	}

	componentDidMount() {
		const { previewMode, customerState } = this.props;

		if (previewMode.isActive() || customerState.isUnknownCustomer) {
			return this.setState({ isLoading: false });
		}

		this.fetchRecentlyViewedEntities();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		any: () => this.hasAnyEntities(state),
		isLoading: () => state.isLoading,
		save: entity => this.saveEntityAsRecentlyViewed(entity),
		getTitles: () => this.getTitles(state),
	});

	renderChildren(dataStore) {
		return typeof this.props.children === 'function'
			? this.props.children(dataStore)
			: this.props.children;
	}

	fetchRecentlyViewedEntities = async () => {
		const { customerEntitiesController } = this.props;
		this.request = customerEntitiesController.get();
		const entities = await this.request.promise;
		this.setState({ entities, isLoading: false });
	};

	hasAnyEntities = state => {
		if (state.isLoading) {
			return true;
		}
		return state.entities.length > 0;
	};

	saveEntityAsRecentlyViewed = async entity => {
		const { customerEntitiesController } = this.props;
		const didSave = await customerEntitiesController.save(entity);
		if (didSave) {
			this.fetchRecentlyViewedEntities();
		}
	};

	getTitles = state => {
		return state.entities.map(titleEntity => ({
			sku: titleEntity.entityId,
			name: titleEntity.entityName,
			author: titleEntity.entity.author,
			imageUri: titleEntity.entityImageUri,
		}));
	};
}

const connect = combineConnectors([connectToPreviewMode, connectToCustomerID]);

export default connect(RecentlyViewedDataStoreBase);
