import React from 'react';
import PropTypes from 'prop-types';
import { Button, notification, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import ArrowUpIcon from '../../UI/ArrowUpIcon';
import './copyLinkToWishlistAnchor.css';

const propTypes = {
	isMobile: PropTypes.func,
	text: PropTypes.element,
};

function CopyLinkToWishlistAnchor({ isMobile, text }) {
	const buildLinkToWishlist = () => {
		const host = window.location.host;
		const protocol = window.location.protocol;

		const referralSource = localStorage.getItem('data');
		return referralSource
			? `${protocol}//${host}/wishlist?referralsource=${referralSource}`
			: null;
	};

	const onCopy = translate => {
		notification.success({
			message: translate('copied_link_to_your_clipboard'),
		});
	};

	const renderShareButton = () => {
		return (
			<Button
				type="link"
				style={{ fontSize: text ? 14 : 16 }}
				className="share-wishlist-button">
				<ArrowUpIcon className="share-wishlist-icon" />
				{text}
			</Button>
		);
	};

	const wishlistLink = buildLinkToWishlist();

	return wishlistLink ? (
		<Translate>
			{({ translate }) => {
				return (
					<div className="copy-link-to-wishlist-anchor">
						<CopyToClipboard
							text={wishlistLink}
							onCopy={() => onCopy(translate)}>
							{isMobile() ? (
								renderShareButton()
							) : (
								<Tooltip
									title={
										<Translate id="copy_wishlist_to_clipboard" />
									}
									placement="right">
									{renderShareButton()}
								</Tooltip>
							)}
						</CopyToClipboard>
					</div>
				);
			}}
		</Translate>
	) : null;
}

export default withIsMobile(CopyLinkToWishlistAnchor);

CopyLinkToWishlistAnchor.propTypes = propTypes;
