import React from 'react';
import api from '../../api';
import { useParams } from 'react-router-dom';
import { useCustomerID } from '../data/CustomerIDStore';
import useCampaign from './data/useCampaign';
import LoadingPage from '../UI/LoadingPage';
import IFrameRenderer from './UI/IFrameRenderer';
import withIsMobile from '../hoc/withIsMobile';
import Page from '../Navigation/Page';
import { capitalize } from '../../util';
import { HeaderLayout } from '../Navigation/HeaderLayout';
import { useBooksellerAppSettings } from '../data/BooksellerPreferencesDataStore';
import './campaignEmailPreviewPage.css';

function CampaignEmailPreviewPage({ isMobile }) {
	const { campaignId } = useParams();
	const { customerId, orgId } = useCustomerID();
	const {
		headerLayout,
		doShowCustomerAppBrandingWhenSharingCampaign,
	} = useBooksellerAppSettings();
	const { isLoadingCampaign, hasError, campaign } = useCampaign(campaignId);

	const [isLoading, setIsLoading] = React.useState(true);
	const [campaignHtml, setCampaignHtml] = React.useState('');

	React.useEffect(() => {
		let request;
		const endpoint = `/orgs/${orgId}/customer/${customerId}/campaign/${campaignId}/email/content`;

		const fetchCampaignPreview = async () => {
			request = api.cancelableGet(endpoint);
			const html = await request.promise;

			if (html) {
				setCampaignHtml(html);
			}
			setIsLoading(false);
		};

		fetchCampaignPreview();

		return () => {
			api.revoke(request);
		};
	}, [campaignId, customerId, orgId]);

	React.useEffect(() => {
		if (hasError) {
			throw new Error(`Failed to load campaign for ${campaignId}`);
		}
	}, [hasError, campaignId]);

	const doShowCampaignHeader = () => {
		return (
			typeof campaign.subject === 'string' && campaign.subject.length > 0
		);
	};

	const renderCampaignHeader = () => {
		return (
			<div className="campaign-header">
				<h2 style={{ marginBottom: 0 }}>
					{capitalize(campaign.subject)}
				</h2>
			</div>
		);
	};

	const renderCampaignEmailPreview = () => {
		if (!campaignHtml) {
			return <em style={{ fontSize: 18 }}>No Preview Available</em>;
		}

		return (
			<div className="email-preview">
				<div className="email-iframe-container">
					<IFrameRenderer
						title="Email Preview"
						content={campaignHtml}
						style={{ border: 'none' }}
					/>
				</div>
			</div>
		);
	};

	const doShowRetailerLogo = () => {
		return headerLayout === HeaderLayout.overlap;
	};

	return (
		<Page
			doShowRetailerBrandLogo={doShowRetailerLogo()}
			doShowAppHeader={
				(doShowCustomerAppBrandingWhenSharingCampaign ?? true) &&
				!isMobile()
			}>
			<div className="campaign-email-preview-page">
				{isLoading || isLoadingCampaign ? (
					<LoadingPage />
				) : (
					<>
						{doShowCampaignHeader() && renderCampaignHeader()}
						{renderCampaignEmailPreview()}
					</>
				)}
			</div>
		</Page>
	);
}

export default withIsMobile(CampaignEmailPreviewPage);
