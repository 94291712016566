import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import combineConnectors from '../../data/combineConnectors';
import { withSellerAppSettings } from '../../data/BooksellerPreferencesDataStore';
import makeConnectTo from '../../data/makeConnectTo';
import {
	connectToPreviewMode,
	connectToCustomerID,
} from '../../data/CustomerIDStore';

const BagContext = React.createContext();

export const useBagDataStore = () => React.useContext(BagContext);

export const connectToBag = makeConnectTo(BagContext, 'bagDataStore');

const shoppingCartGuidKey = 'shoppingCartGuid';

class BagDataStore extends React.Component {
	static propTypes = {
		previewMode: PropTypes.object,
		customerState: PropTypes.object,
		children: PropTypes.element,
		appSettings: PropTypes.shape({
			doIncludeCommerce: PropTypes.bool,
		}),
	};

	state = {
		isLoading: false,
		bagItems: [],
	};

	render() {
		const bagDataStore = this.getDataStore();
		return (
			<BagContext.Provider value={bagDataStore}>
				{this.props.children}
			</BagContext.Provider>
		);
	}

	componentDidMount() {
		const { previewMode, appSettings } = this.props;

		if (previewMode.isActive() || !appSettings.doIncludeCommerce) {
			return this.setState({ isLoading: false });
		}
		if (this.hasShoppingCart()) {
			this.fetchCustomerCheckoutBag();
		}
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getTitles: () => this.getTitles(state),
		hasItems: () => state.bagItems.length > 0,
		save: recommendableEntity => this.save(recommendableEntity),
		remove: recommendableEntity => this.remove(recommendableEntity),
		includes: sku => this.includes(state.bagItems, sku),
		refresh: () => this.fetchCustomerCheckoutBag(),
		getTotalEntitiesCount: () => state.bagItems.length,
		getQuantitiesBySku: () => this.getQuantitiesBySku(state),
		updateQuantity: (item, quantity) => {
			return this.updateQuantity(item, quantity);
		},
		getShoppingCartGuid: () => this.getShoppingCartGuid(),
	});

	fetchCustomerCheckoutBag() {
		const guid = this.getShoppingCartGuid();

		if (!guid) {
			return this.setState({
				isLoading: false,
				bagItems: [],
			});
		}

		this.request = api.cancelableGet(`/shoppingCarts/${guid}`);

		return this.request.promise
			.then(shoppingCart => {
				if (shoppingCart.didCompleteOrder) {
					localStorage.removeItem(shoppingCartGuidKey);
					return this.setState({
						isLoading: false,
						bagItems: [],
					});
				}

				this.setState({
					isLoading: false,
					bagItems: shoppingCart.items,
				});
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load checkout bag');
				});
			});
	}

	getTitles = state => {
		return state.bagItems.map(item => ({
			sku: item.sku,
			name: item.product.name,
			author: item.product.author,
			imageUri: item.productImageUri,
			quantity: item.quantity,
			entity: item.product ? item.product : {},
		}));
	};

	includes = (bagItems, sku) => {
		for (let i = 0; i < bagItems.length; i++) {
			if (bagItems[i].sku === sku) {
				return true;
			}
		}
		return false;
	};

	save = async recommendableEntity => {
		const guid = this.getShoppingCartGuid();
		const { orgId, customerId } = this.props.customerState;
		const sku = recommendableEntity.id;

		if (guid === null) {
			const shoppingCart = {
				orgId,
				customerId,
				items: [
					{
						sku,
						quantity: 1,
					},
				],
			};

			const endpoint = '/shoppingCarts';
			const newShoppingCart = await api.post(endpoint, shoppingCart);

			if (newShoppingCart) {
				localStorage.setItem(shoppingCartGuidKey, newShoppingCart.id);
			}
		} else {
			const shoppingCartItem = {
				shoppingCartGuid: guid,
				sku,
				quantity: 1,
			};

			const endpoint = `/shoppingCarts/${guid}/items`;
			await api.post(endpoint, shoppingCartItem);
		}

		return this.fetchCustomerCheckoutBag();
	};

	remove = async recommendableEntity => {
		const shoppingCartItem = this.getBagItem(recommendableEntity.id);
		if (!shoppingCartItem) return;

		const cartItemToDelete = {
			id: shoppingCartItem.id,
			sku: shoppingCartItem.sku,
			shoppingCartGuid: shoppingCartItem.shoppingCartGuid,
		};

		const endpoint = `/shoppingCarts/${shoppingCartItem.shoppingCartGuid}/items`;
		await api.call('DELETE', endpoint, cartItemToDelete);

		const bagItemCount = this.getBagItemCount();
		if (bagItemCount === 1) {
			localStorage.removeItem(shoppingCartGuidKey);
		}

		return this.fetchCustomerCheckoutBag();
	};

	updateQuantity = async (recommendableEntity, nextQuantity) => {
		const shoppingCartItem = this.getBagItem(recommendableEntity.id);

		if (!shoppingCartItem) return;

		const itemToUpdate = {
			id: shoppingCartItem.id,
			sku: shoppingCartItem.sku,
			quantity: nextQuantity,
			shoppingCartGuid: shoppingCartItem.shoppingCartGuid,
		};

		const endpoint = `/shoppingCarts/${shoppingCartItem.shoppingCartGuid}/items`;
		const updatedItem = await api.put(endpoint, itemToUpdate);

		const nextBagItems = this.state.bagItems.map(item => {
			if (item.sku === updatedItem.sku) {
				return updatedItem;
			}
			return item;
		});

		this.setState({ bagItems: nextBagItems });
	};

	getQuantitiesBySku = (state = this.state) => {
		return state.bagItems.reduce((quantitiesBySku, item) => {
			quantitiesBySku[item.sku] = item.quantity;
			return quantitiesBySku;
		}, {});
	};

	getBagItem = sku => {
		return this.state.bagItems.find(item => item.sku === sku);
	};

	getBagItemCount = () => {
		return this.state.bagItems.length;
	};

	hasShoppingCart() {
		const guid = this.getShoppingCartGuid();
		return guid !== null;
	}

	getShoppingCartGuid = () => {
		return localStorage.getItem(shoppingCartGuidKey);
	};
}

const connect = combineConnectors([
	connectToPreviewMode,
	withSellerAppSettings,
	connectToCustomerID,
]);

export default connect(BagDataStore);
