import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import { UnsubscribeScope } from '../../../unsubscribe';
import { MessageFrequencyType } from '../../../messageFrequency';

const FrequencyContext = React.createContext();

export const connectToMessageFrequencyPreferences = Component => {
	return props => {
		return (
			<FrequencyContext.Consumer>
				{messageFrequencyDataStore => {
					const mfds = messageFrequencyDataStore;
					return (
						<Component
							messageFrequencyDataStore={mfds}
							{...props}
						/>
					);
				}}
			</FrequencyContext.Consumer>
		);
	};
};

class MessageFrequencyDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		messageFrequencyPreferences: [],
	};

	render() {
		return (
			<FrequencyContext.Provider value={this.getDataStore()}>
				{typeof this.props.children === 'function'
					? this.props.children(this.state)
					: this.props.children}
			</FrequencyContext.Provider>
		);
	}

	componentDidMount() {
		if (this.props.customerState.isPreviewMode) {
			return this.setState({ isLoading: false });
		}
		this.fetchFrequencyPreferences();
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		savePreference: value => this.saveFrequencyPreference(value),
		getPreference: () => this.getEmailFrequencyPreferenceValue(),
	});

	fetchFrequencyPreferences = () => {
		const { customerId } = this.props.customerState;
		const endpoint = `/preferences/messages/frequency/${customerId}`;

		this.request = api.cancelableGet(endpoint);

		return this.request.promise.then(messageFrequencyPreferences => {
			const isArr = Array.isArray(messageFrequencyPreferences);

			if (isArr && messageFrequencyPreferences.length > 0) {
				return this.setState({
					isLoading: false,
					messageFrequencyPreferences,
				});
			}
			return this.setState({ isLoading: false });
		});
	};

	// I.E every two months
	getEmailFrequencyPreferenceValue = () => {
		const result = this.getRecommendationEmailFrequencyPreference();
		if (result.hasPreference) {
			const value = this.parseValue(result.frequencyPreference.value);
			return value ? value : null;
		}
		return null;
	};

	getRecommendationEmailFrequencyPreference() {
		const { messageFrequencyPreferences: prefs } = this.state;

		for (let i = 0; i < prefs.length; i++) {
			const current = prefs[i];
			if (current.scope === UnsubscribeScope.recommendations) {
				return { hasPreference: true, frequencyPreference: current };
			}
		}
		return { hasPreference: false };
	}

	parseValue(value) {
		if (typeof value !== 'string') return 0;
		const tokens = value.split(',');
		return parseInt(tokens[1]);
	}

	// Number -> setState with new state of the customerSummary
	saveFrequencyPreference = frequencyValue => {
		const endpoint = '/preferences/messages/frequency';
		const body = this.makeMessageFrequencyPreferenceDTO(frequencyValue);

		const result = this.getRecommendationEmailFrequencyPreference();
		const httpMethod = result.hasPreference ? 'PUT' : 'POST';

		return api
			.call(httpMethod, endpoint, body)
			.then(() => this.fetchFrequencyPreferences());
	};

	makeMessageFrequencyPreferenceDTO = preferenceValue => ({
		customerId: this.props.customerState.customerId,
		scope: UnsubscribeScope.recommendations,
		type: MessageFrequencyType.XMessagesEveryYWeeks,
		value: `${this.getNumberOfEmails()},${preferenceValue}`,
	});

	getNumberOfEmails = () => 1;
}

export default connectToCustomerID(MessageFrequencyDataStore);
