import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import CollectionLanding from './Collection/CollectionLanding';
import UnsubscribePageRoot from './Unsubscribe/UnsubscribePageRoot';
import BrowseShelvesPage from './BrowseShelves/BrowseShelvesPage';
import HomePageRoot from './Home/HomePageRoot';
import SectionHome from './Section/SectionHome';
import RewardsPage from './Rewards/RewardsPage';
import WishlistCollectionPage from './Wishlist/WishlistCollectionPage';
import { connectToSellerPreferences } from './data/BooksellerPreferencesDataStore';
import { withIsUnknownCustomer } from './data/CustomerIDStore';
import combineConnectors from './data/combineConnectors';
import OrderPage from './Order/OrderPage';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import AccountPage from './CustomerAccount/AccountPage';
import CampaignEmailPreviewPage from './Campaign/CampaignEmailPreviewPage';
import Page from './Navigation/Page';
import { paths } from '../paths';
import SubscribePage from './Subscribe/SubscribePage';

class AppSwitch extends React.Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		location: PropTypes.object,
		isUnknownCustomer: PropTypes.bool,
		appSettings: PropTypes.shape({
			doShowBrowseShelvesPage: PropTypes.bool,
		}),
	};

	render() {
		const { appSettings, isUnknownCustomer } = this.props;
		return (
			<Switch>
				<Route
					path={paths.explore()}
					render={routerProps => {
						return (
							<Page>
								<HomePageRoot {...routerProps} />
							</Page>
						);
					}}
				/>
				<Route
					path={paths.collection()}
					render={() => (
						<Page>
							<CollectionLanding />
						</Page>
					)}
				/>
				<Route
					path={paths.wishlist()}
					render={() => (
						<Page>
							<WishlistCollectionPage />
						</Page>
					)}
				/>
				<Route path={paths.section()} render={this.renderSectionPage} />
				{appSettings.doShowBrowseShelvesPage && (
					<Route
						path={paths.browseShelves()}
						render={() => (
							<Page>
								<BrowseShelvesPage />
							</Page>
						)}
					/>
				)}
				{!isUnknownCustomer && (
					<Route
						path={paths.account()}
						render={() => (
							<Page>
								<AccountPage />
							</Page>
						)}
					/>
				)}
				{!isUnknownCustomer && (
					<Route
						path={paths.unsubscribe()}
						render={() => (
							<Page>
								<UnsubscribePageRoot />
							</Page>
						)}
					/>
				)}
				<Route
					path={paths.subscribe()}
					render={() => <SubscribePage />}
				/>
				{!isUnknownCustomer && (
					<Route
						path={paths.rewards()}
						render={() => (
							<Page>
								<RewardsPage />
							</Page>
						)}
					/>
				)}
				<Route
					path={paths.campaign()}
					render={() => <CampaignEmailPreviewPage />}
				/>
				<Route
					path={paths.login()}
					component={() => (
						<Page>
							<Login />
						</Page>
					)}
				/>
				<Route
					path={paths.signup()}
					component={() => (
						<Page>
							<Login goToSignUp={true} />
						</Page>
					)}
				/>
				<Route
					path={paths.logout()}
					component={() => (
						<Page>
							<Logout />
						</Page>
					)}
				/>
				{appSettings.doIncludeCommerce && (
					<Route
						path={paths.order()}
						render={() => (
							<Page>
								<OrderPage />
							</Page>
						)}
					/>
				)}
				<Redirect to={paths.explore()} />
			</Switch>
		);
	}

	componentDidUpdate(prevProps) {
		if (!window.Appcues) return;

		const thisLocation = this.props.location.pathname;
		const previousLocation = prevProps.location.pathname;

		if (thisLocation !== previousLocation) {
			window.Appcues.page();
		}
	}

	renderSectionPage = router => {
		const { sectionId, sectionLevel } = router.match.params;
		return (
			<Page>
				<SectionHome
					key={`${sectionLevel}-${sectionId}`}
					entityId={sectionId}
					sectionLevel={sectionLevel}
				/>
			</Page>
		);
	};
}

const withSellerAppSettings = Component => {
	return connectToSellerPreferences(Component, sellerPreferencesStore => ({
		appSettings: sellerPreferencesStore.getAppSettings(),
	}));
};

const connectors = [withIsUnknownCustomer, withSellerAppSettings];

export default combineConnectors(connectors)(withRouter(AppSwitch));
