import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import { connectToCustomerID, connectToPreviewMode } from './CustomerIDStore';
import { UnsubscribeScope, UnsubscribeEventType } from '../../unsubscribe';
import combineConnectors from '../data/combineConnectors';
import makeConnectTo from './makeConnectTo';

const CustomerSummaryContext = React.createContext();

export const connectToCustomer = makeConnectTo(
	CustomerSummaryContext,
	'customerDataStore',
);

export const useCustomerSummaryDataStore = () => {
	return React.useContext(CustomerSummaryContext);
};

class CustomerSummaryDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		previewMode: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		customerSummary: {},
		unsubscribes: [],
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<CustomerSummaryContext.Provider value={dataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(dataStore)
					: this.props.children}
			</CustomerSummaryContext.Provider>
		);
	}

	componentDidMount() {
		if (this.props.previewMode.isActive()) {
			return this.setPreviewModeCustomer();
		}
		if (this.props.customerState.isUnknownCustomer) {
			return this.setUnknownCustomer();
		}
		this.fetchCustomerSummary();
	}

	componentDidUpdate(prevProps) {
		const prevCustomerId = prevProps.customerState.customerId;
		const currentCustomerId = this.props.customerState.customerId;

		if (prevCustomerId !== currentCustomerId) {
			this.fetchCustomerSummary();
		}
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getFullName: () => this.getFullName(state),
		getFirstName: () => state.customerSummary.firstName,
		getLastName: () => state.customerSummary.lastName,
		getEmailAddress: () => this.getEmailAddress(),
		saveUnsubscribe: reason => this.saveUnsubscribe(reason),
		saveReSubscribe: () => this.saveReSubscribe(),
		isUnsubscribedFromRecommendationEmails: () => {
			return this.isUnsubscribedFromRecommendationEmails();
		},
	});

	getEmailAddress = () => {
		const { customerState } = this.props;
		const { emailAddress } = customerState;
		const isStr = typeof emailAddress === 'string';

		if (emailAddress && isStr && emailAddress.length > 1) {
			return emailAddress;
		}
		return '';
	};

	fetchCustomerSummary = () => {
		const { orgId, customerId } = this.props.customerState;
		const endpoint = `/org/${orgId}/customers/${customerId}/summary`;

		let summary;
		this.request = api.cancelableGet(endpoint);

		return this.request.promise
			.then(customerSummary => {
				summary = customerSummary;

				const nextEndpoint = `/unsubscribeStatus/${customerId}`;
				this.request = api.cancelableGet(nextEndpoint);

				return this.request.promise;
			})
			.then(unsubscribes => {
				return this.setState({
					isLoading: false,
					customerSummary: summary,
					unsubscribes,
				});
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load customer summary');
				});
			});
	};

	saveUnsubscribe = reason => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		const dto = this.makeUnsubscribeDTO(reason);
		return this.updateSubscriptionState(dto);
	};

	saveReSubscribe = () => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		const dto = this.makeReSubscribeDTO();
		return this.updateSubscriptionState(dto);
	};

	updateSubscriptionState = subscribeDTO => {
		return api
			.post('/unsubscribe', subscribeDTO)
			.then(this.fetchCustomerSummary);
	};

	makeUnsubscribeDTO = reason => ({
		customerId: this.props.customerState.customerId,
		reason: reason ? reason : null,
		scope: UnsubscribeScope.recommendations,
		method: 'App',
		eventType: UnsubscribeEventType.unsubscribe,
		messageId: this.props.customerState.messageId,
	});

	makeReSubscribeDTO = () => ({
		customerId: this.props.customerState.customerId,
		scope: UnsubscribeScope.recommendations,
		method: 'App',
		eventType: UnsubscribeEventType.subscribe,
	});

	isUnsubscribedFromRecommendationEmails = () => {
		const { unsubscribes } = this.state;

		if (!Array.isArray(unsubscribes) || this.props.previewMode.isActive()) {
			return false;
		}

		for (let i = 0; i < unsubscribes.length; i++) {
			const record = unsubscribes[i];

			if (record.scope === UnsubscribeScope.recommendations) {
				return true;
			}
		}
		return false;
	};

	getFullName = ({ customerSummary }) => {
		const { firstName, lastName } = customerSummary;
		return `${firstName} ${lastName}`;
	};

	setPreviewModeCustomer() {
		const customerSummary = this.getPreviewModeCustomerSummary();
		this.setState({ isLoading: false, customerSummary });
	}

	getPreviewModeCustomerSummary = () => ({
		customerId: -1,
		emailAdd: '360testing@abovethetreeline.com',
		favorites: [],
		firstName: 'Tester',
		firstPurchase: '2019-09-28T10:48:04',
		hasEmail: true,
		lastName: 'Testing',
		lastPurchase: null,
		loyaltyExpiration: null,
		recencySegment: 'top',
		recommendationExclusions: [],
		transactions: 3,
		volumeSegment: 'low',
	});

	setUnknownCustomer() {
		const customerSummary = this.getUnknownCustomerSummary();
		this.setState({ isLoading: false, customerSummary });
	}

	getUnknownCustomerSummary = () => ({
		customerId: 0,
		emailAdd: '',
		favorites: [],
		firstName: '',
		firstPurchase: '2019-09-28T10:48:04',
		hasEmail: false,
		lastName: '',
		lastPurchase: null,
		loyaltyExpiration: null,
		recencySegment: 'top',
		recommendationExclusions: [],
		transactions: 0,
		volumeSegment: 'low',
	});
}

const connectors = [connectToCustomerID, connectToPreviewMode];

export default combineConnectors(connectors)(CustomerSummaryDataStore);
