import React from 'react';
import PropTypes from 'prop-types';
import TitleCard from './UI/TitleCard';
import PublisherContent from './UI/PublisherContent';
import { connectToTitle } from './data/TitleDataStore';
import { Spin } from 'antd';
import StaffReview from '../StaffPicks/UI/StaffReview';
import { StaffUserAvatar } from '../StaffPicks/UI/StaffUserAvatar';
import withIsMobile from '../hoc/withIsMobile';
import combineConnectors from '../data/combineConnectors';
import { connectToTransactions } from '../data/TransactionsDataStore';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import TitleIndiePicks from './UI/TitleIndiePicks';
import RelatedTitlesLane from './Related/RelatedTitlesLane';
import RetailTitleLaneType from '../../entity/RetailTitleLaneType';
import TitleInset from '../Rewards/UI/TitleInset';
import { withIsUnknownCustomer } from '../data/CustomerIDStore';
import { Translate } from 'react-localize-redux';
import RecommendableEntity from '../../entity/RecommendableEntity';
import RecentlyViewedEntity from '../RecentlyViewed/RecentlyViewedEntity';
import { connectToStaffPicks } from '../StaffPicks/data/StaffPicksDataStore';
import TitleActionButtons from './UI/TitleActionButtons';
import './titleDetail.less';

class TitleDetail extends React.Component {
	static propTypes = {
		// Mapped to props
		staffPicksDataStore: PropTypes.object,
		titleDataStore: PropTypes.object,
		transactionsDataStore: PropTypes.object,
		isMobile: PropTypes.func,
		isUnknownCustomer: PropTypes.bool,
		appSettings: PropTypes.shape({
			doIncludeCommerce: PropTypes.bool,
		}),
		gradient: PropTypes.string,
	};

	render() {
		if (this.isLoading()) {
			return this.renderLoading();
		}
		return (
			<RecentlyViewedEntity recommendableEntity={this.getTitleAsEntity()}>
				{this.renderTitleUI()}
			</RecentlyViewedEntity>
		);
	}

	isLoading() {
		const dataStores = [
			this.props.titleDataStore,
			this.props.transactionsDataStore,
		].filter(ds => typeof ds !== 'undefined');

		for (let i = 0; i < dataStores.length; i++) {
			const ds = dataStores[i];
			if (typeof ds.isLoading === 'function' && ds.isLoading()) {
				return true;
			}
		}
		return false;
	}

	getTitleAsEntity = () => {
		const { titleDataStore } = this.props;
		const title = titleDataStore.getTitle();
		const entityType = RecommendableEntity.title;
		return new RecommendableEntity(title.sku, entityType, title.name);
	};

	renderTitleUI = () => {
		const { titleDataStore, transactionsDataStore } = this.props;
		const title = titleDataStore.getTitle();
		const userHasPurchased = transactionsDataStore.includes(title.sku);

		return (
			<div className="title-detail">
				{this.props.isMobile() && (
					<div className="title-fixed-header" />
				)}
				{!this.props.isUnknownCustomer && (
					<TitleInset
						sku={title.sku}
						userHasPurchased={userHasPurchased}
					/>
				)}
				{this.renderTitleCard(title)}
				{titleDataStore.hasReviews() && this.renderReviews(title)}
				<TitleIndiePicks title={title} />
				<RelatedTitlesLane
					titleName={title.name}
					sku={title.sku}
					retailTitleLaneType={RetailTitleLaneType.relatedComps}
				/>
				<RelatedTitlesLane
					sku={title.sku}
					retailTitleLaneType={RetailTitleLaneType.relatedSection}
				/>
				{title.attributes && (
					<PublisherContent titleAttributes={title.attributes} />
				)}
			</div>
		);
	};

	renderTitleCard = title => {
		return (
			<TitleCard title={title}>
				<div className="title-action-buttons">
					<TitleActionButtons title={title} isTitleDetail={true} />
				</div>
			</TitleCard>
		);
	};

	renderReviews(title) {
		const { titleDataStore, gradient, staffPicksDataStore } = this.props;
		const staffReviewerIds = staffPicksDataStore.getSelectedStaffReviewerIds();
		const visibleReviews = title.reviews.filter(r =>
			staffReviewerIds.includes(r.appUserId.toString()),
		);

		return visibleReviews.map(review => {
			const { assessmentId, appUserId, updatedDate } = review;
			const key = `${assessmentId}-${appUserId}`;
			const userReviewer = titleDataStore.getReviewer(appUserId);

			return (
				<div
					className="title-detail-staff-review"
					key={key}
					style={{ backgroundImage: gradient }}>
					<StaffReview
						doShowActionButtons={false}
						title={title}
						updatedDate={updatedDate}
						reviewText={review.review}
						reviewerFirstName={userReviewer.firstName}
						reviewerFullName={userReviewer.fullName}>
						<StaffUserAvatar
							firstName={userReviewer.firstName}
							lastName={userReviewer.lastName}
							avatarUri={userReviewer.avatar.uri}
							displayName={userReviewer.fullName}
							size={UserAvatar.medium}
							appUserId={appUserId}
						/>
					</StaffReview>
				</div>
			);
		});
	}

	renderLoading() {
		return (
			<div style={{ padding: '6rem 2rem', display: 'flex' }}>
				<Spin spinning={true} />
				<span style={{ marginLeft: '1rem' }}>
					<Translate id="loading" />
					...
				</span>
			</div>
		);
	}
}

const mapSettingsToProps = store => ({
	appSettings: store.getAppSettings(),
	gradient: store.getGradient(),
});

const connectors = [
	connectToTitle,
	connectToTransactions,
	connectToStaffPicks,
	withIsUnknownCustomer,
	C => connectToSellerPreferences(C, mapSettingsToProps),
];

export default combineConnectors(connectors)(withIsMobile(TitleDetail));
