import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import Scroller from '../../UI/Scroller/Scroller';
import AuthorCard from './AuthorCard';
import './authorsScroller.css';

AuthorScrollerItem.propTypes = {
	children: PropTypes.element,
	author: Customer360PropTypes.Author,
};

function AuthorScrollerItem({ author, children }) {
	return (
		<div className="authors-scroller-item">
			<AuthorCard author={author}>{children}</AuthorCard>
		</div>
	);
}

export default class AuthorsScroller extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	};

	static AuthorItem = AuthorScrollerItem;

	render() {
		return (
			<Scroller>
				<div
					className="authors-scroller"
					style={{ marginBottom: '1rem' }}>
					{this.props.children}
				</div>
			</Scroller>
		);
	}
}
