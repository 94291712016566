import React from 'react';
import PropTypes from 'prop-types';
import TitleLanesDataStore from '../../../data/TitleLanesDataStore';
import { connectToCustomerID } from '../../../data/CustomerIDStore';
import RetailTitleLaneType from '../../../../entity/RetailTitleLaneType';
import makeConnectTo from '../../../data/makeConnectTo';
import { Translate } from 'react-localize-redux';

const RelatedContext = React.createContext();

export const connectToRelated = makeConnectTo(
	RelatedContext,
	'relatedLaneDataStore',
);

class RelatedDataStore extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
		sku: PropTypes.string,
		titleName: PropTypes.string,
		// Mapped to props
		customerState: PropTypes.object,
	};

	state = {
		isUpdating: false,
	};

	render() {
		return (
			<TitleLanesDataStore
				titleName={this.props.titleName}
				getEndpoint={this.getEndpoint}
				laneConfigs={this.getLaneConfigs()}>
				{this.renderChildren}
			</TitleLanesDataStore>
		);
	}

	renderChildren = titleLanesDataStore => {
		const relatedDataStore = this.getRelatedDataStore(titleLanesDataStore);

		if (typeof this.props.children === 'function') {
			return this.props.children(relatedDataStore);
		}
		return (
			<RelatedContext.Provider value={relatedDataStore}>
				{this.props.children}
			</RelatedContext.Provider>
		);
	};

	getRelatedDataStore = titleLanesDataStore => ({
		...titleLanesDataStore,
		getLane: () => {
			return titleLanesDataStore.getLane(this.props.retailTitleLaneType);
		},
		getTitles: () => {
			return titleLanesDataStore.getTitles(
				this.props.retailTitleLaneType,
			);
		},
		fetchMoreTitles: () => {
			return titleLanesDataStore.fetchMoreTitles(
				this.props.retailTitleLaneType,
			);
		},
		hasTitlesInLane: () => {
			return titleLanesDataStore.hasTitlesInLane(
				this.props.retailTitleLaneType,
			);
		},
		isUpdating: () => this.state.isUpdating,
		updateLane: () => this.updateLane(titleLanesDataStore),
		fetchAllAvailableTitles: () => {
			const type = this.props.retailTitleLaneType;
			return titleLanesDataStore.fetchAllAvailableTitles(type);
		},
	});

	updateLane = titleLanesDataStore => {
		this.setState({ isUpdating: true });
		return titleLanesDataStore
			.updateLane(this.props.retailTitleLaneType)
			.then(() => {
				this.setState({ isUpdating: false });
			});
	};

	getEndpoint = (laneNameKey, total) => {
		const { retailTitleLaneType, sku, customerState: c } = this.props;
		return `/org/${c.orgId}/title/${sku}/${retailTitleLaneType}/${total}`;
	};

	getLaneConfigs() {
		const relatedTitlesLaneConfig = {
			key: this.props.retailTitleLaneType,
			icon: '',
			title: this.getTitle(this.props.retailTitleLaneType),
		};
		return [relatedTitlesLaneConfig];
	}

	getTitle = retailTitleLaneType => {
		if (retailTitleLaneType === RetailTitleLaneType.relatedComps) {
			return <Translate id="more_books_like_this" />;
		} else if (retailTitleLaneType === RetailTitleLaneType.relatedSection) {
			return <Translate id="top_sellers_on_the_same_shelves" />;
		}
	};
}

export default connectToCustomerID(RelatedDataStore);
