export const paths = {
	account: () => '/account',
	browseShelves: () => '/browse-shelves',
	campaign: () => '/campaign/:campaignId/email',
	collection: () => '/collection/:catalogId',
	events: () => '/events',
	explore: () => '/explore',
	isAuthPage: pathname => authPages.includes(pathname),
	login: () => '/login',
	logout: () => '/logout',
	order: () => '/order',
	rewards: () => '/rewards',
	section: () => '/section/:sectionLevel/:sectionId',
	signup: () => '/signup',
	subscribe: () => '/subscribe',
	unsubscribe: () => '/unsubscribe',
	wishlist: () => '/wishlist',
};

const authPages = [paths.login(), paths.signup()];
