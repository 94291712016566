import React from 'react';
import PropTypes from 'prop-types';

export default class IframeRenderer extends React.Component {
	static propTypes = {
		content: PropTypes.string.isRequired,
		width: PropTypes.number,
		height: PropTypes.number,
		style: PropTypes.object,
		title: PropTypes.string.isRequired,
	};

	static defaultProps = {
		style: {},
	};

	render() {
		return (
			<iframe
				title={this.props.title}
				ref="iframe"
				className="responsive-email-iframe"
				style={this.props.style}
			/>
		);
	}

	componentDidMount() {
		this._updateIframe();
		this.refs.iframe.addEventListener('load', this._updateIframe);
	}

	_updateIframe = () => {
		const iframe = this.refs.iframe;
		const document = iframe.contentDocument;
		document.body.innerHTML = this.props.content;
	};
}
