import React from 'react';
import PropTypes from 'prop-types';
import { FullDateFormatNoDayOfWeek } from '../../../dateFormats';
import ShowMoreText from 'react-show-more-text';
import LaneContainer from '../../UI/LaneContainer';
import { Translate } from 'react-localize-redux';
import './indieReview.less';

export default class IndieReview extends React.Component {
	static propTypes = {
		reviewer: PropTypes.object,
		reviewText: PropTypes.string,
		updatedDate: PropTypes.string,
		titleName: PropTypes.string,
		children: PropTypes.element,
	};

	render() {
		const { reviewText } = this.props;

		return (
			<LaneContainer backgroundClass="round-background">
				<div className="indie-review">
					<div className="indie-review-header">
						<div className="indie-review-header-item">
							{this.props.children}
						</div>
						{this.renderHeader()}
					</div>
					{reviewText && (
						<React.Fragment>
							{this.renderReviewText()}
							<div className="indie-review-time-stamp">
								<Translate
									id="written_on_x"
									data={{
										date: new Date(
											this.props.updatedDate,
										).toLocaleDateString(
											FullDateFormatNoDayOfWeek,
										),
									}}
								/>
							</div>
						</React.Fragment>
					)}
				</div>
			</LaneContainer>
		);
	}

	renderHeader() {
		const { reviewer } = this.props;
		return (
			<div className="indie-review-header-title">
				<Translate
					id="x_from_y_in_z"
					data={{
						name: reviewer.fullName,
						store: reviewer.orgName,
						location:
							reviewer.orgCity && reviewer.orgState ? (
								`${reviewer.orgCity}, ${reviewer.orgState}`
							) : (
								<Translate id="unknown" />
							),
					}}
				/>
			</div>
		);
	}

	renderReviewText() {
		const { reviewText } = this.props;
		return (
			<div className="indie-review-text">
				<ShowMoreText
					lines={4}
					more="more"
					less="less"
					anchorClass=""
					expanded={false}>
					{reviewText}
				</ShowMoreText>
			</div>
		);
	}
}
