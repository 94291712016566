import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]).isRequired,
		FallbackComponent: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.element,
		]), // not required
	};

	state = { hasError: false, error: { message: null, details: null } };

	render() {
		const { FallbackComponent: Fallback } = this.props;

		if (this.state.hasError) {
			// Render any custom fallback UI
			return Fallback ? <Fallback /> : this.defaultFallback();
		}

		return this.props.children;
	}

	// Update state so the next render will show the fallback UI.
	static getDerivedStateFromError() {
		return { hasError: true };
	}

	// Log the error to an error reporting service
	componentDidCatch(error, info) {
		this.setState({
			error: {
				message: error.message,
				details: info.componentStack,
			},
		});
		console.error(error, info);
	}

	defaultFallback() {
		const rootStyle = {
			padding: '2rem',
			paddingTop: '5rem',
			height: '100vh',
		};

		return (
			<div style={rootStyle}>
				<h3>
					Sorry, something went wrong. Thanks for bearing with us as
					we continue to refine the Edelweiss Indie360 beta.
				</h3>
				<details
					style={{ whiteSpace: 'pre-wrap', paddingBottom: '5rem' }}>
					Error: {this.state.error.message}
					<br />
					{this.state.error.details}
				</details>
			</div>
		);
	}
}
