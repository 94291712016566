import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

ShowingTitlesCount.propTypes = {
	numberOfTitles: PropTypes.number,
};

export default function ShowingTitlesCount({ numberOfTitles }) {
	const getCountLabel = () => {
		return numberOfTitles === 1 ? (
			<Translate id="showing_one_title" />
		) : (
			<Translate
				id="showing_x_titles"
				data={{ number: numberOfTitles }}
			/>
		);
	};
	return <em>{getCountLabel()}</em>;
}
