import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import DetailedTitleSearch from './DetailedTitleSearch';
import { Spin } from 'antd';
import delay from '../../../util/delay';

TitleSearchService.propTypes = {
	isMobile: PropTypes.func,
	children: PropTypes.func,
};

export function useTitleSearchService(isMobile) {
	const [isCancelingSearch, setIsCancelingSearch] = React.useState(false);
	const [isTitleSearchActive, setIsTitleSearchActive] = React.useState(false);

	const cancelSearch = async () => {
		setIsCancelingSearch(true);
		await delay(200);
		setIsCancelingSearch(false);
		setIsTitleSearchActive(false);
	};

	const onSearchResultsReceived = () => {
		setIsTitleSearchActive(true);
	};

	const renderTitleSearch = header => {
		return (
			<Spin spinning={isCancelingSearch}>
				<DetailedTitleSearch
					isMobile={isMobile}
					onCancel={cancelSearch}
					doShowResults={isTitleSearchActive}
					onSearchResultsReceived={onSearchResultsReceived}
					header={header}
				/>
			</Spin>
		);
	};

	return { isTitleSearchActive, renderTitleSearch };
}

function TitleSearchService({ isMobile, children }) {
	const titleSearchService = useTitleSearchService(isMobile);
	return children(titleSearchService);
}

export default withIsMobile(TitleSearchService);
