import React from 'react';
import PropTypes from 'prop-types';
import Overlay from './Overlay';
import { connectToLogo } from '../data/BooksellerLogoStore';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import SplashImageUpload from '../FileUpload/SplashImage';
import withIsMobile from '../hoc/withIsMobile';
import Fade from 'treeline-react-core-components/Fade';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import { HeaderLayout } from '../Navigation/HeaderLayout';
import classNames from 'classnames';
import './booksellerSplashImage.less';

class BooksellerSplashImage extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		previewMode: PropTypes.object,
		logoStore: PropTypes.object,
		header: PropTypes.object,
		gradient: PropTypes.string,
		headerLayout: PropTypes.string,
	};

	state = {
		isUploadVisible: true,
	};

	render() {
		const { gradient, header } = this.props;
		const imageUri = this.getImageUri();
		return (
			<div
				className="splash-image-root relative"
				style={{ backgroundImage: gradient }}>
				{imageUri ? (
					<div className="bookseller-splash-image">
						<img src={imageUri} alt="bookseller splash" />
					</div>
				) : (
					this.state.isUploadVisible &&
					this.renderPreviewModeEmptySplash()
				)}
				{header && <Overlay header={header} />}
				{this.doAllowSplashUpload() && this.renderUploadService()}
			</div>
		);
	}

	componentDidMount() {
		this.props.logoStore.setSplashVisbility(true);
	}

	componentWillUnmount() {
		if (this.props.logoStore.hasSplashPreview()) {
			this.props.logoStore.clearSplashPreview();
		}
		this.props.logoStore.setSplashVisbility(false);
	}

	getImageUri() {
		const { logoStore, previewMode } = this.props;
		const splashUri = logoStore.getSplashImage();

		const splashPreviewSrc = logoStore.getSplashPreviewSource();
		const showUploadPreview = splashPreviewSrc && previewMode.isActive();

		return showUploadPreview ? splashPreviewSrc : splashUri;
	}

	doAllowSplashUpload() {
		const { isUploadVisible: isVisible } = this.state;
		const { previewMode, isMobile, header } = this.props;
		return !header && previewMode.isActive() && !isMobile() && isVisible;
	}

	renderUploadService() {
		const { headerLayout } = this.props;
		const isOverlapLayout = headerLayout === HeaderLayout.overlap;
		const splashImageUploadContainerClasses = classNames(
			'flex-align-center',
			{
				'splash-image-upload-service-container': !isOverlapLayout,
				'splash-image-upload-service-container-overlap': isOverlapLayout,
			},
		);
		return (
			<Overlay>
				<div className={splashImageUploadContainerClasses}>
					<Fade>
						<div className="splash-image-upload-service">
							<SplashImageUpload
								onIgnore={() =>
									this.setState({
										isUploadVisible: false,
									})
								}
							/>
						</div>
					</Fade>
				</div>
			</Overlay>
		);
	}

	renderPreviewModeEmptySplash() {
		return <div className="preview-mode-empty-splash" />;
	}
}

const withSellerPreferences = Component => {
	return connectToSellerPreferences(Component, preferences => ({
		gradient: preferences.getGradient(),
		headerLayout: preferences.getAppSettingAtKey('headerLayout'),
	}));
};

const connectors = [connectToLogo, withSellerPreferences, connectToPreviewMode];

export default combineConnectors(connectors)(
	withIsMobile(BooksellerSplashImage, 825),
);
