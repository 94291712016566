import React from 'react';
import PropTypes from 'prop-types';
import delay from '../../../util/delay';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Modal, Result, Spin, notification, Tooltip } from 'antd';
import { useBagDataStore } from '../data/BagDataStore';
import useRemoveFromBag from '../data/useRemoveFromBag';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import { useDrawer } from '../../context/DrawerContext';
import withIsMobile from '../../hoc/withIsMobile';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import { Translate } from 'react-localize-redux';
import './bagControllerButton.css';

BagControllerButton.propTypes = {
	previewMode: PropTypes.object,
	sku: PropTypes.string,
	isMobile: PropTypes.func,
	titleName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

function BagControllerButton({ previewMode, sku, titleName, isMobile, label }) {
	const drawer = useDrawer();
	const bagDataStore = useBagDataStore();

	const history = useHistory();
	const routeMatch = useRouteMatch();

	const [isVisible, setIsVisible] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isRedirecting, setIsRedirecting] = React.useState(false);

	const title = { sku, name: titleName };
	const { removeFromBag } = useRemoveFromBag(title, setIsLoading);

	const getTitleAsEntity = () => {
		const type = RecommendableEntity.title;
		return new RecommendableEntity(sku, type, titleName, title);
	};

	const addToBag = async () => {
		if (previewMode.isActive()) {
			return previewMode.notify();
		}

		setIsLoading(true);
		const recommendableEntity = getTitleAsEntity();

		await bagDataStore.save(recommendableEntity);

		setIsLoading(false);
		setIsVisible(true);
	};

	const handleRemoveFromBag = translate => async () => {
		if (previewMode.isActive()) {
			return previewMode.notify();
		}

		await removeFromBag();
		if (routeMatch.path !== '/order') {
			setIsLoading(false);
			notification.success({ message: translate('removed_from_bag') });
		}
	};

	const redirectToOrderPage = async () => {
		setIsRedirecting(true);
		await delay(900);

		setIsVisible(false);
		await delay(500);

		drawer && drawer.close && drawer.close();
		await delay(500);

		history.push('/order');
	};

	const renderAddedToBagModal = () => {
		return (
			<Modal
				closable={false}
				visible={isVisible}
				title={<Translate id="added_to_checkout_bag" />}
				okText={<Translate id="checkout_now" />}
				cancelText={<Translate id="continue_browsing" />}
				onOk={redirectToOrderPage}
				onCancel={() => setIsVisible(false)}>
				<Spin spinning={isRedirecting}>
					<Result
						status="success"
						title={<Translate id="do_you_want_to_checkout_now" />}
					/>
				</Spin>
			</Modal>
		);
	};

	const isMobileSize = isMobile();
	const isInBag = bagDataStore.includes(sku);

	const getButtonLabel = () => {
		return label && !isInBag ? (
			label
		) : (
			<>
				<div
					className={`bag-button-icon icon-${
						isInBag ? 'approved' : 'add-circle'
					}`}
				/>
				<Translate id="bag" />
			</>
		);
	};

	const renderButton = translate => {
		return (
			<div className="bag-controller-button-root">
				<Button
					disabled={isLoading || routeMatch.path === '/order'}
					className="bag-controller-button"
					onClick={
						isInBag ? handleRemoveFromBag(translate) : addToBag
					}>
					{getButtonLabel()}
				</Button>
				{renderAddedToBagModal()}
			</div>
		);
	};

	return (
		<Translate>
			{({ translate }) => {
				return isMobileSize ? (
					renderButton(translate)
				) : (
					<Tooltip
						placement="top"
						title={
							isInBag ? (
								<Translate id="currently_in_bag" />
							) : (
								<Translate id="add_to_bag_and_checkout" />
							)
						}>
						{renderButton(translate)}
					</Tooltip>
				);
			}}
		</Translate>
	);
}

export default connectToPreviewMode(withIsMobile(BagControllerButton, 915));
