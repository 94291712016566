import React from 'react';
import PropTypes from 'prop-types';
import { HeaderLayout } from '../Navigation/HeaderLayout';
import { LogoShape } from '../UI/BooksellerLogo/LogoShape';
import { useBooksellerLogoDataStore } from '../data/BooksellerLogoStore';
import { useBooksellerAppSettings } from '../data/BooksellerPreferencesDataStore';
import withIsMobile from '../hoc/withIsMobile';

HeaderMargin.propTypes = {
	isMobile: PropTypes.func,
	isPreviewModeBannerVisible: PropTypes.bool,
};

function HeaderMargin({ isMobile, isPreviewModeBannerVisible }) {
	const { getLogoShape } = useBooksellerLogoDataStore();
	const { headerLayout } = useBooksellerAppSettings();

	const findMarginTop = () => {
		if (headerLayout === HeaderLayout.classic && !isMobile()) {
			switch (getLogoShape()) {
				case LogoShape.square:
					return isPreviewModeBannerVisible ? 205 : 125;
				case LogoShape.portrait:
					return isPreviewModeBannerVisible ? 230 : 150;
				default:
					return isPreviewModeBannerVisible ? 154 : 75;
			}
		}
		return isPreviewModeBannerVisible ? 154 : 75;
	};

	return (
		<div
			style={{
				marginTop: findMarginTop(),
				flexShrink: 0,
				flexGrow: 1,
			}}
		/>
	);
}

export default withIsMobile(HeaderMargin);
