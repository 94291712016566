import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BooksellerLogo from '../UI/BooksellerLogo/BooksellerLogo';
import { LinkName } from './NavigationItems';
import { useOrganizationDataStore } from '../data/OrganizationDataStore';
import withIsMobile from '../hoc/withIsMobile';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import { LogoShape } from '../UI/BooksellerLogo/LogoShape';
import { useBooksellerLogoDataStore } from '../data/BooksellerLogoStore';
import classNames from 'classnames';
import './overlapHeaderLayout.less';

OverlapHeaderLayout.propTypes = {
	isMobile: PropTypes.func,
};

function OverlapHeaderLayout({ isMobile }) {
	const { getOrgName } = useOrganizationDataStore();
	const { getAppSettings } = useBooksellerPreferencesDataStore();
	const { headerColor, iconColor } = getAppSettings();
	const { getLogoShape, getSplashVisibility } = useBooksellerLogoDataStore();

	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const findMaxScrollPosition = () => {
		switch (getLogoShape()) {
			case LogoShape.square:
				return isMobile() ? 140 : 275;
			case LogoShape.portrait:
				return isMobile() ? 140 : 300;
			default:
				return isMobile() ? 100 : 200;
		}
	};

	const doUseExplorePageDisplay =
		getSplashVisibility() && scrollPosition < findMaxScrollPosition();

	const headerLogoClass = classNames({
		'header-logo-explore-overlap': doUseExplorePageDisplay,
		'header-logo-overlap': !doUseExplorePageDisplay,
	});

	return (
		<>
			<div
				className="overlap-header-logo-background"
				style={{
					backgroundColor: headerColor,
				}}
			/>
			<div className="header-logo-container">
				<Link to={LinkName.Home} className={headerLogoClass}>
					<BooksellerLogo />
				</Link>
				{!doUseExplorePageDisplay && !isMobile() && (
					<div
						className="header-org-name"
						style={{ color: iconColor }}>
						{getOrgName()}
					</div>
				)}
			</div>
		</>
	);
}

export default withIsMobile(OverlapHeaderLayout);
