import { useState, useEffect } from 'react';
import { useMounted } from './useMounted';

export const useImage = src => {
	const [hasLoaded, setHasLoaded] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [hasStartedInitialFetch, setHasStartedInitialFetch] = useState(false);
	const isMounted = useMounted();

	useEffect(() => {
		setHasStartedInitialFetch(true);
		setHasLoaded(false);
		setHasError(false);

		const image = new Image();
		image.src = src;

		const handleError = () => {
			setHasError(true);
		};

		const handleLoad = () => {
			if (isMounted.current) {
				setHasLoaded(true);
				setHasError(false);
			}
		};

		image.onerror = handleError;
		image.onload = handleLoad;

		return () => {
			image.removeEventListener('error', handleError);
			image.removeEventListener('load', handleLoad);
		};
	}, [src, isMounted]);

	return { hasLoaded, hasError, hasStartedInitialFetch };
};
