import React from 'react';
import { WindowSizeContext } from '../context/WindowSize';

const WithIsMobileContext = React.createContext();
export const useWithIsMobile = () => React.useContext(WithIsMobileContext);

export default function withIsMobile(Component, mobileThreshold = 815) {
	return class MobileIdentifier extends React.Component {
		render() {
			return (
				<WindowSizeContext.Consumer>
					{windowSize => (
						<WithIsMobileContext.Provider
							value={{ isMobile: this.initIsMobile(windowSize) }}>
							<Component
								{...this.props}
								isMobile={this.initIsMobile(windowSize)}
							/>
						</WithIsMobileContext.Provider>
					)}
				</WindowSizeContext.Consumer>
			);
		}

		initIsMobile = ({ width }) => (target = mobileThreshold) => {
			if (typeof width !== 'number' || width === 0) {
				return false;
			}
			return width <= target;
		};
	};
}
