import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import LoadableAutocomplete from 'treeline-react-core-components/LoadableAutocomplete';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import { urlPrefix } from '../../../api';
import { FullDateFormatNoDayOfWeek } from '../../../dateFormats';
import { Translate } from 'react-localize-redux';

class TitleAutocompleteRow extends React.Component {
	static propTypes = {
		title: PropTypes.object,
	};

	render() {
		const { title } = this.props;
		return (
			<div className="clickable">
				<div
					className="acc-font bold dotDot full-width"
					title={title.title}>
					{title.title}
				</div>
				<div className="dotDot full-width">{title.author}</div>
				<div className="dotDot full-width text-small">
					<Translate id="published" />:{' '}
					{new Date(title.pubDate).toLocaleDateString(
						FullDateFormatNoDayOfWeek,
					)}
				</div>
			</div>
		);
	}
}

class TitleAutocomplete extends React.Component {
	static propTypes = {
		// Passed in from parent
		placeholder: PropTypes.string,
		onSelectOption: PropTypes.func,
		renderOption: PropTypes.func,
		isClearable: PropTypes.bool,
		// Mapped to props
		customerState: PropTypes.object,
	};

	static defaultProps = {
		onSelectOption: () => {},
		placeholder: <Translate id="find_a_title" />,
		isClearable: true,
		renderTitleOption: title => {
			return <TitleAutocompleteRow title={title} />;
		},
	};

	state = {
		isLoading: false,
	};

	render() {
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className="title-autocomplete">
							<Spin spinning={this.state.isLoading}>
								<LoadableAutocomplete
									optionIdName="sku"
									optionDisplayNameKey="title"
									placeholder={this.props.placeholder}
									canShowSuccess={false}
									onSelectHook={this.onSelectHook}
									hasSearchIcon={true}
									isClearable={this.props.isClearable}
									getURLForOptions={this.getURLForOptions}
									getOptionLabel={
										this.props.renderTitleOption
									}
									inputRequiredFeedback={this.getFeedback(
										translate,
									)}
									handleOptionsResponse={
										this.handleOptionsResponse
									}
								/>
							</Spin>
						</div>
					);
				}}
			</Translate>
		);
	}

	handleOptionsResponse = aggregateDTO => {
		if (aggregateDTO && aggregateDTO.retailTitles) {
			return aggregateDTO.retailTitles;
		}
		return [];
	};

	getURLForOptions = userInput => {
		const { orgId } = this.props.customerState;
		const endpoint = `/org/${orgId}/search/titles?q=${userInput}`;
		return `${urlPrefix}${endpoint}`;
	};

	onSelectHook = autocompleteState => {
		const { selectedId: sku } = autocompleteState;

		this.setState({ isLoading: true });
		const selected = autocompleteState.selectedOption;

		this.props.onSelectOption(sku, this.buildTitle(selected)).then(() => {
			this.setState({ isLoading: false });
		});
	};

	buildTitle(selectedTitleOption) {
		if (!selectedTitleOption) return {};
		const { sku, title, author } = selectedTitleOption;
		return { sku, author, name: title };
	}

	getFeedback = translate => {
		return translate('too_short');
	};
}

export default connectToCustomerID(TitleAutocomplete);
