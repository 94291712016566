import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Translate } from 'react-localize-redux';
import withIsMobile from '../../hoc/withIsMobile';
import './wishlistEntityIndicator.css';

WishlistEntityIndicator.propTypes = {
	style: PropTypes.object,
	isMobile: PropTypes.func,
};

WishlistEntityIndicator.defaultProps = {
	style: {
		right: 30,
		bottom: -16,
	},
};

function WishlistEntityIndicator({ style, isMobile }) {
	return (
		<div className="wishlist-entity-indicator">
			<Tooltip
				title={
					!isMobile() ? (
						<Translate id="added_to_your_wishlist" />
					) : null
				}
				placement="rightTop">
				<div style={style} className="icon-star" />
			</Tooltip>
		</div>
	);
}

export default withIsMobile(WishlistEntityIndicator);
