import React from 'react';
import PropTypes from 'prop-types';
import ScrollerType from './ScrollerType.js';

export const ScrollerConfigContext = React.createContext();

const ScrollerConfigurationModel = PropTypes.shape({
	scrollerType: PropTypes.string,
});

ScrollerConfigProvider.propTypes = {
	children: PropTypes.element,
	scrollerConfig: PropTypes.shape({
		mobile: ScrollerConfigurationModel,
		desktop: ScrollerConfigurationModel,
	}),
};

ScrollerConfigProvider.defaultProps = {
	scrollerConfig: {
		mobile: { scrollerType: ScrollerType.simpleBar },
		desktop: { scrollerType: ScrollerType.simpleBar },
	},
};

export function ScrollerConfigProvider({ scrollerConfig, children }) {
	return (
		<ScrollerConfigContext.Provider value={scrollerConfig}>
			{children}
		</ScrollerConfigContext.Provider>
	);
}
