import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LocalizeProvider, withLocalize } from 'react-localize-redux';
import LocalizationInitializer from 'treeline-react-core-components/LocalizationInitializer';
import keys from './keys';
import LoadingPage from '../UI/LoadingPage';

const WrappedInit = withRouter(withLocalize(LocalizationInitializer));

export default class LocalizationInit extends React.Component {
	static propTypes = {
		children: PropTypes.element,
	};

	render() {
		const url = this.getLocalizationApiUrl();
		return (
			<LocalizeProvider>
				<WrappedInit localizationKeys={keys} localizationApiUrl={url}>
					{({ isLoadingTranslations }) => {
						if (isLoadingTranslations) {
							return <LoadingPage />;
						}
						return this.props.children;
					}}
				</WrappedInit>
			</LocalizeProvider>
		);
	}

	getLocalizationApiUrl = () => {
		const { REACT_APP_LOCALIZATION_API_URL: url } = process.env;
		return url;
	};
}
