import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connectToSections } from '../data/SectionsDataStore';
import TitleLanesDataStore from '../data/TitleLanesDataStore';
import TitleLanes from '../TitleLanes';
import { connectToCustomerID } from '../data/CustomerIDStore';
import RetailTitleLaneType from '../../entity/RetailTitleLaneType';
import { connectToFavorites } from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import SectionLevel from '../../entity/SectionLevel';
import { Link } from 'react-router-dom';
import combineConnectors from '../data/combineConnectors';
import { Translate } from 'react-localize-redux';

/**
 * Title lanes 'highlighting' the Sections in which the customer
 * has 'recently' purchased titles.
 */

class RecentShelvesTitleLanes extends React.Component {
	static propTypes = {
		// Passed in from parent
		maxLanes: PropTypes.number,
		// Mapped to props
		// From SectionsDataStore
		isLoading: PropTypes.bool,
		sections: PropTypes.arrayOf(PropTypes.object),
		departments: PropTypes.arrayOf(PropTypes.object),
		recentSectionIds: PropTypes.arrayOf(PropTypes.string),
		getOneSection: PropTypes.func,
		// From CustomerFavoritesDataStore
		isLoadingFavorites: PropTypes.bool,
		idsOfFavoriteSections: PropTypes.arrayOf(PropTypes.string),
		// From CustomerIDDataStore
		customerState: PropTypes.object,
	};

	static defaultProps = {
		maxLanes: 5,
	};

	render() {
		const { isLoading, isLoadingFavorites } = this.props;
		return (
			<div className="recent-shelves-title-lanes">
				{isLoading || isLoadingFavorites
					? this.renderLoading()
					: this.renderRecentSectionHighlightLanes()}
			</div>
		);
	}

	renderLoading() {
		return (
			<Spin spinning={this.props.isLoading}>
				<div style={{ height: '8rem' }} />
			</Spin>
		);
	}

	renderRecentSectionHighlightLanes() {
		const sectionIds = this.getRecentSectionIdsToDisplay();
		const laneConfigs = this.getRecentSectionLaneConfigs(sectionIds);

		return (
			<React.Fragment>
				{sectionIds[0] && (
					<TitleLanesDataStore
						laneConfigs={laneConfigs}
						getEndpoint={this.getRecentSectionTitlesEndpoint}>
						{titleLanesDataStore => {
							return (
								<TitleLanes
									titleLanesDataStore={titleLanesDataStore}
								/>
							);
						}}
					</TitleLanesDataStore>
				)}
			</React.Fragment>
		);
	}

	getRecentSectionIdsToDisplay() {
		const { recentSectionIds, maxLanes } = this.props;
		const topFavSectionIds = this.getTopFavoriteSectionIds();

		const sectionIdsToShow = [...topFavSectionIds, ...recentSectionIds];

		if (typeof maxLanes !== 'number') {
			return sectionIdsToShow;
		}

		// Else trim off the array up to and not including the idx identified by `this.props.maxLanes`
		return sectionIdsToShow.slice(0, maxLanes);
	}

	getTopFavoriteSectionIds() {
		const { recentSectionIds } = this.props;
		const { idsOfFavoriteSections, getOneSection } = this.props;

		const validSectionIds = idsOfFavoriteSections.filter(sectionId => {
			const isInRecentIds = recentSectionIds.includes(sectionId);
			const hasSection = typeof getOneSection(sectionId) !== 'undefined';
			return !isInRecentIds && hasSection;
		});

		return validSectionIds.sort((a, b) => {
			return (
				getOneSection(b).transactions - getOneSection(a).transactions
			);
		});
	}

	getRecentSectionLaneConfigs = (recentSectionIds = []) => {
		return recentSectionIds.map(sectionId => {
			const section = this.props.getOneSection(sectionId);
			const laneKey = this.getLaneNameKeyFromSectionId(sectionId);

			return {
				key: laneKey,
				icon: '',
				subtitle: <Translate id="browse_new_top_sellers" />,
				title: (
					<Link
						className="acc-font bold clickable"
						to={`/section/section/${sectionId}`}>
						<span>
							{section ? (
								section.displayName
							) : (
								<Translate id="unknown" />
							)}
						</span>
					</Link>
				),
			};
		});
	};

	getRecentSectionTitlesEndpoint = (titleLaneKey, total) => {
		const { sectionHighlights } = RetailTitleLaneType;

		const sectionId = this.getSectionIdFromLaneKey(titleLaneKey);
		const sectionLvl = SectionLevel.section;

		const { customerState: c } = this.props;

		return `/org/${c.orgId}/titles/${sectionHighlights}/${sectionLvl}/${sectionId}/${total}`;
	};

	getSectionIdFromLaneKey = laneKey => {
		return laneKey.replace(/sectionHighlights_/g, '').trim();
	};

	getLaneNameKeyFromSectionId = sectionId => {
		const { sectionHighlights } = RetailTitleLaneType;
		return `${sectionHighlights}_${sectionId}`;
	};
}

const mapSectionsDataStore = sectionsDataStore => ({
	isLoading: sectionsDataStore.isLoading(),
	sections: sectionsDataStore.getSections(),
	departments: sectionsDataStore.getDepartments(),
	recentSectionIds: sectionsDataStore.getRecentSectionIds(),
	getOneSection: sectionId => sectionsDataStore.getSection(sectionId),
});

const mapFavoritesDataStore = customerFavoritesDataStore => {
	const isLoading = customerFavoritesDataStore.isLoading();
	const favSections = customerFavoritesDataStore.getFavoriteGenres();
	return {
		isLoadingFavorites: isLoading,
		idsOfFavoriteSections: favSections.map(entity => entity.entityId),
	};
};

const connectors = [
	connectToCustomerID,
	Component => connectToSections(Component, mapSectionsDataStore),
	Component => connectToFavorites(Component, mapFavoritesDataStore),
];

export default combineConnectors(connectors)(RecentShelvesTitleLanes);
