import PropTypes from 'prop-types';

const Author = PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	displayName: PropTypes.string,
	initials: PropTypes.string,
	skuCount: PropTypes.number,
});

export default Author;
