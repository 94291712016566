import React from 'react';
import Customer360PropTypes from '../../../types';
import { Button, Tooltip } from 'antd';
import RemoveFavoriteController from '../RemoveFavoriteController';
import { Translate } from 'react-localize-redux';

export default class RemoveGenreFavoriteButton extends React.Component {
	static propTypes = {
		entity: Customer360PropTypes.RecommendableEntity,
	};

	render() {
		return (
			<RemoveFavoriteController entity={this.props.entity}>
				{({ isLoading, handleRemove }) => {
					return (
						<div className="remove-genre-favorite-button">
							<Tooltip title={<Translate id="remove" />}>
								<Button
									type="danger"
									size="small"
									icon="close"
									loading={isLoading}
									disabled={isLoading}
									onClick={handleRemove}
								/>
							</Tooltip>
						</div>
					);
				}}
			</RemoveFavoriteController>
		);
	}
}
