import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import { connectToStaffPicks } from '../data/StaffPicksDataStore';
import StaffPicksDetail from '../Drawers/StaffPicksDetail';
import DetailDrawerAnchor from '../../UI/DetailDrawerAnchor';
import { Tooltip } from 'antd';
import { Translate } from 'react-localize-redux';

export class StaffUserAvatar extends React.Component {
	static propTypes = {
		appUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
			.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		avatarUri: PropTypes.string, // can be undefined
		displayName: PropTypes.string.isRequired,
		size: PropTypes.string,
		shouldRenderStaffDetails: PropTypes.bool,
		shelfName: PropTypes.string,
		isInLane: PropTypes.bool,
	};

	static defaultProps = {
		size: UserAvatar.large,
		shouldRenderStaffDetails: true,
		isInLane: false,
	};

	render() {
		const {
			appUserId,
			shouldRenderStaffDetails,
			avatarUri,
			displayName,
			size,
			firstName,
			shelfName,
			isInLane,
		} = this.props;
		return shouldRenderStaffDetails ? (
			<DetailDrawerAnchor
				renderDrawerContent={() => (
					<StaffPicksDetail
						appUserId={appUserId}
						shelfName={shelfName}
					/>
				)}>
				<Tooltip
					placement="topLeft"
					title={
						!isInLane ? (
							<Translate
								id="see_more_reviews_from_x"
								data={{ firstName }}
							/>
						) : null
					}>
					{this.renderUserAvatar(avatarUri, displayName, size)}
				</Tooltip>
			</DetailDrawerAnchor>
		) : (
			this.renderUserAvatar(avatarUri, displayName, size)
		);
	}

	renderUserAvatar = (avatarUri, displayName, size) => {
		return (
			<div>
				<UserAvatar
					displayName={displayName}
					avatarUri={avatarUri}
					avatarSizeClass={size}
					userInitials={this.getInitials()}
				/>
			</div>
		);
	};

	getInitials = () => {
		const { firstName, lastName } = this.props;
		if (!firstName || !lastName) return 'CU';

		const first = firstName.charAt(0).toUpperCase();
		const last = lastName.charAt(0).toUpperCase();

		return `${first}${last}`;
	};
}

const mapStaffPicksToProps = (staffPicksDataStore, { appUserId }) => {
	const staffUser = staffPicksDataStore.getStaffUser(appUserId);
	return {
		firstName: staffUser.firstName,
		lastName: staffUser.lastName,
		displayName: staffUser.fullName,
		avatarUri: staffUser.avatarUri.uri ? staffUser.avatarUri.uri : '',
	};
};

export default connectToStaffPicks(StaffUserAvatar, mapStaffPicksToProps);
