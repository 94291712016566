import React, { useState, useEffect, useContext } from 'react';
import { UserManager, Log } from 'oidc-client';
import { useCustomerID } from '../data/CustomerIDStore';
import api from '../../api';

export const AuthContext = React.createContext();

export const AuthProvider = ({ authOptions, children }) => {
	const { oidcClientOptions } = authOptions;

	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const [user, setUser] = useState(null);
	const [userManager] = useState(() => new UserManager(oidcClientOptions));

	const { orgId } = useCustomerID();
	const { setCustomerId } = useCustomerID();

	useEffect(() => {
		const initAuth = async () => {
			console.debug('initialize auth provider - start');

			// occurs when signinSilent and signinSilentCallback are called
			userManager.events.addUserLoaded(user => {
				console.debug('user loaded');
				setUser(user);
				setIsAuthenticated(true);
			});

			const { oidcClientLoggerOptions } = authOptions;

			if (oidcClientLoggerOptions) {
				Log.logger = oidcClientLoggerOptions.logger;
				Log.level = oidcClientLoggerOptions.level;
			}

			console.debug('attempting to signin');

			try {
				const user = await userManager.signinSilent();
				console.debug('signin successful - ' + user.profile.name);

				api.setFetchOptions({
					mode: 'cors',
					headers: new Headers({
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + user.access_token,
					}),
				});

				const customer = await api.post(`/signin?orgId=${orgId}`);
				setCustomerId(customer.customerId);
			} catch (error) {
				console.debug('signin failed - ' + error.message);
			} finally {
				// Regardless of the signin outcome, the AuthProvider is now initialized
				setIsAuthenticating(false);
			}

			console.debug('initialize auth provider - end');
		};

		initAuth();
		// eslint-disable-next-line
	}, [authOptions, userManager]);

	const loginWithRedirect = signinParams => {
		console.debug(
			'signing in via redirect - return uri: ' +
				signinParams.state.returnUri,
		);

		userManager.clearStaleState();
		userManager.signinRedirect(signinParams);
	};

	const logoutWithRedirect = () => {
		const idToken = getIdToken();
		const signoutParams = idToken ? { logoutId: idToken } : null;

		console.debug('signing out via redirect - logoutId: ' + idToken);

		userManager.signoutRedirect(signoutParams);
		userManager.clearStaleState();
	};

	const getIdToken = () => user && user.id_token;

	const getAccessToken = () => user && user.access_token;

	const getProfile = () => user && user.profile;

	return (
		<AuthContext.Provider
			value={{
				isAuthenticating,
				isAuthenticated,
				user,
				getIdToken,
				getAccessToken,
				getProfile,
				loginWithRedirect,
				logoutWithRedirect,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuth = () => useContext(AuthContext);
