const RetailTitleLaneType = {
	frontlist: 'frontlist',
	recentPub: 'recentPub',
	staffReviews: 'staffReviews',
	sectionHighlights: 'sectionHighlights',
	deeperBacklist: 'deeperBacklist',
	wishList: 'wishList',
	relatedComps: 'relatedComps',
	relatedSection: 'relatedSection',
	relatedSeries: 'relatedSeries',
};

export default RetailTitleLaneType;
