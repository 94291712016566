import React from 'react';
import PropTypes from 'prop-types';
import BooksellerLogoStore from './BooksellerLogoStore';
import BooksellerContentDataStore from './BooksellerContentDataStore';
import CustomerSummaryDataStore from './CustomerSummaryDataStore';
import SectionsDataStore from './SectionsDataStore';
import OrganizationDataStore from './OrganizationDataStore';
import TransactionsDataStore from './TransactionsDataStore';
import BagDataStore from '../Bag/data/BagDataStore';
import LoadingPage from '../UI/LoadingPage';
import ContextAggregator from '../context/ContextAggregator';
import GlobalDataStoresLoader from './GlobalDataStoresLoader';
import BooksellerPreferencesDataStore from './BooksellerPreferencesDataStore';
import RatingsAndReviewsDataStore from './RatingsAndReviewsDataStore';
import { useAuth } from '../Auth/AuthProvider';

const dataStores = [
	BagDataStore,
	SectionsDataStore,
	BooksellerLogoStore,
	TransactionsDataStore,
	OrganizationDataStore,
	CustomerSummaryDataStore,
	BooksellerContentDataStore,
	RatingsAndReviewsDataStore,
];

GlobalDataStores.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

export default function GlobalDataStores({ children }) {
	const { isAuthenticating } = useAuth();

	if (isAuthenticating) {
		return <LoadingPage />;
	}

	return (
		<BooksellerPreferencesDataStore>
			{({ isLoading }) => {
				if (isLoading()) {
					return <LoadingPage />;
				}

				return (
					<ContextAggregator componentConfigs={dataStores}>
						<GlobalDataStoresLoader>
							{children}
						</GlobalDataStoresLoader>
					</ContextAggregator>
				);
			}}
		</BooksellerPreferencesDataStore>
	);
}
