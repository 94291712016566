import { ApiFetcher } from 'api-fetcher-treeline/dist';

const setupApiFetcher = (urlPrefix, config) => {
	const api = new ApiFetcher(urlPrefix, config);

	return api;
};

export const urlPrefix = `${process.env.REACT_APP_API_URL}/api/customer360`;
export const eventsApiUrlPrefix = process.env.REACT_APP_EVENTS_API_URL;

const api = setupApiFetcher(urlPrefix);

export default api;

export const isBadRequest = err => {
	return err && typeof err.message && err.message.indexOf('400') > -1;
};

export const eventsApi = setupApiFetcher(`${eventsApiUrlPrefix}/api/events`);

export const e360Api = config =>
	setupApiFetcher(`${process.env.REACT_APP_API_URL}/api/e360Mobile`, config);
