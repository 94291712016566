import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

TitleListPrice.propTypes = {
	title: PropTypes.object,
	orgCountry: PropTypes.string,
};

export default function TitleListPrice({ title, orgCountry }) {
	let price = <Translate id="unavailable" />;

	const hasTitlePosData = () => {
		const { pos } = title;
		return Array.isArray(pos) && pos.length && typeof pos[0] === 'object';
	};

	const getCultureIndicator = () => {
		return orgCountry && orgCountry === 'UK' ? 'en-GB' : 'en-US';
	};

	const getCurrencyIndicator = () => {
		return orgCountry && orgCountry === 'UK' ? 'GBP' : 'USD';
	};

	if (hasTitlePosData() && title.pos[0].listPrice) {
		price = title.pos[0].listPrice;
	} else if (title.listPrice) {
		price = title.listPrice;
	} else if (title.retailPrice) {
		price = title.retailPrice;
	}

	if (typeof price === 'number') {
		price = `${price.toLocaleString(getCultureIndicator(), {
			style: 'currency',
			currency: getCurrencyIndicator(),
		})}`;
	}

	return <span className="title-list-price">{price}</span>;
}
