const authOptions = {
	// https://github.com/IdentityModel/oidc-client-js/wiki
	oidcClientOptions: {
		authority: process.env.REACT_APP_OIDC_CLIENT_AUTHORITY_URI,
		client_id: process.env.REACT_APP_OIDC_CLIENT_CLIENT_ID,
		redirect_uri:
			window.location.origin +
			process.env.REACT_APP_OIDC_CLIENT_AUTH_CALLBACK_PATH,
		post_logout_redirect_uri: window.location.origin,
		response_type: 'code',
		scope: process.env.REACT_APP_OIDC_CLIENT_SCOPE,
		filterProtocolClaims: true,
		loadUserInfo: true,
		automaticSilentRenew: true,
		silent_redirect_uri:
			window.location.origin +
			process.env.REACT_APP_OIDC_CLIENT_SILENT_REFRESH_CALLBACK_PATH,
	},
	oidcClientLoggerOptions: {
		logger: console,
		level: process.env.REACT_APP_OIDC_CLIENT_LOGGER_LEVEL,
	},
};

export default authOptions;
