import React from 'react';
import PropTypes from 'prop-types';
import MyPurchasesDrawerAnchor from './Purchases/MyPurchasesDrawerAnchor';
import { connectToTransactions } from '../data/TransactionsDataStore';
import CustomerHeader from './UI/CustomerHeader';
import RewardsProgress from '../Rewards/RewardsProgress';
import { withIsDemoBookstore } from '../data/CustomerIDStore.js';
import combineConnectors from '../data/combineConnectors';
import MyReviewsDrawerAnchor from './Reviews/MyReviewsDrawerAnchor';
import MyOrderPerferencesDrawerAnchor from './OrderPerferences/MyOrderPerferencesDrawerAnchor';
import { Collapse, Icon, Tooltip } from 'antd';
import AuthorFavoritesLane from '../CustomerFavorites/AuthorFavorites/AuthorFavoritesLane';
import TitleFavoritesLane from '../CustomerFavorites/TitleFavorites/TitleFavoritesLane';
import SeriesFavoritesLane from '../CustomerFavorites/SeriesFavorites/SeriesFavoritesLane';
import ShelfFavoritesLane from '../CustomerFavorites/ShelfFavorites/ShelfFavoritesLane';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import CustomerFavoritesService from '../CustomerFavorites/CustomerFavoritesService';
import withIsMobile from '../hoc/withIsMobile';
import AuthAnchor from './Auth/AuthAnchor';
import AccountAnchor from './UI/AccountAnchor';
import { connectToRatingsAndReviews } from '../data/RatingsAndReviewsDataStore';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import { Translate } from 'react-localize-redux';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import './accountPage.less';

const { Panel } = Collapse;

class AccountPage extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		purchases: PropTypes.arrayOf(PropTypes.object),
		reviews: PropTypes.object,
		isDemoBookstore: PropTypes.bool,
		preferencesDataStore: PropTypes.object,
	};

	render() {
		const {
			purchases,
			isDemoBookstore,
			reviews,
			preferencesDataStore,
		} = this.props;
		const isMobile = this.props.isMobile();
		const gradient = preferencesDataStore.getGradient();

		return (
			<div className="account-page-root">
				<div
					className="account-page"
					style={{
						backgroundImage: isMobile ? 'none' : gradient,
					}}>
					<div
						className="page-container"
						style={{
							display: isMobile ? 'block' : 'flex',
							padding: isMobile ? '0 0' : '0 15px',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<CustomerHeader />
						<div
							style={{
								order: isMobile ? '2' : '-1',
							}}>
							{isDemoBookstore ? (
								<RewardsProgress />
							) : !isMobile ? (
								<div className="account-order-anchors-container">
									<Tooltip
										title={<Translate id="coming_soon" />}>
										<AccountAnchor
											label={<Translate id="my_orders" />}
											count={0}
										/>
										<AccountAnchor
											label={
												<Translate id="my_order_preferences" />
											}
											count={0}
										/>
									</Tooltip>
								</div>
							) : null}
						</div>
						<div style={{ marginTop: '15px', order: '3' }}>
							{!isMobile && <AuthAnchor />}
							<MyReviewsDrawerAnchor>
								<AccountAnchor
									label={<Translate id="my_reviews" />}
									count={
										reviews.productsBySku &&
										Object.keys(reviews.productsBySku)
											.length
									}
								/>
							</MyReviewsDrawerAnchor>
							<MyPurchasesDrawerAnchor>
								<AccountAnchor
									label={<Translate id="my_purchases" />}
									count={purchases.length}
								/>
							</MyPurchasesDrawerAnchor>
							{isDemoBookstore && (
								<MyOrderPerferencesDrawerAnchor>
									<AccountAnchor
										label={
											<Translate id="my_order_preferences" />
										}
										count={0}
									/>
								</MyOrderPerferencesDrawerAnchor>
							)}
							{isMobile && (
								<Collapse
									defaultActiveKey={['1']}
									expandIconPosition="right"
									expandIcon={({ isActive }) => (
										<Icon
											type="right"
											rotate={isActive ? 90 : 0}
											style={{
												fontSize: '15px',
												right: '25px',
											}}
										/>
									)}>
									<Panel
										header={
											<div style={{ marginLeft: '-5px' }}>
												<Translate id="my_favorites" />
											</div>
										}
										key="1"
										style={{ marginBottom: '20px' }}>
										<CustomerFavoritesService />
									</Panel>
								</Collapse>
							)}
						</div>
					</div>
				</div>
				{!isMobile && (
					<WishlistSkusDataStore>
						<CustomerFavoritesDataStore>
							<div className="account-page-customer-favorites">
								<div style={{ backgroundImage: gradient }}>
									<div className="page-container">
										<TitleFavoritesLane />
										<span style={{ marginLeft: '50px' }} />
										<AuthorFavoritesLane />
									</div>
								</div>
								<div style={{ backgroundImage: gradient }}>
									<div className="page-container">
										<ShelfFavoritesLane />
										<span style={{ marginLeft: '50px' }} />
										<SeriesFavoritesLane />
									</div>
								</div>
							</div>
						</CustomerFavoritesDataStore>
					</WishlistSkusDataStore>
				)}
			</div>
		);
	}
}

const withUserTransactions = Component => {
	const mapToProps = transactionsDataStore => {
		const purchases =
			transactionsDataStore.state.transactionsData.transactions;
		return {
			purchases,
		};
	};
	return connectToTransactions(Component, mapToProps);
};

const withUserReviews = Component => {
	const mapToProps = ratingsAndReviewsDataStore => {
		const reviews = ratingsAndReviewsDataStore.state.reviews;
		return {
			reviews,
		};
	};
	return connectToRatingsAndReviews(Component, mapToProps);
};

const connectors = [
	withIsDemoBookstore,
	withUserTransactions,
	withUserReviews,
	connectToSellerPreferences,
];

export default combineConnectors(connectors)(withIsMobile(AccountPage));
