import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../hoc/withIsMobile';
import combineConnectors from '../data/combineConnectors';
import TitleJacketImage from '../Title/UI/TitleJacketImage';
import { connectToBag } from '../Bag/data/BagDataStore';
import { connectToFavorites } from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import { connectToWishlistSkus } from '../Wishlist/data/WishlistSkusDataStore';
import { connectToTransactions } from '../data/TransactionsDataStore';
import WishlistEntityIndicator from '../Wishlist/UI/WishlistEntityIndicator';
import PurchasedEntityIndicator from '../Rewards/UI/PurchasedEntityIndicator';
import FavoriteEntityIndicator from '../CustomerFavorites/UI/FavoriteEntityIndicator';
import RecommendableEntity from '../../entity/RecommendableEntity';
import BagEntityIndicator from '../Bag/UI/BagEntityIndicator';
import TitleDetailDrawer from '../Title/TitleDetailDrawer';
import { Translate } from 'react-localize-redux';

TitleJacketImageWithIconBadges.propTypes = {
	title: PropTypes.object,
	size: PropTypes.string,
	isInTitleLane: PropTypes.bool,
	isMobile: PropTypes.func,
	titleIsInBag: PropTypes.bool,
	titleIsInWishlist: PropTypes.bool,
	titleIsFavorite: PropTypes.bool,
	titleWasPurchased: PropTypes.bool,
};

TitleJacketImageWithIconBadges.defaultProps = {
	isInTitleLane: false,
};

function TitleJacketImageWithIconBadges({
	title,
	size,
	isInTitleLane,
	isMobile,
	titleIsInBag,
	titleIsFavorite,
	titleIsInWishlist,
	titleWasPurchased,
}) {
	const renderTitleJacketImage = () => {
		if (isInTitleLane) {
			const jacketSize = isMobile() ? 'mobile' : 'medium';
			return (
				<div
					className={`title-jacket-frame-${jacketSize} title-jacket-wrapper`}>
					<TitleJacketImage
						title={title}
						size={TitleJacketImage[jacketSize]}>
						{renderIconBadges()}
					</TitleJacketImage>
				</div>
			);
		}

		return (
			<div style={{ paddingBottom: 10, width: '90%' }}>
				<TitleJacketImage
					title={title}
					size={size ? size : TitleJacketImage.fixedwidth}>
					{renderIconBadges()}
				</TitleJacketImage>
			</div>
		);
	};

	const renderIconBadges = () => {
		return (
			<React.Fragment>
				{titleIsInBag && (
					<BagEntityIndicator
						right={
							titleWasPurchased
								? isInTitleLane
									? 58
									: 23
								: isInTitleLane
								? 33
								: -8
						}
					/>
				)}
				{titleIsFavorite && renderFavoritesBadge()}
				{titleIsInWishlist && (
					<WishlistEntityIndicator
						style={
							isInTitleLane
								? { right: 30, bottom: -12 }
								: { right: -6, bottom: -6 }
						}
					/>
				)}
				{titleWasPurchased && (
					<PurchasedEntityIndicator right={isInTitleLane ? 30 : -6} />
				)}
			</React.Fragment>
		);
	};

	const renderFavoritesBadge = () => {
		const type = RecommendableEntity.title;
		const entity = new RecommendableEntity(title.sku, type, title.name);

		return (
			<FavoriteEntityIndicator
				isInTitleLane={isInTitleLane}
				entity={entity}
				tooltip={<Translate id="pinned_as_favorite" />}
				style={{
					bottom: isInTitleLane ? -12 : -6,
					right: titleIsInWishlist
						? isInTitleLane
							? 58
							: 23
						: isInTitleLane
						? 33
						: -8,
				}}
			/>
		);
	};

	return (
		<TitleDetailDrawer sku={title.sku}>
			{renderTitleJacketImage()}
		</TitleDetailDrawer>
	);
}

const mapWishlistToProps = (wishlistSkusDataStore, { title }) => {
	const hasDataStore = typeof wishlistSkusDataStore !== 'undefined';
	return {
		titleIsInWishlist:
			hasDataStore && wishlistSkusDataStore.includes(title.sku),
	};
};

const mapFavoritesToProps = (customerFavoritesDataStore, { title }) => {
	const hasFavoritesDataStore =
		typeof customerFavoritesDataStore !== 'undefined';
	return {
		titleIsFavorite:
			hasFavoritesDataStore &&
			customerFavoritesDataStore.includes(
				title.sku,
				RecommendableEntity.title,
			),
	};
};

const mapTransactionsToProps = (transactionsDataStore, { title }) => ({
	titleWasPurchased: transactionsDataStore.includes(title.sku),
});

const withIsInBag = Component => {
	return connectToBag(Component, (bagDataStore, { title }) => ({
		titleIsInBag: bagDataStore.includes(title.sku),
	}));
};

const connectors = [
	withIsInBag,
	Component => connectToFavorites(Component, mapFavoritesToProps),
	Component => connectToWishlistSkus(Component, mapWishlistToProps),
	Component => connectToTransactions(Component, mapTransactionsToProps),
];

export default combineConnectors(connectors)(
	withIsMobile(TitleJacketImageWithIconBadges),
);
