import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import LoadableAutocomplete from 'treeline-react-core-components/LoadableAutocomplete';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import { urlPrefix } from '../../../api';
import SectionLevel from '../../../entity/SectionLevel';
import { Translate } from 'react-localize-redux';

class SectionAutocompleteRow extends React.Component {
	static propTypes = {
		section: PropTypes.object,
	};

	render() {
		const { section } = this.props;
		return (
			<div className="clickable">
				<div className="acc-font bold dotDot">
					{this.getSectionLevel(section.key)}
				</div>
				<div className="dotDot">{section.name}</div>
			</div>
		);
	}

	getSectionLevel(key) {
		const sectionLevel = key.split('.')[0];
		if (sectionLevel === 'section') {
			return 'Section';
		} else {
			return 'Department';
		}
	}
}

class SectionAutocomplete extends React.Component {
	static propTypes = {
		// Passed in from parent
		placeholder: PropTypes.string,
		onSelectOption: PropTypes.func,
		renderOption: PropTypes.func,
		// Mapped to props
		customerState: PropTypes.object,
	};

	static defaultProps = {
		placeholder: <Translate id="search_for_genres" />,
		onSelectOption: () => {},
		renderOption: section => <SectionAutocompleteRow section={section} />,
	};

	state = {
		isLoading: false,
	};

	render() {
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className="section-autocomplete">
							<Spin spinning={this.state.isLoading}>
								<LoadableAutocomplete
									optionIdName="key"
									optionDisplayNameKey="name"
									placeholder={this.props.placeholder}
									canShowSuccess={false}
									onSelectHook={this.onSelectHook}
									hasSearchIcon={true}
									getURLForOptions={this.getURLForOptions}
									getOptionLabel={this.props.renderOption}
									inputRequiredFeedback={this.getFeedback(
										translate,
									)}
									minCharsRequired={2}
								/>
							</Spin>
						</div>
					);
				}}
			</Translate>
		);
	}

	getURLForOptions = userInput => {
		const { orgId } = this.props.customerState;
		const endpoint = `/org/${orgId}/search/sections?q=${userInput}`;
		return `${urlPrefix}${endpoint}`;
	};

	onSelectHook = autocompleteState => {
		const { selectedId: sectionKey } = autocompleteState;

		this.setState({ isLoading: true });
		const section = this.buildSection(autocompleteState.selectedOption);

		this.props.onSelectOption(section, sectionKey).then(() => {
			this.setState({ isLoading: false });
		});
	};

	buildSection(selectedSection = {}) {
		const { key, name } = selectedSection;

		const sectionId = this.getEntityIdFromSectionKey(key);
		const sectionLevel = this.getSectionLevelFromSectionKey(key);

		return { sectionId, sectionLevel, name };
	}

	getEntityIdFromSectionKey(sectionKey) {
		if (typeof sectionKey === 'number' || !sectionKey) {
			return sectionKey;
		}
		if (sectionKey.indexOf('section.') > -1) {
			return parseInt(sectionKey.replace('section.', '').trim());
		}
		if (sectionKey.indexOf('department.') > -1) {
			return parseInt(sectionKey.replace('department.', '').trim());
		}
		return parseInt(sectionKey);
	}

	getSectionLevelFromSectionKey(sectionKey) {
		if (typeof sectionKey === 'number' || !sectionKey) {
			return SectionLevel.section;
		}
		if (sectionKey.indexOf('section.') > -1) {
			return SectionLevel.section;
		}
		if (sectionKey.indexOf('department.') > -1) {
			return SectionLevel.department;
		}
		return SectionLevel.section;
	}

	getFeedback = translate => {
		return translate('too_short');
	};
}

export default connectToCustomerID(SectionAutocomplete);
