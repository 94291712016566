import React from 'react';
import PropTypes from 'prop-types';
import { Button, notification, Tooltip } from 'antd';
import WishlistController from './WishlistController';
import { connectToPreviewMode } from '../data/CustomerIDStore';
import { connectToWishlist } from './data/WishlistLaneDataStore';
import { connectToWishlistSkus } from './data/WishlistSkusDataStore';
import combineConnectors from '../data/combineConnectors';
import { Translate } from 'react-localize-redux';
import './wishlistButton.css';

class WishlistButton extends React.Component {
	static propTypes = {
		previewMode: PropTypes.object,
		wishlistSkusDataStore: PropTypes.object,
		wishlistLaneDataStore: PropTypes.object,
		// passed in from parent
		sku: PropTypes.string,
		titleName: PropTypes.string,
	};

	state = {
		isLoading: false,
		originalState: null,
		isOnWishlist: null,
	};

	render() {
		return (
			<Translate>
				{({ translate }) => {
					return (
						<WishlistController sku={this.props.sku}>
							{wishlist => {
								if (this.state.isOnWishlist === null) {
									return (
										<Button loading>
											<Translate id="loading" />
											...
										</Button>
									);
								}
								return this.renderButton(wishlist, translate);
							}}
						</WishlistController>
					);
				}}
			</Translate>
		);
	}

	componentDidMount() {
		const { previewMode, sku, wishlistSkusDataStore } = this.props;

		if (previewMode.isActive()) {
			return this.setState({ isOnWishlist: false, originalState: false });
		}

		const isAlreadyOnWishlist =
			wishlistSkusDataStore && wishlistSkusDataStore.includes(sku);

		this.setState({
			isOnWishlist: isAlreadyOnWishlist,
			originalState: isAlreadyOnWishlist,
		});
	}

	componentWillUnmount() {
		if (this.wasWishlistUpdated() && this.props.wishlistLaneDataStore) {
			this.props.wishlistLaneDataStore.updateLane();
		}
	}

	wasWishlistUpdated = () => {
		const { isOnWishlist: currentState, originalState } = this.state;
		return currentState !== originalState;
	};

	renderButton(wishlist, translate) {
		if (this.state.isOnWishlist) {
			return this.renderRemoveButton(wishlist, translate);
		}
		return this.renderAddButton(wishlist, translate);
	}

	renderAddButton = (wishlist, translate) => {
		return this.renderWishlistButton({
			icon: 'add-circle',
			onClick: () => this.add(wishlist, translate),
		});
	};

	renderRemoveButton = (wishlist, translate) => {
		return this.renderWishlistButton({
			icon: 'approved on-wishlist',
			onClick: () => this.remove(wishlist, translate),
		});
	};

	renderWishlistButton = ({ onClick, icon }) => {
		const { isLoading, isOnWishlist } = this.state;
		return (
			<div className="wishlist-button-root">
				<Tooltip
					title={
						isOnWishlist ? (
							<Translate id="on_your_wishlist" />
						) : (
							<Translate id="add_to_your_wishlist" />
						)
					}
					placement="top">
					<Button
						className="wishlist-button"
						onClick={onClick}
						loading={this.state.isLoading}
						disabled={isLoading || isOnWishlist === null}>
						<div className={`wishlist-status-icon icon-${icon}`} />
						<Translate id="wishlist" />
					</Button>
				</Tooltip>
			</div>
		);
	};

	remove = (wishlist, translate) => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		this.setState({ isLoading: true });
		return wishlist.remove().then(this.onRemoveSuccess(translate));
	};

	onRemoveSuccess = translate => {
		const {
			wishlistSkusDataStore,
			wishlistLaneDataStore,
			sku,
			titleName,
		} = this.props;
		wishlistSkusDataStore.remove(sku);
		wishlistLaneDataStore && wishlistLaneDataStore.updateLane();
		this.confirmWishlistWasUpdated();
		this.notify(
			translate('removed_from_your_wishlist', {
				name: titleName,
			}),
		);
	};

	add = (wishlist, translate) => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		this.setState({ isLoading: true });
		return wishlist.add().then(this.onAddSuccess(translate));
	};

	onAddSuccess = translate => {
		const {
			wishlistSkusDataStore,
			wishlistLaneDataStore,
			sku,
			titleName,
		} = this.props;
		wishlistSkusDataStore.add(sku);
		wishlistLaneDataStore && wishlistLaneDataStore.updateLane();
		this.confirmWishlistWasUpdated();
		this.notify(translate('added_to_your_wishlist', { name: titleName }));
	};

	confirmWishlistWasUpdated = () => {
		const isOnList = this.state.isOnWishlist;

		this.setState({
			isLoading: false,
			isOnWishlist: !isOnList,
		});
	};

	notify = message => {
		notification.success({ message, duration: 2 });
	};
}

const connectors = [
	connectToWishlist,
	connectToWishlistSkus,
	connectToPreviewMode,
];

export default combineConnectors(connectors)(WishlistButton);
