import React from 'react';
import PropTypes from 'prop-types';

const DrawerContext = React.createContext();

export const useDrawer = () => React.useContext(DrawerContext);

DrawerContextProvider.propTypes = {
	children: PropTypes.element,
	drawer: PropTypes.shape({
		open: PropTypes.func,
		close: PropTypes.func,
	}),
};

export default function DrawerContextProvider({ drawer, children }) {
	return (
		<DrawerContext.Provider value={drawer}>
			{children}
		</DrawerContext.Provider>
	);
}
