import { useLocation } from 'react-router-dom';

export default function useActiveRoute(routesToCheck = [], alsoMatches = []) {
	const { pathname } = useLocation();

	const isAnyMatchActive = () => {
		for (let i = 0; i < alsoMatches.length; i++) {
			if (alsoMatches[i].test(pathname)) {
				return true;
			}
		}
		return false;
	};

	const isAnyRouteActive = () => {
		const predicate = r => pathname.indexOf(r) > -1;
		const activeRoutes = routesToCheck.filter(predicate);
		return activeRoutes.length > 0;
	};

	return {
		isAnyRouteActive,
		isAnyMatchActive,
		isActive: route => pathname === route,
	};
}
