import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Translate } from 'react-localize-redux';
import TitleDetailDrawer from '../TitleDetailDrawer';
import combineConnectors from '../../data/combineConnectors';
import { connectToTransactions } from '../../data/TransactionsDataStore';
import { connectToRatingsAndReviews } from '../../data/RatingsAndReviewsDataStore';
import withIsMobile from '../../hoc/withIsMobile';
import BuyTitleButton from '../Buy/BuyTitleButton';
import { withIsUnknownCustomer } from '../../data/CustomerIDStore';
import './buyOrReviewButton.css';

BuyOrReviewButton.propTypes = {
	title: PropTypes.object,
	isTitleDetail: PropTypes.bool,
	purchases: PropTypes.array,
	reviews: PropTypes.object,
	isMobile: PropTypes.func,
	isUnknownCustomer: PropTypes.bool,
};

function BuyOrReviewButton({
	title,
	isTitleDetail,
	purchases,
	reviews,
	isMobile,
	isUnknownCustomer,
}) {
	const didUserPurchaseSku =
		typeof purchases.find(p => p.sku === title.sku) !== 'undefined';
	const didUserReviewSku =
		reviews.productsBySku && reviews.productsBySku[title.sku];
	const buttonWidth = isMobile() ? 280 : 100;

	return (
		<div
			className="buy-or-review-button"
			style={
				!isMobile()
					? { marginRight: '0.5rem' }
					: isUnknownCustomer
					? { marginBottom: 10 }
					: null
			}>
			{!didUserPurchaseSku || isTitleDetail ? (
				<BuyTitleButton
					title={title}
					buyFromThirdPartyButtonWidth={buttonWidth}
					label={
						didUserPurchaseSku && isTitleDetail ? (
							<Translate id="buy_again" />
						) : (
							undefined
						)
					}
				/>
			) : (
				<TitleDetailDrawer sku={title.sku}>
					<Button
						className="review-button"
						style={{ width: buttonWidth }}>
						{didUserReviewSku ? (
							<Translate id="edit_review" />
						) : (
							<Translate id="review" />
						)}
					</Button>
				</TitleDetailDrawer>
			)}
		</div>
	);
}

const withUserPurchases = Component => {
	const mapToProps = transactionsDataStore => {
		const purchases =
			transactionsDataStore.state.transactionsData.transactions;
		return {
			purchases,
		};
	};
	return connectToTransactions(Component, mapToProps);
};

const withUserTitleReviews = Component => {
	const mapToProps = ratingsAndReviewsDataStore => {
		const reviews = ratingsAndReviewsDataStore.state.reviews;
		return {
			reviews,
		};
	};
	return connectToRatingsAndReviews(Component, mapToProps);
};

const connectors = [
	withIsMobile,
	withUserTitleReviews,
	withUserPurchases,
	withIsUnknownCustomer,
];

export default combineConnectors(connectors)(BuyOrReviewButton);
