import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';

TitleSkeleton.propTypes = {
	isMobile: PropTypes.func,
	isTitleDetail: PropTypes.bool,
};

function TitleSkeleton({ isMobile, isTitleDetail }) {
	const skeletonSize = isMobile()
		? `mobile${isTitleDetail ? '-title-detail' : ''}`
		: 'medium';

	return (
		<div className={`child title-jacket-frame-${skeletonSize}`}>
			<div className="flex-container title-jacket-image wrapper">
				<div className="title-tag"></div>
				<div
					className={`animate jcktShadow skeleton-box-jacket skeleton-${skeletonSize}-image`}
				/>
			</div>
		</div>
	);
}

export default withIsMobile(TitleSkeleton);
