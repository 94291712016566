import React from 'react';
import PropTypes from 'prop-types';

ArrowUpIcon.propTypes = {
	className: PropTypes.string,
};

ArrowUpIcon.defaultProps = {
	className: '',
};

export default function ArrowUpIcon({ className }) {
	return <div className={`icon-arrow-up-alt1 ${className}`} />;
}
