import React from 'react';
import PropTypes from 'prop-types';
import LocalizationInit from './Localization/LocalizationInit';
import GlobalDataStores from './data/GlobalDataStores';
import WindowSizeProvider from './context/WindowSize';
import ErrorBoundary from './UI/Error/ErrorBoundary';
import CustomerIDStore from './data/CustomerIDStore';
import { AuthProvider } from './Auth/AuthProvider';
import authOptions from '../auth/authOptions';
import LoadingPage from './UI/LoadingPage';

export default class AppFrame extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]),
	};

	render() {
		return (
			<div className="app-frame">
				<ErrorBoundary>
					<WindowSizeProvider>
						<LocalizationInit>
							<CustomerIDStore>
								{({ isLoading }) => {
									if (isLoading) {
										return <LoadingPage />;
									}
									return (
										<AuthProvider authOptions={authOptions}>
											<GlobalDataStores>
												{this.props.children}
											</GlobalDataStores>
										</AuthProvider>
									);
								}}
							</CustomerIDStore>
						</LocalizationInit>
					</WindowSizeProvider>
				</ErrorBoundary>
			</div>
		);
	}
}
