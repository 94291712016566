export const MMDDYYYYFormat = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
};

export const MMDDYYFormat = {
	year: '2-digit',
	month: '2-digit',
	day: '2-digit',
};

export const Day = {
	day: 'numeric',
};

export const Month = {
	month: 'long',
};

export const FullDateFormatNoDayOfWeek = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

export const FullDateFormat = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

export const FullDateFormatWithTime = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
};
