import PropTypes from 'prop-types';
import Review from './Review';

const Title = PropTypes.shape({
	sku: PropTypes.string,
	supplier: PropTypes.string,
	author: PropTypes.string,
	ean: PropTypes.string,
	familyItem: PropTypes.string,
	formatDescription: PropTypes.string,
	name: PropTypes.string,
	pubDate: PropTypes.string,
	imageUri: PropTypes.string,
	pos: PropTypes.arrayOf(PropTypes.object),
	reviews: PropTypes.arrayOf(Review),
	offers: PropTypes.arrayOf(PropTypes.object),
});

export default Title;
