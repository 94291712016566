import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import useRemoveFromBag from '../data/useRemoveFromBag';
import { Button, Popconfirm, Modal } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';

RemoveFromBagAnchor.propTypes = {
	isMobile: PropTypes.func,
	title: Customer360PropTypes.Title,
};

function RemoveFromBagAnchor({ title, isMobile }) {
	const [isLoading, setIsLoading] = React.useState(false);
	const { removeFromBag } = useRemoveFromBag(title, setIsLoading);

	const [isVisible, setIsVisible] = React.useState(false);

	const renderAsPopConfirm = () => {
		return (
			<Popconfirm
				okText={<Translate id="yes" />}
				cancelText={<Translate id="no" />}
				title={<Translate id="remove_this_item_from_your_bag" />}
				onConfirm={removeFromBag}>
				<Button
					size="small"
					type="danger"
					loading={isLoading}
					disabled={isLoading}>
					<Translate id="remove" />
				</Button>
			</Popconfirm>
		);
	};

	const onModalOk = async () => {
		setIsVisible(false);
		await removeFromBag();
	};

	const renderWithConfirmModal = () => {
		return (
			<>
				<Button
					icon="close"
					size="small"
					type="danger"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => setIsVisible(true)}
				/>
				<Modal
					closable={false}
					visible={isVisible}
					title={<Translate id="remove_this_item_from_your_bag" />}
					okText={<Translate id="yes" />}
					cancelText={<Translate id="no" />}
					onCancel={() => setIsVisible(false)}
					onOk={onModalOk}>
					<b>
						<Translate id="click_yes_to_remove" />{' '}
						<em>{title.name}</em>
					</b>
				</Modal>
			</>
		);
	};

	return (
		<div className="remove-from-bag-anchor">
			{isMobile() ? renderWithConfirmModal() : renderAsPopConfirm()}
		</div>
	);
}

export default withIsMobile(RemoveFromBagAnchor);
