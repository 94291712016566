import React from 'react';
import { useHistory } from 'react-router-dom';
import BagOrderTitleSummaries from '../Bag/UI/BagOrderTitleSummaries';
import { useBagDataStore } from '../Bag/data/BagDataStore';
import useBagDataTotalAmount from '../Bag/data/useBagDataTotalAmount';
import { useOrganizationDataStore } from '../data/OrganizationDataStore';
import { Modal, Result, Form, Radio } from 'antd';
import OrderLaneContainer from './UI/OrderLaneContainer';
import './orderPage.css';
import LocalizedPrice from '../Localization/LocalizedPrice';
import { DeliveryMode } from '../../orders/DeliveryMode';
import OrderTotalBreakdown from './OrderTotalBreakdown';
import PayPalOrderButton from './PayPalOrderButton';
import useTaxes from '../hooks/useTaxes';
import withIsMobile from '../hoc/withIsMobile';
import { PropTypes } from 'prop-types';
import { Translate } from 'react-localize-redux';

OrderPage.propTypes = {
	isMobile: PropTypes.func,
};

function OrderPage({ isMobile }) {
	const bagDataStore = useBagDataStore();
	const bagDataTotalAmount = useBagDataTotalAmount();
	const { getFixedTotal } = bagDataTotalAmount;

	const {
		state: orgDataStoreState,
		getOrgCountry,
		getOrgShippingMethods,
	} = useOrganizationDataStore();
	const { zip, city, state: stateProvince } = orgDataStoreState.organization;

	const { getLocationTaxRate } = useTaxes();
	const [orgTaxRate, setOrgTaxRate] = React.useState(null);
	const { salesTax: orgSalesTax, useTax: orgUseTax } = orgTaxRate || {
		salesTax: null,
		useTax: null,
	};

	const shippingMethods = getOrgShippingMethods();
	const hasShippingMethods = shippingMethods && shippingMethods.length > 0;

	const history = useHistory();

	const [isVisible, setIsVisible] = React.useState(false);
	const [error, setError] = React.useState({});

	const firstShippingMethod = hasShippingMethods ? shippingMethods[0].id : -1;
	const [shippingMethod, setShippingMethod] = React.useState(
		firstShippingMethod,
	);

	const defaultDeliveryChoice = hasShippingMethods
		? DeliveryMode.HomeDelivery
		: DeliveryMode.Pickup;

	const [deliveryChoice, setDeliveryChoice] = React.useState(
		defaultDeliveryChoice,
	);

	React.useEffect(() => {
		getLocationTaxRate({ postalCode: zip, city, stateProvince }).then(
			res => {
				setOrgTaxRate(
					res || {
						salesTax: null,
						useTax: null,
					},
				);
			},
		);
		// eslint-disable-next-line
	}, []);

	const getShippingPrice = shipping => {
		let price = 0;
		const anyItemType = 0;
		if (isShipping()) {
			const shippingItemFee =
				shipping.shippingItemFees &&
				shipping.shippingItemFees.find(
					fee => fee.itemType === anyItemType,
				);

			if (typeof shippingItemFee === 'undefined') {
				price = shipping.price;
			} else {
				var items = bagDataStore.getTitles();
				var itemCount = items.reduce(function(total, item) {
					return total + item.quantity;
				}, 0);
				price =
					shipping.price + itemCount * shippingItemFee.shippingFee;
			}
		}

		return Number(price.toFixed(2));
	};

	const getSelectedShippingPrice = () => {
		const shipping = shippingMethods.find(sm => sm.id === shippingMethod);

		return getShippingPrice(shipping);
	};

	const isShipping = () => {
		return deliveryChoice === DeliveryMode.HomeDelivery;
	};

	const onChangeDelivery = e => {
		setDeliveryChoice(e.target.value);
	};

	const renderDeliveryOrPickupRadios = () => {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px',
		};
		return (
			<div style={{ marginBottom: 30 }}>
				<Form.Item
					label={
						<Translate id="how_would_you_like_to_get_your_order" />
					}
					style={{ marginBottom: -3 }}
					required
				/>
				<Radio.Group value={deliveryChoice} onChange={onChangeDelivery}>
					<Radio style={radioStyle} value={DeliveryMode.Pickup}>
						<Translate id="pick_up" />
					</Radio>
					{hasShippingMethods && (
						<Radio
							style={radioStyle}
							value={DeliveryMode.HomeDelivery}>
							<Translate id="deliver_to_my_address" />
						</Radio>
					)}
				</Radio.Group>
			</div>
		);
	};

	const onChangeShipping = e => {
		setShippingMethod(e.target.value);
	};

	const renderShippingMethodRadio = () => {
		if (!isShipping()) {
			return;
		}

		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px',
		};

		return (
			<div style={{ marginBottom: 30 }}>
				<Form.Item
					label={<Translate id="choose_shipping" />}
					style={{ marginBottom: -3 }}
					required
				/>
				<Radio.Group value={shippingMethod} onChange={onChangeShipping}>
					{shippingMethods.map(so => (
						<Radio style={radioStyle} value={so.id} key={so.id}>
							{so.name} -{' '}
							<LocalizedPrice price={getShippingPrice(so)} />
						</Radio>
					))}
				</Radio.Group>
			</div>
		);
	};

	const onModalOk = () => {
		setIsVisible(false);
		history.push('/explore');
	};

	const getTax = taxRate => {
		if (!taxRate) {
			taxRate = orgSalesTax;
		}
		if (!taxRate || !taxRate.totalRate) {
			return 0.0;
		}
		const orderTotal = Number(getFixedTotal());
		let taxCost = orderTotal * taxRate.totalRate;

		if (taxRate.isFreightTaxable) {
			const shippingPrice = getSelectedShippingPrice();
			taxCost += shippingPrice * taxRate.totalRate;
		}

		return Number(taxCost.toFixed(2));
	};

	const getOrderTotal = taxRate => {
		let orderTotal = Number(getFixedTotal());

		const shippingPrice = getSelectedShippingPrice();
		orderTotal += shippingPrice;

		const tax = getTax(taxRate);
		orderTotal += tax;

		return orderTotal.toFixed(2);
	};

	const renderCompletePaypalOrder = () => {
		return (
			<>
				<div style={isMobile() ? { marginRight: '30px' } : null}>
					<OrderTotalBreakdown
						getShippingPrice={getSelectedShippingPrice}
						getOrderTotal={getOrderTotal}
						getTax={getTax}
						isShipping={isShipping}
						doShowTax={getOrgCountry() === 'US'}
					/>
				</div>
				<PayPalOrderButton
					isShipping={isShipping}
					deliveryChoice={deliveryChoice}
					orgSalesTax={orgSalesTax}
					orgUseTax={orgUseTax}
					getOrderTotal={getOrderTotal}
					getShippingPrice={getSelectedShippingPrice}
					getTax={getTax}
					setError={setError}
					setIsVisible={setIsVisible}
				/>
			</>
		);
	};

	const bagHasItems = bagDataStore.hasItems();

	return (
		<div className="order-page">
			<OrderLaneContainer>
				<div className="order-form-content">
					<BagOrderTitleSummaries />
					{bagHasItems && renderDeliveryOrPickupRadios()}
					{bagHasItems && renderShippingMethodRadio()}
					{bagHasItems && renderCompletePaypalOrder()}
				</div>
			</OrderLaneContainer>
			<Modal
				closable={false}
				visible={isVisible}
				title={
					error.exists ? (
						<Translate id="error" />
					) : (
						<Translate id="order_complete" />
					)
				}
				onOk={onModalOk}>
				<Result
					status={error.exists ? 'error' : 'success'}
					title={
						error.exists ? (
							<Translate id="failure" />
						) : (
							<Translate id="successfully_processed_your_order" />
						)
					}
					subTitle={
						error.exists ? null : (
							<Translate id="an_email_was_sent_with_order_details" />
						)
					}
				/>
			</Modal>
		</div>
	);
}

export default withIsMobile(OrderPage);
