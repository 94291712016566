import React from 'react';
import RecentShelvesTitleLanes from '../RecentShelves/RecentShelvesTitleLanes';
import StaffPicksSummary from '../StaffPicks/StaffPicksSummary';
import StaffPickLaneType from '../../entity/StaffPickLaneType';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import CustomerFavoritesDataStore from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import TitleSearchService from '../Title/Search/TitleSearchService';
import LoadingPage from '../UI/LoadingPage';
import Shelves from './Shelves';
import './browseShelvesPage.css';
import { Translate } from 'react-localize-redux';

export default class BrowseShelvesPage extends React.Component {
	render() {
		return (
			<WishlistSkusDataStore>
				{wishlistSkus => {
					return (
						<CustomerFavoritesDataStore>
							{favorites =>
								this.renderContent(wishlistSkus, favorites)
							}
						</CustomerFavoritesDataStore>
					);
				}}
			</WishlistSkusDataStore>
		);
	}

	renderContent = (wishlistSkus, favorites) => {
		if (wishlistSkus.isLoading() || favorites.isLoading()) {
			return <LoadingPage />;
		}
		return (
			<TitleSearchService>
				{this.renderWithTitleSearchService}
			</TitleSearchService>
		);
	};

	renderWithTitleSearchService = titleSearchService => {
		return (
			<div className="browse-shelves-page">
				<div style={{ paddingBottom: '2rem' }}>
					<Translate>
						{({ translate }) => {
							return titleSearchService.renderTitleSearch(
								this.getHeader(translate),
							);
						}}
					</Translate>
					<RecentShelvesTitleLanes maxLanes={5} />
					<StaffPicksSummary
						staffPickLaneType={StaffPickLaneType.bySection}
					/>
					<Shelves />
				</div>
			</div>
		);
	};

	getHeader = translate => {
		return translate('browse_our_shelves');
	};
}
