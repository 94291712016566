import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { connectToLogo } from '../../data/BooksellerLogoStore';
import SplashUploadAnchor from './SplashUploadAnchor';
import SaveUploadedSplashButton from './SaveUploadedSplashButton';
import DeleteSplashButton from './DeleteSplashButton';
import useSplashImageController from './hooks/useSplashImageController';
import { Translate } from 'react-localize-redux';
import './splashImageUpload.css';

const propTypes = {
	onIgnore: PropTypes.func,
	logoStore: PropTypes.object,
};

function SplashImageUpload({ onIgnore, logoStore }) {
	const { clearPreviewAndReset } = useSplashImageController();
	const hasUploadedPreview = logoStore.hasSplashPreview();

	return (
		<>
			<div className="splash-image-upload">
				{hasUploadedPreview ? (
					<SaveUploadedSplashButton />
				) : (
					<SplashUploadAnchor />
				)}
				{!hasUploadedPreview && logoStore.hasSplashImage() && (
					<DeleteSplashButton />
				)}
				{hasUploadedPreview ? (
					<Button onClick={clearPreviewAndReset}>
						<Translate id="cancel" />
					</Button>
				) : (
					<Button onClick={onIgnore}>
						<Translate id="dismiss" />
					</Button>
				)}
			</div>
			<div className="recommended-size-text">
				<Translate
					id="the_recommended_image_size_is"
					data={{ size: '1600 x 350px.' }}
				/>
			</div>
		</>
	);
}

SplashImageUpload.propTypes = propTypes;

export default connectToLogo(SplashImageUpload);
