import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import LoadableAutocomplete from 'treeline-react-core-components/LoadableAutocomplete';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import { urlPrefix } from '../../../api';
import { cancelableDelay } from 'treeline-react-core-components/util';
import BrowseTitleAutocompleteRow from './BrowseTitleAutocompleteRow';
import { Translate } from 'react-localize-redux';
import './browseTitleAutocomplete.css';

class BrowseTitleAutocomplete extends React.Component {
	static propTypes = {
		// Passed in from parent
		placeholder: PropTypes.string,
		onSelectOption: PropTypes.func,
		renderOption: PropTypes.func,
		isClearable: PropTypes.bool,
		includeProductImagesInResults: PropTypes.bool,
		handleOptionsResponse: PropTypes.func,
		shouldResetInternalState: PropTypes.bool,
		// Mapped to props
		customerState: PropTypes.object,
	};

	static defaultProps = {
		onSelectOption: () => {},
		isClearable: true,
		includeProductImagesInResults: false,
		renderTitleOption: title => {
			return <BrowseTitleAutocompleteRow title={title} />;
		},
		shouldResetInternalState: false,
	};

	state = {
		isLoading: false,
		userInput: '',
	};

	render() {
		const { isClearable, shouldResetInternalState } = this.props;
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className="browse-title-autocomplete">
							<Spin spinning={this.state.isLoading}>
								<LoadableAutocomplete
									optionIdName="sku"
									optionDisplayNameKey="title"
									apiCallDelay={850}
									placeholder={this.getPlaceholder(translate)}
									canShowSuccess={false}
									onSelectHook={this.onSelectHook}
									hasSearchIcon={true}
									isClearable={isClearable}
									getURLForOptions={this.getURLForOptions}
									getOptionLabel={
										this.props.renderTitleOption
									}
									handleOptionsResponse={
										this.handleOptionsResponse
									}
									inputRequiredFeedback={this.getFeedback(
										translate,
									)}
									shouldResetInternalState={
										shouldResetInternalState
									}
								/>
							</Spin>
						</div>
					);
				}}
			</Translate>
		);
	}

	handleOptionsResponse = options => {
		const { userInput } = this.state;
		const { handleOptionsResponse } = this.props;
		if (typeof handleOptionsResponse === 'function') {
			return handleOptionsResponse(userInput, options);
		}
		if (Array.isArray(options)) {
			return options;
		}
		if (options && typeof options === 'object' && options.retailTitles) {
			return options.retailTitles;
		}
		return [];
	};

	getURLForOptions = userInput => {
		this.setState({ userInput });
		const { orgId } = this.props.customerState;
		const base = this.getEndpointBase();
		const endpoint = `/org/${orgId}${base}?q=${userInput}`;
		return `${urlPrefix}${endpoint}`;
	};

	getEndpointBase() {
		const { includeProductImagesInResults } = this.props;
		const searchTitles = '/search/titles';

		if (!includeProductImagesInResults) {
			return searchTitles;
		}
		return `${searchTitles}/retailTitleSearchResults,productImages,productInfo`;
	}

	onSelectHook = autocompleteState => {
		const { selectedId: sku } = autocompleteState;

		this.setState({ isLoading: true });

		this.delay = cancelableDelay(500);

		this.delay.promise.then(() => {
			const instance = this.getTitleAutocompleteInstance();
			this.props.onSelectOption(sku, instance);
		});
	};

	getTitleAutocompleteInstance = (state = this.state) => ({
		state,
		update: nextState => this.setState(nextState),
	});

	getFeedback = translate => {
		return translate('too_short');
	};

	getPlaceholder = translate => {
		return this.props.placeholder
			? this.props.placeholder
			: translate('find_a_title');
	};
}

export default connectToCustomerID(BrowseTitleAutocomplete);
