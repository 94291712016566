import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import makeConnectTo from '../../data/makeConnectTo';

const ImageUploadContext = React.createContext();

const name = 'imageUploadDataStore';
export const connectToImageUpload = makeConnectTo(ImageUploadContext, name);

export const useImageUpload = () => useContext(ImageUploadContext);

export default class ImageUploadDataStore extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	};

	initialState = {
		fileType: null,
		fileName: null,
		base64Image: null,
		croppedImageBase64Source: null,
		didChooseImage: false,
		isLoading: false,
		isCropActive: false,
	};

	state = this.initialState;

	render() {
		const dataStore = this.getDataStore();
		return (
			<ImageUploadContext.Provider value={dataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(dataStore)
					: this.props.children}
			</ImageUploadContext.Provider>
		);
	}

	getDataStore = (state = this.state) => ({
		state,
		set: nextState => this.setState(s => ({ ...s, ...nextState })),
		reset: () => this.reset(),
		isCropActive: () => state.isCropActive,
		getUploadedImageAsBase64: () => {
			return this.getUploadedImageAsBase64();
		},
		setBase64ImageResult: uploadResult => {
			return this.setBase64ImageResult(uploadResult);
		},
		setCroppedImageBase64: croppedImageBase64Source => {
			this.setState({ croppedImageBase64Source });
		},
		getUploadedFileType: () => state.fileType,
		getOriginalBase64: () => state.base64Image,
		getImageSourceForPreview: () => this.getImageSourceForPreview(state),
	});

	setBase64ImageResult = uploadResult => {
		const { base64, fileType, fileName } = uploadResult;

		this.setState({
			fileType,
			fileName,
			base64Image: base64,
			didChooseImage: true,
			isLoading: false,
		});
	};

	reset = () => {
		this.setState(this.initialState);
	};

	setCroppedImageBase64 = croppedImageBase64Source => {
		this.setState({ croppedImageBase64Source });
	};

	getImageSourceForPreview = state => {
		const { base64Image, croppedImageBase64Source: cropped } = state;
		return cropped ? cropped : base64Image;
	};

	getUploadedImageAsBase64() {
		const { croppedImageBase64Source, base64Image } = this.state;

		if (croppedImageBase64Source) {
			return this.normalizeBase64(croppedImageBase64Source);
		}
		return this.normalizeBase64(base64Image);
	}

	normalizeBase64 = base64 => {
		const match = /data:image\/.+;base64,/g;
		return base64.replace(match, '').trim();
	};
}
