import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import TitleJacketImage from '../../Title/UI/TitleJacketImage';
import TitleSummary from '../../Title/UI/TitleSummary';
import { Card } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import TitleJacketImageWithIconBadges from '../../UI/TitleJacketImageWithIconBadges';

class CollectionTitleCard extends React.Component {
	static propTypes = {
		title: Customer360PropTypes.Title,
		children: PropTypes.element,
		onTitleJacketClick: PropTypes.func, // Not required
		// Mapped to props
		isMobile: PropTypes.func,
		titleIsInWishlist: PropTypes.bool,
	};

	static defaultProps = {
		titleIsInWishlist: false,
	};

	render() {
		const { title } = this.props;
		const isMobile = this.props.isMobile();
		return (
			<Card
				className="title-card"
				style={{
					alignItems: 'center',
					backgroundColor: '#fff',
					display: 'flex',
					height: isMobile ? '190px' : '230px',
					marginBottom: '1rem',
					width: isMobile ? '100%' : '98%',
				}}>
				<div
					className="flex"
					style={{ alignItems: 'center', width: '100%' }}>
					<div
						style={{
							marginRight: isMobile ? '5px' : '15px',
							width: '20%',
						}}>
						<TitleJacketImageWithIconBadges
							size={TitleJacketImage.fillwidth}
							title={title}
							onClick={this.props.onTitleJacketClick}
						/>
					</div>
					<div style={{ width: '80%' }}>
						<TitleSummary
							title={title}
							shouldTruncateName={isMobile}
							titleNameFontSize={isMobile ? 14 : 16}>
							{this.props.children}
						</TitleSummary>
					</div>
				</div>
			</Card>
		);
	}
}

export default withIsMobile(CollectionTitleCard);
