import React from 'react';
import UnsubscribePage from './UnsubscribePage';
import MessageFrequencyDataStore from './data/MessageFrequencyDataStore';
import WishlistSkusDataStore from '../Wishlist/data/WishlistSkusDataStore';
import LoadingPage from '../UI/LoadingPage';
import './unsubscribe.css';

export default class UnsubscribePageRoot extends React.Component {
	render() {
		return (
			<div className="unsubscribe-root">
				<WishlistSkusDataStore>
					<MessageFrequencyDataStore>
						{this.renderPageContent}
					</MessageFrequencyDataStore>
				</WishlistSkusDataStore>
			</div>
		);
	}

	renderPageContent = ({ isLoading }) => {
		if (isLoading) {
			return (
				<LoadingPage style={{ padding: '2rem 5rem', fontSize: 20 }} />
			);
		}
		return <UnsubscribePage />;
	};
}
