import PropTypes from 'prop-types';
import React from 'react';
import Scroller from '../UI/Scroller/Scroller';
import TitleSkeletons from './TitleSkeletons';
import { Button } from 'antd';
import { makeCancelable } from 'api-fetcher-treeline/dist';
import TitleJacketImageWithIconBadges from '../UI/TitleJacketImageWithIconBadges';

class ViewMore extends React.Component {
	static propTypes = {
		fetchMoreTitles: PropTypes.func,
	};

	state = { isLoading: false };

	render() {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					paddingRight: 5,
				}}>
				<Button
					icon="arrow-right"
					size="large"
					loading={this.state.isLoading}
					disabled={this.state.isLoading}
					onClick={this.onClick}
					style={{ borderRadius: '50%' }}></Button>
			</div>
		);
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	onClick = () => {
		this.setState({ isLoading: true });

		this.request = makeCancelable(this.props.fetchMoreTitles());

		this.request.promise.then(() => {
			this.setState({ isLoading: false });
		});
	};
}

export default class TitleScroller extends React.Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		titles: PropTypes.arrayOf(PropTypes.object),
		canFetchMoreTitles: PropTypes.bool,
		fetchMoreTitles: PropTypes.func,
		renderOneTitle: PropTypes.func,
	};

	static defaultProps = {
		titles: [],
		isLoading: false,
		canFetchMoreTitles: false,
		renderOneTitle: title => (
			<TitleJacketImageWithIconBadges
				title={title}
				isInTitleLane={true}
			/>
		),
	};

	render() {
		return (
			<div className="title-scroller">
				<Scroller>{this.renderTitles()}</Scroller>
			</div>
		);
	}

	renderTitles() {
		const {
			isLoading,
			canFetchMoreTitles,
			fetchMoreTitles,
			titles,
		} = this.props;
		if (isLoading) {
			return <TitleSkeletons />;
		}
		return (
			<React.Fragment>
				{titles.map(this.renderOneTitle)}
				{canFetchMoreTitles && fetchMoreTitles && (
					<ViewMore fetchMoreTitles={fetchMoreTitles} />
				)}
			</React.Fragment>
		);
	}

	renderOneTitle = (title, idx) => {
		return (
			<div style={{ minWidth: '75px' }} key={title.sku}>
				{this.props.renderOneTitle(title, idx)}
			</div>
		);
	};
}
