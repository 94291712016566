import React from 'react';
import { Spin } from 'antd';

export default function LoadingPage({ style }) {
	return (
		<div style={style}>
			<Spin spinning />
			<span style={{ marginLeft: '1rem' }}>Loading...</span>
		</div>
	);
}

LoadingPage.defaultProps = {
	style: { padding: '5rem', fontSize: 20 },
};
