import React from 'react';
import PropTypes from 'prop-types';
import AuthorAutocompleteRow from './AuthorAutocompleteRow';
import { Spin } from 'antd';
import LoadableAutocomplete from 'treeline-react-core-components/LoadableAutocomplete';
import { useCustomerID } from '../../data/CustomerIDStore';
import { urlPrefix } from '../../../api';
import { Translate } from 'react-localize-redux';

const propTypes = {
	onSelectOption: PropTypes.func,
	placeholder: PropTypes.string,
	renderAuthorOption: PropTypes.func,
};

const defaultProps = {
	onSelectOption: () => {},
	placeholder: <Translate id="find_an_author" />,
	renderAuthorOption: author => {
		return <AuthorAutocompleteRow author={author} />;
	},
};

export default function AuthorsAutocomplete(props) {
	const { placeholder, onSelectOption, renderAuthorOption } = props;

	const { orgId } = useCustomerID();
	const [isLoading, setIsLoading] = React.useState(false);

	const getURLForOptions = userInput => {
		const endpoint = `/org/${orgId}/search/authors?q=${userInput}`;
		return `${urlPrefix}${endpoint}`;
	};

	const onSelectHook = async autocompleteState => {
		const { selectedId: id, selectedOption } = autocompleteState;
		setIsLoading(true);
		await onSelectOption(id, selectedOption);
		setIsLoading(false);
	};

	const getFeedback = translate => {
		return translate('too_short');
	};

	return (
		<Translate>
			{({ translate }) => {
				return (
					<div className="authors-autocomplete">
						<Spin spinning={isLoading}>
							<LoadableAutocomplete
								optionIdName="id"
								optionDisplayNameKey="name"
								placeholder={placeholder}
								canShowSuccess={false}
								onSelectHook={onSelectHook}
								hasSearchIcon={true}
								getURLForOptions={getURLForOptions}
								getOptionLabel={renderAuthorOption}
								inputRequiredFeedback={getFeedback(translate)}
							/>
						</Spin>
					</div>
				);
			}}
		</Translate>
	);
}

AuthorsAutocomplete.propTypes = propTypes;
AuthorsAutocomplete.defaultProps = defaultProps;
