import React from 'react';
import { Form, Radio, Input, Button } from 'antd';
import { DeliveryMode } from '../../../orders/DeliveryMode';
import PropTypes from 'prop-types';
import { connectToOrg } from '../../data/OrganizationDataStore';
import { Translate } from 'react-localize-redux';

class MyOrderPreferences extends React.Component {
	static propTypes = {
		form: PropTypes.object,
		orgDataStore: PropTypes.object,
	};

	state = { shippingMethod: {}, deliveryChoice: {} };

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll();
	};

	getPlaceholder = (translate, id) => {
		return translate(id);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { orgDataStore } = this.props;
		const shippingMethods = orgDataStore.getOrgShippingMethods();

		return (
			<Translate>
				{({ translate }) => {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								padding: '20px',
								backgroundColor: 'white',
							}}>
							<div
								style={{
									fontSize: '20px',
									paddingBottom: '20px',
								}}>
								<Translate id="my_order_preferences" />
							</div>
							<Form
								onSubmit={this.handleSubmit}
								style={{ width: '80%' }}>
								{this.renderDeliveryOrPickupRadios()}
								{shippingMethods.length
									? this.renderShippingMethodRadio(
											shippingMethods,
									  )
									: null}
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}>
									<Form.Item
										style={{
											width: '48%',
											marginBottom: '10px',
										}}>
										{getFieldDecorator('firstName', {
											rules: [
												{
													required: true,
													message: (
														<Translate id="please_enter_your_first_name" />
													),
												},
											],
										})(
											<Input
												placeholder={this.getPlaceholder(
													translate,
													'first_name',
												)}
											/>,
										)}
									</Form.Item>
									<Form.Item
										style={{
											width: '48%',
											marginBottom: '10px',
										}}>
										{getFieldDecorator('lastName', {
											rules: [
												{
													required: true,
													message: (
														<Translate id="please_enter_your_last_name" />
													),
												},
											],
										})(
											<Input
												placeholder={this.getPlaceholder(
													translate,
													'last_name',
												)}
											/>,
										)}
									</Form.Item>
								</div>
								<Form.Item style={{ marginBottom: '10px' }}>
									{getFieldDecorator('address1', {
										rules: [
											{
												required: true,
												message: (
													<Translate id="please_enter_your_address" />
												),
											},
										],
									})(
										<Input
											placeholder={this.getPlaceholder(
												translate,
												'address_one',
											)}
										/>,
									)}
								</Form.Item>
								<Form.Item style={{ marginBottom: '10px' }}>
									{getFieldDecorator('address2')(
										<Input
											placeholder={this.getPlaceholder(
												translate,
												'address_two',
											)}
										/>,
									)}
								</Form.Item>
								<Form.Item style={{ marginBottom: '10px' }}>
									{getFieldDecorator('city', {
										rules: [
											{
												required: true,
												message: (
													<Translate id="please_enter_a_city" />
												),
											},
										],
									})(
										<Input
											placeholder={this.getPlaceholder(
												translate,
												'city',
											)}
										/>,
									)}
								</Form.Item>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}>
									<Form.Item
										style={{
											width: '48%',
											marginBottom: '10px',
										}}>
										{getFieldDecorator('state', {
											rules: [
												{
													required: true,
													message: (
														<Translate id="please_enter_a_state_or_province" />
													),
												},
											],
										})(
											<Input
												placeholder={this.getPlaceholder(
													translate,
													'state_or_province',
												)}
											/>,
										)}
									</Form.Item>
									<Form.Item
										style={{
											width: '48%',
											marginBottom: '10px',
										}}>
										{getFieldDecorator('zipCode', {
											rules: [
												{
													required: true,
													message: (
														<Translate id="please_enter_a_zip_code" />
													),
												},
											],
										})(
											<Input
												placeholder={this.getPlaceholder(
													translate,
													'zip_or_postal_code',
												)}
											/>,
										)}
									</Form.Item>
								</div>
								<Form.Item style={{ float: 'right' }}>
									<Button type="primary" htmlType="submit">
										<Translate id="save" />
									</Button>
								</Form.Item>
							</Form>
						</div>
					);
				}}
			</Translate>
		);
	}

	renderDeliveryOrPickupRadios = () => {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px',
		};
		return (
			<div
				style={{
					marginBottom: 30,
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Form.Item
					label={
						<Translate id="how_would_you_like_to_get_your_order" />
					}
					style={{ marginBottom: -3 }}
					required
				/>
				<Radio.Group
					name={'deliveryChoice'}
					onChange={e => this.onChange(e)}>
					<Radio style={radioStyle} value={DeliveryMode.Pickup}>
						<Translate id="pick_up" />
					</Radio>
					<Radio style={radioStyle} value={DeliveryMode.HomeDelivery}>
						<Translate id="deliver_to_my_address" />
					</Radio>
				</Radio.Group>
			</div>
		);
	};

	renderShippingMethodRadio = shippingMethods => {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px',
		};

		return (
			<div
				style={{
					marginBottom: 30,
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Form.Item
					label={<Translate id="choose_shipping" />}
					style={{ marginBottom: -3 }}
					required
				/>
				<Radio.Group
					name={'shippingMethod'}
					onChange={e => this.onChange(e)}>
					{shippingMethods.map((method, i) => {
						return (
							<Radio key={i} style={radioStyle} value={method.id}>
								{method.name} <Translate id="shipping" />
								-${method.price}
							</Radio>
						);
					})}
				</Radio.Group>
			</div>
		);
	};
}

export default MyOrderPreferences = Form.create({})(
	connectToOrg(MyOrderPreferences),
);
