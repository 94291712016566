import React from 'react';
import PropTypes from 'prop-types';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';
import withIsMobile from '../hoc/withIsMobile';
import { useLocation } from 'react-router-dom';

Page.propTypes = {
	isMobile: PropTypes.func,
	children: PropTypes.element.isRequired,
	doShowAppHeader: PropTypes.bool,
	doShowRetailerBrandLogo: PropTypes.bool,
};

Page.defaultProps = {
	doShowAppHeader: true,
	doShowRetailerBrandLogo: true,
};

function Page({
	children,
	isMobile,
	doShowAppHeader,
	doShowRetailerBrandLogo,
}) {
	const { pathname } = useLocation();

	React.useEffect(() => {
		window.scrollTo && window.scrollTo(0, 0);
	}, [pathname]);

	const renderChildrenWithHeader = () => {
		return (
			<LandingHeader doShowRetailerBrandLogo={doShowRetailerBrandLogo}>
				{children}
			</LandingHeader>
		);
	};

	return (
		<>
			{doShowAppHeader ? renderChildrenWithHeader() : children}
			{isMobile() && <LandingFooter />}
		</>
	);
}

export default withIsMobile(Page, 915);
