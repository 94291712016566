import React from 'react';
import Customer360PropTypes from '../../../types';
import PropTypes from 'prop-types';
import { Tooltip, notification } from 'antd';
import FavoriteEntityIndicator from './FavoriteEntityIndicator';
import { connectToFavorites } from '../data/CustomerFavoritesDataStore';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import FavoriteButton from './FavoriteButton';
import './favoriteEntityAnchor.css';

class FavoriteEntityAnchor extends React.Component {
	static propTypes = {
		entity: Customer360PropTypes.RecommendableEntity,
		// Mapped to props
		isMobile: PropTypes.func,
		previewMode: PropTypes.object,
		customerFavoritesDataStore: PropTypes.object,
		isTitleDetail: PropTypes.bool,
	};

	state = {
		isLoading: false,
		isActive: false,
		didInitialize: false,
	};

	render() {
		const { isMobile, isTitleDetail } = this.props;
		return (
			<Translate>
				{({ translate }) => {
					return !isTitleDetail ? (
						this.renderFavoriteButton(translate)
					) : (
						<div className="favorite-entity-anchor">
							{this.state.didInitialize && (
								<Tooltip
									title={
										!isMobile() ? this.getTooltip() : null
									}
									placement="top">
									<div
										onClick={() =>
											this.handleToggleAsFavorite(
												translate,
											)
										}>
										{!this.state.isLoading &&
											this.renderIcon()}
									</div>
								</Tooltip>
							)}
						</div>
					);
				}}
			</Translate>
		);
	}

	componentDidMount() {
		const isActive = this.isActiveInDataStore();
		this.setState({ isActive, didInitialize: true });
	}

	componentWillUnmount() {
		const { previewMode } = this.props;
		const isActive = this.isActiveInDataStore();

		if (!previewMode.isActive() && isActive !== this.state.isActive) {
			this.toggleEntityAsCustomerFavorite();
		}
	}

	renderIcon() {
		const { entity, isTitleDetail } = this.props;
		return (
			<FavoriteEntityIndicator
				isTitleDetail={isTitleDetail}
				isInTitleLane={false}
				entity={entity}
				isActive={this.state.isActive}
				style={{ padding: 0 }}
			/>
		);
	}

	getTooltip() {
		if (this.state.isActive) {
			return <Translate id="currently_pinned_as_favorite" />;
		}
		return <Translate id="click_to_pin_favorite" />;
	}

	isActiveInDataStore() {
		const { customerFavoritesDataStore: favorites, entity } = this.props;
		return favorites.includes(entity.id, entity.type);
	}

	handleToggleAsFavorite = translate => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		const lastState = this.state.isActive;
		const nextState = !lastState;

		this.setState({ isActive: nextState });
		this.toggleEntityAsCustomerFavorite();

		let message;

		if (nextState) {
			message = translate('added_to_favorites');
		} else {
			message = translate('removed_from_favorites');
		}

		notification.success({ message, duration: 1 });
	};

	toggleEntityAsCustomerFavorite() {
		const { customerFavoritesDataStore: favorites, entity } = this.props;

		if (this.isActiveInDataStore()) {
			return favorites.remove(entity);
		}
		return favorites.save(entity);
	}

	renderFavoriteButton = translate => {
		const { customerFavoritesDataStore, entity } = this.props;
		const { isActive, isLoading } = this.state;
		const isFavoriteEntity = () => {
			return typeof isActive === 'boolean'
				? isActive
				: customerFavoritesDataStore.includes(entity.id, entity.type);
		};
		return (
			<FavoriteButton
				isLoading={isLoading}
				isFavoriteEntity={isFavoriteEntity}
				handleToggle={() => this.handleToggleAsFavorite(translate)}
			/>
		);
	};
}

const connect = combineConnectors([connectToFavorites, connectToPreviewMode]);

export default connect(withIsMobile(FavoriteEntityAnchor));
