import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import makeConnectTo from '../../data/makeConnectTo';

const TransactionDetailsContext = React.createContext();

export const connectToTransactionDetails = makeConnectTo(
	TransactionDetailsContext,
	'transactionDetailsDataStore',
);

class TransactionDetailsDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		transactionsData: [],
	};

	render() {
		return (
			<TransactionDetailsContext.Provider value={this.getDataStore()}>
				{typeof this.props.children === 'function'
					? this.props.children(this.state)
					: this.props.children}
			</TransactionDetailsContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchTransactionDetails();
	}

	componentWillUnmount() {
		api.revoke(this.transactionDetailsFetch);
	}

	getDataStore = (state = this.state) => ({
		state,
	});

	fetchTransactionDetails = () => {
		const { orgId, customerId } = this.props.customerState;
		const request = api.cancelableGet(
			`/org/${orgId}/customer/${customerId}/transactions`,
		);
		request.promise.then(this.cacheApiResponse);
		this.transactionDetailsFetch = request;
	};

	cacheApiResponse = apiResponse => {
		const transactionsData = this.getTransactionDetailsData(apiResponse);
		this.setState({ transactionsData });
	};

	getTransactionDetailsData = apiResponse => {
		const r = apiResponse;
		return {
			transactions: this.buildBrowseableTransactions(r),
		};
	};

	buildBrowseableTransactions(transactionsAggregate) {
		const { skuPosData, productsBySku } = transactionsAggregate;
		return transactionsAggregate.transactions.map(t => {
			const sku = t.sku;
			const title = this.getTitleForTransaction(
				sku,
				productsBySku[sku] && productsBySku[sku],
				skuPosData[sku] && skuPosData[sku],
			);
			const author = this.getAuthorForTransaction(
				sku,
				productsBySku[sku] && productsBySku[sku],
				skuPosData[sku] && skuPosData[sku],
			);
			return { ...t, title, author };
		});
	}

	getTitleForTransaction = (sku, product, pos) => {
		if (product && product.name) {
			return product.name;
		} else if (pos && pos[0] != null) {
			return pos[0].title;
		} else {
			return sku || '';
		}
	};

	getAuthorForTransaction = (sku, product, pos) => {
		if (product && product.name) {
			return product.author;
		} else if (pos && pos[0] != null) {
			return pos[0].author;
		} else {
			return sku || '';
		}
	};
}

export default connectToCustomerID(TransactionDetailsDataStore);
