import coreKeys from 'treeline-react-core-components/localizationKeys'; // These keys are referenced by components in the lib

export default [
	...coreKeys,
	'a_colorful_illustration_of_several_books',
	'about_x',
	'account',
	'add_to_bag',
	'add_to_bag_and_checkout',
	'add_to_favorites',
	'add_to_your_favorites_collection',
	'add_to_your_wishlist',
	'added_to_checkout_bag',
	'added_to_favorites',
	'added_to_your_wishlist',
	'added_x_to_your_bag',
	'address_one',
	'address_two',
	'advanced_search',
	'all_shelves',
	'already_have_an_account',
	'an_email_was_sent_with_order_details',
	'are_you_sure_you_want_to_delete_review',
	'are_you_sure_you_want_to_delete_the_splash_image',
	'are_you_sure_you_want_to_unsubscribe',
	'available_for_order',
	'back_to_browsing',
	'bag',
	'bag_is_empty',
	'books_that_staff_members_have_reviewed',
	'books_youd_like_to_read_next',
	'browse',
	'browse_new_top_sellers',
	'browse_one_available_work',
	'browse_our_shelves',
	'browse_x_available_works',
	'browse_x_titles',
	'buy',
	'buy_again',
	'buy_now',
	'buy_now_uppercase',
	'by',
	'by_author_x',
	'can_we_do_something_to_keep_you',
	'cancel',
	'checkout_now',
	'choose_shipping',
	'city',
	'clear',
	'clear_results',
	'click_to_pin_favorite',
	'click_to_read_staff_review',
	'click_yes_to_remove',
	'coming_soon',
	'complete_registration',
	'complete_registration_to_view_purchases',
	'contains_x_titles',
	'continue_browsing',
	'copied_link_to_your_clipboard',
	'copies',
	'copy',
	'copy_link_to_clipboard',
	'copy_wishlist_to_clipboard',
	'could_you_let_us_know_why',
	'create_a_wishlist',
	'currently_in_bag',
	'currently_pinned_as_favorite',
	'delete',
	'deliver_to_my_address',
	'dismiss',
	'do_you_want_to_checkout_now',
	'edit_review',
	'email',
	'email_is_invalid',
	'email_is_required',
	'enter_the_title_of_a_book_to_search',
	'enter_the_title_of_a_book',
	'estimated_tax',
	'estimated_total',
	'events',
	'explore',
	'failure',
	'favorite',
	'favorite_authors',
	'favorite_books',
	'favorite_series',
	'favorite_shelves',
	'find_a_series',
	'find_a_title',
	'find_an_author',
	'first_name',
	'format',
	'get_better_title_recommendations',
	'get_it_shipped_to_you_in_a_few_days',
	'given_much_love_by_one_indie_bookseller',
	'given_much_love_by_x_indie_booksellers',
	'hallelujah_welcome_back',
	'hours',
	'how_would_you_like_to_get_your_order',
	'i_only_want_to_receive_1_email_every',
	'in_our_x_section',
	'info',
	'invalid_wishlist_url',
	'last_name',
	'learn_more',
	'learn_more_about_x',
	'let_us_know_what_books_you_love_most',
	'let_us_know_what_your_favorite_series_are',
	'let_us_know_where_you_like_to_browse',
	'load_more',
	'loading',
	'log_in',
	'log_out',
	'more_books_like_this',
	'most_actions_are_disabled',
	'much_loves_given_for_x',
	'my_favorites',
	'my_order_preferences',
	'my_orders',
	'my_purchases',
	'my_reviews',
	'my_take_on_x',
	'new_and_trending',
	'new_or_upcoming_titles_recommended_for_you',
	'no',
	'no_content',
	'no_favorite_authors',
	'no_favorite_books',
	'no_favorite_series',
	'no_favorite_shelves',
	'no_results_found',
	'no_thanks_uppercase',
	'no_upcoming_events',
	'not_applicable_in_preview_mode',
	'ok',
	'older_books_that_are_in_demand',
	'on_our_shelves',
	'on_your_wishlist',
	'one_copy_purchased_on_x',
	'one_item',
	'order_complete',
	'our_location',
	'our_locations',
	'pick_up',
	'pinned_as_favorite',
	'please_double_check_url',
	'please_enter_a_city',
	'please_enter_a_state_or_province',
	'please_enter_a_zip_code',
	'please_enter_your_address',
	'please_enter_your_first_name',
	'please_enter_your_last_name',
	'preference_saved_thank_you',
	'preorder',
	'preorder_books_and_we_will_have_them_ready',
	'preorder_these_hot_titles_today',
	'price',
	'preview_mode',
	'published',
	'publisher_description',
	'purchased',
	'rate',
	'rate_and_review',
	'recently_released_books_that_are_selling_well',
	'recently_viewed_full',
	'redirecting_you_in_a_few_seconds',
	'register',
	'remove',
	'remove_this_item_from_your_bag',
	'remove_from_bag',
	'removed_from_bag',
	'removed_from_favorites',
	'removed_from_your_wishlist',
	'reserve_a_copy_for_pick_up_or_delivery',
	'results_for',
	'review',
	'rewards_progress',
	'save',
	'saved_x_to_your_favorites',
	'save_your_order_preferences_for_next_time',
	'search',
	'search_for_authors',
	'search_for_books',
	'search_for_books_by_title_or_author',
	'search_for_genres',
	'search_for_more_titles',
	'search_for_series',
	'search_for_shelves',
	'search_results_will_appear_here',
	'search_to_add_more_books_to_your_order',
	'section',
	'see_all',
	'see_what_our_booksellers_recommend',
	'see_what_x_titles_our_booksellers_recommend',
	'share',
	'share_book',
	'share_wishlist',
	'share_your_favorite_authors_with_us',
	'shelves',
	'shipping',
	'showing_one_title',
	'showing_x_titles',
	'sign_up',
	'sign_up_and_access_more_features',
	'sign_up_and_start_personalizing_your_bookstore_experience',
	'sign_up_for_a_free_account',
	'splash_image_deleted',
	'splash_image_uploaded',
	'state_or_province',
	'staff_picks',
	'staff_picks_by_shelf',
	'still_selling_well',
	'submit',
	'subscribe_to_x_newsletter',
	'success',
	'successfully_processed_your_order',
	'subtotal',
	'tb_author_bio',
	'the_recommended_image_size_is',
	'there_was_an_error_with_your_request',
	'this_is_a_preview',
	'this_title',
	'titles_in_the_x_series',
	'too_short',
	'top_sellers_on_the_same_shelves',
	'try_another_search',
	'unavailable',
	'undo_sign_me_up_again',
	'unknown',
	'unknown_format',
	'unsubscribe_now',
	'upcoming_events',
	'upload',
	'upload_successful',
	'view',
	'view_all',
	'view_your_reviews_and_past_purchases',
	'view_more_details_and_buy_this_title',
	'we_are_sorry_to_see_you_go',
	'week',
	'weeks',
	'welcome',
	'what_did_you_think_review',
	'wishlist',
	'works_by_x',
	'written_by_staff_member_x_on_y',
	'written_on_x',
	'x_copies',
	'x_copies_last_purchased_on_y',
	'x_from_y_in_z',
	'x_items',
	'x_picks',
	'x_recommends_y',
	'x_titles',
	'xs_favorite_shelves',
	'xs_picks',
	'xs_take_on_y',
	'xs_wishlist',
	'xs_y_picks',
	'yes',
	'yes_delete',
	'you_have_been_part_of_a_new_service',
	'you_have_been_unsubscribed_from_our_recommendation_service',
	'your_bag_is_empty',
	'your_order',
	'your_x_has_been_removed',
	'your_x_has_been_saved',
	'your_x_has_been_updated',
	'zip_or_postal_code',
	'see_more_reviews_from_x',
	'newsletter_subscribe',
]; // Add any other localization keys you need for your app here.
