import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

export default class DrawerAnchor extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		renderDrawerContent: PropTypes.func,
		onCloseHook: PropTypes.func,
		placement: PropTypes.string,
		width: PropTypes.string,
		title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
		height: PropTypes.string,
		closable: PropTypes.bool,
		parentStyle: PropTypes.string,
	};

	state = {
		isDrawerVisible: false,
	};

	static defaultProps = {
		placement: 'left',
		width: '700px',
		height: '100%',
		closable: false,
		parentStyle: 'clickable',
	};

	render() {
		return (
			<ControlledDrawerAnchor
				placement={this.props.placement}
				width={this.props.width}
				height={this.props.height}
				closable={this.props.closable}
				style={{ overflowY: 'auto' }}
				title={this.props.title}
				showDrawer={this.showDrawer}
				closeDrawer={this.closeDrawer}
				isDrawerVisible={this.state.isDrawerVisible}
				renderDrawerContent={this.props.renderDrawerContent}
				parentStyle={this.props.parentStyle}>
				{this.props.children}
			</ControlledDrawerAnchor>
		);
	}

	showDrawer = () => {
		this.setState({ isDrawerVisible: true });
	};

	closeDrawer = () => {
		this.setState({ isDrawerVisible: false });

		if (typeof this.props.onCloseHook === 'function') {
			this.props.onCloseHook();
		}
	};
}

export class ControlledDrawerAnchor extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		isDrawerVisible: PropTypes.bool,
		showDrawer: PropTypes.func,
		closeDrawer: PropTypes.func,
		renderDrawerContent: PropTypes.func,
		placement: PropTypes.string,
		width: PropTypes.string,
		height: PropTypes.string,
		closable: PropTypes.bool,
		parentStyle: PropTypes.string,
	};

	static defaultProps = {
		showDrawer: () => {},
	};

	render() {
		return (
			<React.Fragment>
				{this.props.children && this.renderAnchorChild()}
				<Drawer
					width={this.props.width}
					height={this.props.height}
					placement={this.props.placement}
					closable={this.props.closable}
					title={this.props.title}
					bodyStyle={{ backgroundColor: '#e9ebec', border: 0 }}
					onClose={this.closeDrawer}
					visible={this.props.isDrawerVisible}
					destroyOnClose={true}>
					{this.props.renderDrawerContent(this.getInstance())}
				</Drawer>
			</React.Fragment>
		);
	}

	renderAnchorChild() {
		const { children, parentStyle } = this.props;

		if (typeof children === 'function') {
			return children(this.getInstance());
		}

		return (
			<div className={parentStyle} onClick={() => this.showDrawer()}>
				{children}
			</div>
		);
	}

	getInstance = (isDrawerVisible = this.props.isDrawerVisible) => ({
		isVisible: isDrawerVisible,
		open: () => this.showDrawer(),
		close: () => this.closeDrawer(),
		closeDrawer: () => this.closeDrawer(),
	});

	showDrawer = () => {
		this.props.showDrawer();
	};

	closeDrawer = () => {
		this.props.closeDrawer();
	};
}
