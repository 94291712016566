import React from 'react';
import PropTypes from 'prop-types';
import TitleDataStore from '../../Title/data/TitleDataStore';
import { Translate } from 'react-localize-redux';

BagTitleFormat.propTypes = {
	sku: PropTypes.string,
};

export default function BagTitleFormat({ sku }) {
	const getFormatDisplay = format => {
		switch (format) {
			case 'Trade Paperback':
				return 'Paperback';
			default:
				return format;
		}
	};

	return (
		<TitleDataStore sku={sku}>
			{titleDataStore => {
				if (titleDataStore.isLoading()) {
					return (
						<em>
							<Translate id="loading" />
							...
						</em>
					);
				}

				const format = titleDataStore.getFormatDescription();

				return (
					<span>
						{format ? (
							getFormatDisplay(format)
						) : (
							<Translate id="unknown" />
						)}
					</span>
				);
			}}
		</TitleDataStore>
	);
}
