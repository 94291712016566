import React from 'react';
import PropTypes from 'prop-types';
import TitleLanesDataStore from '../../data/TitleLanesDataStore';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import RetailTitleLaneType from '../../../entity/RetailTitleLaneType';
import { Translate } from 'react-localize-redux';

const {
	frontlist,
	recentPub,
	staffReviews,
	deeperBacklist,
} = RetailTitleLaneType;

class SectionTitleLanesDataStore extends React.Component {
	static propTypes = {
		entityId: PropTypes.string,
		sectionLevel: PropTypes.string,
		children: PropTypes.func,
		// Mapped to props
		customerState: PropTypes.object,
	};

	render() {
		return (
			<TitleLanesDataStore
				getEndpoint={this.getEndpointForTitleLanes}
				laneConfigs={this.getLaneConfigs()}>
				{titleLanesDataStore => {
					return this.props.children(titleLanesDataStore);
				}}
			</TitleLanesDataStore>
		);
	}

	getEndpointForTitleLanes = (retailTitleLaneType, total) => {
		const {
			entityId: id,
			sectionLevel: lvl,
			customerState: c,
		} = this.props;

		return `/org/${c.orgId}/titles/${retailTitleLaneType}/${lvl}/${id}/${total}`;
	};

	getLaneConfigs = () => {
		const frontlistConfig = {
			key: frontlist,
			icon: 'lightbulb',
			title: <Translate id="coming_soon" />,
			subtitle: (
				<Translate id="preorder_books_and_we_will_have_them_ready" />
			),
		};

		const recentPubConfig = {
			key: recentPub,
			icon: 'lightbulb',
			title: <Translate id="new_and_trending" />,
			subtitle: (
				<Translate id="recently_released_books_that_are_selling_well" />
			),
		};

		const staffReviewsConfig = {
			key: staffReviews,
			icon: 'review-icon',
			title: <Translate id="staff_picks" />,
			subtitle: <Translate id="books_that_staff_members_have_reviewed" />,
		};

		const deeperBacklistConfig = {
			key: deeperBacklist,
			icon: 'review-icon',
			title: <Translate id="still_selling_well" />,
			subtitle: <Translate id="older_books_that_are_in_demand" />,
		};

		return [
			frontlistConfig,
			recentPubConfig,
			staffReviewsConfig,
			deeperBacklistConfig,
		];
	};
}

export default connectToCustomerID(SectionTitleLanesDataStore);
