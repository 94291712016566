import React from 'react';
import Customer360PropTypes from '../../../types';
import ReactHtmlParser from 'react-html-parser';
import LaneContainer from '../../UI/LaneContainer';
import { Translate } from 'react-localize-redux';
import './publisherContent.css';

PublisherContent.propTypes = {
	titleAttributes: Customer360PropTypes.TitleAttributes,
};

export default function PublisherContent({ titleAttributes: attributes }) {
	return (
		<React.Fragment>
			{(attributes.keyNote || attributes.summary) && (
				<LaneContainer
					majorHeader={<Translate id="publisher_description" />}>
					<div className="publisher-content-container">
						{attributes.keyNote && (
							<div style={{ paddingBottom: 10 }}>
								{ReactHtmlParser(attributes.keyNote)}
							</div>
						)}
						{attributes.summary &&
							ReactHtmlParser(attributes.summary)}
					</div>
				</LaneContainer>
			)}
			{attributes.authorBio && (
				<LaneContainer majorHeader={<Translate id="tb_author_bio" />}>
					<div className="publisher-content-container">
						{ReactHtmlParser(attributes.authorBio)}
					</div>
				</LaneContainer>
			)}
		</React.Fragment>
	);
}
