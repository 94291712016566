import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import makeConnectTo from '../../data/makeConnectTo';
import * as Util from '../../../util';
import Convert from '../../../util/Convert';
import { connectToSellerPreferences } from '../../data/BooksellerPreferencesDataStore';
import combineConnectors from '../../data/combineConnectors';
import { connectToSections } from '../../data/SectionsDataStore';

const StaffPicksContext = React.createContext();

export const useStaffPicksDataStore = () => {
	return React.useContext(StaffPicksContext);
};

export const connectToStaffPicks = makeConnectTo(
	StaffPicksContext,
	'staffPicksDataStore',
);

class StaffPicksDataStore extends React.Component {
	static propTypes = {
		sectionsDataStore: PropTypes.object,
		preferencesDataStore: PropTypes.object,
		entityId: PropTypes.string,
		sectionLevel: PropTypes.string,
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
			PropTypes.func,
		]),
	};

	state = {
		isLoading: true,
		staffPicksData: {},
		selectedStaffReviewers: [],
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<StaffPicksContext.Provider value={dataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(dataStore)
					: this.props.children}
			</StaffPicksContext.Provider>
		);
	}

	componentDidMount() {
		if (!this.props.entityId && !this.props.sectionLevel) {
			this.fetchStaffPicks();
		} else {
			this.fetchStaffPicksBySection();
		}
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getSelectedStaffReviewerIds: () => state.selectedStaffReviewers,
		getStaffUser: appUserId => {
			return this.getStaffUser(state, appUserId);
		},
		getTitleReviewsByStaffUser: appUserId => {
			return this.getTitleReviewsByStaffUser(state, appUserId);
		},
		getCountOfReviewsByStaffUser: appUserId => {
			return this.getCountOfReviewsByStaffUser(state, appUserId);
		},
		getStaffPickSections: () => this.getStaffPickSections(state),
		getCountOfTitlesBySection: sectionCode => {
			return this.getCountOfTitlesBySection(state, sectionCode);
		},
		getTitlesBySection: sectionCode => {
			return this.getTitlesBySection(state, sectionCode);
		},
		hasReviews: () => {
			if (Object.keys(state.staffPicksData.reviewsById).length) {
				return Object.keys(state.staffPicksData.reviewsById).length > 0;
			}
			return false;
		},
		getTitlesWithProductInfoBySection: sectionCode =>
			this.getTitlesWithProductInfoBySection(state, sectionCode),
	});

	fetchStaffPicks = () => {
		const { orgId } = this.props.customerState;
		this.request = api.cancelableGet(
			`/org/${orgId}/titles/staffReviewed/all`,
		);
		this.request.promise.then(this.cacheApiResponse);
	};

	fetchStaffPicksBySection = async () => {
		const { orgId } = this.props.customerState;
		const { sectionLevel, entityId } = this.props;
		this.request = api.cancelableGet(
			`/org/${orgId}/titles/staffReviewed/${sectionLevel}/${entityId}`,
		);
		const response = await this.request.promise;
		this.cacheApiResponse(response);
	};

	cacheApiResponse = apiResponse => {
		const staffPicksData = this.getStaffPicksData(apiResponse);
		this.setState({ staffPicksData });
		this.getSelectedStaffReviewers();
	};

	getStaffPicksData = apiResponse => {
		const r = apiResponse;
		return {
			usersById: r.usersById,
			userAvatarsByAppUserId: r.userAvatarsByAppUserId,
			reviewsById: Util.groupBy(r.reviews, 'appUserId'),
			titlesBySectionCode: this.getSkusBySectionCode(
				r.skuPosData,
				r.productsBySku,
				r.productImageUrisBySku,
			),
			productImageUrisBySku: r.productImageUrisBySku,
			productsBySku: r.productsBySku,
		};
	};

	getStaffUser = (state, appUserId) => {
		const { usersById, userAvatarsByAppUserId } = state.staffPicksData;
		const id = Convert.toString(appUserId);
		return {
			...usersById[id],
			appUserId,
			avatarUri: userAvatarsByAppUserId[id],
		};
	};

	getCountOfReviewsByStaffUser = (state, appUserId) => {
		const { reviewsById } = state.staffPicksData;
		const reviewsByUser = reviewsById[Convert.toString(appUserId)];
		const uniqueReviewsByUser = this.getUniqueReviewsByStaffUser(
			reviewsByUser,
		).filter(r => r.review);
		return uniqueReviewsByUser.length;
	};

	getSelectedStaffReviewers = () => {
		const { preferencesDataStore } = this.props;
		if (
			preferencesDataStore &&
			!preferencesDataStore.isLoading() &&
			this.state.staffPicksData.usersById
		) {
			const staffReviewerIds = preferencesDataStore.getStaffReviewerIds();
			const staffKeys = Object.keys(this.state.staffPicksData.usersById);
			const selectedStaffReviewers =
				Array.isArray(staffReviewerIds) && staffReviewerIds.length
					? staffKeys.filter(key => staffReviewerIds.includes(key))
					: staffKeys;
			this.setState({ selectedStaffReviewers });
		}
		this.setState({ isLoading: false });
	};

	getTitleReviewsByStaffUser = (state, appUserId) => {
		const {
			productsBySku,
			productImageUrisBySku,
			reviewsById,
		} = state.staffPicksData;

		const reviewsByUser = reviewsById[Convert.toString(appUserId)];
		const uniqueReviewsByUser = this.getUniqueReviewsByStaffUser(
			reviewsByUser,
		);

		return uniqueReviewsByUser.map(review => ({
			...review,
			product: {
				...productsBySku[review.sku],
				imageUri: productImageUrisBySku[review.sku],
			},
		}));
	};

	getUniqueReviewsByStaffUser = reviews => {
		return Array.isArray(reviews)
			? reviews.reduce((acc, review) => {
					!acc
						.map(r => r.assessmentId)
						.includes(review.assessmentId) && acc.push(review);
					return acc;
			  }, [])
			: [];
	};

	getSkusBySectionCode(skuPosData, productsBySku, productImageUrisBySku) {
		var rows = [];
		Object.keys(skuPosData).forEach((sku, index) => {
			var sectionCodes = [];
			skuPosData[sku].forEach(posData => {
				if (sectionCodes.indexOf(posData.sectionCode) === -1) {
					rows.push({
						sku: sku,
						product: {
							...productsBySku[sku],
							imageUri: productImageUrisBySku[sku],
						},
						sectionCode: posData.sectionCode,
					});
					sectionCodes.push(posData.sectionCode);
				}
			});
		});
		return Util.groupBy(rows, 'sectionCode');
	}

	getCountOfTitlesBySection = (state, sectionCode) => {
		const sectionTitles = this.getTitlesBySection(state, sectionCode);
		return sectionTitles.length;
	};

	getTitlesBySection = (state, sectionCode) => {
		const sectionTitles = this.getStaffPickSections(state).find(
			section => section.sectionCode === sectionCode,
		);
		return sectionTitles ? sectionTitles.titles : [];
	};

	getTitlesWithProductInfoBySection = (state, sectionCode) => {
		const sectionTitles = this.getTitlesBySection(state, sectionCode);
		const titlesForSelectedReviews = this.getTitlesBySkuForSelectedReviews(
			state,
		);
		return sectionTitles.map(title => {
			const matchingTitle = titlesForSelectedReviews[title.sku];
			return { ...matchingTitle, product: title.product };
		});
	};

	getStaffPickSections = state => {
		const { sectionsDataStore } = this.props;
		const { titlesBySectionCode } = state.staffPicksData;
		const sectionCodesForSectionsWithSelectedReviews = this.getSectionCodesForSectionsWithSelectedReviews(
			state,
		);
		const skusForSelectedReviews = Object.keys(
			this.getTitlesBySkuForSelectedReviews(state),
		);

		return sectionCodesForSectionsWithSelectedReviews
			.reduce((acc, sectionCode) => {
				const section = sectionsDataStore.getSectionFromSectionCode(
					sectionCode,
				);
				if (section) {
					const titles = titlesBySectionCode[sectionCode].filter(
						title => skusForSelectedReviews.includes(title.sku),
					);
					acc.push({ ...section, titles });
				}
				return acc;
			}, [])
			.sort((a, b) => b.titles.length - a.titles.length);
	};

	getSectionCodesForSectionsWithSelectedReviews = state => {
		const { titlesBySectionCode } = state.staffPicksData;
		const skusForSelectedReviews = Object.keys(
			this.getTitlesBySkuForSelectedReviews(state),
		);
		return Object.keys(titlesBySectionCode).filter(key => {
			const sectionTitles = titlesBySectionCode[key].map(
				title => title.sku,
			);
			const titlesWithSelectedReviewers = sectionTitles.filter(sku =>
				skusForSelectedReviews.includes(sku),
			);
			return titlesWithSelectedReviewers.length;
		});
	};

	getTitlesBySkuForSelectedReviews = state => {
		const { reviewsById } = state.staffPicksData;
		const { selectedStaffReviewers } = state;
		return selectedStaffReviewers.reduce((acc, id) => {
			const reviewTitles = Array.isArray(reviewsById[id])
				? reviewsById[id]
				: [];
			reviewTitles.forEach(title => {
				if (title.review && !acc[title.sku]) {
					acc[title.sku] = title;
				}
			});
			return acc;
		}, {});
	};
}

const connect = combineConnectors([
	connectToSellerPreferences,
	connectToCustomerID,
	connectToSections,
]);

export default connect(StaffPicksDataStore);
