import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import { Card, Button } from 'antd';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import { Translate } from 'react-localize-redux';
import FavoriteTitlesDrawer from '../../CustomerFavorites/UI/FavoriteTitlesDrawer';
import { FavoriteType } from '../../CustomerFavorites/FavoriteType';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import TitleAggregateParser from '../../../title/TitleAggregateParser';
import api from '../../../api';

class AuthorCard extends React.Component {
	static propTypes = {
		children: PropTypes.element,
		author: Customer360PropTypes.Author,
		customerState: PropTypes.object,
	};

	state = {
		authorTitles: [],
	};

	componentDidMount() {
		this.fetchAuthorTitles().then(authorTitles =>
			this.setState({
				authorTitles,
			}),
		);
	}

	fetchAuthorTitles = () => {
		const { author, customerState: orgId } = this.props;
		this.request = api.cancelableGet(
			`/org/${orgId}/contributor/${author.id}/titles`,
		);
		return this.request.promise.then(titleAggregate =>
			this.parseTitlesByAuthor(titleAggregate),
		);
	};

	parseTitlesByAuthor = titleAggregate => {
		const parser = new TitleAggregateParser(titleAggregate);
		return parser.buildTitles();
	};

	componentWillUnmount() {
		api.revoke(this.request);
	}

	render() {
		const { author, children } = this.props;
		const { authorTitles } = this.state;
		author.titles = authorTitles;
		return (
			<Card size="small">
				<div className="item-layout">
					<div className="author-avatar-wrapper">
						<UserAvatar
							displayName={author.displayName}
							avatarUri={author.imageUri}
							avatarSizeClass={UserAvatar.medium}
							userInitials={author.initials}
						/>
					</div>
					{Array.isArray(authorTitles) && (
						<AuthorContent author={author}>
							{children}
						</AuthorContent>
					)}
				</div>
			</Card>
		);
	}
}

export default connectToCustomerID(AuthorCard);

function AuthorContent({ author, children }) {
	return (
		<div className="author-content-wrapper">
			<FavoriteTitlesDrawer
				entity={author}
				typeOfFavorite={FavoriteType.author}>
				{drawer => (
					<div className="author-name dotDot clickable acc-font bold">
						<span onClick={drawer.open}>{author.displayName}</span>
					</div>
				)}
			</FavoriteTitlesDrawer>
			<WorksCount authorTitles={author.titles} />
			<div style={{ marginTop: 5 }}>
				{children && (
					<div className="flex-align-center">
						<ViewAuthorTitlesButton author={author} />
						{children}
					</div>
				)}
			</div>
		</div>
	);
}

function ViewAuthorTitlesButton({ author }) {
	return (
		<FavoriteTitlesDrawer
			entity={author}
			typeOfFavorite={FavoriteType.author}>
			{drawer => (
				<Button size="small" type="default" onClick={drawer.open}>
					<Translate id="view" />
				</Button>
			)}
		</FavoriteTitlesDrawer>
	);
}

function WorksCount({ authorTitles }) {
	const titleCount = authorTitles.length;
	return (
		<div style={{ opacity: 0.9, fontSize: 13, marginTop: 2 }}>
			<em>
				{titleCount.length === 1 ? (
					<Translate id="browse_one_available_work" />
				) : (
					<Translate
						id="browse_x_available_works"
						data={{ number: titleCount }}
					/>
				)}
			</em>
		</div>
	);
}
