import React from 'react';
import PropTypes from 'prop-types';
import LoadingPage from '../UI/LoadingPage';
import { useBooksellerLogoDataStore } from './BooksellerLogoStore';
import { useBooksellerContentDataStore } from './BooksellerContentDataStore';
import { useCustomerSummaryDataStore } from './CustomerSummaryDataStore';
import { useSectionsDataStore } from './SectionsDataStore';
import { useBooksellerPreferencesDataStore } from './BooksellerPreferencesDataStore';
import { useOrganizationDataStore } from './OrganizationDataStore';
import { useTransactionsDataStore } from './TransactionsDataStore';
import { useBagDataStore } from '../Bag/data/BagDataStore';
import { useRatingsAndReviewsDataStore } from './RatingsAndReviewsDataStore';

GlobalDataStoresLoader.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

export default function GlobalDataStoresLoader({ children }) {
	const logoDataStore = useBooksellerLogoDataStore();
	const contentsDataStore = useBooksellerContentDataStore();
	const customerSummaryDataStore = useCustomerSummaryDataStore();

	const sectionsDataStore = useSectionsDataStore();
	const organizationDataStore = useOrganizationDataStore();
	const preferencesDataStore = useBooksellerPreferencesDataStore();

	const bagDataStore = useBagDataStore();
	const transactionsDataStore = useTransactionsDataStore();
	const ratingsAndReviewsDataStore = useRatingsAndReviewsDataStore();

	const dataStores = [
		bagDataStore,
		logoDataStore,
		contentsDataStore,
		sectionsDataStore,
		organizationDataStore,
		preferencesDataStore,
		transactionsDataStore,
		customerSummaryDataStore,
		ratingsAndReviewsDataStore,
	];

	const dataStoresDidLoad = () => {
		for (let i = 0; i < dataStores.length; i++) {
			if (dataStores[i].isLoading()) {
				return false;
			}
		}
		return true;
	};

	if (dataStoresDidLoad()) {
		return children;
	}
	return <LoadingPage />;
}
