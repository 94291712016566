import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../hoc/withIsMobile';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';

function PageTitle({ isMobile, content, styleOverrides }) {
	const { getGradient } = useBooksellerPreferencesDataStore();
	return (
		<div
			style={{
				backgroundImage: getGradient(),
				fontSize: isMobile() ? '22px' : '24px',
				padding: '10px 0 10px 0',
				fontWeight: 'bold',
				...styleOverrides,
			}}>
			<div className="page-container">{content}</div>
		</div>
	);
}

export default withIsMobile(PageTitle);

PageTitle.propTypes = {
	isMobile: PropTypes.func,
	styleOverrides: PropTypes.object,
};

PageTitle.defaultProps = {
	styleOverrides: {},
};
