import React from 'react';
import PropTypes from 'prop-types';
import { useCustomerSummaryDataStore } from '../../data/CustomerSummaryDataStore';
import AuthAnchor from '../Auth/AuthAnchor';
import CustomerAvatar from './CustomerAvatar';
import withIsMobile from '../../hoc/withIsMobile';
import { useBooksellerPreferencesDataStore } from '../../data/BooksellerPreferencesDataStore';

CustomerHeader.propTypes = {
	isMobile: PropTypes.func,
};

function CustomerHeader({ isMobile }) {
	const { getFullName, getEmailAddress } = useCustomerSummaryDataStore();
	const { getGradient } = useBooksellerPreferencesDataStore();

	return (
		<div
			style={{
				alignItems: 'center',
				backgroundImage: isMobile() ? getGradient() : 'none',
				display: 'flex',
				flexDirection: isMobile() ? 'row' : 'column',
				justifyContent: 'center',
				paddingTop: isMobile() ? '0' : '20px',
			}}>
			<CustomerAvatar size={isMobile() ? 80 : 90} />
			<div
				style={{
					alignItems: 'space-between',
					display: 'flex',
					flexDirection: 'column',
					height: isMobile() ? '120px' : '100px',
					justifyContent: 'center',
					marginLeft: isMobile() ? '25px' : 0,
					textAlign: isMobile() ? 'initial' : 'center',
				}}>
				<div style={{ fontSize: '20px' }}>{getFullName()}</div>
				<div style={{ paddingBottom: '10px' }}>{getEmailAddress()}</div>
				{isMobile() && <AuthAnchor />}
			</div>
		</div>
	);
}

export default withIsMobile(CustomerHeader);
