import React from 'react';
import PropTypes from 'prop-types';
import LaneContainer, { SeeAllAnchor } from '../../UI/LaneContainer';
import SearchResultsTitleScroller from './SearchResultsTitleScroller';
import { Translate } from 'react-localize-redux';
import AllAvailableTitles from '../../TitleLanes/AllAvailableTitles';
import StaffPicksDataStore from '../../StaffPicks/data/StaffPicksDataStore';

TitleLanesByStatus.propTypes = {
	children: PropTypes.element,
	titlesByStatus: PropTypes.shape({
		onShelf: PropTypes.arrayOf(PropTypes.object),
		comingSoon: PropTypes.arrayOf(PropTypes.object),
		availableForOrder: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default function TitleLanesByStatus({ titlesByStatus, children }) {
	const titleStatusHeaders = {
		onShelf: {
			majorHeader: <Translate id="on_our_shelves" />,
			subHeader: (
				<Translate id="reserve_a_copy_for_pick_up_or_delivery" />
			),
		},
		comingSoon: {
			majorHeader: <Translate id="coming_soon" />,
			subHeader: <Translate id="preorder_these_hot_titles_today" />,
		},
		availableForOrder: {
			majorHeader: <Translate id="available_for_order" />,
			subHeader: <Translate id="get_it_shipped_to_you_in_a_few_days" />,
		},
	};

	const renderAllTitles = (titles, headers) => {
		return (
			<StaffPicksDataStore>
				<AllAvailableTitles
					header={headers.majorHeader}
					subtitle={headers.subHeader}
					titles={titles}
				/>
			</StaffPicksDataStore>
		);
	};

	return (
		<div style={{ marginBottom: '10rem' }}>
			{titlesByStatus &&
				Object.keys(titlesByStatus).map((status, idx) => {
					const headers = titleStatusHeaders[status];
					const titles = titlesByStatus[status];
					return (
						titles.length > 0 && (
							<LaneContainer
								key={idx}
								majorHeader={headers.majorHeader}
								subHeader={headers.subHeader}>
								<SearchResultsTitleScroller titles={titles} />
								{titles.length > 3 && (
									<SeeAllAnchor
										renderAllEntities={() =>
											renderAllTitles(titles, headers)
										}
									/>
								)}
							</LaneContainer>
						)
					);
				})}
			{children}
		</div>
	);
}
