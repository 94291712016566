import React from 'react';
import ReactDOM from 'react-dom';
import {
	useBooksellerAppSettings,
	appSettingsByKey,
} from '../data/BooksellerPreferencesDataStore';
import { useCustomerID } from '../data/CustomerIDStore';
import { useCustomerSummaryDataStore } from '../data/CustomerSummaryDataStore';
import { useBagDataStore } from '../Bag/data/BagDataStore';
import useBagDataTotalAmount from '../Bag/data/useBagDataTotalAmount';
import useLocalizedCurrencyCode from '../Localization/useLocalizedCurrencyCode';
import { useOrganizationDataStore } from '../data/OrganizationDataStore';
import useTaxes from '../hooks/useTaxes';
import usePayPalOrders from './data/usePayPalOrders';
import useScript from 'react-script-hook';
import LoadingPage from '../UI/LoadingPage';
import { Alert } from 'antd';

export default function PayPalOrderButton({
	isShipping,
	deliveryChoice,
	orgSalesTax,
	orgUseTax,
	getOrderTotal,
	getShippingPrice,
	getTax,
	setError,
	setIsVisible,
}) {
	const bagDataStore = useBagDataStore();
	const bagDataTotalAmount = useBagDataTotalAmount();

	const titles = bagDataStore.getTitles();
	const { amountsBySku, getFixedTotal } = bagDataTotalAmount;

	const booksellerAppSettings = useBooksellerAppSettings();
	const { emailAddress } = useCustomerID();
	const { getFirstName, getLastName } = useCustomerSummaryDataStore();

	const { getCultureAndCurrencyCodes } = useLocalizedCurrencyCode();
	const { currency } = getCultureAndCurrencyCodes();

	const {
		state: orgDataStoreState,
		getOrgName,
		getOrgCountry,
	} = useOrganizationDataStore();
	const { state: stateAbbreviation } = orgDataStoreState.organization;

	const { getLocationTaxRate } = useTaxes();
	const { createPayPalOrders } = usePayPalOrders(deliveryChoice);

	const paypalApiKey = process.env.REACT_APP_PAYPAL_API_KEY;
	const [isLoading, isError] = useScript({
		src: `https://www.paypal.com/sdk/js?client-id=${paypalApiKey}&currency=${currency}`,
	});
	if (isLoading) return <LoadingPage />;
	if (isError)
		return (
			<Alert
				message="There was an error loading the order"
				type="error"
			/>
		);

	let PayPalButton = window.paypal.Buttons.driver('react', {
		React,
		ReactDOM,
		currency_code: currency,
	});

	const onApprove = (data, actions) => {
		return actions.order.capture().then(async details => {
			const { status } = details;
			if (status !== 'COMPLETED' && status !== 'APPROVED') {
				setError({ exists: true, details });
			} else {
				await createPayPalOrders(details);
				await bagDataStore.refresh();
			}
			setIsVisible(true);
		});
	};

	const getPaypalOrder = function() {
		return {
			purchase_units: getPurchaseUnits(),
			application_context: {
				brand_name: getOrgName(),
				shipping_preference: isShipping()
					? 'GET_FROM_FILE'
					: 'NO_SHIPPING',
			},
		};
	};

	const getPurchaseUnits = function() {
		const items = getPurchaseUnitsItems(orgSalesTax);

		const breakdown = {
			item_total: {
				currency_code: currency,
				value: getFixedTotal(),
			},
		};

		const shippingPrice = getShippingPrice();

		if (isShipping()) {
			breakdown.shipping = {
				currency_code: currency,
				value: shippingPrice,
			};
		}

		return [
			{
				amount: getAmount(orgSalesTax),
				items,
				payee: getPayee(),
				payer: getPayer(),
			},
		];
	};

	const getAmount = taxRate => {
		const itemTotal = getFixedTotal();

		const breakdown = {
			item_total: {
				currency_code: currency,
				value: itemTotal,
			},
		};

		const shippingPrice = getShippingPrice();
		if (isShipping()) {
			breakdown.shipping = {
				currency_code: currency,
				value: shippingPrice,
			};
		}

		if (taxRate && taxRate.totalRate) {
			breakdown.tax_total = {
				currency_code: currency,
				value: getTax(),
			};
		}
		return {
			value: getOrderTotal(taxRate),
			breakdown,
			currency_code: currency,
		};
	};

	const getPayee = function() {
		// If we can't find an email address an empty string prevent paypal from loading
		// and sending money to us
		const paypalEmailAddress =
			booksellerAppSettings[appSettingsByKey.paypalEmailAddress] || '';
		return {
			email_address: paypalEmailAddress,
		};
	};

	const getPayer = function() {
		return {
			name: {
				given_name: getFirstName(),
				surname: getLastName(),
			},
			email_address: emailAddress,
		};
	};

	const getPurchaseUnitsItems = () => {
		return titles.map(t => {
			const titleAmount = amountsBySku[t.sku][0];
			const item = {
				unit_amount: {
					currency_code: currency,
					value: titleAmount,
				},
				sku: t.sku,
				name: t.name,
				quantity: t.quantity,
				category: 'PHYSICAL_GOODS',
			};

			return item;
		});
	};

	const doUseStoreSalesTaxRates = shippingAddress =>
		orgSalesTax.originDestination === 'O' &&
		shippingAddress.state === stateAbbreviation;

	const getNewTaxRate = (shippingAddress, destinationTaxRates) => {
		const { salesTax, useTax } = destinationTaxRates;
		if (shippingAddress.state === stateAbbreviation) {
			return salesTax;
		} else {
			return useTax.originDestination === 'O' ? orgUseTax : useTax;
		}
	};

	const getShippingAddressCountryCode = ({ country_code }) => {
		if (country_code === 'GB') {
			return 'UK';
		}

		return country_code;
	};

	return (
		<div className="pay-pal-wrapper">
			<span>
				<PayPalButton
					createOrder={function(data, actions) {
						// This function sets up the details of the transaction, including the amount and line item details.
						const order = getPaypalOrder();
						return actions.order.create(order);
					}}
					onApprove={onApprove}
					onShippingChange={async function(data, actions) {
						if (
							getShippingAddressCountryCode(
								data.shipping_address,
							) !== getOrgCountry()
						) {
							return actions.reject();
						}

						// Only support tax calculations for US at the moment
						if (data.shipping_address.country_code !== 'US') {
							return actions.resolve();
						}

						if (doUseStoreSalesTaxRates(data.shipping_address)) {
							return actions.resolve();
						}

						const {
							postal_code: postalCode,
							city,
							state: stateProvince,
						} = data.shipping_address;

						const locationTaxRate = await getLocationTaxRate({
							postalCode,
							city,
							stateProvince,
						});

						if (!locationTaxRate) {
							return actions.order.patch([
								{
									op: 'replace',
									path:
										"/purchase_units/@reference_id=='default'/amount",
									value: getAmount({
										salesTax: 0,
										useTax: 0,
									}),
								},
							]);
						}

						const taxRate = getNewTaxRate(
							data.shipping_address,
							locationTaxRate,
						);

						if (taxRate && taxRate.totalRate > 0) {
							return actions.order.patch([
								{
									op: 'replace',
									path:
										"/purchase_units/@reference_id=='default'/amount",
									value: getAmount(taxRate),
								},
							]);
						} else {
							return actions.resolve();
						}
					}}
				/>
			</span>
		</div>
	);
}
