import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import BagBuyNowButton from '../../Bag/UI/BagBuyNowButton';
import BuyFromThirdPartyButton from './BuyFromThirdPartyButton';
import BagControllerButton from '../../Bag/UI/BagControllerButton';
import { useBooksellerAppSettings } from '../../data/BooksellerPreferencesDataStore';
import withIsMobile from '../../hoc/withIsMobile';

BuyTitleButton.propTypes = {
	title: Customer360PropTypes.Title,
	label: PropTypes.object,
	block: PropTypes.bool,
	doRenderBagControllerButton: PropTypes.bool,
	buyFromThirdPartyButtonWidth: PropTypes.number,
	isMobile: PropTypes.func,
};

BuyTitleButton.defaultProps = {
	block: true,
	doRenderBagControllerButton: true,
};

function BuyTitleButton({
	title,
	label,
	block,
	doRenderBagControllerButton,
	buyFromThirdPartyButtonWidth,
	isMobile,
}) {
	const { doIncludeCommerce } = useBooksellerAppSettings();
	const { name, sku } = title;

	return doIncludeCommerce ? (
		<>
			{((isMobile() && !label) || !doRenderBagControllerButton) && (
				<BagBuyNowButton
					sku={sku}
					titleName={name}
					label={label}
					block={block}
				/>
			)}
			{doRenderBagControllerButton && (
				<BagControllerButton
					sku={sku}
					titleName={name}
					label={label}
					block={block}
				/>
			)}
		</>
	) : (
		<BuyFromThirdPartyButton
			title={title}
			label={label}
			block={block}
			width={buyFromThirdPartyButtonWidth}
		/>
	);
}

export default withIsMobile(BuyTitleButton);
