import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { connectToSellerContent } from '../../data/BooksellerContentDataStore';
import { Translate } from 'react-localize-redux';
import './buyFromThirdPartyButton.less';

class BuyFromThirdPartyButton extends React.Component {
	static propTypes = {
		// passed in
		title: PropTypes.shape({
			sku: PropTypes.string,
			pubDate: PropTypes.string,
			eCommerceUri: PropTypes.string,
		}),
		size: PropTypes.string,
		type: PropTypes.string,
		label: PropTypes.object,
		block: PropTypes.bool,
		width: PropTypes.number,
		// mapped to props
		booksellerContentStore: PropTypes.object,
	};

	static defaultProps = {
		label: <Translate id="buy" />,
		type: 'default',
		size: 'default',
		block: false,
	};

	render() {
		const href = this.getHref();
		const { label, type, size, block, width } = this.props;

		return (
			<div
				style={width ? { width } : null}
				className="buy-from-third-party-button">
				<Tooltip
					title={
						<Translate id="view_more_details_and_buy_this_title" />
					}>
					<Button
						block={block}
						href={href}
						target="_blank"
						type={type}
						className="buy-now-button"
						size={size}>
						<div className="buy-from-third-party-label-container">
							<div className="icon-add-circle" />
							<div className="buy-from-third-party-label">
								{label}
							</div>
						</div>
					</Button>
				</Tooltip>
			</div>
		);
	}

	getHref() {
		const { title, booksellerContentStore: content } = this.props;

		if (title.eCommerceUri) {
			return title.eCommerceUri;
		}

		const { ean, skuDisplay } = title;
		const isbn13 = ean ? ean : skuDisplay;

		if (!content.state.storeInfo.links.titles) return '';
		return content.state.storeInfo.links.titles
			.replace('{sku}', isbn13)
			.trim();
	}

	getBuyButtonLabel() {
		const { title: t } = this.props;
		return this.isFrontList(t.pubDate) ? (
			<Translate id="preorder" />
		) : (
			this.props.label
		);
	}

	isFrontList(pubDate) {
		if (!pubDate) return false;
		return new Date(pubDate) > new Date();
	}
}

export default connectToSellerContent(BuyFromThirdPartyButton);
