import React from 'react';
import useBagDataTotalAmount from '../Bag/data/useBagDataTotalAmount';
import { Row, Col } from 'antd';
import LocalizedPrice from '../Localization/LocalizedPrice';
import { Translate } from 'react-localize-redux';

export default function OrderTotalBreakdown(props) {
	const bagDataTotalAmount = useBagDataTotalAmount();
	const { getTotal } = bagDataTotalAmount;

	return (
		<div className="order-total-wrapper">
			<hr></hr>
			<Row type="flex" justify="end">
				<Col>
					<span className="order-total">
						<Translate id="subtotal" />:
					</span>
				</Col>
				<Col span={2}>
					<Row type="flex">
						<Col push={2}>
							<span className="bold">
								<LocalizedPrice price={getTotal()} />
							</span>
						</Col>
					</Row>
				</Col>
			</Row>
			{props.isShipping() && (
				<Row type="flex" justify="end">
					<Col>
						<span className="order-total">
							<Translate id="shipping" />:
						</span>
					</Col>
					<Col span={2}>
						<Row type="flex">
							<Col push={2}>
								<span className="bold">
									<LocalizedPrice
										price={props.getShippingPrice()}
									/>
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
			{props.doShowTax && (
				<Row type="flex" justify="end">
					<Col>
						<span className="order-total">
							<Translate id="estimated_tax" />:
						</span>
					</Col>
					<Col span={2}>
						<Row type="flex">
							<Col push={2}>
								<span className="bold">
									<LocalizedPrice price={props.getTax()} />
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
			<Row type="flex" justify="end">
				<Col>
					<span className="order-total">
						<Translate id="estimated_total" />:
					</span>
				</Col>
				<Col span={2}>
					<Row type="flex">
						<Col push={2}>
							<span className="price">
								<LocalizedPrice price={props.getOrderTotal()} />
							</span>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
