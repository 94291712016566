import PropTypes from 'prop-types';
import React from 'react';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import withIsMobile from '../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import StoreLocationInfo from './StoreLocationInfo';
import './organizationInfo.less';

OrganizationInfo.propTypes = {
	storeLocations: PropTypes.array,
	isMobile: PropTypes.func,
};

function OrganizationInfo({ storeLocations, isMobile }) {
	const appSettings = useBooksellerPreferencesDataStore();
	const { textColor, iconColor } = appSettings;
	return (
		<div
			className="organization-info-container"
			style={{ color: isMobile() ? textColor : iconColor }}>
			<div className="page-container">
				<div className="location-header">
					<Translate
						id={`our_location${
							storeLocations.length > 1 ? 's' : ''
						}`}
					/>
				</div>
				<div className="organization-info">
					{storeLocations.map((location, i) => (
						<StoreLocationInfo key={i} location={location} />
					))}
				</div>
			</div>
		</div>
	);
}

export default withIsMobile(OrganizationInfo);
