import React from 'react';
import PropTypes from 'prop-types';
import ReviewDetail from './ReviewDetail';
import { connectToRatingsAndReviews } from '../../data/RatingsAndReviewsDataStore';
import { Translate } from 'react-localize-redux';

class MyReviews extends React.Component {
	static propTypes = {
		reviews: PropTypes.object,
	};

	render() {
		const { reviews } = this.props;
		const reviewSkus = reviews.productsBySku
			? Object.keys(reviews.productsBySku)
			: [];
		return (
			<div style={{ backgroundColor: 'white', paddingBottom: '20%' }}>
				<div
					style={{
						padding: '20px 0px 0px 20px',
						fontSize: '20px',
					}}>
					<Translate id="my_reviews" /> ({reviewSkus.length || 0})
				</div>
				{reviewSkus.map((sku, index) => (
					<ReviewDetail
						key={index}
						rating={reviews.customerRatings.find(
							rating => rating.entityId === sku,
						)}
						review={reviews.customerReviews.find(
							review => review.entityId === sku,
						)}
						posData={reviews.skuPosData[sku]}
						uri={reviews.productImageUrisBySku[sku]}
					/>
				))}
			</div>
		);
	}
}

const withUserReviews = Component => {
	const mapToProps = ratingsAndReviewsDataStore => {
		const reviews = ratingsAndReviewsDataStore.state.reviews;
		return {
			reviews,
		};
	};
	return connectToRatingsAndReviews(Component, mapToProps);
};

export default withUserReviews(MyReviews);
