import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import { connectToLogo } from '../../data/BooksellerLogoStore';
import { connectToCustomer } from '../../data/CustomerSummaryDataStore';
import PageTitle from '../../UI/PageTitle';
import BooksellerSplashImage from '../../UI/BooksellerSplashImage';
import combineConnectors from '../../data/combineConnectors';
import { Translate } from 'react-localize-redux';

const propTypes = {
	isMobile: PropTypes.func,
	logoStore: PropTypes.object,
	customerDataStore: PropTypes.object,
};

function WishlistCollectionPageHeader(props) {
	const getPageTitle = () => {
		const loading = props.customerDataStore.isLoading();
		const refFullName = props.customerDataStore.getFullName();

		const refLastName = props.customerDataStore.getLastName();
		const refFirstName = props.customerDataStore.getFirstName();

		const referralName = refLastName ? refFullName : refFirstName;
		return loading || !referralName ? (
			<Translate id="wishlist" />
		) : (
			<Translate
				id="xs_wishlist"
				options={{ renderInnerHtml: true }}
				data={{ name: referralName }}
			/>
		);
	};

	const isMobile = props.isMobile();
	const hasSplash = props.logoStore.hasSplashImage();

	return (
		<div className="wishlist-collection-page-header">
			{hasSplash && (
				<div style={{ marginBottom: '1.5rem' }}>
					{isMobile && (
						<PageTitle
							content={getPageTitle()}
							styleOverrides={{ textAlign: 'center' }}
						/>
					)}
					<BooksellerSplashImage
						header={isMobile ? null : getPageTitle()}
					/>
				</div>
			)}
			{!hasSplash && (
				<PageTitle
					content={getPageTitle()}
					styleOverrides={{ marginBottom: '1.5rem' }}
				/>
			)}
		</div>
	);
}

const connectors = [connectToLogo, connectToCustomer];

export default combineConnectors(connectors)(
	withIsMobile(WishlistCollectionPageHeader, 550),
);

WishlistCollectionPageHeader.propTypes = propTypes;
