import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Spin, notification } from 'antd';
import UpdateConfirmation from '../UI/UpdateConfirmation';
import { connectToOrg } from '../../data/OrganizationDataStore';
import { connectToCustomer } from '../../data/CustomerSummaryDataStore';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import { Translate } from 'react-localize-redux';

class UnsubscribeService extends React.Component {
	static propTypes = {
		orgName: PropTypes.string,
		customerDataStore: PropTypes.object,
		previewMode: PropTypes.object,
	};

	state = {
		isLoading: false,
		didUpdate: false,
		isUnsubscribeModalOpen: false,
		unsubscribeReason: '',
	};

	render() {
		return (
			<Translate>
				{({ translate }) => {
					return (
						<React.Fragment>
							<p>{this.getMessagePrompt()}</p>
							<div style={{ display: 'flex' }}>
								<Button
									type="secondary"
									size="small"
									onClick={this.getOnClick(translate)}
									loading={this.state.isLoading}>
									{this.getButtonLabel()}
								</Button>
								{this.state.didUpdate && <UpdateConfirmation />}
							</div>
							<Modal
								closable={false}
								title={
									<Translate id="are_you_sure_you_want_to_unsubscribe" />
								}
								visible={this.state.isUnsubscribeModalOpen}
								onCancel={this.closeModal}
								onOk={this.saveUnsubscribe(translate)}
								okText={<Translate id="yes" />}>
								<Spin spinning={this.state.isLoading}>
									<Input.TextArea
										value={this.state.unsubscribeReason}
										onChange={this.updateReason}
										placeholder={this.getPlaceholder(
											translate,
										)}
									/>
								</Spin>
							</Modal>
						</React.Fragment>
					);
				}}
			</Translate>
		);
	}

	getMessagePrompt() {
		if (this.isUnsubscribed()) {
			return (
				<Translate id="you_have_been_unsubscribed_from_our_recommendation_service" />
			);
		}
		return (
			<Translate
				id="you_have_been_part_of_a_new_service"
				data={{ orgName: this.props.orgName }}
			/>
		);
	}

	updateReason = evt => {
		this.setState({
			unsubscribeReason: evt.currentTarget.value,
		});
	};

	getOnClick = translate => {
		if (this.isUnsubscribed()) {
			return this.saveReSubscribe(translate);
		}
		return this.showUnsubscribeModal;
	};

	saveReSubscribe = translate => async () => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		this.setState({ isLoading: true });

		await this.props.customerDataStore.saveReSubscribe().then(() => {
			notification.success({
				message: translate('hallelujah_welcome_back'),
			});
		});
		this.resetUIState();
	};

	closeModal = () => {
		this.setState({ isUnsubscribeModalOpen: false });
	};

	showUnsubscribeModal = () => {
		this.setState({ isUnsubscribeModalOpen: true });
	};

	saveUnsubscribe = translate => async () => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		const { unsubscribeReason: reason } = this.state;
		this.setState({ isLoading: true });

		await this.props.customerDataStore.saveUnsubscribe(reason).then(() => {
			notification.success({
				message: translate('preference_saved_thank_you'),
			});
		});
		this.resetUIState();
	};

	resetUIState = () => {
		this.setState({
			isLoading: false,
			didUpdate: true,
			isUnsubscribeModalOpen: false,
		});
	};

	getButtonLabel() {
		if (this.isUnsubscribed()) {
			return <Translate id="undo_sign_me_up_again" />;
		}
		return <Translate id="unsubscribe_now" />;
	}

	isUnsubscribed() {
		const { customerDataStore } = this.props;
		return customerDataStore.isUnsubscribedFromRecommendationEmails();
	}

	getPlaceholder = translate => {
		return translate('could_you_let_us_know_why');
	};
}

const mapOrgToProps = orgStore => ({
	orgName: orgStore.getOrgName(),
});

const connectors = [
	connectToCustomer,
	connectToPreviewMode,
	Component => connectToOrg(Component, mapOrgToProps),
];

export default combineConnectors(connectors)(UnsubscribeService);
