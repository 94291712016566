import React from 'react';
import { PropTypes } from 'prop-types';
import TitleJacketImage from '../../Title/UI/TitleJacketImage';
import useBagDataTotalAmount from '../data/useBagDataTotalAmount';
import LocalizedPrice from '../../Localization/LocalizedPrice';
import { Row, Col, Descriptions } from 'antd';
import TitleDetailDrawer from '../../Title/TitleDetailDrawer';
import withIsMobile from '../../hoc/withIsMobile';
import RemoveFromBagAnchor from './RemoveFromBagAnchor';
import { getAuthorDisplayNameAndInitials } from '../../../author/author.utils';
import BagTitleFormat from './BagTitleFormat';
import BagItemQuantitySelect from './BagItemQuantitySelect';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import { Translate } from 'react-localize-redux';
import './bagOrderTitleSummary.less';

BagOrderTitleSummary.propTypes = {
	title: PropTypes.object,
	isMobile: PropTypes.func,
};

function BagOrderTitleSummary({ title, isMobile }) {
	const { getPrice } = useBagDataTotalAmount();
	const price = getPrice(title.sku);

	const renderPrice = () => {
		return (
			<span className="price">
				{isNaN(price) ? (
					<Translate id="unavailable" />
				) : (
					<LocalizedPrice price={price * title.quantity} />
				)}
			</span>
		);
	};

	const getTitleAsEntity = () => {
		const type = RecommendableEntity.title;
		return new RecommendableEntity(title.sku, type, title.name, title);
	};

	const isMobileSize = isMobile();

	const entity = getTitleAsEntity();

	return (
		<div className="order-summary-container">
			{isMobileSize && (
				<>
					<div className="mobile-remove-from-bag-anchor">
						<RemoveFromBagAnchor title={title} />
					</div>
					<div className="mobile-order-item-jacket-image">
						<TitleDetailDrawer sku={title.sku}>
							<TitleJacketImage title={title} />
						</TitleDetailDrawer>
					</div>
				</>
			)}
			<Row justify="space-around">
				{!isMobileSize && (
					<Col span={4}>
						<TitleDetailDrawer sku={title.sku}>
							<div className="order-item-jacket-image">
								<TitleJacketImage
									size={TitleJacketImage.fixedwidth}
									title={title}
								/>
							</div>
						</TitleDetailDrawer>
					</Col>
				)}
				<Col
					span={isMobileSize ? 24 : 12}
					align={isMobileSize ? 'center' : 'left'}>
					<div style={isMobileSize ? { textAlign: 'center' } : null}>
						<Descriptions
							title={title.name}
							column={1}
							size="small">
							<Descriptions.Item label={<Translate id="by" />}>
								{
									getAuthorDisplayNameAndInitials(
										title.author,
									).displayName
								}
							</Descriptions.Item>
							<Descriptions.Item
								label={<Translate id="format" />}>
								<BagTitleFormat sku={title.sku} />
							</Descriptions.Item>
							{isMobileSize && (
								<Descriptions.Item
									label={<Translate id="price" />}>
									{renderPrice()}
								</Descriptions.Item>
							)}
							<Descriptions.Item>
								<BagItemQuantitySelect
									entity={entity}
									quantity={title.quantity}
								/>
							</Descriptions.Item>
						</Descriptions>
					</div>
				</Col>
				{!isMobileSize && (
					<Col span={8} align="right">
						<Row type="flex" align="bottom" justify="end">
							<Col align="right">
								<>
									<div className="order-item-price">
										<Translate id="price" />:{' '}
										{renderPrice()}
									</div>
									<RemoveFromBagAnchor title={title} />
								</>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
		</div>
	);
}

export default withIsMobile(BagOrderTitleSummary);
