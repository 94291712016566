import React from 'react';
import PropTypes from 'prop-types';
import { connectToCustomerID } from './CustomerIDStore';
import makeConnectTo from './makeConnectTo';
import api from '../../api';
import { HeaderLayout } from '../Navigation/HeaderLayout';
import loginPageImage from '../../assets/images/login_page_image.jpg';

const customerAppPrefType = 'indie360.customerAppSettings';

const PreferencesContext = React.createContext();

export const connectToSellerPreferences = makeConnectTo(
	PreferencesContext,
	'preferencesDataStore',
);

export const useBooksellerPreferencesDataStore = () => {
	return React.useContext(PreferencesContext);
};

export const useBooksellerAppSettings = () => {
	const { getAppSettings } = useBooksellerPreferencesDataStore();
	return getAppSettings();
};

const defaultLoginPageImage = window.location.origin + loginPageImage;

export const appSettingsByKey = {
	appHeaderLogo: 'appHeaderLogo',
	doIncludeCommerce: 'doIncludeCommerce',
	doShowBookstoreLoyaltyPopup: 'doShowBookstoreLoyaltyPopup',
	doShowBrowseShelvesPage: 'doShowBrowseShelvesPage',
	doShowMessageOnExplorePageOnly: 'doShowMessageOnExplorePageOnly',
	doShowCustomerAppBrandingWhenSharingCampaign:
		'doShowCustomerAppBrandingWhenSharingCampaign',
	gradientColor: 'gradientColor',
	headerColor: 'headerColor',
	headerLayout: 'headerLayout',
	iconColor: 'iconColor',
	loginPageImage: 'loginPageImage',
	paypalEmailAddress: 'paypalEmailAddress',
	primaryFont: 'primaryFont',
	primaryColor: 'primaryColor',
	staffReviewers: 'staffReviewers',
	textColor: 'textColor',
};

const initialAppSettings = {
	appHeaderLogo: null,
	doIncludeCommerce: false,
	doShowBookstoreLoyaltyPopup: true,
	doShowBrowseShelvesPage: true,
	doShowMessageOnExplorePageOnly: true,
	gradientColor: null,
	headerColor: '#ffffff',
	headerLayout: HeaderLayout.classic,
	iconColor: '#525050',
	loginPageImage: defaultLoginPageImage,
	paypalEmailAdddress: null,
	primaryFont: null,
	primaryColor: '#005373',
	staffReviewers: [],
	textColor: '#525050',
};

export const withSellerAppSettings = Component => {
	return connectToSellerPreferences(Component, store => ({
		appSettings: store.getAppSettings(),
	}));
};

class BooksellerPreferencesDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.arrayOf(PropTypes.element),
		]),
	};

	state = {
		isLoading: true,
		customerAppSettings: initialAppSettings,
	};

	render() {
		const dataStore = this.getDataStore();
		return (
			<PreferencesContext.Provider value={dataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(dataStore)
					: this.props.children}
			</PreferencesContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchCustomerAppPreferences();
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getAppSettings: () => state.customerAppSettings,
		getAppSettingAtKey: key => state.customerAppSettings[key],
		getGradient: () => this.getGradient(),
		getStaffReviewerIds: () => this.getStaffReviewerIds(),
	});

	fetchCustomerAppPreferences = () => {
		this.request = this.fetchPreferencesOfType(customerAppPrefType);

		this.request.promise
			.then(preferenceDTOs => {
				return this.buildPreferencePairs(preferenceDTOs);
			})
			.then(preferencesByName => {
				const customerAppSettings = {
					...initialAppSettings,
					...preferencesByName,
				};

				this.addCustomStyling(customerAppSettings);

				this.setState({
					isLoading: false,
					customerAppSettings,
				});
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load store preferences');
				});
			});
	};

	fetchPreferencesOfType = type => {
		const { orgId } = this.props.customerState;
		return api.cancelableGet(`/org/${orgId}/preferences?type=${type}`);
	};

	buildPreferencePairs = preferenceDTOs => {
		let nextState = {};
		if (!Array.isArray(preferenceDTOs)) return nextState;

		preferenceDTOs.forEach(preferenceDTO => {
			nextState[preferenceDTO.name] = this.parseOneSettingValue(
				preferenceDTO.value,
			);
		});

		return nextState;
	};

	parseOneSettingValue = settingValue => {
		if (settingValue === 'true') {
			return true;
		}

		if (settingValue === 'false') {
			return false;
		}

		if (this.isNumeric(settingValue)) {
			return parseInt(settingValue);
		}

		return settingValue;
	};

	isNumeric(settingValue) {
		if (typeof settingValue === 'number') {
			return true;
		}

		return !isNaN(Number(settingValue));
	}

	getFontFamily = primaryFont => {
		try {
			return JSON.parse(primaryFont).fontFamily;
		} catch (e) {
			return primaryFont;
		}
	};

	addCustomStyling = customerAppSettings => {
		const {
			primaryColor,
			primaryFont,
			textColor,
			iconColor,
		} = customerAppSettings;

		const fontFamily = primaryFont ? this.getFontFamily(primaryFont) : null;

		let varsToModify = {
			'@primary-color': primaryColor || '#005373',
			'@font-family': fontFamily || 'Open Sans',
			'@text-color': textColor || '#525050',
			'@icon-color': iconColor || textColor || '#525050',
		};

		if (fontFamily) {
			const WebFont = require('webfontloader');

			WebFont.load({
				google: {
					families: ['Open Sans', fontFamily],
				},
			});
		}

		window.less.modifyVars(varsToModify);
	};

	getGradient = () => {
		const defaultGradient = '#ffffff, #f1f1f1';
		let sellerGradient = this.state.customerAppSettings.gradientColor;
		return `linear-gradient(${
			sellerGradient
				? sellerGradient.split('+').join(',')
				: defaultGradient
		})`;
	};

	getStaffReviewerIds = () => {
		const { staffReviewers } = this.state.customerAppSettings;
		const reviewerIds = staffReviewers ? staffReviewers.toString() : [];
		if (reviewerIds && reviewerIds.length) {
			return reviewerIds.includes(',')
				? reviewerIds.split(',')
				: [reviewerIds];
		}
		return [];
	};
}

export default connectToCustomerID(BooksellerPreferencesDataStore);
