import React from 'react';
import { Translate } from 'react-localize-redux';

export const TitleFormatDescription = {
	paperback: 'Paperback',
	hardcover: 'Hardcover',
	audioCassette: 'Audio Cassette',
	audioCD: 'Audio CD',
	unknown: <Translate id="unknown_format" />,
};
