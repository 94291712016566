import React from 'react';
import PropTypes from 'prop-types';

const ComponentConfig = PropTypes.shape({
	props: PropTypes.object,
	component: PropTypes.element,
});

const propTypes = {
	children: PropTypes.element,
	componentConfigs: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.func, ComponentConfig]),
	),
};

function getComponentAndItsProps(componentConfig) {
	if (typeof componentConfig === 'object' && componentConfig.component) {
		return componentConfig;
	}
	return {
		component: componentConfig,
	};
}

export default function ContextAggregator({ componentConfigs, children }) {
	return componentConfigs.reduce((WrappedChild, config) => {
		const { component: C, props } = getComponentAndItsProps(config);
		if (props && typeof props === 'object') {
			return <C {...props}>{WrappedChild}</C>;
		}
		return <C>{WrappedChild}</C>;
	}, children);
}

ContextAggregator.propTypes = propTypes;
