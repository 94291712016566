import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Card, Button } from 'antd';
import { makeCancelable } from 'api-fetcher-treeline';
import api from '../../api';
import withIsMobile from '../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import StaffReview from '../StaffPicks/UI/StaffReview';
import TitleJacketImage from '../Title/UI/TitleJacketImage';
import TitleDataStore from '../Title/data/TitleDataStore';
import TitleDescription from '../Title/UI/TitleDescription';
import UserAvatar from 'treeline-react-core-components/UserAvatar';
import { StaffUserAvatar } from '../StaffPicks/UI/StaffUserAvatar';
import { connectToStaffPicks } from '../StaffPicks/data/StaffPicksDataStore';
import TitleJacketImageWithIconBadges from '../UI/TitleJacketImageWithIconBadges';
import FavoriteEntityAnchor from '../CustomerFavorites/UI/FavoriteEntityAnchor';
import RecommendableEntity from '../../entity/RecommendableEntity';
import { connectToFavorites } from '../CustomerFavorites/data/CustomerFavoritesDataStore';
import { withIsUnknownCustomer } from '../data/CustomerIDStore';
import combineConnectors from '../data/combineConnectors';
import TitleDetailDrawer from '../Title/TitleDetailDrawer';
import { connectToOrg } from '../data/OrganizationDataStore';
import './allAvailableTitles.less';

class AllAvailableTitles extends React.Component {
	static propTypes = {
		renderOneTitle: PropTypes.func, // (title) => Jsx
		fetchAllAvailableTitles: PropTypes.func, // () => Promise
		header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
		subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		isMobile: PropTypes.func,
		titles: PropTypes.array,
		staffPicksDataStore: PropTypes.object,
		orgDataStore: PropTypes.object,
		doShowStaffAvatar: PropTypes.bool,
	};

	static defaultProps = {
		header: <Translate id="view_all" />,
		doShowStaffAvatar: true,
	};

	request = null;
	loadMoreTitlesAmount = 10;
	scrollRefToLastViewedTitle = React.createRef();

	state = {
		isLoading: true,
		allTitles: [],
		canLoadMoreTitles: false,
		hasLoadedMoreTitles: false,
		currentMaxTitles: this.loadMoreTitlesAmount,
	};

	render() {
		return (
			<Card
				className="all-available-titles-container"
				size="small"
				style={{
					backgroundColor: '#fff',
					margin: '0 auto',
				}}>
				{this.renderHeader()}
				{this.props.subtitle && this.renderSubtitle()}
				<div style={this.getTitleWrapperStyle()}>
					{this.state.isLoading
						? this.renderLoading()
						: this.renderTitles()}
					{this.state.canLoadMoreTitles && (
						<Button
							className="all-available-titles-load-more-button"
							onClick={() => this.handleLoadMoreTitles()}>
							<Translate id="load_more" />
						</Button>
					)}
				</div>
			</Card>
		);
	}

	handleLoadMoreTitles = () => {
		const { allTitles, currentMaxTitles } = this.state;
		const newMaxTitles = currentMaxTitles + this.loadMoreTitlesAmount;
		const canLoadMoreTitles = allTitles.length > newMaxTitles;
		this.setState({
			currentMaxTitles: newMaxTitles,
			canLoadMoreTitles,
			hasLoadedMoreTitles: true,
		});
	};

	renderTitles = () => {
		const { currentMaxTitles, allTitles } = this.state;
		const titlesToRender = allTitles.slice(0, currentMaxTitles);
		return (
			<>
				{this.renderTitleCount()}
				{titlesToRender.map((title, idx) =>
					this.renderOneTitle(title, idx),
				)}
			</>
		);
	};

	getNewState = allTitles => {
		const { currentMaxTitles } = this.state;
		return {
			isLoading: false,
			allTitles,
			canLoadMoreTitles: allTitles.length > currentMaxTitles,
		};
	};

	componentDidMount() {
		const { titles } = this.props;
		if (!titles) {
			this.request = makeCancelable(this.props.fetchAllAvailableTitles());
			this.request.promise
				.then(({ titles }) => {
					const allTitles = Array.isArray(titles) ? titles : [];
					return this.getNewState(allTitles);
				})
				.then(newState => this.setState(newState))
				.catch(err => !err.isCanceled && console.log(err));
		} else {
			const newState = this.getNewState(titles);
			this.setState(newState);
		}
	}

	handleScrollToLastViewedTitle = () => {
		this.scrollRefToLastViewedTitle.current &&
			this.scrollRefToLastViewedTitle.current.scrollIntoView({
				behavior: 'smooth',
			});
		this.setState({ hasLoadedMoreTitles: false });
	};

	componentDidUpdate() {
		this.state.hasLoadedMoreTitles && this.handleScrollToLastViewedTitle();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	renderLoading() {
		return (
			<Spin className="all-available-titles-spin-container" spinning />
		);
	}

	renderHeader() {
		const { header, subtitle } = this.props;
		return (
			<div
				className="all-available-titles-header"
				style={{ paddingBottom: subtitle ? '0.2rem' : 0 }}>
				{header}
			</div>
		);
	}

	renderSubtitle() {
		const { subtitle } = this.props;
		return (
			<div className="all-available-titles-subtitle">
				{subtitle && subtitle.text && subtitle.link
					? [subtitle.text, subtitle.link]
					: subtitle}
			</div>
		);
	}

	renderTitleCount = () => {
		const { allTitles } = this.state;
		return (
			<div className="all-available-titles-count">
				{allTitles.length === 1 ? (
					<Translate id="showing_one_title" />
				) : (
					<Translate
						id="browse_x_titles"
						data={{ number: allTitles.length }}
					/>
				)}
			</div>
		);
	};

	getTitleWrapperStyle = () => {
		const { isLoading } = this.state;
		const isMobile = this.props.isMobile();
		return {
			display: 'flex',
			flexDirection: 'column',
			margin: '0 auto',
			justifyContent: isLoading
				? 'center'
				: isMobile
				? 'space-around'
				: 'space-between',
		};
	};

	renderReviewOrDescription = (titleDataStore, title) => {
		if (titleDataStore.isLoading()) {
			return this.renderLoading();
		}
		const { isMobile } = this.props;
		const titleData = titleDataStore.getTitle();
		const firstVisibleReview = this.findFirstVisibleReview(titleData);
		return firstVisibleReview ? (
			this.findStaffReviewer(titleDataStore, title, firstVisibleReview)
		) : (
			<TitleDescription title={titleData} isTitleDetail={isMobile()} />
		);
	};

	findFirstVisibleReview = titleData => {
		const { staffPicksDataStore } = this.props;
		const staffReviewerIds = staffPicksDataStore.getSelectedStaffReviewerIds();
		return (
			titleData.reviews &&
			titleData.reviews.find(
				r =>
					r.text && staffReviewerIds.includes(r.appUserId.toString()),
			)
		);
	};

	findStaffReviewer = (titleDataStore, title, firstVisibleReview) => {
		if (titleDataStore.isLoading()) {
			return this.renderLoading();
		}
		const staffReviewer = titleDataStore.getReviewer(
			firstVisibleReview ? firstVisibleReview.appUserId : title.appUserId,
		);
		return this.renderStaffReview(title, staffReviewer, firstVisibleReview);
	};

	renderStaffReview = (title, staffReviewer, firstVisibleReview) => {
		const { doShowStaffAvatar } = this.props;
		const review = firstVisibleReview || title;
		return (
			<div key={title.sku}>
				<StaffReview
					title={title}
					updatedDate={review.updatedDate}
					reviewText={review.review}
					reviewerFirstName={staffReviewer.firstName}
					reviewerFullName={staffReviewer.fullName}>
					{doShowStaffAvatar ? (
						<StaffUserAvatar
							firstName={staffReviewer.firstName}
							lastName={staffReviewer.lastName}
							avatarUri={staffReviewer.avatar.uri}
							displayName={staffReviewer.fullName}
							size={UserAvatar.medium}
							appUserId={staffReviewer.id}
						/>
					) : null}
				</StaffReview>
			</div>
		);
	};

	renderFavoriteEntityAnchor(title) {
		const type = RecommendableEntity.title;
		const entity = new RecommendableEntity(title.sku, type, title.name);
		return (
			<div style={{ position: 'relative' }}>
				<FavoriteEntityAnchor entity={entity} isTitleDetail={true} />
			</div>
		);
	}

	isLastViewedTitle = idx => {
		const { currentMaxTitles } = this.state;
		const lastViewedTitleIndex = this.loadMoreTitlesAmount + 1;
		return idx === currentMaxTitles - lastViewedTitleIndex;
	};

	renderOneTitle = (title, idx) => {
		const { isMobile, isUnknownCustomer } = this.props;
		return (
			<div
				className="all-available-titles-title-container"
				ref={
					this.isLastViewedTitle(idx)
						? this.scrollRefToLastViewedTitle
						: null
				}
				key={title.sku}>
				<div className="all-available-titles-title-drawer-container">
					{isMobile() ? (
						<div style={{ margin: 15 }}>
							<TitleDetailDrawer sku={title.sku}>
								<TitleJacketImage
									size={TitleJacketImage.fixedwidth}
									title={title}
								/>
							</TitleDetailDrawer>
							{!isUnknownCustomer &&
								this.renderFavoriteEntityAnchor(title)}
						</div>
					) : (
						<TitleJacketImageWithIconBadges
							title={title}
							size={TitleJacketImage.fillwidth}
						/>
					)}
				</div>
				<div className="all-available-titles-title-description-container">
					<TitleDataStore sku={title.sku}>
						{titleDataStore =>
							title.review
								? this.findStaffReviewer(titleDataStore, title)
								: this.renderReviewOrDescription(
										titleDataStore,
										title,
								  )
						}
					</TitleDataStore>
				</div>
			</div>
		);
	};
}

const connectors = [
	connectToOrg,
	connectToFavorites,
	connectToStaffPicks,
	withIsUnknownCustomer,
	withIsMobile,
];

export default combineConnectors(connectors)(AllAvailableTitles);
