import React from 'react';
import PropTypes from 'prop-types';
import Scroller from '../UI/Scroller/Scroller';
import StaffUserAvatar from './UI/StaffUserAvatar';
import LaneContainer from '../UI/LaneContainer';
import { connectToStaffPicks } from './data/StaffPicksDataStore';
import { Translate } from 'react-localize-redux';

class StaffPicksByMemberLane extends React.Component {
	static propTypes = {
		shelfName: PropTypes.string,
		staffPicksDataStore: PropTypes.object,
	};

	render() {
		const { staffPicksDataStore, shelfName } = this.props;
		const staffUserIds = staffPicksDataStore.getSelectedStaffReviewerIds();
		return staffUserIds.length ? (
			<LaneContainer
				majorHeader={<Translate id="staff_picks" />}
				subHeader={
					shelfName ? (
						<Translate
							data={{ shelfName }}
							id="see_what_x_titles_our_booksellers_recommend"
						/>
					) : (
						<Translate id="see_what_our_booksellers_recommend" />
					)
				}>
				<Scroller>{this.renderStaffAvatars(staffUserIds)}</Scroller>
			</LaneContainer>
		) : null;
	}

	renderStaffAvatars = staffUserIds => {
		const { staffPicksDataStore } = this.props;
		const staffUsersWithReviews = staffUserIds.filter(staffUserId =>
			staffPicksDataStore.getCountOfReviewsByStaffUser(staffUserId),
		);
		return staffUsersWithReviews.map(staffUserId => {
			return (
				<React.Fragment key={staffUserId}>
					{this.renderOneStaffAvatarAnchor(staffUserId)}
				</React.Fragment>
			);
		});
	};

	renderOneStaffAvatarAnchor = staffUserId => {
		const { staffPicksDataStore, shelfName } = this.props;
		const reviewsCt = staffPicksDataStore.getCountOfReviewsByStaffUser(
			staffUserId,
		);
		return (
			<div style={{ marginRight: '20px', paddingBottom: '0.5rem' }}>
				<div className="relative">
					<div style={{ height: '105px', overflowY: 'hidden' }}>
						<StaffUserAvatar
							appUserId={staffUserId}
							shelfName={shelfName}
							isInLane={true}
						/>
					</div>
					<div className="picks-count">{reviewsCt}</div>
				</div>
				<div className="picks-name">
					{staffPicksDataStore.getStaffUser(staffUserId).firstName}
				</div>
			</div>
		);
	};
}

export default connectToStaffPicks(StaffPicksByMemberLane);
