/**
 *
 * @param {Number} num
 * @param {String} label
 * Converts given label into plural if applicable
 */
export const toPlural = (num, label) => {
	const n = typeof num === 'number' ? num : Number(num);

	const base = `${n.toLocaleString()} ${label}`;

	return n === 1 ? base : `${base}s`;
};

export const formatAsDollarValue = value => {
	if (!value) return '$ 0';
	const val = typeof value === 'number' ? value.toString() : value;
	return `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 *
 * @param {Array} sourceArray
 * @param {String} property
 * @returns {Object}
 * Creates a map of array items by the found values of the given 'property'
 */
export const groupBy = (sourceArray, property) => {
	return sourceArray.reduce((accumulator, nextItemInSourceArray) => {
		const valueAtProperty = nextItemInSourceArray[property];

		if (!accumulator[valueAtProperty]) {
			accumulator[valueAtProperty] = [];
		}

		accumulator[valueAtProperty].push(nextItemInSourceArray);
		return accumulator;
	}, {});
};

/**
 *
 * @param {String} src
 * @returns {String}
 * Capitalize the string. Make the first letter uppercase and return new formatted string.
 */
export const capitalize = src => {
	return src.charAt(0).toUpperCase() + src.slice(1);
};
