import React from 'react';
import CustomerListType from '../../../entity/CustomerListType';
import CustomerEntitiesController from '../../data/CustomerEntitiesController';
import RecentlyViewedDataStoreBase from './RecentlyViewedDataStoreBase';
import makeConnectTo from '../../data/makeConnectTo';
import { RecentlyViewedContext } from './RecentlyViewedDataStoreBase';

export const connectToRecentlyViewed = makeConnectTo(
	RecentlyViewedContext,
	'recentlyViewedDataStore',
);

export const useRecentlyViewedDataStore = () =>
	React.useContext(RecentlyViewedContext);

export default function RecentlyViewedDataStore({ children }) {
	const listType = CustomerListType.recentlyViewed;

	const renderBaseDataStore = customerEntitiesController => {
		return (
			<RecentlyViewedDataStoreBase
				customerEntitiesController={customerEntitiesController}>
				{children}
			</RecentlyViewedDataStoreBase>
		);
	};

	return (
		<CustomerEntitiesController customerListType={listType}>
			{renderBaseDataStore}
		</CustomerEntitiesController>
	);
}
