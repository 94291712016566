import PropTypes from 'prop-types';

const TitleAttributes = PropTypes.shape({
	authorBio: PropTypes.string,
	dateAdded: PropTypes.string,
	isActiveTitle: PropTypes.bool,
	quotes: PropTypes.string,
	summary: PropTypes.string,
});

export default TitleAttributes;
