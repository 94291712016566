import React from 'react';
import useBagDataTotalAmount from '../data/useBagDataTotalAmount';
import LocalizedPrice from '../../Localization/LocalizedPrice';

export default function BagTotal() {
	const { getTotal } = useBagDataTotalAmount();

	return (
		<span className="price">
			<LocalizedPrice price={getTotal()} />
		</span>
	);
}
