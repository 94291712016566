import React from 'react';
import { Tooltip } from 'antd';
import withIsMobile from '../../hoc/withIsMobile';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import './bagEntityIndicator.css';

BagEntityIndicator.propTypes = {
	right: PropTypes.number,
	isMobile: PropTypes.func,
};

BagEntityIndicator.defaultProps = {
	right: 33,
};

function BagEntityIndicator({ right, isMobile }) {
	return (
		<div className="bag-entity-indicator" style={{ right: right }}>
			<Tooltip
				title={
					!isMobile() ? <Translate id="currently_in_bag" /> : null
				}>
				<div className="icon-shop" />
			</Tooltip>
		</div>
	);
}

export default withIsMobile(BagEntityIndicator);
