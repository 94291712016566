import React from 'react';
import useSplashImageController from './hooks/useSplashImageController';
import { Button, Icon } from 'antd';
import { Translate } from 'react-localize-redux';

export default function SaveUploadedSplashButton() {
	const { isLoading, saveUploadedSplash } = useSplashImageController();
	return (
		<Translate>
			{({ translate }) => {
				return (
					<Button
						type="primary"
						onClick={() => saveUploadedSplash(translate)}
						loading={isLoading}>
						{isLoading ? (
							<Translate id="loading" />
						) : (
							<>
								<Icon type="check-circle" />{' '}
								<Translate id="save" />
							</>
						)}
					</Button>
				);
			}}
		</Translate>
	);
}
