import React from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import * as Util from '../../util';
import Convert from '../../util/Convert';
import { connectToCustomerID } from './CustomerIDStore';
import makeConnectTo from '../data/makeConnectTo';
import cloneDeep from 'lodash/cloneDeep';

const SectionsContext = React.createContext();

const name = 'sectionsDataStore';
export const connectToSections = makeConnectTo(SectionsContext, name);

export const useSectionsDataStore = () => {
	return React.useContext(SectionsContext);
};

/**
 * Holds all the Sections and Departments for a given Org.
 */

class SectionsDataStore extends React.Component {
	static propTypes = {
		customerState: PropTypes.object,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	};

	state = {
		isLoading: true,
		isLoadingRecent: true,
		sections: [],
		departments: [],
		sectionsById: {},
		departmentsById: {},
		recentSectionIds: [], // Sections 'recently' purchased from by the customer
	};

	render() {
		const sectionsStore = this.getDataStore();
		return (
			<SectionsContext.Provider value={sectionsStore}>
				{typeof this.props.children === 'function'
					? this.props.children(sectionsStore)
					: this.props.children}
			</SectionsContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchSections();
		this.fetchRecentSectionIds();
	}

	componentWillUnmount() {
		this.request && this.request.cancel && this.request.cancel();
	}

	getDataStore = (state = this.state) => ({
		state,
		isLoading: () => state.isLoading,
		getSections: () => state.sections,
		getDepartments: () => state.departments,
		getRecentSectionIds: () => {
			const ids = this.getRecentSectionIds(state);
			return ids.map(secId => Convert.toString(secId));
		},
		getSection: sectionId => {
			return this.getSection(state, sectionId);
		},
		getSectionFromSectionCode: sectionCode => {
			const { sections } = state;
			return sections.find(s => s.sectionCode === sectionCode);
		},
		getDepartment: deptId => {
			return this.getDepartment(state, deptId);
		},
		isSection: entityId => {
			const section = this.getSection(state, entityId);
			return typeof section !== 'undefined';
		},
		isDepartment: entityId => {
			const dept = this.getDepartment(state, entityId);
			return typeof dept !== 'undefined';
		},
	});

	getSection = (state, sectionId) => {
		return state.sectionsById[Convert.toString(sectionId)];
	};

	getDepartment = (state, deptId) => {
		return state.departmentsById[Convert.toString(deptId)];
	};

	fetchRecentSectionIds = () => {
		const { customerState: c } = this.props;
		const endpoint = `/org/${c.orgId}/customers/${c.customerId}/sections/highlighted`;

		return api
			.get(endpoint)
			.then(recentSectionIds => {
				const rs = Array.isArray(recentSectionIds)
					? recentSectionIds
					: [];
				this.setState({
					recentSectionIds: rs,
					isLoadingRecent: false,
				});
			})
			.catch(err => {
				this.setState({ isLoadingRecent: false });
				console.error(err.message);
			});
	};

	fetchSections = () => {
		const { customerState: c } = this.props;
		this.request = api.cancelableGet(`/org/${c.orgId}/sections`);

		this.request.promise
			.then(response => {
				const nextState = this.buildSectionsAndDepartments(response);
				this.setState({ isLoading: false, ...nextState });
			})
			.catch(err => {
				console.error(err.message);
				this.setState(() => {
					throw new Error('Failed to load sections');
				});
			});
	};

	getRecentSectionIds = state => {
		const topSections = this.getSectionsWithMostTransactions(10);
		const topSectionIds = topSections.map(section => section.sectionId);

		if (!state.isLoadingRecent && state.recentSectionIds.length > 0) {
			return [...state.recentSectionIds, ...topSectionIds];
		}
		return topSectionIds;
	};

	getSectionsWithMostTransactions = nSectionsRequired => {
		const bestSellingSections = this.sortSectionsByBestSelling();
		return bestSellingSections.slice(0, nSectionsRequired);
	};

	buildSectionsAndDepartments = res => {
		const sections = this.sortSectionsByName(res);
		const sectionsById = this.buildSectionsById(res);
		const { departments, departmentsById } = this.buildDepartments(res);

		return {
			sections,
			departments,
			sectionsById,
			departmentsById,
		};
	};

	buildDepartments(response) {
		const sections = this.ensureAllSectionsHaveDeptId(response);

		const sectionsGroupedByDeptId = Util.groupBy(sections, 'departmentId');

		const depts = Object.keys(sectionsGroupedByDeptId).map(
			departmentId => ({
				departmentId,
				departmentName:
					sectionsGroupedByDeptId[departmentId][0].departmentName,
				sections: sectionsGroupedByDeptId[departmentId],
				countOfSections: sectionsGroupedByDeptId[departmentId].length,
			}),
		);

		depts.sort((a, b) =>
			('' + a.departmentName).localeCompare(b.departmentName),
		);

		const departmentsById = {};

		Object.keys(sectionsGroupedByDeptId).forEach(departmentId => {
			departmentsById[departmentId] = {
				departmentId,
				departmentName:
					sectionsGroupedByDeptId[departmentId][0].departmentName,
				sections: sectionsGroupedByDeptId[departmentId],
				countOfSections: sectionsGroupedByDeptId[departmentId].length,
			};
		});

		return { departments: depts, departmentsById };
	}

	ensureAllSectionsHaveDeptId(response) {
		if (!Array.isArray(response)) {
			return [];
		}
		return response.map(section => {
			if (section.departmentId && section.departmentId !== '0') {
				return section;
			}
			return { ...section, ...this.getOtherDepartment() };
		});
	}

	buildSectionsById(sections) {
		const sectionsById = {};

		sections.forEach(sec => {
			const id = Convert.toString(sec.sectionId);
			sectionsById[id] = sec;
		});

		return sectionsById;
	}

	sortSectionsByName(sections) {
		return sections.sort((a, b) =>
			('' + a.sectionName).localeCompare(b.sectionName),
		);
	}

	sortSectionsByBestSelling() {
		const sections = cloneDeep(this.state.sections);
		return sections.sort((a, b) => b.transactions - a.transactions);
	}

	getOtherDepartment = () => ({
		departmentId: '0',
		departmentName: 'Other',
	});
}

export default connectToCustomerID(SectionsDataStore);
