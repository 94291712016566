import React from 'react';
import PropTypes from 'prop-types';
import UnsubscribeService from './Services/UnsubscribeService';
import LaneContainer from '../UI/LaneContainer';
import EmailFrequencyService from './Services/EmailFrequencyService';
import CustomerFavoritesService from '../CustomerFavorites/CustomerFavoritesService';
import { connectToCustomer } from '../data/CustomerSummaryDataStore';
import { Translate } from 'react-localize-redux';

class UnsubscribePage extends React.Component {
	static propTypes = {
		customerDataStore: PropTypes.object,
	};

	render() {
		const isUnsubscribed = this.props.customerDataStore.isUnsubscribedFromRecommendationEmails();

		return (
			<div style={{ paddingTop: '1rem', marginBottom: '5rem' }}>
				<div className="unsubscribe-page">
					<PageDivider>
						<LaneContainer
							style={{ paddingRight: '5%' }}
							majorHeader={
								<Translate id="we_are_sorry_to_see_you_go" />
							}>
							<div style={{ paddingBottom: '1rem' }}>
								<UnsubscribeService />
							</div>
						</LaneContainer>
					</PageDivider>
					{!isUnsubscribed && this.renderSubscriptionModifiers()}
				</div>
			</div>
		);
	}

	renderSubscriptionModifiers() {
		return (
			<React.Fragment>
				<PageDivider>
					<LaneContainer
						style={{ paddingRight: '5%' }}
						majorHeader={
							<Translate id="can_we_do_something_to_keep_you" />
						}>
						<div style={{ paddingBottom: '1rem' }}>
							<EmailFrequencyService />
						</div>
					</LaneContainer>
				</PageDivider>
				<PageDivider>
					<CustomerFavoritesService doShowSeriesLane={false} />
				</PageDivider>
			</React.Fragment>
		);
	}
}

function PageDivider({ children }) {
	return <div style={{ paddingBottom: '1.5rem' }}>{children}</div>;
}

export default connectToCustomer(UnsubscribePage);
