import React from 'react';
import PropTypes from 'prop-types';
import { Button, notification, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import ArrowUpIcon from '../../UI/ArrowUpIcon';
import './copyLinkToTitleAnchor.css';

const propTypes = {
	sku: PropTypes.string,
	isMobile: PropTypes.func,
};

function CopyLinkToTitleAnchor({ sku, isMobile }) {
	const buildLinkToTitle = () => {
		const baseUrl = window.location.origin;
		const data = localStorage.getItem('data');
		let linkUrl = `${baseUrl}/explore/title/${sku}`;
		if (data) {
			linkUrl = linkUrl.concat(`?referralsource=${data}`);
		}
		return linkUrl;
	};

	const onCopy = translate => {
		notification.success({
			message: translate('copied_link_to_your_clipboard'),
		});
	};

	const renderShareButton = () => {
		return (
			<Button className="share-title-button">
				<ArrowUpIcon className="share-title-icon" />
				<Translate id="share" />
			</Button>
		);
	};

	return (
		<Translate>
			{({ translate }) => {
				return (
					<div className="copy-link-to-title-anchor">
						<CopyToClipboard
							text={buildLinkToTitle()}
							onCopy={() => onCopy(translate)}>
							{isMobile() ? (
								renderShareButton()
							) : (
								<Tooltip
									title={
										<Translate id="copy_link_to_clipboard" />
									}
									placement="top">
									{renderShareButton()}
								</Tooltip>
							)}
						</CopyToClipboard>
					</div>
				);
			}}
		</Translate>
	);
}

export default withIsMobile(CopyLinkToTitleAnchor);

CopyLinkToTitleAnchor.propTypes = propTypes;
