import React from 'react';
import PropTypes from 'prop-types';
import Customer360PropTypes from '../../../types';
import Scroller from '../../UI/Scroller/Scroller';
import { Link } from 'react-router-dom';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import SectionLevel from '../../../entity/SectionLevel';
import './sectionsScroller.css';

class SectionsScrollerItem extends React.Component {
	static propTypes = {
		doLinkToSectionRoute: PropTypes.bool,
		entity: Customer360PropTypes.RecommendableEntity,
		genreCount: PropTypes.number,
		onClickOnName: PropTypes.func, // optional
		count: PropTypes.number, // optional
		children: PropTypes.element, // optional
	};

	static defaultProps = {
		doLinkToSectionRoute: false,
	};

	render() {
		return (
			<div
				className={`sections-scroller-item ${
					this.props.genreCount > 1 ? 'over-one-genre' : ''
				}`}>
				<div className="section-item-base">
					{typeof this.props.count === 'number' && (
						<div className="section-item-count">
							{this.props.count}
						</div>
					)}
					{this.props.doLinkToSectionRoute
						? this.renderNameWithLinkToSectionOrDepartment()
						: this.renderNameWithOnClick()}

					{this.props.children}
				</div>
			</div>
		);
	}

	renderNameWithLinkToSectionOrDepartment() {
		const { entity } = this.props;
		const isSection = entity.type === RecommendableEntity.section;

		const sectionLvl = isSection
			? SectionLevel.section
			: SectionLevel.department;

		return (
			<Link
				to={`/section/${sectionLvl}/${entity.id}`}
				className="section-item-name dotDot clickable acc-font bold">
				{this.props.entity.name}
			</Link>
		);
	}

	renderNameWithOnClick() {
		const hasOnClick = typeof this.props.onClickOnName === 'function';
		const baseClassNames = 'dotDot section-item-name bold';
		return (
			<div
				onClick={hasOnClick ? this.props.onClickOnName : () => {}}
				className={
					hasOnClick ? `${baseClassNames} clickable` : baseClassNames
				}>
				{this.props.entity.name}
			</div>
		);
	}
}

export default class SectionsScroller extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	};

	static SectionAnchor = SectionsScrollerItem;

	render() {
		return (
			<Scroller>
				<div
					className={`sections-scroller ${
						this.props.children.length > 4
							? 'columns-over-four'
							: ''
					}`}
					style={{ marginBottom: '1rem' }}>
					{this.props.children}
				</div>
			</Scroller>
		);
	}
}
