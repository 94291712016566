import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

CustomerAvatar.propTypes = {
	size: PropTypes.number,
};

export default function CustomerAvatar({ size }) {
	return <Avatar size={size || 90} icon="user" />;
}
