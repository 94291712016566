import React, { useEffect } from 'react';
import { cancelableDelay } from '../../../util/delay';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

export default function WishlistPageError() {
	const history = useHistory();

	useEffect(() => {
		const delay = cancelableDelay(3500);
		delay.promise.then(() => history.push('/explore'));

		return () => {
			delay.cancel();
		};
	}, [history]);

	return (
		<div className="page-container" style={{ paddingTop: 20 }}>
			<h4>
				<Translate id="invalid_wishlist_url" />
			</h4>
			<p>
				<Translate id="please_double_check_url" />
			</p>
			<p>
				<Translate id="redirecting_you_in_a_few_seconds" />
			</p>
		</div>
	);
}
