import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { CollectionStore } from '../data/CollectionStore';
import { Card } from 'antd';
import './collectionOverview.css';

class CollectionOverview extends React.Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		overview: PropTypes.string,
	};

	render() {
		const { overview, isLoading } = this.props;
		return (
			<Card
				className="collection-overview"
				size="small"
				loading={isLoading}
				style={{ marginBottom: '1rem' }}>
				<div className="collection-overview-text">
					{ReactHtmlParser(overview)}
				</div>
			</Card>
		);
	}
}

export default CollectionStore.connect(CollectionOverview, collectionStore => ({
	isLoading: collectionStore.state.isLoading,
	overview: collectionStore.getCatalog().overview,
}));
