import React from 'react';
import PropTypes from 'prop-types';
import TitleScroller from '../../TitleLanes/TitleScroller';

SearchResultsTitleScroller.propTypes = {
	titles: PropTypes.arrayOf(PropTypes.object),
};

export default function SearchResultsTitleScroller({ titles }) {
	const [maxVisibleTitles, setMaxVisible] = React.useState(10);

	const totalTitlesCount = titles.length;
	const titlesToRender = titles.slice(0, maxVisibleTitles);

	const showMoreTitles = () => {
		return new Promise(resolve =>
			resolve(setMaxVisible(currentMax => currentMax + 10)),
		);
	};

	return (
		<TitleScroller
			titles={titlesToRender}
			fetchMoreTitles={showMoreTitles}
			canFetchMoreTitles={totalTitlesCount > maxVisibleTitles}
		/>
	);
}
