import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { connectToImageUpload } from './ImageUploadDataStore';

class ImageUploadAnchor extends React.Component {
	static propTypes = {
		children: PropTypes.element,
		onUpload: PropTypes.func,
		imageUploadDataStore: PropTypes.object,
	};

	render() {
		return (
			<Upload
				accept="image/*"
				showUploadList={false}
				customRequest={this.transformFileOnDrop}>
				{this.props.children}
			</Upload>
		);
	}

	transformFileOnDrop = uploadInstance => {
		return this.readImageFileOnDrop(uploadInstance)
			.then(this.handleTransformedImage)
			.catch(err => console.error(err));
	};

	readImageFileOnDrop = uploadInstance => {
		const { file } = uploadInstance;

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			const fileType = file.type;
			const fileName = file.name;

			reader.onload = () => {
				if (reader.result) {
					resolve({ fileType, fileName, base64: reader.result });
				} else {
					reject(Error('Failed conversion to base64'));
				}
			};
		});
	};

	handleTransformedImage = uploadResult => {
		if (uploadResult) {
			const { imageUploadDataStore } = this.props;
			imageUploadDataStore.setBase64ImageResult(uploadResult);
			this.onUpload(uploadResult.base64);
		}
	};

	onUpload = uploadResult => {
		if (typeof this.props.onUpload === 'function') {
			return this.props.onUpload(uploadResult);
		}
	};
}

export default connectToImageUpload(ImageUploadAnchor);
