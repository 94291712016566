import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import makeConnectTo from '../../data/makeConnectTo';
import { connectToCustomerID } from '../../data/CustomerIDStore';
import Convert from '../../../util/Convert';

const TitleIndiePicksContext = React.createContext();

export const connectToTitleIndiePicks = makeConnectTo(
	TitleIndiePicksContext,
	'titleIndiePicksDataStore',
);

class TitleIndiePicksDataStore extends React.Component {
	static propTypes = {
		sku: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		// Mapped to props
		customerState: PropTypes.object,
	};

	state = {
		title: {},
		isLoading: true,
		reviewerUsersById: {},
	};

	render() {
		const titleIndiePicksDataStore = this.getDataStore();
		return (
			<TitleIndiePicksContext.Provider value={titleIndiePicksDataStore}>
				{typeof this.props.children === 'function'
					? this.props.children(titleIndiePicksDataStore)
					: this.props.children}
			</TitleIndiePicksContext.Provider>
		);
	}

	componentDidMount() {
		this.fetchTitleIndiePicks();
	}

	componentWillUnmount() {
		api.revoke(this.request);
	}

	getDataStore = (state = this.state) => ({
		state,
		getTitleIndiePicks: () => state.title,
		getReviewers: () => state.reviewerUsersById,
		isLoading: () => state.isLoading,
		hasReviews: () => state.title.reviews.length > 0,
		getReviewer: appUserId => this.getReviewer(state, appUserId),
	});

	fetchTitleIndiePicks = () => {
		const { sku, customerState: c } = this.props;
		const endpoint = `/org/${c.orgId}/titles/${sku}/indiePicks`;

		this.request = api.cancelableGet(endpoint);
		this.request.promise.then(this.buildStateFromTitleAggregate);
	};

	getReviewer = (state, appUserId) => {
		const id = Convert.toString(appUserId);
		const reviewer = state.reviewerUsersById[id];
		return reviewer ? reviewer : {};
	};

	buildStateFromTitleAggregate = titleAggregate => {
		const title = this.buildTitle(titleAggregate);
		const reviewerUsersById = this.buildReviewerUsersById(titleAggregate);

		this.setState({
			title,
			isLoading: false,
			reviewerUsersById,
		});
	};

	buildTitle = titleAggregate => ({
		reviews: titleAggregate.reviews,
	});

	buildReviewerUsersById = titleAggregate => {
		const reviewerUsersById = {};

		const {
			usersById,
			userAvatarsByAppUserId: avatarsById,
		} = titleAggregate;

		if (!usersById || !avatarsById) return reviewerUsersById;

		Object.keys(usersById).forEach(appUserId => {
			reviewerUsersById[appUserId] = usersById[appUserId];
			reviewerUsersById[appUserId].avatar = avatarsById[appUserId];
		});

		return reviewerUsersById;
	};
}

export default connectToCustomerID(TitleIndiePicksDataStore);
