import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Translate } from 'react-localize-redux';

export default class PreviewModeAlert extends React.Component {
	static propTypes = {
		style: PropTypes.object,
		onClose: PropTypes.func,
	};

	static defaultProps = {
		style: {},
	};

	render() {
		return (
			<div className="preview-mode-alert" style={this.props.style}>
				<Alert
					banner
					closable
					type="info"
					afterClose={this.props.onClose}
					message={<Translate id="preview_mode" />}
					description={<Translate id="most_actions_are_disabled" />}
				/>
			</div>
		);
	}
}
