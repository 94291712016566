import React from 'react';
import PropTypes from 'prop-types';
import AuthorsAutocomplete from '../../Authors/UI/AuthorsAutocomplete';
import LaneContainer from '../../UI/LaneContainer';
import withIsMobile from '../../hoc/withIsMobile';
import RecommendableEntity from '../../../entity/RecommendableEntity';
import AuthorsScroller from '../../Authors/UI/AuthorsScroller';
import { Spin, Button, notification } from 'antd';
import delay from '../../../util/delay';
import RemoveFavoriteController from '../RemoveFavoriteController';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import { connectToFavorites } from '../data/CustomerFavoritesDataStore';
import { Translate } from 'react-localize-redux';

class AuthorFavoritesLane extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		customerFavoritesDataStore: PropTypes.object,
	};

	render() {
		const mobile = this.props.isMobile();
		return (
			<LaneContainer
				majorHeader={<Translate id="favorite_authors" />}
				subHeader={
					!mobile ? (
						<Translate id="share_your_favorite_authors_with_us" />
					) : (
						''
					)
				}
				extra={
					<Translate>
						{({ translate }) => {
							return (
								<div className="autocomplete-wrapper">
									<AuthorsAutocomplete
										onSelectOption={(id, author) =>
											this.onSelectAuthor(
												id,
												author,
												translate,
											)
										}
										placeholder={this.getPlaceholder(
											translate,
										)}
									/>
								</div>
							);
						}}
					</Translate>
				}>
				{this.handleRenderFavorites()}
			</LaneContainer>
		);
	}

	handleRenderFavorites = () => {
		const isLoading = this.props.customerFavoritesDataStore.isLoading();
		return isLoading
			? this.renderLoadingFavorites()
			: this.renderAuthorFavorites();
	};

	renderLoadingFavorites() {
		return (
			<Spin>
				<div style={{ height: '5rem', width: '100%' }} />
			</Spin>
		);
	}

	getPlaceholder = translate => {
		return translate('search_for_authors');
	};

	onSelectAuthor = (id, author, translate) => {
		if (this.props.previewMode.isActive()) {
			return this.makeEmptyPromise();
		}
		const entity = this.getAuthorAsEntity(id, author);
		const name = entity.name;
		return this.props.customerFavoritesDataStore.save(entity).then(() =>
			notification.success({
				message: translate('saved_x_to_your_favorites', {
					name: name,
				}),
			}),
		);
	};

	getAuthorAsEntity = (id, author) => {
		const entityType = RecommendableEntity.author;
		return new RecommendableEntity(id, entityType, author.name);
	};

	renderAuthorFavorites = () => {
		const { customerFavoritesDataStore } = this.props;
		const authors = customerFavoritesDataStore.getFavoriteAuthors();
		return authors.length ? (
			<AuthorsScroller>
				{authors.map(this.renderOneAuthorInScroller)}
			</AuthorsScroller>
		) : (
			<p style={{ fontWeight: 'bold', fontSize: '14px' }}>
				<em>
					<Translate id="no_favorite_authors" />
				</em>
			</p>
		);
	};

	renderOneAuthorInScroller = author => {
		const entity = this.getAuthorAsEntity(author.id, author);
		return (
			<RemoveFavoriteController key={author.id} entity={entity}>
				{({ isLoading, handleRemove }) => {
					return (
						<Spin spinning={isLoading}>
							<AuthorsScroller.AuthorItem author={author}>
								<Button
									type="link"
									size="small"
									className="author-remove-link"
									onClick={handleRemove}>
									<Translate id="remove" />
								</Button>
							</AuthorsScroller.AuthorItem>
						</Spin>
					);
				}}
			</RemoveFavoriteController>
		);
	};

	makeEmptyPromise = () => {
		return delay(300).then(() => this.props.previewMode.notify());
	};
}

const connectors = [connectToFavorites, connectToPreviewMode, withIsMobile];
export default combineConnectors(connectors)(AuthorFavoritesLane);
