import React from 'react';
import PropTypes from 'prop-types';
import { withIsUnknownCustomer } from '../data/CustomerIDStore';
import { connectToSellerPreferences } from '../data/BooksellerPreferencesDataStore';
import combineConnectors from '../data/combineConnectors';
import NavigationAnchor from './NavigationAnchor';
import NavigationItems from './NavigationItems';
import RetailerMessage from '../UI/RetailerMessage';
import { connectToSellerContent } from '../data/BooksellerContentDataStore';
import './landingFooter.css';

class LandingFooter extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		isUnknownCustomer: PropTypes.bool,
		headerColor: PropTypes.string,
		doShowBrowseShelvesPage: PropTypes.bool,
		booksellerContentStore: PropTypes.object,
	};

	render() {
		const {
			headerColor,
			isUnknownCustomer,
			doShowBrowseShelvesPage,
			booksellerContentStore: content,
		} = this.props;

		const { HomeNav, ShelvesNav, SignUpNav, AccountNav } = NavigationItems;

		const { retailerMessageBody } = content.state.customerApp;
		const hasMessageBody = retailerMessageBody.length > 0;

		return (
			<div className="landing-footer" style={{ background: headerColor }}>
				<div className="columns">
					{hasMessageBody && <RetailerMessage />}
					{this.renderNavItem(HomeNav)}
					{doShowBrowseShelvesPage && this.renderNavItem(ShelvesNav)}
					{this.renderNavItem(
						isUnknownCustomer ? SignUpNav : AccountNav,
					)}
					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}

	renderNavItem(navItem) {
		return <NavigationAnchor {...navItem} />;
	}
}

const withFooterAppSettings = Component => {
	return connectToSellerPreferences(Component, store => {
		const appSettings = store.getAppSettings();
		return {
			headerColor: appSettings.headerColor,
			doShowBrowseShelvesPage: appSettings.doShowBrowseShelvesPage,
		};
	});
};

const connectors = [
	withIsUnknownCustomer,
	withFooterAppSettings,
	connectToSellerContent,
];

export default combineConnectors(connectors)(LandingFooter);
