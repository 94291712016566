import React from 'react';
import PropTypes from 'prop-types';
import './overlay.css';
import { useBooksellerPreferencesDataStore } from '../data/BooksellerPreferencesDataStore';
import { HeaderLayout } from '../Navigation/HeaderLayout';

const propTypes = {
	style: PropTypes.object,
	header: PropTypes.object,
	children: PropTypes.element,
};

const defaultProps = {
	style: {},
};

export function OverlayItem({ className, children }) {
	return (
		<div
			className={className}
			style={{
				position: 'absolute',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 2,
			}}>
			{children}
		</div>
	);
}

export default function Overlay({ style, header, children }) {
	const { getAppSettingAtKey } = useBooksellerPreferencesDataStore();
	const headerLayout = getAppSettingAtKey('headerLayout');

	return (
		<React.Fragment>
			<div
				className="treeline-overlay"
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'rgba(0,0,0,0.83)',
					zIndex: 2,
					...style,
				}}
			/>
			{header && (
				<OverlayItem className="treeline-overlay-header">
					<div className="page-container">
						<div
							className="overlay-header-container"
							style={
								headerLayout === HeaderLayout.overlap
									? {
											width: '100%',
											justifyContent: 'flex-end',
											paddingRight: '10px',
									  }
									: null
							}>
							<div style={{ paddingLeft: 5 }}>{header}</div>
						</div>
					</div>
				</OverlayItem>
			)}
			{children && (
				<OverlayItem className="treeline-overlay-child">
					{children}
				</OverlayItem>
			)}
		</React.Fragment>
	);
}

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
