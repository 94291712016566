import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './accountAnchor.less';

AccountAnchor.propTypes = {
	label: PropTypes.object,
	count: PropTypes.number,
};

AccountAnchor.defaultProps = {
	count: 0,
};

export default function AccountAnchor({ label, count }) {
	return (
		<div className="account-anchor-container clickable">
			{label}
			<div className="count-container">
				{count}
				<Icon type="right" />
			</div>
		</div>
	);
}
