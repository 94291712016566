import React from 'react';
import PropTypes from 'prop-types';
import { Button, InputNumber } from 'antd';
import UpdateConfirmation from '../UI/UpdateConfirmation';
import { connectToMessageFrequencyPreferences } from '../data/MessageFrequencyDataStore';
import { connectToPreviewMode } from '../../data/CustomerIDStore';
import combineConnectors from '../../data/combineConnectors';
import withIsMobile from '../../hoc/withIsMobile';
import { Translate } from 'react-localize-redux';
import { notification } from 'antd';

class EmailFrequencyService extends React.Component {
	static propTypes = {
		isMobile: PropTypes.func,
		previewMode: PropTypes.object,
		messageFrequencyDataStore: PropTypes.object,
	};

	state = {
		isLoading: false,
		didSaveNewFrequency: false,
		numberOfEmailsPrefence: 0,
	};

	render() {
		const isMobile = this.props.isMobile();
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div className={this.getRootClassNames()}>
							{this.renderNumberInput()}
							<div style={{ display: 'flex' }}>
								<Button
									style={this.getButtonStyle()}
									type="primary"
									size="small"
									loading={this.state.isLoading}
									onClick={() =>
										this.saveNewPreference(translate)
									}>
									<Translate id="save" />
								</Button>
								<div
									style={
										isMobile ? { marginTop: '9px' } : null
									}>
									{this.state.didSaveNewFrequency && (
										<UpdateConfirmation />
									)}
								</div>
							</div>
						</div>
					);
				}}
			</Translate>
		);
	}

	componentDidMount() {
		this.setInitialNumberOfEmailsFrequencyPreference();
	}

	getRootClassNames() {
		const baseName = 'reduce-frequency';
		if (this.props.isMobile()) {
			return baseName;
		}
		return `flex-align-center ${baseName}`;
	}

	getButtonStyle() {
		if (this.props.isMobile()) {
			return { marginTop: '0.5rem' };
		}
		return { marginLeft: '1rem' };
	}

	setInitialNumberOfEmailsFrequencyPreference() {
		const { messageFrequencyDataStore } = this.props;
		const preference = messageFrequencyDataStore.getPreference();
		this.setState({ numberOfEmailsPrefence: preference });
	}

	saveNewPreference = translate => {
		if (this.props.previewMode.isActive()) {
			return this.props.previewMode.notify();
		}

		const { numberOfEmailsPrefence: pref } = this.state;
		const { messageFrequencyDataStore } = this.props;

		this.setState({ isLoading: true });

		messageFrequencyDataStore
			.savePreference(pref)
			.then(() => {
				this.setState({ isLoading: false, didSaveNewFrequency: true });
			})
			.then(() => this.getNotification(translate));
	};

	getNotification = translate => {
		notification.success({
			message: translate('preference_saved_thank_you'),
		});
	};

	renderNumberInput() {
		const { numberOfEmailsPrefence: count } = this.state;
		return (
			<Translate>
				{({ translate }) => {
					return (
						<div>
							<Translate id="i_only_want_to_receive_1_email_every" />{' '}
							<InputNumber
								value={count}
								min={1}
								max={12}
								placeholder="2"
								onChange={this.updatePreferenceNumber}
							/>
							<span>
								{' '}
								{count === 1 ? (
									this.getSingularLabel(
										translate,
									).toLowerCase()
								) : (
									<Translate id="weeks" />
								)}
								.
							</span>
						</div>
					);
				}}
			</Translate>
		);
	}

	getSingularLabel = translate => {
		return translate('week');
	};

	updatePreferenceNumber = numberOfEmailsPrefence => {
		if (numberOfEmailsPrefence > 0) {
			this.setState({ numberOfEmailsPrefence });
		}
	};
}

const connectors = [connectToMessageFrequencyPreferences, connectToPreviewMode];

export default combineConnectors(connectors)(
	withIsMobile(EmailFrequencyService),
);
