import React from 'react';
import PropTypes from 'prop-types';
import TitleScroller from './TitleScroller';
import withIsMobile from '../hoc/withIsMobile';
import LaneContainer from '../UI/LaneContainer';
import AllAvailableTitles from './AllAvailableTitles';
import { SeeAllAnchor } from '../UI/LaneContainer';
import TitleSkeletons from './TitleSkeletons';
import StaffPicksDataStore from '../StaffPicks/data/StaffPicksDataStore';
import ShowingTitlesCount from './ShowingTitlesCount';
import TitleJacketImageWithIconBadges from '../UI/TitleJacketImageWithIconBadges';

class TitleLanes extends React.Component {
	static propTypes = {
		titleLanesDataStore: PropTypes.object.isRequired,
		renderOneTitle: PropTypes.func, // optional
		shouldRenderLaneTitlesWithIcons: PropTypes.bool, // optional
		laneConfigs: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				icon: PropTypes.string,
				subtitle: PropTypes.oneOfType([
					PropTypes.object,
					PropTypes.string,
				]),
				// 'header'
				title: PropTypes.oneOfType([
					PropTypes.element,
					PropTypes.string,
				]),
			}),
		),
		renderIcon: PropTypes.func,
		// show all controls
		doRenderSeeAllAnchor: PropTypes.bool,
		// Render something in the bottom right corner of the lane
		extraBottom: PropTypes.element,
		// mapped to props
		isMobile: PropTypes.func,
		isRenderedInDrawer: PropTypes.bool,
	};

	static defaultProps = {
		laneConfigs: [],
		doRenderSeeAllAnchor: true,
		shouldRenderLaneTitlesWithIcons: false,
		extraBottom: null,
		isRenderedInDrawer: false,
	};

	render() {
		return (
			<div className="title-lanes">
				{this.getLaneConfigsToRender().map(laneConfig => {
					return (
						<React.Fragment key={laneConfig.key}>
							{this.renderOneLane(laneConfig)}
						</React.Fragment>
					);
				})}
			</div>
		);
	}

	getLaneConfigsToRender() {
		const { titleLanesDataStore } = this.props;
		if (titleLanesDataStore.state.isLoading) {
			return titleLanesDataStore.getLaneConfigs();
		}
		return titleLanesDataStore.getLaneConfigs().filter(laneConfig => {
			return titleLanesDataStore.hasTitlesInLane(laneConfig.key);
		});
	}

	renderOneLane = laneConfig => {
		const { key, title, subtitle } = laneConfig;
		const { renderIcon } = this.props;
		const header =
			typeof renderIcon === 'function' ? this.renderHeader(title) : title;
		return (
			<LaneContainer
				majorHeader={header}
				subHeader={subtitle}
				extra={this.renderExtra(key)}>
				{this.renderLaneTitleScroller(key)}
			</LaneContainer>
		);
	};

	renderHeader = title => {
		const { renderIcon } = this.props;
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ marginRight: 5 }}>{title}</div>
				{renderIcon()}
			</div>
		);
	};

	renderExtra = laneKey => {
		const key = laneKey;
		const { isMobile } = this.props;
		return !isMobile() && this.getShowingTitlesTotal(key);
	};

	getShowingTitlesTotal(laneKey) {
		const { titleLanesDataStore } = this.props;
		if (!titleLanesDataStore.state.isLoading) {
			const titles = titleLanesDataStore.getTitles(laneKey);
			const numberOfTitles = titles.length;
			return <ShowingTitlesCount numberOfTitles={numberOfTitles} />;
		}
	}

	renderLaneTitleScroller(laneKey) {
		const { titleLanesDataStore, isRenderedInDrawer } = this.props;
		if (titleLanesDataStore.state.isLoading) {
			return (
				<div style={{ display: 'flex' }}>
					<TitleSkeletons isRenderedInDrawer={isRenderedInDrawer} />
				</div>
			);
		}

		const lane = titleLanesDataStore.getLane(laneKey);
		const laneTitles = titleLanesDataStore.getTitles(laneKey);
		const total = laneTitles.length;

		return (
			<React.Fragment>
				<TitleScroller
					titles={laneTitles}
					isLoading={titleLanesDataStore.state.isLoading}
					canFetchMoreTitles={lane.totalAvailableCount > total}
					fetchMoreTitles={() => {
						return titleLanesDataStore.fetchMoreTitles(laneKey);
					}}
					renderOneTitle={(title, idx) => {
						return this.renderOneTitle(title, laneKey, idx);
					}}
				/>
				{this.canRenderSeeAllAnchor(laneKey) && (
					<div className={this.getBottomContainerClassNames()}>
						<SeeAllAnchor
							renderAllEntities={() => {
								return this.renderAllAvailableTitlesInLane(
									laneKey,
									false,
								);
							}}
						/>
						{this.props.extraBottom}
					</div>
				)}
			</React.Fragment>
		);
	}

	getBottomContainerClassNames = () => {
		const base = 'lane-container-bottom';
		const { extraBottom } = this.props;
		if (extraBottom) return `${base} flex-default`;
		return base;
	};

	canRenderSeeAllAnchor(laneKey) {
		const { titleLanesDataStore, doRenderSeeAllAnchor } = this.props;

		const {
			getLaneHeader,
			getLaneSubtitle,
			fetchAllAvailableTitles,
		} = titleLanesDataStore;

		const isFunc = x => typeof x === 'function';

		const hasRequiredFuncs =
			isFunc(getLaneHeader) &&
			isFunc(getLaneSubtitle) &&
			isFunc(fetchAllAvailableTitles);

		const titles = titleLanesDataStore.getTitles(laneKey);
		const hasRequiredTitleCt = titles.length > 2;

		return doRenderSeeAllAnchor && hasRequiredFuncs && hasRequiredTitleCt;
	}

	renderAllAvailableTitlesInLane = (laneKey, isInTitleLane) => {
		const { titleLanesDataStore } = this.props;
		const header = titleLanesDataStore.getLaneHeader(laneKey);
		const subtitle = titleLanesDataStore.getLaneSubtitle(laneKey);
		return (
			<StaffPicksDataStore>
				<AllAvailableTitles
					header={header}
					subtitle={subtitle}
					renderOneTitle={
						isInTitleLane
							? this.renderOneTitle
							: this.renderOneTitleInDrawer
					}
					fetchAllAvailableTitles={() => {
						return titleLanesDataStore.fetchAllAvailableTitles(
							laneKey,
						);
					}}
				/>
			</StaffPicksDataStore>
		);
	};

	renderOneTitle = (title, laneKeyName, idx) => {
		if (typeof this.props.renderOneTitle === 'function') {
			const { renderOneTitle, titleLanesDataStore } = this.props;
			return renderOneTitle(title, laneKeyName, titleLanesDataStore, idx);
		}
		return (
			<TitleJacketImageWithIconBadges
				isInTitleLane={true}
				title={title}
			/>
		);
	};

	renderOneTitleInDrawer = (title, laneKeyName, idx) => {
		return <TitleJacketImageWithIconBadges title={title} />;
	};
}

export default withIsMobile(TitleLanes);
