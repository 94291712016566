import React from 'react';
import PropTypes from 'prop-types';
import withIsMobile from '../../hoc/withIsMobile';
import CollectionTitleSkeletons from './CollectionTitleSkeletons';

const propTypes = {
	isMobile: PropTypes.func,
	isLoading: PropTypes.bool,
};

function CollectionTitlesWrapper(props) {
	const isMobile = props.isMobile();
	return (
		<div
			className="flex"
			style={{
				flexWrap: 'wrap',
				justifyContent: isMobile ? 'center' : 'space-between',
			}}>
			{props.isLoading ? <CollectionTitleSkeletons /> : props.children}
		</div>
	);
}

CollectionTitlesWrapper.propTypes = propTypes;

export default withIsMobile(CollectionTitlesWrapper);
